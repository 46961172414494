import { Component, OnInit } from '@angular/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { ParamService } from '@monsido/core/param/param.service';
import { UrlService } from '@uirouter/core';
import { BackendAdminDomainsRepo } from '../../../services/api/backend_admin/backend-admin-domains-repo';
import { HttpHeaders } from '@angular/common/http';
import { Domain } from '@monsido/modules/models/api/domain';
import { LayoutModule, MonPromptService, TableModule, TableSearchModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CommonModule } from '@angular/common';
import { BackendAdminDomainsModule } from '@monsido/pages/backend-admin/domains/domains.module';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { TranslateService } from 'app/services/translate/translate.service';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-backend-admin-domains',
    standalone: true,
    templateUrl: './backend-admin-domains.component.html',
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        TableSearchModule,
        TableModule,
        BackendAdminDomainsModule,
        ConfirmModule,
    ],
})
export class BackendAdminDomainsComponent extends BaseApiComponent implements OnInit {
    domains: Domain[] = [];
    selectedDomains: Domain[] = [];

    constructor (
        urlService: UrlService,
        private paramService: ParamService,
        private backendAdminDomainsRepo: BackendAdminDomainsRepo,
        private promptService: MonPromptService,
        private translateService: TranslateService,
    ) {
        super(urlService);
    }

    ngOnInit (): void {
        this.loadLocations();
    }

    async getPage (): Promise<void> {
        this.paramService.setParams({
            search: this.search,
            page_size: this.pageSize,
            page: this.page,
        });
        await this.getDomains();
    }

    async getDomains (): Promise<void> {
        const params = {
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        };
        this.loading = true;
        try {
            this.domains = await this.backendAdminDomainsRepo.getAll(params, new HttpHeaders({ noParseSearch: 'true' }));
            this.loading = false;
        } catch (res) {
            this.loading = res.status === -1 && res.xhrStatus === 'abort';
        }
    }

    async deleteDomains (): Promise<void> {
        const promises = this.selectedDomains.map(domain => {
            return this.backendAdminDomainsRepo.destroy(domain.customer_id, domain.id, {}, new HttpHeaders({ noGlobal: 'true' }));
        });
        try {
            await Promise.all(promises);
        } finally {
            this.promptService.alert(this.translateService.getString('Selected domains have been deleted'));
            setTimeout(() => {
                this.selectedDomains.length = 0;
                this.page = 1;
                this.getPage();
            }, 200);
        }
    }
}
