<div class="table-container-body">
    <ng-container *ngFor="let type of tableData">
        <h3 [id]="type.title + 'Title'">{{type.title}} {{'text' | translate:'Original/Spelling/Readability text'}}</h3>
        <table class="table table-hover" [attr.aria-labelledby]="type.title + 'Title'">
            <thead>
                <tr>
                    <th translate>Text</th>
                    <th translate>Locale</th>
                    <th translate>Default locale</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let language of type.texts">
                    <tr>
                        <td>{{language.text}}</td>
                        <td>{{language.locale}}</td>
                        <td>{{language.default_locale}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
</div>
