<mon-spinner-snake *ngIf="loading"></mon-spinner-snake>
<ng-container *ngIf="!loading && domain">
    <mon-page-header
        [monIcon]="['DOMAINS', 'ICON']"
        header="{{ 'Details for' | translate }} {{ domain.title }}"
    >
    </mon-page-header>
    <div class="clearfix">
        <button
            class="btn btn-secondary pull-right outer-b-small"
            (click)="onEditDomain()"
        >
            {{ "Edit domain" | translate }}
        </button>
    </div>
    <mon-panel [header]="'Base info' | translate">

            <div class="card-body" slot="mon-panel-body">
                <div class="font-size-1-1">
                    <div class="row">
                        <div
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500"
                        >
                            {{ "Url" | translate }}:
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            <a href="{{ domain.url }}" target="_blank" aria-describedby="linkOpensInNewTab">{{ domain.url }}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500"
                        >
                            {{ "Customer" | translate }}:
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            <a
                            uiSref="{{isBackendAdmin ? 'base.backend_admin.customers.show' : 'base.admin.customers.show'}}"
                            [uiParams]="{customer_id: domain.customer_id}"
                            >{{ domain.customer.name }}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500"
                        >
                            {{ "Cms" | translate }}:
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            {{ domain.cms | monUnderscoreless }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                            {{'Active status' | translate}}:
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            {{ domain.active ? 'Active' : 'Inactive' | translate }}
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500"
                            translate
                        >
                            Notes:
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            <span *ngIf="domain.notes">{{
                                domain.notes
                            }}</span>
                            <span *ngIf="!domain.notes" translate>No notes</span>
                        </div>
                    </div>
                </div>
            </div>

    </mon-panel>

    <mon-panel [header]="'Plan traits' | translate">
            <div class="card-body" slot="mon-panel-body">
                <div class="font-size-1-1">
                    <ng-container *ngFor="let planTrait of domain.customer.plan_traits | keyvalue">
                        <div
                            class="row"
                            *ngIf="hideFeatures.indexOf(planTrait.key) < 0"
                        >
                            <div
                                class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize"
                            >
                                {{ planTrait.key | monUnderscoreless }}
                            </div>
                            <div class="col-sm-26 col-lg-36 text-grey">
                                <span *ngIf="![true, false].includes(planTrait.value)">{{ planTrait.value }}</span>
                                <span
                                    [ngClass]="planTrait.value === true ? activePlanIcon: inactivePlanIcon"
                                    *ngIf="[true, false].includes(planTrait.value)"
                                ></span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

    </mon-panel>

    <mon-panel [header]="'Features' | translate">
            <div class="card-body" slot="mon-panel-body">
                <div class="font-size-1-1">
                    <div class="row" *ngFor="let feature of domain.features | keyvalue">
                        <div
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize"
                        >
                            {{ feature.key | monUnderscoreless }}
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            <span *ngIf="![true, false].includes(feature.value)">{{ feature.value }}</span>
                            <span
                                [ngClass]="feature.value === true ? activeFeatureIcon: inactiveFeatureIcon"
                                *ngIf="[true, false].includes(feature.value)"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>

    </mon-panel>

    <mon-panel [header]="'Scan' | translate">
            <div class="card-body" slot="mon-panel-body">
                <div class="font-size-1-1">
                    <div class="row" *ngFor="let scan of domain.scan | keyvalue">
                        <div
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize"
                        >
                            {{ scan.key | monUnderscoreless }}
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            <span *ngIf="![true, false].includes(scan.value)">{{ scan.value }}</span>
                            <span
                                [ngClass]="scan.value === true ? activeScanIcon : inactiveScanIcon"
                                *ngIf="[true, false].includes(scan.value)"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>

    </mon-panel>

    <mon-panel [header]="'Tech info' | translate">



            <div class="card-body" slot="mon-panel-body">
                <div class="font-size-1-1">
                    <div class="row">
                        <p
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500"
                        >
                            {{ "path constraints" | translate }}:
                        </p>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            <div *ngFor="let constraint of domain.path_constraints">
                                <p>{{ constraint.constraint }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-22 col-lg-12 text-right text-black font-weight-500"
                        >
                            {{ "Link excludes" | translate }}:
                        </div>
                        <div class="col-sm-26 col-lg-36 text-grey">
                            <span *ngFor="let exclude of domain.link_excludes">
                                <p>{{ exclude.regex }}</p>
                            </span>
                            <br />
                        </div>
                    </div>
                </div>
            </div>

    </mon-panel>

    <mon-panel *ngIf="crawls.length > 0" [header]="'Crawls information' | translate">
            <div class="card-body" slot="mon-panel-body">
                <div class="row">
                    <div class="col-xs-48">
                        <mon-line-chart
                            [height]="300"
                            [data]="lineChartData"
                            [labels]="lineChartLabels"
                            [series]="lineChartSeries"
                            [colors]="lineChartColors"
                            [override]="lineChartOverride"
                            [options]="lineChartOptions"
                        ></mon-line-chart>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-48 col-lg-48 col-sm-48 font-size-1-1">
                        <mon-table-container [collection]="crawls">
                            <div class="table-container-body">
                                <table class="table table-hover table-fixed">
                                    <thead>
                                        <tr>
                                            <th translate>Type</th>
                                            <th translate>Queued at</th>
                                            <th translate>Completed at</th>
                                            <th translate>Run time</th>
                                            <th translate>Page count</th>
                                            <th translate>Document count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let crawl of crawls"
                                        >
                                            <td>{{ crawl.type }}</td>
                                            <td
                                                ><mon-user-date
                                                    [twoLines]="true"
                                                    [date]="crawl.queued_at"
                                                ></mon-user-date
                                            ></td>
                                            <td
                                                ><mon-user-date
                                                    [twoLines]="true"
                                                    [date]="crawl.post_processing_done_at"
                                                ></mon-user-date
                                            ></td>
                                            <td>{{ crawl.run_time }}</td>
                                            <td>{{
                                                crawl.page_count | number:"0.0"
                                            }}</td>
                                            <td>{{
                                                crawl.documents_count | number:"0.0"
                                            }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </mon-table-container>
                    </div>
                </div>
            </div>
    </mon-panel>
</ng-container>
