import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { Domain } from '@monsido/modules/models/api/domain';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { LayoutModule, TableModule, TableSearchModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { UrlService } from '@uirouter/core';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DomainsTableComponent } from 'app/pages/admin/domains/domains-table/domains-table.component';
import { MonIconsPipe } from 'app/filters/mon-icons.pipe';
import { TranslateModule } from 'app/modules/translate.module';

export type PageEventParamsType = {
    page: number;
    page_size: number;
    search?: string;
    reloadAll?: boolean;
}

@Component({
    selector: 'mon-domains-tab',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        NgbTooltipModule,
        TableSearchModule,
        TableModule,
        DomainsTableComponent,
        MonIconsPipe,
        TranslateModule,
    ],
    templateUrl: './domains-tab.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainsTabComponent extends BaseApiComponent implements OnInit {

    @Input() customer: CustomerInterface;
    @Input() search: string;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() domains: CollectionInterface<Domain> = [];
    @Input() loading: boolean = false;

    @Output() getPageEvent: EventEmitter<PageEventParamsType> = new EventEmitter();
    @Output() createDomain: EventEmitter<void> = new EventEmitter();

    constructor ($location: UrlService) {
        super($location);
    }

    ngOnInit (): void {
        this.getPage();
    }

    getPage (reloadAll: boolean = false): void {
        this.getPageEvent.emit({
            reloadAll,
            page: this.page,
            page_size: this.pageSize,
        });
    }

    onSearchChanged (searchText: string): void {
        this.getPageEvent.emit({
            page: this.page,
            page_size: this.pageSize,
            search: searchText,
        });
    }
}
