<mon-page-header [monIcon]="['SEARCH', 'ICON']"
                 header="{{'Request checker' | translate}}">
    <div class="text-right">
    </div>
</mon-page-header>

<form name="form"  #form="ngForm" (ngSubmit)="search()">
    <div class="row">
        <div class="col-48 col-md-48">
            <mon-form-field-input [(ngModel)]="url"
                                  monLabel="{{ 'URL' | translate }}"
                                  monPlaceholder="{{ 'URL' | translate }}"
                                  [type]="'text'"
                                  #urlModel="ngModel"
                                  [name]="'url'"
                                  data-test="search-input"
                                  required>
            </mon-form-field-input>
            <mon-form-field-errors
                [showError]="urlModel.control.touched && urlModel.control.invalid"
                [errors]="urlModel.control.errors">
            </mon-form-field-errors>
        </div>
    </div>

    <div class="row">
        <div class="col-48 col-md-48">
            <mon-form-field-checkbox [(model)]="enableJavascript"
                                     monId="enable_javascript"
                                     (change)="onEnableJSChange()"
                                     monLabel="{{ 'Enable JavaScript' | translate}}"
                                     [monName]="'enableJavascript'"
                                     data-test="checkbox">
            </mon-form-field-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-48 col-md-48">
            <mon-form-field-select [(model)]="accessibility"
                                   [monDisabled]="!enableJavascript"
                                   monLabel="{{ 'Accessibility' | translate}}"
                                   [monOptions]="accessibilityOptions"
                                   [monReturnValue]="'value'"
                                   [monName]="'accessibility'"
                                   data-test="accessibility-dropdown">
            </mon-form-field-select>
        </div>
    </div>
    <div class="row">
        <div class="col-48 col-md-48">
            <mon-form-field-select [(model)]="readabilityTest"
                                   monLabel="{{ 'Readability' | translate}}"
                                   [monOptions]="readabilityOptions"
                                   [monReturnValue]="'value'"
                                   [monName]="'readability'"
                                   [monAllowClear]="true">
            </mon-form-field-select>
        </div>
    </div>

    <div class="row">
        <div class="col-48 col-md-48">
            <mon-form-field-select [(model)]="loginForm.type"
                                   monLabel="{{ 'Select login type' | translate}}"
                                   [monOptions]="loginOptions"
                                   [monReturnValue]="'value'"
                                   [monName]="'loginType'">
            </mon-form-field-select>
        </div>
    </div>
    <div class="row" *ngIf="loginForm.type !== 'none'">
        <mon-form-domain-scan-login-types-basic-auth class="px-0" *ngIf="loginForm.type === 'basic_auth'" [monLogin]="loginForm" [monForm]="form" [monUrl]="url" [monRequestChecker]="true"></mon-form-domain-scan-login-types-basic-auth>
        <mon-form-domain-scan-login-types-office365 class="px-0" *ngIf="loginForm.type === 'office365'" [monLogin]="loginForm" [monForm]="form" [monRequestChecker]="true"></mon-form-domain-scan-login-types-office365>
        <mon-form-domain-scan-login-types-custom-multistep class="px-3" *ngIf="loginForm.type === 'custom_multistep'" [monLogin]="loginForm" [monForm]="form" [monRequestChecker]="true"></mon-form-domain-scan-login-types-custom-multistep>
        <mon-form-domain-scan-login-types-form class="px-0" *ngIf="loginForm.type === 'form'" [monLogin]="loginForm" [monForm]="form" [monRequestChecker]="true"></mon-form-domain-scan-login-types-form>
    </div>

    <div class="row sub-heading">
        <div class="col-sm-8">
            <label translate>Source code excludes</label>
        </div>
        <div class="col-sm-4">
            <button type="button" class="btn btn-border-radius btn-secondary" (click)="addSourceCodeExclude()">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
            </button>
        </div>
    </div>
    <div class="row" *ngFor="let exclude of excludes; index as i">
        <div class="col-48 col-md-48">
            <div class="row">
                <div class="col-sm-8 text-right">
                    <label translate class="col-form-label" [attr.for]="'sourceCodeSelectorInput'+i">CSS Target Selector*</label>
                </div>
                <div class="col-sm-20 col-lg-26">
                    <mon-form-field-input
                        [monId]="'sourceCodeSelectorInput'+i"
                        [type]="'text'"
                        [(ngModel)]="excludes[i].selector"
                        [name]="'sourceCodeSelector'+i"
                        monPlaceholder="CSS Selector"
                        #selectorModel="ngModel"
                        required>
                    </mon-form-field-input>
                    <mon-form-field-errors
                        [showError]="selectorModel.control.touched && selectorModel.control.invalid"
                        [errors]="selectorModel.control.errors"
                        [offset]="0">
                    </mon-form-field-errors>
                </div>
                <mon-form-field-select class="col-sm-16 col-lg-12"
                    [(model)]="excludes[i].module"
                    monLabel="{{'Module' | translate}}"
                    [monOptions]="sourceCodeExcludeOptions"
                    [monReturnValue]="'value'"
                    [monName]="'sourceCodeModule'+i">
                </mon-form-field-select>
                <div class="col-sm-4 col-lg-2">
                    <button class="btn btn-border-radius btn-secondary pull-right" type="button" (click)="removeSourceCodeExclude(i)">
                        <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                    </button> 
                </div>
            </div>
        </div>
    </div> 

    <div class="row">
        <div class="col-sm-8 text-right pt-2">
            <label translate>Link Excludes</label>
        </div>
        <div class="col-sm-4">
            <button type="button" class="btn btn-border-radius btn-secondary mb-1" (click)="addScanOption(linkExcludes, { regex: '' })">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
            </button>
        </div>
    </div>

    <div class="row" *ngFor="let internalUrl of linkExcludes; index as i">
        <div class="col-48 col-md-48 pt-1">
            <div class="row">
                <div class="col-sm-offset-8 col-sm-10 col-md-8 col-lg-6">
                    <label translate class="col-form-label" [attr.for]="'linkExcludeRegexInput'+i">Exclude pattern*</label>
                </div>
                <div class="col-sm-26 col-md-28 col-lg-30">
                    <mon-form-field-input
                        [monId]="'linkExcludeRegexInput'+i"
                        [type]="'text'"
                        [(ngModel)]="linkExcludes[i].regex"
                        [name]="'linkExcludeRegex'+i"
                        monPlaceholder="{{ 'Exclude pattern' | translate }}"
                        #selectorModel="ngModel"
                        required>
                    </mon-form-field-input>
                    <mon-form-field-errors
                        [showError]="selectorModel.control.touched && selectorModel.control.invalid"
                        [errors]="selectorModel.control.errors"
                        [offset]="0">
                    </mon-form-field-errors>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <button class="btn btn-border-radius btn-secondary pull-right" type="button" (click)="removeScanOption(linkExcludes, i)">
                        <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-8 text-right pt-2">
            <label translate>Internal URLs</label>
        </div>
        <div class="col-sm-4">
            <button type="button" class="btn btn-border-radius btn-secondary mb-1" (click)="addScanOption(internalUrls, { operator: '', value: '' })">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
            </button>
        </div>
    </div>

    <div class="row" *ngFor="let internalUrl of internalUrls; index as i">
        <div class="col-48 col-md-48 pt-1">
            <div class="row">
                <div class="col-sm-6 col-sm-offset-8 col-md-8 col-lg-6">
                    <label class="col-form-label" [attr.for]="'internalUrlSelect'+i" translate>Operator*</label>
                </div>
                <mon-form-field-select
                    class="col-sm-12 col-md-10 col-lg-8"
                    [(model)]="internalUrls[i].operator"
                    [monOptions]="internalUrlOptions"
                    [monReturnValue]="'value'"
                    monPlaceholder="{{ 'Select operator' | translate }}"
                    [monName]="'Operator'+i"
                    [monRequired]="true">
                </mon-form-field-select>
                <div class="col-sm-4 col-md-4 col-lg-2">
                    <label class="col-form-label" [attr.for]="'internalUrlSelect'+i" translate>URL*</label>
                </div>
                <div class="col-sm-14 col-md-14 col-lg-20">
                    <mon-form-field-input
                        [monId]="'internalUrlSelect'+i"
                        [type]="'text'"
                        [(ngModel)]="internalUrls[i].value"
                        [name]="'internalUrlSelect'+i"
                        monPlaceholder="{{ 'URL' | translate }}"
                        #selectorModel="ngModel"
                        required>
                    </mon-form-field-input>
                    <mon-form-field-errors
                        [showError]="selectorModel.control.touched && selectorModel.control.invalid"
                        [errors]="selectorModel.control.errors"
                        [offset]="0">
                    </mon-form-field-errors>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <button class="btn btn-border-radius btn-secondary pull-right" type="button" (click)="removeScanOption(internalUrls, i)">
                        <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-48 col-md-48">
            <button class="btn btn-secondary pull-right" [disabled]="searching || form.invalid" type="submit">
                <span [hidden]="searching" translate>Start</span>
                <mon-icon [hidden]="!searching"
                          [spin]="searching"
                          [icon]="['SPINNER', 'ICON']">
                </mon-icon>
            </button>
        </div>
    </div>
</form>

<div class="row">
    <div class="col-48">
        <mon-tabs
                maxOptionsDisplay="10"
                [status]="activeTab"
                [options]="tabs"
                [showSearch]="false"
                (onStatus)="onTabChange($event)"
        ></mon-tabs>
    </div>
</div>

<mon-panel [showHeader]="false">
    <div class="card-body" slot="mon-panel-body">
        <div class="row">
            <div class="col-xs-48" *ngIf="scan">
                <mon-request-checker-base-info-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.base.value" data-test="base-info-tab">
                </mon-request-checker-base-info-component>

                <mon-request-checker-redirect-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.redirect.value" data-test="redirect-tab">
                </mon-request-checker-redirect-component>

                <mon-request-checker-advance-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.advance.value">
                </mon-request-checker-advance-component>

                <mon-request-checker-html-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.html.value">
                </mon-request-checker-html-component>

                <mon-request-checker-article-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.article.value">
                </mon-request-checker-article-component>

                <mon-request-checker-response-headers-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.header.value">
                </mon-request-checker-response-headers-component>

                <mon-request-checker-cookies-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.cookies.value">
                </mon-request-checker-cookies-component>

                <mon-request-checker-links-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.links.value">
                </mon-request-checker-links-component>

                <mon-request-checker-accessibility-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.accessibility.value">
                </mon-request-checker-accessibility-component>

                <mon-request-checker-texts-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.texts.value">
                </mon-request-checker-texts-component>

                <mon-request-checker-readability-component [url]="url" [scan]="scan" *ngIf="activeTab === checkerTabs.readability.value">
                </mon-request-checker-readability-component>
            </div>
        </div>
    </div>
</mon-panel>
