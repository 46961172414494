import { Ng2StateDeclaration } from '@uirouter/angular';
import { HeatmapsComponent } from '@monsido/pages/backend-admin/support/heatmaps/heatmaps.component';

export const HEATMAPS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.support.heatmaps',
        url: '/heatmaps',
        views: {
            '^.^.^.$default': {
                component: HeatmapsComponent,
            },
        },
    },
];
