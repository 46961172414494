import { Injectable } from '@angular/core';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { Domain } from '@monsido/modules/models/api/interfaces/crawl-queue.interface';
import { BackendCrawlQueueRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-crawl-queue.repo';
import { FormBackAdminCrawlDetailsComponent } from '@monsido/forms/backend_admin/crawl-details/crawl-details.component';
import { CrawlQueueCommonService } from 'app/common/services/crawl-queue-common/crawl-queue-common.service';
import { TransitionPromise } from '@uirouter/core';

@Injectable()
export class BackendAdminCrawlQueueService {
    search: string;
    crawls: CrawlQueue[] = [];
    timeout: ReturnType<typeof setTimeout>;

    constructor (private backendCrawlQueueRepo: BackendCrawlQueueRepo, private crawlQueueCommonService: CrawlQueueCommonService) {}

    goToDomain (domain: Domain): TransitionPromise {
        return this.crawlQueueCommonService.goToDomain(domain, 'base.backend_admin.customers.info.domain');
    }

    getCrawls (crawls: CrawlQueue[], search: string): CrawlQueue[] {
        return this.crawlQueueCommonService.getCrawls(crawls, search);
    }

    destroyTimer (): void {
        clearTimeout(this.timeout);
    }

    getCrawlQueue (domainId: number): Promise<void> {
        return this.backendCrawlQueueRepo.get(domainId);
    }

    getAllCrawlQueue (params: Record<string, string | number>): Promise<CrawlQueue[]> {
        return this.backendCrawlQueueRepo.getAll(params);
    }

    stopCrawl (domainId: number): Promise<void> {
        return this.backendCrawlQueueRepo.stopCrawl(domainId);
    }

    rejectCrawl (domainId: number): Promise<void> {
        return this.backendCrawlQueueRepo.stopCrawl(domainId);
    }

    releaseCrawl (domainId: number): Promise<void> {
        return this.backendCrawlQueueRepo.stopCrawl(domainId);
    }

    openDialog (crawl: CrawlQueue, cb: (...args: unknown[]) => void): void {
        this.crawlQueueCommonService.openDialog(crawl, cb, {
            component: FormBackAdminCrawlDetailsComponent,
            classes: 'mon-dialog-size-md',
        });
    }
}
