import { Injectable } from '@angular/core';
import { ApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/api-client';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { AccessibilitySourceCodeExclude } from '@monsido/modules/models/api/interfaces/accessibility-source-code-exclude.interface';

@Injectable()
export class AccessibilitySourceCodeExcludeRepo {
    constructor (private client: ApiClient) {}

    get (excludeId: string, params?: PlainHttpParams, headers?: HttpHeaders): Promise<AccessibilitySourceCodeExclude> {
        return this.client.getPromise('accessibility/source_code_excludes/' + excludeId, createDefaultHttpParams(params, headers));
    }

    getAll (params?: PlainHttpParams, headers?: HttpHeaders): Promise<AccessibilitySourceCodeExclude[]> {
        params = params || {};
        return this.client.getPromise('accessibility/source_code_excludes', createDefaultHttpParams(params, headers));
    }

    create (params: AccessibilitySourceCodeExclude): Promise<void> {
        return this.client.postPromise('accessibility/source_code_excludes', params);
    }

    update (sourceExclude: AccessibilitySourceCodeExclude): Promise<void> {
        return this.client.patchPromise('accessibility/source_code_excludes/' + sourceExclude.id, sourceExclude);
    }

    destroy (excludeId: string): Promise<void> {
        return this.client.deletePromise('accessibility/source_code_excludes/' + excludeId);
    }
}
