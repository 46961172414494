import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { Domain } from '@monsido/modules/models/api/domain';
import { cloneDeep } from 'lodash';
import { DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'mon-form-domain-users',
    templateUrl: 'user.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainUsersComponent implements OnChanges {
    @Input() monDomain: Domain;
    @Input() monUsers: User[];

    @ViewChild('domainUserFieldsForm') public form: NgForm;

    domainUsers: DomainUsersEntity[] = [];

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.monUsers && changes.monUsers.currentValue) {
            const users = changes.monUsers.currentValue;
            this.domainUsers = cloneDeep(this.monDomain.domain_users);

            usersLoop: for (let i = 0; i < users.length; i++) {
                for (let j = 0; j < this.monDomain.domain_users.length; j++) {
                    if (users[i].id === this.domainUsers[j].user_id) {
                        this.domainUsers[j].user = cloneDeep(users[i]);
                        continue usersLoop;
                    }
                }

                const domainUser: DomainUsersEntity = {
                    domain_id: this.monDomain.id,
                    user_id: users[i].id,
                    visible: false,
                    send_report: false,
                    user: cloneDeep(users[i]),
                };

                this.domainUsers.push(domainUser);
                this.monDomain.domain_users.push(domainUser);
            }
        }
    }
}
