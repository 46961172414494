<table class="table table-hover table-fixed">
    <caption class="sr-only">{{'Domains table' | translate}}</caption>
    <thead>
    <tr>
        <th class="col-sm-2 col-md-2 col-lg-2" translate>ID</th>
        <th translate>Domain title / URL</th>
        <th class="col-sm-5 col-md-5 col-lg-4 text-center" translate>Enabled options</th>
        <th class="col-sm-2 col-md-1 col-lg-1 text-center"></th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right" translate>Users</th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right">
            <span *ngIf="customer">
                {{'Crawled pages' | translate}} ({{ customer.html_pages_count }})
            </span>
            <span *ngIf="!customer">
                {{'Crawled pages' | translate}}
            </span>
        </th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right"
            *ngIf="customer">
            {{'Crawled documents' | translate}} ({{ customer.documents_count }})
        </th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right" *ngIf="customer">
            <span translate>Pages left</span>
            <span class="fa-stack fa-sm ms-1"
                  aria-hidden="true"
                  ngbTooltip="{{'Indicates the remaining no. of pages that are allowed to be scanned' | translate}}">
              <span class="fas fa-circle fa-stack-2x"></span>
              <span class="fas fa-question fa-stack-1x fa-inverse"></span>
            </span>
        </th>
        <th class="text-right col-sm-5 col-md-4 col-lg-4"
            *ngIf="customer">
            {{'Total Crawled pages and documents' | translate}} ({{ customer.html_pages_count + customer.documents_count }})
        </th>
        <th class="col-sm-1 col-md-1 col-lg-1"></th>
        <th class="col-sm-8 col-md-7 col-lg-4 text-right" translate>Last scan</th>
        <th class="col-sm-12 col-md-12 col-lg-10" *ngIf="isAdmin" translate>Partner</th>
        <th class="col-sm-12 col-md-12 col-lg-10" *ngIf="showCustomer" translate>Customer</th>
        <th class="text-right col-sm-5 col-md-5 col-lg-4"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let domain of domains" class="cursor-pointer">
        <td class="text-left" (click)="goToDomain(domain)">
            <div class="outer-r-small">{{domain.id}}</div>
            <div class="text-v-middle" [ngClass]="['DOMAINS', 'NOTES'] | monIcons" *ngIf="domain.notes" ngbTooltip="{{domain.notes}}"></div>
        </td>
        <td (click)="goToDomain(domain)">
            <div class="d-flex flex-column text-ellipsis">
                <div class="text-ellipsis inner-b-smurf">
                    <div class="inline-block" (click)="$event.stopPropagation()">
                        <a target="_blank" aria-describedby="linkOpensInNewTab" [attr.href]="domain.url">{{domain.title}}</a>
                    </div>
                </div>
                <div class="text-default text-ellipsis">
                    <div class="inline-block" (click)="$event.stopPropagation()">
                        <a target="_blank" aria-describedby="linkOpensInNewTab" [attr.href]="domain.url" [ngClass]="['LINKS', 'EXTERNAL'] | monIcons"></a>&nbsp;<span class="text-dark-grey">{{domain.url}}</span>
                    </div>
                </div>
            </div>
        </td>
        <td class="text-v-middle text-center" (click)="goToDomain(domain)">
            <span *ngIf="domainHasAccessibility(domain)"
                  class="text-grey outer-r-smurf me-2"
                  [ngClass]="['DOMAINS', 'FEATURES', 'ACCESSIBILITY'] | monIcons"
                  ngbTooltip="{{ 'Accessibility' | translate }}">
            </span>
            <span *ngIf="domainHasStatistics(domain)"
                  class="text-grey outer-r-smurf me-2"
                  [ngClass]="['DOMAINS', 'FEATURES', 'STATISTICS'] | monIcons"
                  ngbTooltip="{{ 'Statistics' | translate }}">
            </span>
            <span *ngIf="domainHasReadability(domain)"
                  class="text-grey outer-r-smurf me-2"
                  [ngClass]="['DOMAINS', 'FEATURES', 'READABILITY_TEST'] | monIcons"
                  ngbTooltip="{{ 'Readability' | translate }}">
            </span>
            <span *ngIf="domain.scan.scan_documents"
                  class="text-grey outer-r-smurf me-2"
                  [ngClass]="['DOMAINS', 'SCAN', 'SCAN_DOCUMENTS'] | monIcons"
                  ngbTooltip="{{ 'Document scan' | translate }}">
            </span>
            <span *ngIf="domain.scan.enable_javascript"
                  class="text-grey me-2"
                  [ngClass]="['DOMAINS', 'SCAN', 'ENABLE_JAVASCRIPT'] | monIcons"
                  ngbTooltip="{{ 'Javascript crawl' | translate }}">
            </span>
            <span *ngIf="domain.scan.scan_subdomains"
                  class="text-grey me-2"
                  [ngClass]="['DOMAINS', 'SCAN', 'SCAN_SUBDOMAIN'] | monIcons"
                  ngbTooltip="{{ 'Subdomain scanning enabled' | translate }}">
            </span>
            <span *ngIf="domainHasPageFix(domain)"
                  class="text-grey me-2"
                  [ngClass]="['DOMAINS', 'FEATURES', 'PAGE_CORRECT'] | monIcons"
                  ngbTooltip="{{ 'PageCorrect' | translate }}">
            </span>
        </td>
        <td class="text-center text-v-middle" (click)="goToDomain(domain)">
            <span class="text-v-middle inactive-domain outer-r-smurf me-2"
                  [ngClass]="['DOMAINS', 'INACTIVE'] | monIcons"
                  *ngIf="!domain.active"
                  ngbTooltip="{{'Domain is inactive' | translate}}"></span>
            <span class="text-nowrap"
                  ngbTooltip="{{ 'Crawling' | translate }}"
                  *ngIf="domain.crawl_status">
                <span class="text-dark-grey fa-spin me-2" [ngClass]="['SPINNER', 'SYNC'] | monIcons"></span>
                <span class="text-dark-grey" *ngIf="domain.crawl_status">{{ domain.crawl_status.progress }} %</span>
            </span>
        </td>
        <td class="text-right">
            {{getUserCount(domain) | number}}
        </td>
        <td class="text-right" (click)="goToDomain(domain)">
            <span *ngIf="customer && domain.crawl_history">
                {{ domain.crawl_history.page_count | number }}
            </span>
            <span *ngIf="!domain.crawl_history && domain.crawled_pages > 0">
                {{ domain.crawled_pages | number }}
            </span>
        </td>
        <td class="text-right" *ngIf="customer" (click)="goToDomain(domain)">
            <span *ngIf="domain.crawl_history">
                {{ domain.crawl_history.documents_count | number }}
            </span>
        </td>
        <td class="text-right" *ngIf="customer">
            <span *ngIf="!hasMaxScannedPages(domain)" class="text-grey">
                N/A
                <span class="fa-stack fa-sm" ngbTooltip="{{'Max Pages are not defined on the Domain' | translate}}">
                  <span class="fas fa-circle fa-stack-2x"></span>
                  <span class="fas fa-question fa-stack-1x fa-inverse"></span>
                </span>
            </span>
            <span *ngIf="hasMaxScannedPages(domain)" [ngClass]="{'text-danger': getPagesLeft(domain) < 0}">
                {{ getPagesLeft(domain) | number }}
                <span class="fa-stack fa-sm" ngbTooltip="{{'Pages scanned exceeds, max scanned pages' | translate}}">
                  <span class="fas fa-circle fa-stack-2x"></span>
                  <span class="fas fa-question fa-stack-1x fa-inverse"></span>
                </span>
            </span>
        </td>
        <td class="text-right" *ngIf="customer" (click)="goToDomain(domain)">
            <span *ngIf="domain.crawl_history">
                {{ domain.crawl_history.page_count + domain.crawl_history.documents_count | number }}
            </span>
        </td>
        <td>
            <span *ngIf="hasConstraintsOrExcludes(domain)"
                  class="text-grey"
                  [ngClass]="['DOMAINS', 'CONSTRAINTS'] | monIcons"
                  ngbTooltip="{{ 'Indicates when some parts of the domain are not scanned due to Constraints / Exclusions being applied' | translate }}">
            </span>
        </td>
        <td class="text-right" (click)="goToDomain(domain)">
            <mon-user-date [twoLines]="true" [date]="domain.last_scan"></mon-user-date>
            <span *ngIf="!domain.last_scan" translate>Never scanned</span>
        </td>
        <td *ngIf="isAdmin" (click)="goToDomain(domain)">
            <a (click)="onOpenReseller(domain)">
                {{ domain.customer.reseller.name }}
            </a>
        </td>
        <td *ngIf="showCustomer" class="text-left" (click)="$event.stopPropagation()">
            <a class="borderless btn btn-link inner-none" uiSref="base.admin.customers.show" [uiParams]="{customer_id: domain.customer_id}">
                {{domain.customer.name}}
            </a>
        </td>
        <td class="text-right">
            <mon-table-dropdown data-test="action-button">
                <li role="menuitem" uiSref="base.admin.customers.info.domain" [uiParams]="{customer_id: domain.customer_id, domain_id: domain.id}">
                    <a>
                        <span class="fa-fw mr-1" [ngClass]="['ACTIONS', 'DETAILS'] | monIcons"></span>
                        <span translate>Domain details</span>
                    </a>
                </li>
                <li role="menuitem" class="cursor-pointer" (click)="onEditDomain(domain)">
                    <a >
                        <span class="fa-fw mr-1" [ngClass]="['ACTIONS', 'EDIT'] | monIcons"></span>
                        <span translate>Edit domain</span>
                    </a>
                </li>
                <li role="menuitem"
                    monConfirm="{{ 'Are you sure you want to rescan this domain' | translate }}"
                    (monConfirmAction)="onRescan(domain)"
                    class="cursor-pointer">
                    <a>
                        <span class="fa-fw mr-1" [ngClass]="['DOMAINS', 'RESCAN'] | monIcons"></span>
                        <span translate>Rescan domain</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a class="cursor-pointer" (click)="cloneDomain(domain)">
                        <span class="fa-fw mr-1" [ngClass]="['ACTIONS', 'CLONE'] | monIcons"></span>
                        <span translate>Clone domain</span>
                    </a>
                </li>
                <li *ngIf="domainHasPageAssist(domain)">
                    <a (click)="openPageAssist(domain)" href>
                        <span class="far fa-fw fa-universal-access inner-r-smurf" aria-hidden="true"></span>
                        <span translate>Open PageAssist</span>
                    </a>
                </li>
                <li *ngIf="domainHasPageFix(domain)">
                    <a (click)="openPageFix(domain)" role="button" href>
                        <span class="fa-fw fas fa-lock-alt inner-r-smurf" aria-hidden="true"></span>
                        <span translate>Open PageCorrect</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a monPrompt="To confirm deletion, please input the id of the domain - {{domain.title}} ( id: {{domain.id}})"
                        (monPromptAction)="deleteDomain(domain, $event)">
                        <span class="fa-fw mr-1" [ngClass]="['ACTIONS', 'DELETE'] | monIcons"></span>
                        <span translate>Delete domain</span>
                    </a>
                </li>
            </mon-table-dropdown>
        </td>
    </tr>
    </tbody>
</table>
