import { Injectable } from '@angular/core';
import { BackendAdminApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { BackendUserModel } from './models/user';
import { PlainHttpParams } from '@monsido/http/params';
import { Customer } from '@monsido/modules/models/api/customer';
import { HttpHeaders } from '@angular/common/http';
import { Domain } from '@monsido/modules/models/api/domain';
import { UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';

@Injectable({
    providedIn: 'root',
})
export class BackendAdminCustomersRepo {

    constructor (private backendAdminApiClient: BackendAdminApiClient) { }

    /** Customer **/
    public get (id: number, data: PlainHttpParams, headers: HttpHeaders): Promise<Customer> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('customers/' + id, params);
    }

    public getAll (data: PlainHttpParams, headers: HttpHeaders): Promise<Customer[]> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('customers', params);
    }

    /** Users **/
    public getAllUsers (customerId: number, data: PlainHttpParams): Promise<BackendUserModel[]> {
        const params = createDefaultHttpParams(data);
        return this.backendAdminApiClient.getPromise('customers/' + customerId + '/users', params).then(this.toUserModels);
    }

    public createUser (customerId: number, user: UserInterface): Promise<BackendUserModel> {
        return this.backendAdminApiClient.postPromise('customers/' + customerId + '/users', user).then(this.toUserModel);
    }

    public getUser (customerId: number, userId: number): Promise<BackendUserModel> {
        return this.backendAdminApiClient.getPromise('customers/' + customerId + '/users/' + userId).then(this.toUserModel);
    }

    public updateUser (customerId: number, userId: number, user: UserInterface): Promise<BackendUserModel> {
        return this.backendAdminApiClient.patchPromise('customers/' + customerId + '/users/' + userId, user).then(this.toUserModel);
    }

    public destroyUser (customerId: number, userId: number, headers: HttpHeaders = null): Promise<void> {
        const params = createDefaultHttpParams(null, headers);
        return this.backendAdminApiClient.deletePromise('customers/' + customerId + '/users/' + userId, params);
    }

    /** Domain **/
    public getAllDomains (customerId: number, data: PlainHttpParams): Promise<Domain[]> {
        const params = createDefaultHttpParams(data);
        return this.backendAdminApiClient.getPromise('customers/' + customerId + '/domains', params).then(this.toDomainModels);
    }

    /** Spelling errors import  */
    public importSpellingErrors (customerId: number, data: PlainHttpParams): Promise<unknown> {
        return this.backendAdminApiClient.postPromise('customers/' + customerId + '/import/confirmed-spelling-errors', data);
    }

    /** Dictionary **/
    public importDictionary (customerId: number, data: PlainHttpParams): Promise<unknown> {
        return this.backendAdminApiClient.postPromise('customers/' + customerId + '/import/dictionary', data);
    }

    // private

    private toDomainModels (models: Domain[]): Domain[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toDomainModel(models[i]);
        }
        return models;
    }

    private toDomainModel (model: Domain): Domain {
        return new Domain(model);
    }

    private toUserModels (models: BackendUserModel[]): BackendUserModel[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toUserModel(models[i]);
        }
        return models;
    }

    private toUserModel (model: BackendUserModel): BackendUserModel {
        return new BackendUserModel(model);
    }
}
