import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveDialog, FormsBuilderModule, LayoutModule, IconsModule, ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ResellersRepo } from 'app/services/api/backend_admin/resellers-repo';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';
import { Reseller } from '@monsido/modules/models/api/interfaces/user.interface';
import { MonIconsPipe } from '../../../filters/mon-icons.pipe';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-backend-admin-reseller',
    standalone: true,
    templateUrl: './form-backend-admin-reseller.component.html',
    styleUrls: ['./form-backend-admin-reseller.component.scss'],
    imports: [CommonModule, FormsBuilderModule, IconsModule, MonIconsPipe, LayoutModule, TranslateModule],
})
export class FormBackendAdminResellerComponent implements OnInit {
    @Input() reseller: Reseller;
    @Input() monDialogId: number;

    constructor (
        private translateService: TranslateService,
        private resellersRepo: ResellersRepo,
        private globalHelperService: GlobalHelperService,
        private toastService: ToastService,
        private activeDialog: ActiveDialog) {}

    saving: boolean;
    validTrainingLink: boolean;

    ngOnInit (): void {
        this.saving = false;
        if (!this.reseller) {
            this.reseller = {} as Reseller;
        }
        this.validTrainingLink = true;
    }

    submit (): void {
        this.saving = true;
        if (this.reseller.id) {
            this.resellersRepo.update(this.reseller, undefined)
                .then(() => {
                    this.close();
                    this.toastService.success(this.translateService.getString('Partner updated'));
                }, () => {})
                .finally(() => {
                    this.saving = false;
                });
        } else {
            this.resellersRepo.create(this.reseller, undefined)
                .then(() => {
                    this.close();
                    this.toastService.success(this.translateService.getString('Partner created'));
                }, () => {})
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    close (): void {
        this.activeDialog.close(this.monDialogId);
    }

    isValidTrainingLink (trainingLink: string): void {
        if (this.globalHelperService.isValidHttpUrl(trainingLink) || this.globalHelperService.isValidEmail(trainingLink)) {
            this.validTrainingLink = true;
        } else {
            this.validTrainingLink = false;
        }
    }
}
