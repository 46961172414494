<div class="form-page-assist-custom-navigation-collection">
    <ul class="list-group">
        <li class="list-group-item"
             [ngClass]="getNestClass(customNavigation)"
             *ngFor="let customNavigation of customNavigations; let $index = index;">
            <div class="d-flex align-items-center">
                <p class="mb-0 text-link mr-auto">
                    {{customNavigation.title}}
                </p>
                <button class="btn btn-link"
                        type="button"
                        [attr.aria-label]="'Add Sub Custom Navigation' | translate"
                        ngbTooltip="{{'Add Sub Custom Navigation' | translate}}"
                        (click)="create(customNavigation)">
            <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                </button>
                <button class="btn btn-link"
                        type="button"
                        [attr.aria-label]="'Edit Custom Navigation' | translate"
                        ngbTooltip="{{'Edit Custom Navigation' | translate}}"
                        (click)="edit(customNavigation, $index)">
                    <mon-icon [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                </button>
                <button class="btn btn-link"
                        type="button"
                        [attr.aria-label]="'Delete Custom Navigation' | translate"
                        ngbTooltip="{{'Delete Custom Navigation' | translate}}"
                        (click)="delete($index)">
                        <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                </button>
            </div>
        </li>
    </ul>
    <div class="mt-2">
        <button class="btn btn-secondary"
                type="button"
                [attr.aria-label]="'Add Custom Navigation' | translate"
                ngbTooltip="{{'Add Custom Navigation' | translate}}"
                [tooltipClass]="'ml-1'"
                [container]="'body'"
                [placement]="'right'"
                (click)="create()">
            <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
        </button>
    </div>
</div>
