import { MonEventsServiceFactory, MonEventsServiceInterface } from '@monsido_services/services/mon-events/dist/mon-events.service';
import { Injectable } from '@angular/core';
import { eventsType } from '@monsido/services/mon-event/events.type';

@Injectable()
export class MonEventService {
    monEvents: MonEventsServiceInterface = MonEventsServiceFactory();

    addListener (type: eventsType, fn: (...args: unknown[]) => number | void): number {
        return this.monEvents.addListener(type, fn);
    }

    run (...args: Array<unknown>): Promise<void[] | void> {
        return this.monEvents.run(...args);
    }

    remove (id: number): void {
        this.monEvents.remove(id);
    }
}
