<div class="mon-dialog-header">
    <mon-page-header header="{{'Details for crawl' | translate }}"
                     subHeader="{{crawl.domain.id}} - {{crawl.domain.title}}"
    ></mon-page-header>
</div>

<div class="mon-dialog-body">
    <div class="px-3 pt-3 font-size-1-1">
        <mon-spinner [loading]="loading">
            <mon-table-dropdown title="{{'Release' | translate }}" class="mr-1">
                <li ngbDropdownItem role="menuitem">
                    <a  monConfirm="{{'Confirm release?' | translate}}"
                       (monConfirmAction)="releaseCrawl('ok')" href>
                        <mon-icon [icon]="['ACTIONS', 'APPROVE']"></mon-icon>
                        <span class="ml-1" translate>OK</span>
                    </a>
                </li>
                <li ngbDropdownItem role="menuitem">
                     <a  monConfirm="{{'Confirm release due to url-rewrite?' | translate}}"
                       (monConfirmAction)="releaseCrawl('url-rewrite')" href>
                        <mon-icon [icon]="['ACTIONS', 'REDO']"></mon-icon>
                        <span class="ml-1" translate>Url-rewrite</span>
                    </a>
                </li>
            </mon-table-dropdown>

            <mon-table-dropdown title="{{'Reject' | translate }}" class="mr-1">
                <li ngbDropdownItem role="menuitem">
                    <a  monConfirm="{{'Confirm reject due to being stuck in support?' | translate}}"
                       (monConfirmAction)="rejectCrawl('stuck-in-support')" href>
                        <mon-icon [icon]="['ACTIONS', 'STUCK']"></mon-icon>
                        <span class="ml-1" translate>Stuck in support</span>
                    </a>
                </li>
                <li ngbDropdownItem role="menuitem">
                     <a  monConfirm="{{'Confirm reject for other reason?' | translate}}"
                       (monConfirmAction)="rejectCrawl('other')" href>
                        <mon-icon [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                        <span class="ml-1" translate>Other</span>
                    </a>
                </li>
            </mon-table-dropdown>

            <mon-table-dropdown title="{{'Reject & Rescan' | translate }}" class="mr-1">
                <li ngbDropdownItem role="menuitem">
                    <a  monConfirm="{{'Confirm reject and do a new rescan?' | translate}}"
                       (monConfirmAction)="rejectRescanCrawl('rescan')" href>
                        <mon-icon [icon]="['SPINNER', 'SYNC']"></mon-icon>
                        <span class="ml-1" translate>Rescan</span>
                    </a>
                </li>
                <li ngbDropdownItem role="menuitem">
                     <a  monConfirm="{{'Confirm reject due to url-rewrite?' | translate}}"
                       (monConfirmAction)="rejectRescanCrawl('url-rewrite')" href>
                        <mon-icon [icon]="['ACTIONS', 'REDO']"></mon-icon>
                        <span class="ml-1" translate>Url-rewrite</span>
                    </a>
                </li>
            </mon-table-dropdown>

            <button *ngIf="crawl.state === 'STARTED' || crawl.state === 'QUEUED' || crawl.state === 'BACKOFF'"
                    monConfirm="{{'Confirm stop crawl?' | translate}}"
                    (monConfirmAction)="stopCrawl()"
                    class="btn btn-secondary"
                    translate>
                Stop
            </button>
            <div class="card monsido-panel mt-3">
                <div class="card-body">
                    <mon-panel-header pHeader="{{'Crawl detail' | translate}}"></mon-panel-header>
                    <div class="card-body" *ngIf='domain'>
                        <div class="font-size-1-1">
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Reason</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{crawl.on_hold_info?.reason || ' '}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Percentage</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{crawl.on_hold_info?.percentage || 0 | number}}%
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Pages(current, new)</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{domain.crawl_history.page_count + domain.crawl_history.documents_count || 0 | number}}
                                    -&gt;
                                    {{crawl.on_hold_info?.page_count || 0 | number}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Links (current, new)</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{domain.crawl_history.links_count || 0 | number}}
                                    -&gt;
                                    {{crawl.on_hold_info?.link_count || 0 | number}}
                                </div>
                            </div>
                            <div class="row" *ngIf="domain.features.accessibility">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Accessibility errors (current, new)</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{domain.crawl_history.accessiblity_issues_count || 0 | number}}
                                    -&gt;
                                    {{crawl.on_hold_info?.acc_count || 0 | number}}
                                </div>
                            </div>
                            <div class="row" *ngIf="crawl.on_hold_info?.removed_pages_csv">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>CSV with "missing" pages</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    <a [href]="crawl.on_hold_info?.removed_pages_csv" target="_blank" aria-describedby="linkOpensInNewTab">
                                        {{crawl.on_hold_info?.removed_pages_csv}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <mon-panel-header pHeader="{{'Domain' | translate}}"></mon-panel-header>
            <div class="card monsido-panel mt-3">
                <div class="card-body">
                    <mon-panel-header pHeader="{{'Base info' | translate}}">
                    </mon-panel-header>

                    <div class="card-body" *ngIf="domain">
                        <div class="font-size-1-1">
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    Url (domain id)
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <a [href]="domain.url" target="_blank" aria-describedby="linkOpensInNewTab">{{ domain.url }} - ({{domain.id}})</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    Customer (id)
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <a uiSref="base.admin.customers.show"
                                       [uiParams]="{customer_id: domain.customer.id}"
                                       data-test="domain-customer-name">
                                       {{ domain.customer.name }} - ({{ domain.customer.id }})
                                    </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    CMS
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    {{ domain.cms }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    Active status
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    {{ domain.active ? 'Active' : 'Inactive' | translate }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Notes</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <span *ngIf="domain.notes">{{ domain.notes }}</span>
                                    <span *ngIf="!domain.notes" translate>No notes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mon-spinner>
    </div>
</div>
