import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepType } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.service';
import { Cookie } from '@monsido/modules/models/support/cookie';
import { CookiesRepo } from '@monsido/modules/endpoints/api/support/cookies.repo';
import { CookieDescriptionLanguages, CookieType } from '@monsido/modules/models/support/interfaces/cookie.interface';

export type CookieImportStepType = 1 | 2 | 3 | 4;

@Injectable({
    providedIn: 'root',
})
export class ImportCookiesService {
    private step: BehaviorSubject<StepType> = new BehaviorSubject<StepType>(1);

    constructor (private cookieRepo: CookiesRepo) {}

    setStep (step: StepType): void {
        this.step.next(step);
    }

    parseType (category: string): CookieType {
        switch (category) {
            case 'Essential':
                return CookieType.ESSENTIAL;

            case 'Marketing':
                return CookieType.MARKETING;

            default:
                return CookieType.BASIC;
        }
    }

    async importCookies (cookies: Cookie[]): Promise<void> {
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].id) {
                await this.cookieRepo.update(cookies[i]);
            } else {
                await this.cookieRepo.create(cookies[i]);
            }
        }
    }

    prepareNewCookie (
        language: CookieDescriptionLanguages,
        name: string,
        platform: string,
        category: string,
        description: string,
        retention: string,
    ): Cookie {
        return new Cookie({
            name: name,
            platform: platform,
            type: this.parseType(category),
            descriptions: [{ language: language, text: description }],
            expirations: [{ language: language, text: retention }],
        });
    }

    prepareUpdateCookie (
        language: CookieDescriptionLanguages,
        platform: string,
        category: string,
        description: string,
        retention: string,
        cookie: Cookie,
    ): Cookie {
        cookie.type = this.parseType(category);
        cookie.platform = platform;
        let updatedLanguage = false;
        for (let i = 0; i < cookie.descriptions.length; i++) {
            if (cookie.descriptions[i].language === language) {
                cookie.descriptions[i].text = description;
                updatedLanguage = true;
                break;
            }
        }
        if (!updatedLanguage) {
            cookie.descriptions.push({ language: language, text: description });
        }
        let updatedExpirations = false;
        for (let i = 0; i < cookie.expirations.length; i++) {
            if (cookie.expirations[i].language === language) {
                cookie.expirations[i].text = retention;
                updatedExpirations = true;
                break;
            }
        }
        if (!updatedExpirations) {
            cookie.expirations.push({ language: language, text: retention });
        }
        return cookie;
    }
    getStepObs (): Observable<StepType> {
        return this.step.asObservable();
    }

    getCurrentStep (): StepType {
        return this.step.getValue();
    }

    resetSteps (): void {
        this.step.next(1);
    }
}
