import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';

export const SUPPORT_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.support',
        url: '/support',
        abstract: true,
        component: BaseStateComponent,
    },
];
