<mon-page-header [monIcon]="['PARTNERS', 'ICON']" header="{{'Partners' | translate}}">
    <div>
        <button data-test="new-partner-button" class="btn btn-secondary pull-right outer-t-small" (click)="createReseller()">{{'New partner' | translate}}</button>
    </div>
</mon-page-header>
<mon-panel
        [minSearchLength]="1"
        [search]="search"
        [showHeader]="false"
        (onSearch)="onSearch($event)"
        [showSearch]="true" 
        data-test="search-input">
    <div class="card-body" slot="mon-panel-body">
        <mon-table-container
                [collection]="resellers"
                (pageChangeEvent)="onPageChange($event)"
                (perPageChange)="onPageSizeChange($event)"
                class="table-vertial-align"
                [loading]="loading">
            <div class="table-container-body">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="col-sm-12 col-md-12 col-lg-10" translate>Name</th>
                        <th class="text-right col-sm-4 col-md-3 col-lg-2" translate>Customers</th>
                        <th class="text-right col-sm-4 col-md-3 col-lg-2" translate>Domains</th>
                        <th class="text-right col-sm-5 col-md-5 col-lg-4"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr data-test="partner-name" *ngFor="let reseller of resellers"
                            uiSref="base.backend_admin.resellers.show"
                            [uiParams]="{reseller_id: reseller.id}"
                            class="cursor-pointer">
                            <td>{{reseller.name}}</td>
                            <td class="text-right">
                            {{ reseller.customers_count || 0 }}
                            </td>
                            <td class="text-right">
                                {{ reseller.domains_count || 0 }}
                            </td>
                            <td class="text-right" (click)="stopPropagation($event)">
                                <mon-table-dropdown data-test="action-button">
                                    <li role="menuitem">
                                        <a data-test="edit-button" (click)="editReseller(reseller)">
                                            <span [ngClass]="['ACTIONS', 'EDIT'] | monIcons"></span>
                                            {{ 'Edit partner' | translate }}
                                        </a>
                                    </li>
                                    <li role="menuitem">
                                        <a data-test="delete-button" monPrompt="To confirm deletion, please input the id of the partner ( id: {{reseller.id}})"
                                           (monPromptAction)="onDeleteReseller($event)(reseller)">
                                            <span class="mr-1" [ngClass]="['ACTIONS', 'DELETE'] | monIcons"></span>
                                            {{'Delete partner' | translate}}
                                        </a>
                                    </li>
                                </mon-table-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </div>
</mon-panel>
