import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { createDefaultHttpParams } from '@monsido/modules/endpoints/api/support-api-client';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';

@Injectable()
export class BackendSpellingToolRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    getAll (params?: PlainHttpParams, headers?: HttpHeaders): Promise<LanguageInterface[]> {
        return this.adminClient.getPromise(`spelling_tool/languages`, createDefaultHttpParams(params, headers));
    }
}
