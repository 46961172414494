import { Component } from '@angular/core';
import { BackendHebReportRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-heb-report.repo';

@Component({
    selector: 'mon-heb-report',
    templateUrl: 'heb-report.html',
})
export class HebReportComponent {
    constructor (private hebReportRepo: BackendHebReportRepo) {}

    async downloadLatest (): Promise<void> {
        const latestReport = await this.hebReportRepo.getLatest();

        const csvURL = window.URL.createObjectURL(latestReport.blob);
        const tempLink = document.createElement('a');

        tempLink.href = csvURL;
        tempLink.setAttribute('download', latestReport.fileName);
        tempLink.click();

        window.URL.revokeObjectURL(csvURL);
    }
}
