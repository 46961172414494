import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '../backend-admin-api-client';
import { Observable } from 'rxjs';
import { VerticalsType } from '@monsido/pages/backend-admin/verticals/verticals.type';

@Injectable({
    providedIn: 'root',
})
export class BackendVerticalsRepoService {
    constructor (private adminClient: BackendAdminApiClient) {}

    get (): Observable<Array<VerticalsType>> {
        return this.adminClient.getObservable<Array<VerticalsType>>('verticals');
    }
}
