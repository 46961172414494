<div ngForm="domainUserFieldsForm" #domainUserFieldsForm="ngForm">
<table class="table" monSelectAllCheckbox *ngIf="domainUsers && domainUsers.length > 0">
    <thead>
        <tr>
            <th>{{ 'User' | translate }}</th>
            <th>
                <input type="checkbox">
                {{ 'Visible' | translate }}
            </th>
            <th>
                <input type="checkbox">
                {{ 'Send report' | translate }}
            </th>
            <th translate>Admin</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let user of domainUsers; let $index = index">
        <tr *ngIf="user.user">
            <td>
                <span *ngIf="user.user">{{user.user.first_name}} {{ user.user.last_name}}</span>
            </td>
            <td>
                <input type="checkbox" name="visible-{{$index}}" [(ngModel)]="monDomain.domain_users[$index].visible" [checked]="monDomain.domain_users[$index].visible" [disabled]="user.user && user.user.customer_admin">
            </td>
            <td>
                <input type="checkbox" name="send_report-{{$index}}" [(ngModel)]="monDomain.domain_users[$index].send_report" [checked]="monDomain.domain_users[$index].send_report">
            </td>
            <td>
                <mon-icon class="text-success" [icon]="['USERS', 'ADMIN']" *ngIf="user.user && user.user.customer_admin"></mon-icon>
                <mon-icon class="text-danger" [icon]="['USERS', 'NOT_ADMIN']" *ngIf="!user.user || !user.user.customer_admin"></mon-icon>
            </td>
        </tr>
        </ng-container>
    </tbody>
</table>

<div *ngIf="monDomain.domain_users.length === 0" class="row">
    <div class="col-lg-48 col-sm-48 text-center">
        <h3 class="text-grey" translate>No users available for this domain</h3>
    </div>
</div>
</div>
