import { Ng2StateDeclaration } from '@uirouter/angular';
import { PasswordResetComponent } from './reset-password/password-reset.component';
import { LoginRouteService } from './login-route.service';

export const LOGIN_STATES: Ng2StateDeclaration[] = [
    {
        name: 'login',
        url: '',
        abstract: true,
    },
    {
        name: 'login.reset',
        url: '/reset',
        views: {
            '^.^.$default': {
                component: PasswordResetComponent,
            },
        },
        data: { anonymous_access: true },
    },
    {
        name: 'login.auth',
        url: '/auth',
        data: { anonymous_access: true },
        resolve: [
            {
                token: 'loginAuth',
                deps: [LoginRouteService],
                resolveFn: async (loginRouteService: LoginRouteService): Promise<void> => {
                    await loginRouteService.init();
                },
            },
        ],
    },
];
