<div class="col-lg-48 outer-l-none inner-l-none">
    <mon-panel-header pHeader="{{'Users for this partner' | translate}}" subHeader="{{'This partner has ' | translate}} {{ users.length }} {{ 'users right now' | translate}}">
        <div class="col-sm-offset-46 col-sm-2 text-center inner-none">
            <button class="btn btn-secondary pull-right outer-r-small" (click)="create()">+ {{'Add new user' | translate}}</button>
        </div>
    </mon-panel-header>
    <div class="card-body">
        <div class="col-xs-48">
            <button class="btn btn-secondary"
                    [disabled]="selectedUsers.length === 0"
                    monConfirm="{{'Are you sure you want to delete selected users' | translate}}"
                    (monConfirmAction)="selectedUsers.length > 0 && deleteUsers()"
                    translate>
                Delete selected users
            </button>
        </div>
        <div class="col-md-48 col-lg-48 col-xs-48">
            <mon-table-container
                    [collection]="users"
                    (pageChangeEvent)="onPageChange($event)"
                    (perPageChange)="onPageSizeChange($event)"
                    class="table-vertial-align"
                    [loading]="loading">
                <div class="table-container-body">
                    <table class="table outer-b-none">
                        <thead>
                        <tr>
                            <th class="col-md-2">
                                <mon-table-select-item-all [(selectedItems)]="selectedUsers" [items]="users">
                                </mon-table-select-item-all>
                            </th>
                            <th class="col-sm-20 col-md-20 col-lg-20" translate>User</th>
                            <th class="col-sm-8 col-md-7 col-lg-4 text-center" translate>Latest login</th>
                            <th> </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of users">
                            <td>
                                <mon-table-select-item class="inline-block" [(selectedItems)]="selectedUsers" [item]="user">
                                </mon-table-select-item>
                            </td>
                            <td class="vertical-align-middle text-left">
                                <mon-email-avatar [user]="user"></mon-email-avatar>
                            </td>
                            <td class="vertical-align-middle text-center">
                                <mon-user-date [twoLines]="true" [date]="user.last_login_at"></mon-user-date>
                                <div class="text-grey outer-t-medium outer-b-medium" *ngIf="user.last_login_at === ''">
                                    {{'Never' | translate}}
                                </div>
                            </td>
                            <td class="text-right vertical-align-middle">
                                <mon-table-dropdown>
                                    <li role="menuitem">
                                        <a (click)="edit(user)" class="cursor-pointer">
                                            <span class="mr-1" ng-class="['ACTIONS', 'EDIT'] | monIcons"></span>
                                            <span translate>Edit user</span>
                                        </a>
                                    </li>
                                    <li role="menuitem">
                                        <a href="#" monConfirm="{{'Are you sure you want to delete this user?' | translate}}"
                                        (monConfirmAction)="onConfirmDelete(user)">
                                            <span class="mr-1" ng-class="['ACTIONS', 'DELETE'] | monIcons"></span>
                                            <span translate>Delete user</span>
                                        </a>
                                    </li>
                                </mon-table-dropdown>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mon-table-container>
            <hr class="line-thickness-4 outer-t-none"/>
        </div>
    </div>
</div>
