import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'monIsBoolean',
})
export class IsBooleanPipe implements PipeTransform {
    transform (value: unknown): boolean {
        return typeof value === 'boolean';
    }
}
