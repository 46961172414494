import { NgModule } from '@angular/core';
import { EnvSelectorModule } from '@monsido/modules/env-selector/env-selector.module';
import { CsvModule } from '@monsido/modules/csv/csv.module';

@NgModule({
    providers: [],
    imports: [EnvSelectorModule, CsvModule],
    declarations: []
})
export class ModulesModule {}
