import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

/**
Example usage:
<mon-form-field-input [name]="'first_name'"
                      required
                      #first_name="ngModel"
                      [(ngModel)]="user.first_name"
                      monLabel="{{'First name' | translate}}"
                      monPlaceholder="{{'First name' | translate}}"
                      monValidUrl
                      [useCustomError]="true"
                      [type]="'text'">
</mon-form-field-input>
<div class="invalid-feedback block" *ngIf="first_name.control.errors">
    {{ first_name.control.errors.validUrl | json }}
    Invalid URL
</div>
*/

@Directive({
    selector: '[monValidUrl]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MonValidUrlDirective, multi: true }],
})
export class MonValidUrlDirective implements Validator {
    validate (control: AbstractControl): ValidationErrors | null {
        const { value } = control;
        if (!value) {
            return null;
        }

        try {
            new URL(value);
            return null;
        } catch (err) {
            return { validUrl: true };
        }
    }
}
