import { Injectable } from '@angular/core';
import { MON_ICONS } from '@monsido/core/constants/monsido-icons.constant';

type MonIconsDefinition = {
    [key: string]: string | MonIconsDefinition;
};

@Injectable({
    providedIn: 'root',
})
export class MonIconsService {

    getIcon (path: string[]): string {
        let i;
        let result = MON_ICONS as MonIconsDefinition;
        for (i = 0; i < path.length; i++) {
            if (!result[path[i]]) {
                throw new Error('Invalid icon path: ' + path.join('.'));
            }

            if (typeof result[path[i]] === 'string') {
                return result[path[i]] as string;
            }

            result = result[path[i]] as MonIconsDefinition;
        }

        if (typeof result !== 'string') {
            throw new Error('Invalid icon path: ' + path.join('.'));
        }

        return result;
    }
}
