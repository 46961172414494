<form name="domainToolbarSettingsForm" #domainToolbarSettingsForm="ngForm" (ngSubmit)="!saving && domainToolbarSettingsForm.valid && submit()" class="form-horizontal" >
    <div class="mon-dialog-header">
        <mon-page-header header="{{'Domain PageAssist' | translate }}" panel-left-class="col-48 mt-2"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer bg-body">
        <div class="card monsido-panel mt-4 mx-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-xs-48 mb-3">
                        <label ngbTooltip="{{'Import JSON' | translate}}" for="page_assist_import" class="btn btn-secondary m-0 mr-1">
                            <span class="fas fa-download"></span>
                        </label>
                        <input type="file"
                               (change)="importJSON($event)"
                               accept=".json,application/json"
                               id="page_assist_import"
                               class="display-none"/>

                        <button ngbTooltip="{{'Export JSON' | translate}}"
                                type="button"
                                class="btn btn-secondary"
                                (click)="exportJSON()">
                            <span class="fas fa-upload"></span>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-8 text-right mt-2">
                        <label for="link_to_forms" translate>Replace forms with links</label>
                    </div>
                    <div class="col-sm-40 inner-t-smurf">
                        <mon-switch
                            [(ngModel)]="model.link_to_forms"
                            monLabel="link_to_forms"
                            name="link_to_forms"
                        ></mon-switch>
                    </div>
                </div>

                <div class="mb-3">
                    <mon-form-field-input
                        [monLabel]="'Logo' | translate"
                        [name]="'logo'"
                        [(ngModel)]="model.logo"
                        [monPlaceholder]="'Enter logo selector' | translate"
                    ></mon-form-field-input>
                </div>

                <div class="mb-3">
                    <mon-form-field-textarea
                        name="style"
                        [(model)]="model.style"
                        label="{{ 'Style' | translate }}"
                        [rows]="6"
                        placeholder="{{'Type your custom CSS' | translate}}">
                    </mon-form-field-textarea>

                </div>

                <h3 class="font-weight-300" translate>Elements to Hide</h3>

                <div class="row" *ngFor="let elementToHide of model.elements_to_hide; let index = index; trackBy: identify">
                    <div class="col-sm-44 pl-7">
                        <mon-form-field-input
                            [monLabel]="'Selector' | translate"
                            [name]="'elements_to_hide' + index"
                            [(ngModel)]="model.elements_to_hide[index]"
                            [monPlaceholder]="''"
                        ></mon-form-field-input>
                    </div>
                    <div class="col-sm-4 text-right">
                        <button attr.aria-label="{{'Remove Navigation Selector' | translate}}"
                                class="btn btn-secondary"
                                type="button"
                                (click)="removeElementToHide(index)">
                            <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-sm-8 col-sm-40">
                        <button type="button"
                                class="btn btn-secondary"
                                attr.aria-label="{{'Remove Navigation Selector' | translate}}"
                                (click)="addElementToHide()">
                            <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                        </button>
                    </div>
                </div>

                <h3 class="font-weight-300 pt-4" translate>Navigation Elements</h3>

                <div>
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="addNavigation()"
                            attr.aria-label="{{'Add Navigation Container' | translate}}"
                            [placement]="'right'"
                            [container]="'body'"
                            [tooltipClass]="'ml-1'"
                            ngbTooltip="{{'Add Navigation Container' | translate}}">
                        <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                    </button>
                </div>
                <!-- Navigation START -->
                <fieldset class="mt-3" *ngFor="let navigation of navigations; let $parentIndex = index; trackBy: identify;">
                    <legend translate>Navigation Container</legend>
                    <div class="mb-3 text-right">
                        <button type="button"
                                class="btn btn-secondary"
                                (click)="removeNavigation($parentIndex)"
                                [placement]="'left'"
                                [tooltipClass]="'mr-1'"
                                ngbTooltip="{{'Remove Navigation Container' | translate}}">
                            <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                        </button>
                    </div>
                    <mon-form-field-input
                      *ngIf='model.navigation.length'
                        monId="{{ 'title-' + $parentIndex }}"
                        [monLabel]="'Title' | translate"
                        [name]="'navigation-' + $parentIndex + '-title'"
                        [(ngModel)]="model.navigation[$parentIndex].title"
                        [monPlaceholder]="''"
                        ></mon-form-field-input>
                    <mon-form-field-input
                        *ngIf='model.navigation.length'
                        monId="{{ 'selector-' + $parentIndex }}"
                        [monLabel]="'Main Selector' | translate"
                        [(ngModel)]="model.navigation[$parentIndex].mainselector"
                        [name]="'navigation-' + $parentIndex + '-mainselector'"
                        [monPlaceholder]="''"
                        ></mon-form-field-input>
                    <div class="row" *ngFor="let selector of navigation.selectors; let $index = index; trackBy: identify;">
                        <div class="col-sm-44 pl-7">
                            <mon-form-field-input
                                monId="{{ 'navigation-selector-' + $index }}"
                                [monLabel]="'Selector' | translate"
                                [(ngModel)]="model.navigation[$parentIndex].selectors[$index]"
                                [name]="'navigation-' + $parentIndex + '-selectors-' + $index"
                                [monPlaceholder]="''"
                            ></mon-form-field-input>
                        </div>
                        <div class="col-sm-4 text-right">
                            <button class="btn btn-secondary"
                                    type="button"
                                    attr.aria-label="{{'Remove Navigation Selector' | translate}}"
                                    (click)="removeSelector($parentIndex, $index)">
                                <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                            </button>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="offset-sm-8 col-sm-40">
                            <button type="button"
                                    class="btn btn-secondary"
                                    attr.aria-label="{{'Remove Navigation Selector' | translate}}"
                                    (click)="addSelector($parentIndex)">
                                    <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                            </button>
                        </div>
                    </div>
                    <h3 class="font-weight-300 pt-4" translate>Custom Navigation</h3>
                    <mon-form-page-assist-custom-navigation-collection
                        [monNavigationIndex]="$parentIndex"
                        (monOnEdit)="onEditCustomNavigation($event, $parentIndex)"
                        (monOnCreate)="onCreateCustomNavigation($event, $parentIndex)"
                        (monOnDelete)="onDeleteCustomNavigation($event, $parentIndex)">
                    </mon-form-page-assist-custom-navigation-collection>
                </fieldset>
                <!-- Navigation END -->
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button type="submit"
                class="btn btn-secondary"
                [disabled]="domainToolbarSettingsForm.invalid || saving">
            <mon-icon [icon]="['SPINNER', 'ICON']" [spin]="true" *ngIf="saving"></mon-icon>
            <span *ngIf="saveToAPI" translate>Save</span>
            <span *ngIf="!saveToAPI" translate>Ok</span>
        </button>
    </div>
</form>
