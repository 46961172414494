<div ngModelGroup="customerDetail">

    <mon-form-field-input name="name"
                          required
                          #customer_name="ngModel"
                          [(ngModel)]="customer.name"
                          monLabel="{{ 'Name' | translate }}"
                          monPlaceholder="{{ 'Name' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="customer_name.control.touched && customer_name.control.invalid"
        [errors]="customer_name.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-input name="address"
                          #address="ngModel"
                          [(ngModel)]="customer.address"
                          monLabel="{{ 'Address' | translate }}"
                          monPlaceholder="{{ 'Address' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="address.control.touched && address.control.invalid"
        [errors]="address.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-input name="zipcode"
                          #zipcode="ngModel"
                          [(ngModel)]="customer.zipcode"
                          monLabel="{{ 'Zipcode' | translate }}"
                          monPlaceholder="{{ 'Zipcode' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="zipcode.control.touched && zipcode.control.invalid"
        [errors]="zipcode.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-input name="city"
                          #city="ngModel"
                          [(ngModel)]="customer.city"
                          monLabel="{{ 'City' | translate }}"
                          monPlaceholder="{{ 'City' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="city.control.touched && city.control.invalid"
        [errors]="city.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-input name="state"
                          #state="ngModel"
                          [(ngModel)]="customer.state"
                          monLabel="{{ 'State' | translate }}"
                          monPlaceholder="{{ 'State' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="state.control.touched && state.control.invalid"
        [errors]="state.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-input name="phone_number"
                          #phone_number="ngModel"
                          [(ngModel)]="customer.phone_number"
                          monLabel="{{ 'Phone number' | translate }}"
                          monPlaceholder="{{ 'Phone number' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="phone_number.control.touched && phone_number.control.invalid"
        [errors]="phone_number.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-input name="attention"
                          #attention="ngModel"
                          [(ngModel)]="customer.attention"
                          monLabel="{{ 'Attention' | translate }}"
                          monPlaceholder="{{ 'Attention' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="attention.control.touched && attention.control.invalid"
        [errors]="attention.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-input name="country"
                          required
                          #country="ngModel"
                          [(ngModel)]="customer.country"
                          monLabel="{{ 'Country' | translate }}"
                          monPlaceholder="{{ 'Country' | translate }}"
                          type="text">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="country.control.touched && country.control.invalid"
        [errors]="country.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-select-timezones id="timezone"
                                     [(model)]="customer.timezone"
                                     monLabel="{{ 'Timezone' | translate }}">
    </mon-form-field-select-timezones>

    <mon-form-field-input name="salesforce_account_id"
                          [(ngModel)]="customer.salesforce_account_id"
                          monLabel="{{ 'Salesforce ID' | translate }}"
                          monPlaceholder="{{ 'Salesforce ID' | translate }}"
                          type="text">
    </mon-form-field-input>
    
    <mon-form-field-select
        monName="verticals"
        [monAllowClear]="true"
        monReturnValue="value"
        [monOptions]="verticalsOptions"
        monLabel="{{ 'Vertical' | translate }}"
        monPlaceholder="{{ 'Select vertical' | translate }}"
        [(model)]="customer.vertical"
    ></mon-form-field-select>

    <div class="row">
        <div class="col-sm-offset-8 col-sm-40 inner-t-smurf">
            <mon-switch [(ngModel)]="customer.settings.legacy_compliance"
                        class="inline-block outer-r-large outer-t-smurf pull-left"
                        monLabel="settings_legacy_compliance"
                        name="'settings_legacy_compliance'">
            </mon-switch>
            <label for="settings_legacy_compliance" class="font-weight-400 pl-2 mt-1 inner-t-smurf" translate>
                Use Legacy compliance on Summaries and history center
            </label>
        </div>
    </div>

</div>
