<div class="row" *ngIf="rule">
    <div class="col-xs-14 col-md-13">
        <div class="form-group">
            <label class="col-form-label col-sm-12 text-left" translate>Type*</label>
                <div class="col-sm-36">
                    <mon-form-field-select-ac
                        name="rule_type_{{ruleId}}"
                        monPlaceholder="{{ 'Add rule type' | translate }}"
                        monLabel="{{ 'Add rule type' | translate }}"
                        [monSrOnlyLabel]="true"
                        [(ngModel)]="rule.type"
                        (ngModelChange)="updateEntry()"
                        monReturnValue="value"
                        [monOptions]="typeOptions"
                        [required]="true"
                    ></mon-form-field-select-ac>


                </div>
        </div>
    </div>
    <div class="col-xs-14 col-md-17">
        <div class="form-group">
            <label class="col-form-label col-sm-12 text-left" for="rule_value_{{ruleId}}" translate>Value*</label>
            <div class="col-sm-36">
                <input type="text"
                    class="form-control"
                    id="rule_value_{{ruleId}}"
                    required
                    name="rule_value_{{ruleId}}"
                    #ruleValue="ngModel"
                    (ngModelChange)="updateEntry()"
                    [(ngModel)]="rule.value"/>
            </div>
            <mon-form-field-errors
                [showError]="ruleValue.touched && ruleValue.invalid"
                [errors]="ruleValue.errors">
            </mon-form-field-errors>
        </div>
    </div>
    <div class="col-xs-14 col-md-15">
        <div class="form-group">
            <label class="col-form-label col-sm-12" for="accessibility_check_ids_{{ruleId}}" translate>Checks</label>
            <div class="col-sm-36">
                <mon-form-field-select-ac
                        name="accessibility_check_ids_{{ruleId}}"
                        id="accessibility_check_ids_{{ruleId}}"
                        monPlaceholder="{{ 'Choose checks' | translate }}"
                        monLabel="{{ 'Choose checks' | translate }}"
                        #ruleType="ngModel"
                        [monSrOnlyLabel]="true"
                        [(ngModel)]="rule.accessibility_check_ids"
                        (ngModelChange)="updateEntry()"
                        monReturnValue="id"
                        [monOptions]="accessibilityChecks"
                        [monMultiple]="true"
                    ></mon-form-field-select-ac>
            </div>
        </div>
    </div>
    <div class="col-xs-6 col-md-3 text-right pt-1">
        <button class="btn btn-secondary"
                type="button"
                [disabled]="!canBeDeleted"
                (click)="removeEntry()"
                data-test="delete-button">
            <span class="text-link" [ngClass]="['ACTIONS', 'REMOVE'] | monIcons"></span>
        </button>
    </div>
</div>
