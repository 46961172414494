import { BackendAdminApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { Injectable } from '@angular/core';
import { BackendUserModel } from './models/user';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { Reseller, UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';

export type GetAllResellersParams = {
    page?: number,
    page_size?: number,
    search?: string,
};

@Injectable({
    providedIn: 'root',
})
export class ResellersRepo {

    constructor (private backendAdminApiClient: BackendAdminApiClient) { }

    /** Reseller **/
    public get (id: number, data: PlainHttpParams, headers: HttpHeaders): Promise<Reseller> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('resellers/' + id, params);
    }

    public getAll (data: GetAllResellersParams, headers?: HttpHeaders): Promise<Reseller[]> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('resellers', params);
    }

    public update (reseller: Reseller, headers: HttpHeaders): Promise<Reseller> {
        const params = createDefaultHttpParams(null, headers);
        return this.backendAdminApiClient.patchPromise('resellers/' + reseller.id, reseller, params);
    }

    public create (reseller: Reseller, headers: HttpHeaders): Promise<Reseller> {
        const params = createDefaultHttpParams(null, headers);
        return this.backendAdminApiClient.postPromise('resellers', reseller, params);
    }

    public destroy (resellerId: number, data: PlainHttpParams, headers: HttpHeaders): Promise<void> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.deletePromise('resellers/' + resellerId, params);
    }

    /** Users **/
    public getUser (resellerId: number, userId: number, data: PlainHttpParams, headers: HttpHeaders): Promise<UserInterface> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('resellers/' + resellerId + '/users/' + userId , params).then(this.toUserModel);
    }

    public getAllUsers (resellerId: number, data: PlainHttpParams, headers: HttpHeaders): Promise<UserInterface[]> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('resellers/' + resellerId + '/users', params).then(this.toUserModels.bind(this));
    }

    public updateUser (resellerId: number, user: UserInterface): Promise<UserInterface> {
        return this.backendAdminApiClient.patchPromise('resellers/' + resellerId + '/users/' + user.id, user).then(this.toUserModel);
    }

    public createUser (resellerId: number, user: UserInterface, headers: HttpHeaders): Promise<UserInterface> {
        const params = createDefaultHttpParams(null, headers);
        return this.backendAdminApiClient.postPromise('resellers/' + resellerId + '/users', user, params).then(this.toUserModel);
    }

    public destroyUser (resellerId: number, userId: number, data: PlainHttpParams, headers: HttpHeaders = null): Promise<void> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.deletePromise('resellers/' + resellerId + '/users/' + userId, params);
    }

    /** Customers **/
    public getCustomer (resellerId: number, customerId: number, data: PlainHttpParams, headers: HttpHeaders): Promise<CustomerInterface> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('resellers/' + resellerId + '/customers/' + customerId , params);
    }

    public getAllCustomers (resellerId: number, data: PlainHttpParams, headers?: HttpHeaders): Promise<CustomerInterface[]> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('resellers/' + resellerId + '/customers', params);
    }

    public updateCustomer (resellerId: number, customer: CustomerInterface): Promise<CustomerInterface> {
        return this.backendAdminApiClient.patchPromise('resellers/' + resellerId + '/customers/' + customer.id, customer);
    }

    public createCustomer (resellerId: number, customer: CustomerInterface, headers?: HttpHeaders): Promise<CustomerInterface> {
        const params = createDefaultHttpParams(null, headers || new HttpHeaders());
        return this.backendAdminApiClient.postPromise('resellers/' + resellerId + '/customers', customer, params);
    }

    public destroyCustomer (resellerId: number, customerId: number): Promise<void> {
        return this.backendAdminApiClient.deletePromise('resellers/' + resellerId + '/customers/' + customerId);
    }

    public createCustomerPDFClarityReport (resellerId: number, customerId: number, data: PlainHttpParams, headers?: HttpHeaders): Promise<unknown> {
        const params = createDefaultHttpParams(null, headers);
        return this.backendAdminApiClient.postPromise('resellers/' + resellerId + '/customers/' + customerId + '/clarity_tokens', data, params);
    }

    // private

    private toUserModels (models: UserInterface[]): UserInterface[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toUserModel(models[i]);
        }
        return models;
    }

    private toUserModel (model: UserInterface): BackendUserModel {
        const bu = new BackendUserModel(model);
        return bu;
    }
}
