import { Ng2StateDeclaration } from '@uirouter/angular';
import { BackendAdminUsersComponent } from '../backend-admin-users/backend-admin-users.component';
import { RouteParamsConstant } from 'constant/route-params.constant';


export const BACKEND_ADMIN_USERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.users',
        url: '/user',
        abstract: true,
    },
    {
        name: 'base.backend_admin.users.index',
        url: '?search&page_size&page',
        params: RouteParamsConstant,
        views: {
            '^.^.^.$default': {
                component: BackendAdminUsersComponent,
            },
        },
    },
];
