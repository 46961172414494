import { Injectable } from '@angular/core';
import { ApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/api-client';
import { Plan } from '@monsido/modules/models/api/plan';

@Injectable({
    providedIn: 'root',
})
export class PlansRepo {
    constructor (private apiClient: ApiClient) {}

    getAll (params: Record<string, string | number>): Promise<Plan[]> {
        return this.apiClient.getPromise('plans', createDefaultHttpParams(params));
    }
}
