import { NgModule } from '@angular/core';
import { ResellerAdminUsersModule } from './users/users.module';
import { UIRouterModule } from '@uirouter/angular';
import { RESELLER_ADMIN_STATES } from './reseller-admin.state';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ResellerAdminUsersModule, CommonModule, UIRouterModule.forChild({ states: RESELLER_ADMIN_STATES })],
    exports: [],
    declarations: [],
    providers: [],
})
export class ResellerAdminModule {}
