import { Injectable } from '@angular/core';
import { UserSocialMedia } from '@monsido/modules/models/api/interfaces/user.interface';
import { User } from '@monsido/modules/models/api/user';

@Injectable()
export class UserService {
    constructor () {}

    setSocialMediaParameter (user: User): User {
        // Remove social_media parameter if not valid
        if (user.social_media) {
            // If an object
            Object.keys(user.social_media) // Remove all undefined parameters
                .forEach((media: keyof UserSocialMedia) => {
                    if (user.social_media[media] === undefined) {
                        delete user.social_media[media];
                    }
                });

            if (Object.keys(user.social_media).length === 0) {
                // Check if any parameters are left
                user.social_media = null;
            }
        } else {
            // Remove social_media parameter if not an object
            user.social_media = null;
        }
        return user;
    }
}
