export const CMS = [
    {
        'name': 'Acos',
        'id': 'acos',
    },
    {
        'name': 'Adobe CMS',
        'id': 'adobe',
    },
    {
        'name': 'AEM',
        'id': 'aem',
    },
    {
        'name': 'Cascade',
        'id': 'cascade',
    },
    {
        'name': 'CivicEngage Central',
        'id': 'civicengage_central',
    },
    {
        'name': 'CivicEngage Evolve',
        'id': 'civicengage_evolve',
    },
    {
        'name': 'CorePublish',
        'id': 'corepublish',
    },
    {
        'name': 'Craft CMS',
        'id': 'craft',
    },
    {
        'name': 'Custom CMS',
        'id': 'custom',
    },
    {
        'name': 'Custom meta header',
        'id': 'special',
    },
    {
        'name': 'Drupal',
        'id': 'drupal',
    },
    {
        'name': 'Dynamicweb',
        'id': 'dynamicweb',
    },
    {
        'name': 'Elcom',
        'id': 'elcom',
    },
    {
        'name': 'EPiServer',
        'id': 'episerver',
    },
    {
        'name': 'Finalsite',
        'id': 'finalsite',
    },
    {
        'name': 'Gabbart',
        'id': 'gabbart',
    },
    {
        'name': 'Hubspot',
        'id': 'hubspot',
    },
    {
        'name': 'iCreate',
        'id': 'icreate',
    },
    {
        'name': 'Joomla',
        'id': 'joomla',
    },
    {
        'name': 'Kentico',
        'id': 'kentico',
    },
    {
        'name': 'Konform',
        'id': 'konform',
    },
    {
        'name': 'Municode',
        'id': 'municode',
    },
    {
        'name': 'Omniupdate',
        'id': 'omniupdate',
    },
    {
        'name': 'OpenCities',
        'id': 'opencities',
    },
    {
        'name': 'Plone',
        'id': 'plone',
    },
    {
        'name': 'Presence',
        'id': 'presence',
    },
    {
        'name': 'Publicera',
        'id': 'publicera',
    },
    {
        'name': 'Shopify',
        'id': 'shopify',
    },
    {
        'name': 'SilverStripe',
        'id': 'silverstripe',
    },
    {
        'name': 'SIMSite',
        'id': 'simsite',
    },
    {
        'name': 'Sitecore',
        'id': 'sitecore',
    },
    {
        'name': 'Sitefinity',
        'id': 'sitefinity',
    },
    {
        'name': 'Sitevision',
        'id': 'sitevision',
    },
    {
        'name': 'Smart Sites',
        'id': 'smart_sites',
    },
    {
        'name': 'Squarespace',
        'id': 'squarespace',
    },
    {
        'name': 'Squiz',
        'id': 'squiz',
    },
    {
        'name': 'Umbraco',
        'id': 'umbraco',
    },
    {
        'name': 'Web Open',
        'id': 'civicengage_open',
    },
    {
        'name': 'WordPress',
        'id': 'wordpress',
    },
    {
        'name': 'WordPress Multisite',
        'id': 'wordpress_multisite',
    },
];
