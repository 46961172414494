import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CollectionInterface } from '@monsido/angular-shared-components/dist/angular-shared-components/lib/interfaces/collection.interface';
import { DialogRef, DialogService, MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Observable } from 'rxjs';
import { ConstraintsAndExcludesService } from './constraints-and-excludes-service/constraints-and-excludes.service';
import { LinkExcludedInterface, PathConstraintInterface } from './constraints-and-excludes.interface';
import { ConstraintsAndExcludesEditorComponent } from './constraints-and-excludes-editor/constraints-and-excludes-editor.component';
import { PathConstraintsModel } from './constraints-and-excludes-models/path-constraint.model';
import { LinkExcludeModel } from './constraints-and-excludes-models/link-exclude.model';
import { CMS } from '@monsido/core/constants/cms.constant';
import { TranslateService } from 'app/services/translate/translate.service';

type RequestParamsType = {
    page?: number,
    page_size?: number,
}

type AvailableEntryType = 'constraint' | 'link';

@Component({
    selector: 'mon-constraints-and-excludes',
    templateUrl: './constraints-and-excludes.component.html',
    styleUrls: ['./constraints-and-excludes.component.scss'],
})
export class ConstraintsAndExcludesComponent implements OnInit, AfterViewInit {

    constraints$?: Observable<CollectionInterface<PathConstraintInterface>>;
    linksExcluded$?: Observable<CollectionInterface<LinkExcludedInterface>>;
    loadingConstraints: Observable<boolean>;
    loadingLinksExcluded: Observable<boolean>;
    entryType: Record<string, AvailableEntryType> = {
        constraint: 'constraint',
        link: 'link',
    };
    cmsesById = CMS.reduce((acc, current) => {
        acc[current.id] = current.name;
        return acc;
    }, {} as Record<string, string>);

    private constraintsRequestParams: RequestParamsType = {};
    private linksRequestParams: RequestParamsType = {};
    private deletingConfirmation: string;
    private models = {
        [this.entryType.constraint as AvailableEntryType]: PathConstraintsModel,
        [this.entryType.link as AvailableEntryType]: LinkExcludeModel,
    };

    constructor (
        private constraintsAndExcludesService: ConstraintsAndExcludesService,
        private dialogService: DialogService,
        private monPromptService: MonPromptService,
        private translateService: TranslateService,
    ) {
        this.deletingConfirmation = this.translateService.getString('Are you sure you want to delete this entry?');
    }

    ngOnInit (): void {
        this.constraints$ = this.constraintsAndExcludesService.pathConstraits$;
        this.linksExcluded$ = this.constraintsAndExcludesService.linksExcluded$;
        this.loadingConstraints = this.constraintsAndExcludesService.loadingPathConstraitsProgress$;
        this.loadingLinksExcluded = this.constraintsAndExcludesService.loadingLinksExcludedProgress$;
    }

    ngAfterViewInit (): void {
        this.constraintsAndExcludesService.loadConstraints(this.constraintsRequestParams);
        this.constraintsAndExcludesService.loadLinksExcluded(this.linksRequestParams);
    }

    editEntry (entry: PathConstraintInterface | LinkExcludedInterface, type: AvailableEntryType): void {
        const dialogRef = this.openDialog(type);
        dialogRef.componentInstance.entry = new this.models[type](entry as never);
    }

    addEntry (type: AvailableEntryType): void {
        const dialogRef = this.openDialog(type);
        dialogRef.componentInstance.entry = new this.models[type]();
    }

    deleteEntry (entry: PathConstraintInterface | LinkExcludedInterface, type: AvailableEntryType): void {
        this.monPromptService.confirm(this.deletingConfirmation).then(() => {
            this.constraintsAndExcludesService.deleteEntry(entry, true).subscribe(() => {
                if (type === this.entryType.constraint) {
                    this.constraintsAndExcludesService.loadConstraints(this.constraintsRequestParams);
                } else if (type === this.entryType.link) {
                    this.constraintsAndExcludesService.loadLinksExcluded(this.linksRequestParams);
                }
            });
        }, () => {});
    }

    onPageChange (page: number, type: string): void {
        if (type === this.entryType.constraint) {
            this.constraintsRequestParams.page = page;
            this.constraintsAndExcludesService.loadConstraints(this.constraintsRequestParams);
        } else if (type === this.entryType.link) {
            this.linksRequestParams.page = page;
            this.constraintsAndExcludesService.loadLinksExcluded(this.linksRequestParams);
        }
    }

    onPageSizeChange (pageSize: number, type: string): void {
        if (type === this.entryType.constraint) {
            this.constraintsRequestParams.page_size = pageSize;
            this.constraintsAndExcludesService.loadConstraints(this.constraintsRequestParams);
        } else if (type === this.entryType.link) {
            this.linksRequestParams.page_size = pageSize;
            this.constraintsAndExcludesService.loadLinksExcluded(this.linksRequestParams);
        }
    }

    private openDialog (type?: string): DialogRef<ConstraintsAndExcludesEditorComponent> {
        return this.dialogService.open(ConstraintsAndExcludesEditorComponent, {
            classes: 'mon-dialog-size-sm',
            cb: (entry: PathConstraintInterface | LinkExcludedInterface | undefined) => {
                if (entry) {
                    let observable: Observable<unknown>;
                    if (entry.id) {
                        observable = this.constraintsAndExcludesService.patchEntry(entry, true);
                    } else {
                        observable = this.constraintsAndExcludesService.postEntry(entry, true);
                    }
                    observable.subscribe(() => {
                        if (type === this.entryType.constraint) {
                            this.constraintsAndExcludesService.loadConstraints(this.constraintsRequestParams);
                        } else if (type === this.entryType.link) {
                            this.constraintsAndExcludesService.loadLinksExcluded(this.linksRequestParams);
                        }
                    });
                }
            },
        });
    }

}
