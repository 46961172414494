import { NgModule } from '@angular/core';
import { ConfirmDirective } from '@monsido/confirm/confirm.directive';

@NgModule({
    imports: [],
    exports: [ConfirmDirective],
    declarations: [ConfirmDirective],
    providers: [],
})
export class ConfirmModule {}
