import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { Plan } from '@monsido/modules/models/api/plan';
import { PERFORMANCE } from '@monsido/core/constants/performance.constant';
import { PlanTraitService } from '@monsido/services/plan-trait/plan-trait.service';
import { PLAN_TRAITS } from '@monsido/core/constants/plan-traits.constant';
import { SelectOption } from '@monsido/core/constants/select-options.constant';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-backend-admin-customer-override',
    templateUrl: 'override.html',
    styleUrls: ['./override.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormBackendAdminCustomerOverrideComponent implements OnInit {
    @Input() customer: CustomerInterface;
    @Input() plan: Plan;
    @Output() selectionChange: EventEmitter<string | boolean | number> = new EventEmitter<string | boolean | number>();
    intervalOptions: Record<string, string | number>[];

    form: NgForm;
    spellcheckEngineOptions: Record<string, string | boolean | number>[];
    defaultOptionTranslation: string;
    booleanDropdownOptions: SelectOption[];
    scanFrequenciesOptions: SelectOption[];
    setupClarityOptions: SelectOption[];
    performanceFrequencies: {
        PERFORMANCE_FREQUENCIES: SelectOption[];
    };
    canUseMobileGuidelines: boolean;

    constructor (
        private planTraitService: PlanTraitService,
        private translateService: TranslateService,
        private monEventsService: MonEventService,
    ) {
        this.performanceFrequencies = PERFORMANCE;
    }

    ngOnInit (): void {
        this.booleanDropdownOptions = [
            { value: true, name: this.translateService.getString('Yes') },
            { value: false, name: this.translateService.getString('No') },
        ];

        this.scanFrequenciesOptions = PLAN_TRAITS.SCAN_FREQUENIES;

        this.performanceFrequencies = PERFORMANCE;
        this.intervalOptions = this.planTraitService.getIntervalOptions();
        this.spellcheckEngineOptions = this.planTraitService.getSpellCheckOptions();

        this.defaultOptionTranslation = this.translateService.getString('Uses plan settings');

        this.setupClarityOptions = [
            {
                name: this.translateService.getString('Enable'),
                value: true,
            },
            {
                name: this.translateService.getString('Disable'),
                value: false,
            },
        ];

        this.canUseMobileGuidelines = true;
    }
}
