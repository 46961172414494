export interface CookieInterface {
    id?: number;
    platform: string;
    name: string;
    type: CookieType;
    descriptions?: DescriptionsEntityOrExpirationsEntity[];
    expirations?: DescriptionsEntityOrExpirationsEntity[];
}
export interface DescriptionsEntityOrExpirationsEntity {
    language: CookieDescriptionLanguages;
    text: string;
}

export enum CookieType {
    ANALYTICS = 'analytics',
    BASIC = 'basic',
    ESSENTIAL = 'essential',
    MARKETING = 'marketing',
    PERSONALISATION = 'personalisation',
}

export enum CookieDescriptionLanguages {
    EN_US = 'en_US',
    DA_DK = 'da_DK',
    DE_DE = 'de_DE',
    ES_ES = 'es_ES',
    NB_NO = 'nb_NO',
    NL_NL = 'nl_NL',
}
