import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    constructor () {}

    get (key: string): string {
        return window.localStorage.getItem(key);
    }

    set (key: string, data: string): void {
        window.localStorage.setItem(key, data);
    }
}
