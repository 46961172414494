<div class="row">
    <label for="domain_login_verify_location"
           ngbTooltip="{{'URL to redirect to if form is valid' | translate}}"
           [triggers]="'focus:mouseenter'"
           class="col-form-label text-bold text-right col-sm-8"
           translate>
        Verify URL*
    </label>
    <div class="col-sm-40">
        <mon-form-field-input
               [monId]="'domain_login_verify_location'"
               [name]="'domain_login_verify_location'"
               required
               #location="ngModel"
               [pattern]="verifyLocationPattern"
               [monPlaceholder]="''"
               [(ngModel)]="monLogin.verify.location"></mon-form-field-input>
    </div>
    <mon-form-field-errors
        [patternMsg]="'Is not a valid url' | translate"
        [showError]="location.control.touched && location.control.invalid"
        [errors]="location.control.errors">
    </mon-form-field-errors>
</div>
<div class="row">
    <label class="col-form-label text-right text-bold col-sm-8" for="login_type_custom_multistep_username" translate>
        Username
    </label>
    <div class="col-sm-40">
        <mon-form-field-input
               [type]="'text'"
               [name]="'login_type_custom_multistep_username'"
               [(ngModel)]="monLogin.custom_multistep.username"
               [monPlaceholder]="''"
               [monId]="'login_type_custom_multistep_username'"></mon-form-field-input>
    </div>
</div>
<div class="row">
    <label class="col-form-label text-right text-bold col-sm-8" for="login_type_custom_multistep_password" translate>
        Password
    </label>
    <div class="col-sm-40">
        <mon-form-field-input
               [type]="'password'"
               [name]="'login_type_custom_multistep_password'"
               [(ngModel)]="monLogin.custom_multistep.password"
               [monPlaceholder]="''"
               [monId]="'login_type_custom_multistep_password'"></mon-form-field-input>
    </div>
</div>
<div class="mb-1 row" *ngIf="monRequestChecker">
    <label class="col-form-label text-right text-bold col-sm-8" for="login_type_custom_multistep_add_step" translate>
        Steps
    </label>
    <div class="col-sm-40">
        <button type="button"
                class="btn btn-secondary"
                id="login_type_custom_multistep_add_step"
                (click)="addStep()"
                attr.aria-label="{{'Add Custom Multistep Javascript' | translate}}">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
        </button>
    </div>
</div>
<div class="row" *ngFor="let step of monLogin.custom_multistep.steps; let $index = index; trackBy: trackById">
    <label class="col-form-label text-right text-bold col-sm-8"
           for="login_type_custom_multistep_step_{{$index}}"
           translate>
        JS
    </label>
    <div class="col-sm-36">
        <mon-form-field-textarea
                  monId="login_type_custom_multistep_step_{{$index}}"
                  [rows]="4"
                  name="step_{{$index}}"
                  [placeholder]="''"
                  [(model)]="monLogin.custom_multistep.steps[$index]">
        </mon-form-field-textarea>
    </div>
    <div class="col-sm-4 text-right pl-0">
        <button type="button"
                class="btn btn-secondary"
                (click)="removeStep($index)"
                attr.aria-label="{{'Remove Custom Multistep Javascript' | translate}}">
            <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
        </button>
    </div>
</div>

<div class="row" *ngIf="!monRequestChecker">
    <div class="col-sm-40 offset-8">
        <button type="button"
                class="btn btn-secondary"
                (click)="addStep()"
                attr.aria-label="{{'Add Custom Multistep Javascript' | translate}}">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
        </button>
    </div>
</div>
