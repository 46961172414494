import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';

@Directive({ selector: '[monPrompt]' })
export class PromptDirective {
    @Input() monPrompt: string;
    @Output() monPromptAction: EventEmitter<string> = new EventEmitter<string>();

    constructor (private monPromptService: MonPromptService) {}

    @HostListener('click', ['$event'])
    go (event: MouseEvent): Promise<void> {
        event.preventDefault();
        return this.monPromptService.prompt(this.monPrompt).then(
            (result) => {
                this.monPromptAction.emit(result);
            },
            () => {},
        );
    }
}
