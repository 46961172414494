export const MON_ICONS = {
    CRAWLERS: {
        ICON: 'fas fa-spider',
        PAUSED: 'fas fa-pause',
        NOT_PAUSED: 'fas fa-check',
        STATE_UP: 'fas fa-arrow-up',
        STATE_DOWN: 'fas fa-arrow-down',
    },
    CUSTOMERS: {
        ICON: 'fas fa-building',
        PLAN_TRAITS: {
            ACTIVE: 'fas fa-check',
            INACTIVE: 'fas fa-times',
        },
        FEATURES: {
            ACTIVE: 'fas fa-check',
            INACTIVE: 'fas fa-times',
        },
        SCAN: {
            ACTIVE: 'fas fa-check',
            INACTIVE: 'fas fa-times',
        },
    },
    DATA_PRIVACY_CHECKS: {
        ICON: 'fas fa-lock-alt',
    },
    DOMAINS: {
        ICON: 'fas fa-globe',
        NOTES: 'far fa-comment',
        INACTIVE: 'fas fa-exclamation-triangle',
        CONSTRAINTS: 'far fa-code',
        RESCAN: 'far fa-sync-alt',
        REWRITE_URL: 'fas fa-pencil',
        SCAN: {
            SCAN_DOCUMENTS: 'fas fa-file-alt',
            ENABLE_JAVASCRIPT: 'fab fa-js-square',
            SCAN_SUBDOMAIN: 'fas fa-globe-americas',
        },
        FEATURES: {
            STATISTICS: 'fas fa-chart-pie',
            ACCESSIBILITY: 'fas fa-universal-access',
            READABILITY_TEST: 'fas fa-eye',
            PAGE_CORRECT: 'fas fa-shield-check',
        },
    },
    DOMAIN_GROUPS: {
        ICON: 'fas fa-clone',
    },
    USERS: {
        ICON: 'fas fa-users',
        SINGLE_ICON: 'fas fa-user',
        ADMIN: 'fas fa-check',
        NOT_ADMIN: 'fas fa-ban',
        SUDO: 'fas fa-user-secret',
        CONFIRMED: 'fas fa-clipboard-check',
        DICTIONARY: 'fas fa-book',
    },
    CRAWL_QUEUE: {
        ICON: 'fas fa-tasks',
    },
    SOURCE_CODE_EXCLUDES: {
        ICON: 'fas fa-code',
    },
    CONSTRAINTS_AND_EXCLUDES: {
        ICON: 'fas fa-file-minus',
    },
    PARTNERS: {
        ICON: 'fas fa-university',
    },
    PLANS: {
        ICON: 'fas fa-file-alt',
        ACTIVE: 'fas fa-check',
    },
    WORKER_QUEUE: {
        ICON: 'fas fa-list',
    },
    PRODUCT_UPDATES: {
        ICON: 'fas fa-newspaper',
        DATE: 'fas fa-calendar-alt',
    },
    ACCESSIBILITY_CHECKS: {
        ICON: 'fas fa-universal-access',
    },
    AGENCY: {
        ICON: 'far fa-window-restore',
    },
    SUPPORT: {
        HEATMAPS: {
            ICON: 'fas fa-fire',
        },
        REQUEST_CHECKER: {
            ICON: 'fas fa-server',
        },
    },
    SUPPORT_CENTER: {
        TAGS: {
            ICON: 'fas fa-tags',
            SINGLE_ICON: 'fas fa-tag',
        },
        ARTICLES: {
            ICON: 'fas fa-question-circle',
            TITLE: 'fas fa-book',
            PROMOTED: 'fas fa-check',
            NOT_PROMOTED: 'fas fa-times',
            SMILEYS: {
                GOOD: 'fal fa-smile',
                AVERAGE: 'fal fa-meh',
                BAD: 'fal fa-frown',
            },
        },
        CATEGORIES: {
            ICON: 'fas fa-bookmark',
        },
    },
    ACTIONS: {
        ICON: 'fas fa-chevron-down',
        ADD: 'fas fa-plus',
        REMOVE: 'far fa-trash-alt',
        DELETE: 'far fa-trash-alt', // For API requests
        CREATE: '',
        EDIT: 'fas fa-cog',
        CLOSE: 'fas fa-times',
        DETAILS: 'fas fa-info',
        CLONE: 'far fa-clone',
        HANDLE: 'fas fa-bars',
        IMPORT: 'fas fa-file-import',
        HANDLES: {
            SHOW: 'fas fa-chevron-down',
            HIDE: 'fas fa-chevron-right',
        },
        APPROVE: 'fas fa-check',
        DISAPPROVE: 'fas fa-ban',
        STUCK: 'fas fa-info-circle',
        REDO: 'fas fa-redo-alt',
        DOWNLOAD: 'fas fa-download',
    },
    SPINNER: {
        ICON: 'fas fa-spinner',
        SYNC: 'far fa-sync-alt',
        REFRESH: 'fal fa-sync',
    },
    PROFILE: {
        ICON: 'far fa-copy',
    },
    DASHBOARD: {
        ICON: 'far fa-copy',
    },
    LINKS: {
        EXTERNAL: 'far fa-external-link',
    },
    SEARCH: {
        ICON: 'fas fa-search',
    },
    COOKIES: {
        ICON: 'fas fa-cookie-bite',
    },
    MENUES: {
        RETURN: 'fas fa-arrow-left',
    },
};
