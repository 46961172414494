import { ACCOUNT_TYPE } from '@monsido/core/constants/account-type.constant';

import {
    DomainUsersEntity,
    EmailNotifications,
    UserAgreementsEntity,
    UserContactInfo,
    UserCustomer,
    UserInterface,
    UserSettings,
    UserSocialMedia,
    DomainGroupMembersEntity,
} from '@monsido/modules/models/api/interfaces/user.interface';
import { StringService } from '@monsido/tools/string.service';

export class User implements UserInterface {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    locale: string;
    timezone?: string | null;
    contact_info?: UserContactInfo | null;
    social_media?: UserSocialMedia;
    role_id?: null;
    title?: null;
    description?: null;
    phone?: null;
    created_at: string;
    updated_at: string;
    last_login_at?: string | null;
    user_agreements?: UserAgreementsEntity[] | null;
    labels?: null[] | null;
    customer_admin?: boolean;
    customer?: UserCustomer;
    domain_users?: DomainUsersEntity[];
    domain_group_members?: DomainGroupMembersEntity[];
    settings?: UserSettings;
    email_notifications?: EmailNotifications;
    association_level?: number;
    can_sudo?: boolean;
    api_key?: null;
    send_welcome_email?: boolean;

    constructor (user?: UserInterface) {
        Object.assign(this, user);
    }

    isEditable (): boolean {
        let agreements = this.user_agreements;
        if (Array.isArray(agreements) && agreements.length > 0) {
            agreements = agreements.filter((agreement) => {
                return agreement.account && agreement.account.type === ACCOUNT_TYPE.customer;
            });
            return agreements.length > 0;
        }
        return false;
    }

    getResellerAgreementName (): string {
        if (Array.isArray(this.user_agreements)) {
            const agreement = this.user_agreements.find((dataAgreement) => {
                return dataAgreement.account && dataAgreement.account.type === ACCOUNT_TYPE.reseller;
            });
            if (agreement) {
                return agreement.account.name;
            }
        }
        return null;
    }

    getCustomerCount (): number {
        if (this.user_agreements) {
            return this.user_agreements.reduce((num, agreement) => {
                if (agreement.account && agreement.account.type === ACCOUNT_TYPE.customer) {
                    num++;
                }
                return num;
            }, 0);
        }
        return 0;
    }

    getFirstCustomerId (): number {
        const agreement = this.user_agreements.find((dataAgreement) => {
            return dataAgreement && dataAgreement.account.type === ACCOUNT_TYPE.customer;
        });
        if (agreement) {
            return agreement.account.id;
        } else {
            return null;
        }
    }

    getAvatarInitials (): string {
        let name = StringService.capitalize(this.first_name || '').split(' ')[0];
        if (this.last_name) {
            name += ' ' + StringService.capitalize(this.last_name).split(' ')[0];
        }
        return [name].join(' ').trim();
    }
}
