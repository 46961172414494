import { HttpHandler, HttpHeaders } from '@angular/common/http';
import { MonHttpClientService } from '@monsido/http/http-client.service';
import { Config } from '@monsido/http/config';
import { Injectable } from '@angular/core';
import { Params, PlainHttpParams } from '@monsido/http/params';

@Injectable()
export abstract class ApiClient extends MonHttpClientService {
    abstract setHeader(name: string, value: string): HttpHeaders;
    abstract setConfig(config?: Config): HttpHeaders;
    abstract setUrlPrefix(url: string): HttpHeaders;
}

export function apiClientFactory (handler: HttpHandler): MonHttpClientService {
    return new MonHttpClientService(handler);
}

export function createDefaultHttpParams (params?: PlainHttpParams, headers?: HttpHeaders | Record<string, string>): Params {
    const options: Params = {};
    options.params = params || {};
    if (headers) {
        options.headers = headers instanceof HttpHeaders ? headers : new HttpHeaders(headers);
    }
    return options;
}
