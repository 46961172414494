import { isEqual, isObject, transform } from 'lodash';

export class DiffService {
    // TODO Replace any: https://github.com/Monsido/backend_admin/issues/2302
    // TODO remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static difference<T extends { [key: string]: any }> (object: T, base: T): T {
        return transform(object, (result, value, key) => {
            if (!isEqual(value, base[key])) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result[key] = isObject(value) && isObject(base[key]) ? DiffService.difference(value, base[key]) : value;
            }
        });
    }
}
