import { AdditionalInternalUrls, CmsExtra, CrawlHistory, CrawlStatus, DomainGroupDataType, DomainDataType, Features, LinkExcludes, PageAssistSettings, PathConstraints, Scan, Settings } from 'types/domain';
import { DomainBaseModel } from './domain-base.model';
import { Customer } from '@monsido/modules/models/api/customer';
import { DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';

export class DomainModel extends DomainBaseModel {
    active: boolean;
    customer_id: number;
    url: string;
    timezone?: string;
    cms: string;
    language: string;
    customer: Customer;
    last_scan: string;
    crawled_pages: number;
    crawl_status?: CrawlStatus;
    crawl_history?: CrawlHistory;
    domain_users?: DomainUsersEntity[];
    token: string;
    features?: Features;
    scan: Scan;
    path_constraints?: PathConstraints[];
    link_excludes?: LinkExcludes[];
    excluded_ips?: string;
    domain_groups?: DomainGroupDataType[];
    running_groups: boolean;
    accessibility_source_code_excludes?: string[];
    cms_extra: CmsExtra;
    additional_internal_urls?: AdditionalInternalUrls[];
    source_code_excludes?: string[];
    notes?: string;
    labels?: string[];
    settings: Settings;
    page_assist_settings?: PageAssistSettings;

    constructor (properties: DomainDataType) {
        super(properties);
        this.active = properties.active;
        this.domainType = 'Domain';
        this.customer_id = properties.customer_id;
        this.url = properties.url;
        this.timezone = properties.timezone;
        this.cms = properties.cms;
        this.language = properties.language;
        this.customer = properties.customer;
        this.last_scan = properties.last_scan;
        this.crawled_pages = properties.crawled_pages;
        this.crawl_status = properties.crawl_status;
        this.crawl_history = properties.crawl_history;
        this.domain_users = properties.domain_users;
        this.token = properties.token;
        this.features = properties.features;
        this.scan = properties.scan;
        this.path_constraints = properties.path_constraints;
        this.link_excludes = properties.link_excludes;
        this.excluded_ips = properties.excluded_ips;
        this.domain_groups = properties.domain_groups;
        this.running_groups = properties.running_groups;
        this.accessibility_source_code_excludes = properties.accessibility_source_code_excludes;
        this.cms_extra = properties.cms_extra;
        this.additional_internal_urls = properties.additional_internal_urls;
        this.source_code_excludes = properties.source_code_excludes;
        this.notes = properties.notes;
        this.labels = properties.labels;
        this.settings = properties.settings;
        this.page_assist_settings = properties.page_assist_settings;
    }
}
