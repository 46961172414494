import { PathConstraintInterface } from '../constraints-and-excludes.interface';

export class PathConstraintsModel implements PathConstraintInterface {

    constraint: string = '';
    created_at: string = '';
    default_for_cms: string = '';
    id?: number;
    name: string = '';
    updated_at: string = '';

    constructor (entry?: PathConstraintInterface) {
        if (entry) {
            this.constraint = entry.constraint;
            this.created_at = entry.created_at;
            this.default_for_cms = entry.default_for_cms;
            this.name = entry.name;
            this.updated_at = entry.updated_at;

            if (entry.id != null) {
                this.id = entry.id;
            }
        }
    }

}
