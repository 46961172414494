import { NgModule } from '@angular/core';
import { MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CustomerService } from '@monsido/services/customer/customer.service';
import { UserService } from '@monsido/services/user/user.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ActiveRegionService } from '@monsido/services/active-region/active-region.service';
import { DomainService } from '@monsido/services/domain/domain.service';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';
import { EnvironmentService } from '@monsido/services/enviroment/environment.service';
import { LoadingModule } from '@monsido/services/loading/loading.module';

@NgModule({
    imports: [LoadingModule],
    exports: [],
    declarations: [],
    providers: [
        EnvironmentService,
        CustomerService,
        UserService,
        MonEventService,
        ActiveRegionService,
        DomainService,
        GlobalHelperService,
        MonPromptService,
    ],
})
export class ServicesModule {}
