import { getString } from '../../../utils/translation.utils';

export type SelectOption<T = string | number | boolean> = {
    name: string,
    value: T
};

export const getTranslatedSelectOptions = <T>(selectOptions: SelectOption<T>[], translateFn: (input: string) => string): SelectOption<T>[] => {
    return selectOptions.map(option => {
        return {
            name: translateFn(option.name),
            value: option.value,
        };
    });
};

export const getSelectOptionsValues = <T>(selectOptions: SelectOption<T>[]): SelectOption<T>['value'][] => {
    return selectOptions.map(option => option.value);
};

export const WEEK_DAY_OPTIONS: SelectOption<string>[] = [
    {
        name: getString('Autoselect'),
        value: 'any',
    },
    {
        name: getString('Monday'),
        value: 'monday',
    },
    {
        name: getString('Tuesday'),
        value: 'tuesday',
    },
    {
        name: getString('Wednesday'),
        value: 'wednesday',
    },
    {
        name: getString('Thursday'),
        value: 'thursday',
    },
    {
        name: getString('Friday'),
        value: 'friday',
    },
    {
        name: getString('Saturday'),
        value: 'saturday',
    },
    {
        name: getString('Sunday'),
        value: 'sunday',
    },
];

export const CONNECTION_OPTIONS: SelectOption<number>[] = [
    {
        name: getString('Slow'),
        value: 30,
    },
    {
        name: getString('Normal - recommended'),
        value: 60,
    },
    {
        name: getString('Faster'),
        value: 90,
    },
    {
        name: getString('Very fast - Be Cautious'),
        value: 120,
    },
    {
        name: getString('Superfast - Don\'t use unless you know what you\'re doing'),
        value: 180,
    },
];

export const LOGIN_TYPES_OPTIONS: SelectOption<string>[] = [
    {
        name: getString('None'),
        value: 'none',
    },
    {
        name: getString('Form'),
        value: 'form',
    },
    {
        name: getString('Office 365'),
        value: 'office365',
    },
    {
        name: getString('Basic Auth'),
        value: 'basic_auth',
    },
    {
        name: getString('Custom Multistep'),
        value: 'custom_multistep',
    },
];
