<div class="table-container-body">
    <table class="table table-fixed table-hover">
        <thead>
        <tr>
            <th translate>Check id</th>
            <th translate>Instance of errors</th>
            <th translate>Expand</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let check of tableData | keyvalue">
            <tr>
                <td> {{ check.key }}</td>
                <td> {{ check.value }}</td>
                <td>
                    <button
                        class="btn btn-primary"
                        (click)="expandRows[check.key] = !expandRows[check.key]">
                        <span *ngIf="!expandRows[check.key]">+</span>
                        <span *ngIf="expandRows[check.key]">-</span>
                    </button>
                </td>
            </tr>
            <tr *ngIf="expandRows[check.key]">
                <td colspan="3">
                    <table class="table table-fixed table-hover">
                        <thead>
                            <tr>
                                <th translate>Errors</th>
                            </tr>
                        </thead>
                        <body>
                            <ng-container *ngFor="let row of getErrors(check.key)">
                                <tr>
                                    <td>
                                        <code>{{ row.error_source_code }}</code>
                                    </td>
                                </tr>
                            </ng-container>
                        </body>
                    </table>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>
