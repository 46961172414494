import { getString } from '../../../utils/translation.utils';

export const PERFORMANCE = {
    PERFORMANCE_FREQUENCIES: [
        {
            name: getString('Four times a day'),
            value: 6,
        },
        {
            name: getString('Twice a day'),
            value: 12,
        },
        {
            name: getString('Once a day'),
            value: 24,
        },
        {
            name: getString('Once a week'),
            value: 168,
        },
    ],
};
