<div *ngIf="entryAsPathConstraint || entryAsLinkExclude">
    <div class="mon-dialog-header">
        <mon-page-header header="{{'Path constraint' | translate }}" *ngIf="entryAsPathConstraint"></mon-page-header>
        <mon-page-header header="{{'Link exclude' | translate }}" *ngIf="entryAsLinkExclude"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Details</h3>
            </div>
            <div class="card-body">
                <form *ngIf="entryAsPathConstraint" #constraintForm="ngForm" class="form-horizontal">
                    <mon-form-field-input
                        [name]="'name'"
                        required
                        [(ngModel)]="entryAsPathConstraint.name"
                        [monLabel]="'Name' | translate"
                        [monPlaceholder]="'Enter a name' | translate"
                        [type]="'text'"></mon-form-field-input>
                    <mon-form-field-input
                        [name]="'constraint'"
                        required
                        [(ngModel)]="entryAsPathConstraint.constraint"
                        [monLabel]="'Constraint pattern' | translate"
                        [monPlaceholder]="'Enter a path constraint pattern' | translate"
                        [type]="'text'"></mon-form-field-input>
                    <mon-form-field-select
                        [monName]="'cms'"
                        [monRequired]="true"
                        [(model)]="entryAsPathConstraint.default_for_cms"
                        [monOptions]="cmses"
                        [monLabel]="'CMS' | translate"
                        [monReturnValue]="'id'"
                        [monPlaceholder]="'None' | translate"
                        [monAllowClear]="true">
                    </mon-form-field-select>
                </form>
                <form *ngIf="entryAsLinkExclude" #linkForm="ngForm" class="form-horizontal">
                    <mon-form-field-input
                        [name]="'name'"
                        required
                        [(ngModel)]="entryAsLinkExclude.name"
                        [monLabel]="'Name' | translate"
                        [monPlaceholder]="'Enter a name' | translate"
                        [type]="'text'"></mon-form-field-input>
                    <mon-form-field-input
                        [name]="'constraint'"
                        required
                        [(ngModel)]="entryAsLinkExclude.regex"
                        [monLabel]="'Exclude pattern' | translate"
                        [monPlaceholder]="'Enter a exclude pattern' | translate"
                        [type]="'text'"></mon-form-field-input>
                    <mon-form-field-select
                        [monName]="'cms'"
                        [monRequired]="true"
                        [(model)]="entryAsLinkExclude.default_for_cms"
                        [monOptions]="cmses"
                        [monLabel]="'CMS' | translate"
                        [monReturnValue]="'id'"
                        [monPlaceholder]="'None' | translate"
                        [monAllowClear]="true">
                    </mon-form-field-select>
                </form>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button type="submit" (click)="submit(selectedForm)" class="btn btn-secondary" [disabled]="selectedForm?.invalid" data-test="save-button">
            <span translate>Save</span>
        </button>
    </div>
</div>
