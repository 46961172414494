import { Injectable } from '@angular/core';
import { ApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/api-client';
import { Domain } from '@monsido/modules/models/api/domain';
import { User } from '@monsido/modules/models/api/user';
import { Customer } from '@monsido/modules/models/api/customer';
import { Params, PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { DomainDataType } from 'types/domain';

@Injectable()
export class CustomerRepo {
    constructor (private client: ApiClient) {}

    get (customerId: number, options?: Params, urlPrefix?: string): Promise<Customer> {
        return this.client.getPromise('customers/' + customerId, options, urlPrefix).then(this.toCustomerModel.bind(this));
    }

    // TODO remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAllUsers (customerId: number, params?: PlainHttpParams, headers?: HttpHeaders): Promise<any> {
        return this.client
            .getPromise(`customers/${customerId}/users`, createDefaultHttpParams(params, headers))
            .then(this.toUserModels.bind(this));
    }

    // TODO remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sudoUser (customerId: number, userId: number): Promise<any> {
        return this.client.postPromise('customers/' + customerId + '/users/' + userId + '/sudo', {});
    }

    // TODO remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    destroyUser (customerId: number, userId: number): Promise<any> {
        return this.client.deletePromise(`customers/${customerId}/users/${userId}`);
    }

    createUser (customerId: number, user: User): Promise<void> {
        return this.client.postPromise('customers/' + customerId + '/users', user).then(this.toUserModels.bind(this));
    }

    updateUser (customerId: number, user: User): Promise<void> {
        return this.client.patchPromise('customers/' + customerId + '/users/' + user.id, user).then(this.toUserModels.bind(this));
    }

    getDomain (customerId: number, domainId: number): Promise<void> {
        return this.client.getPromise(`customers/${customerId}/domains/${domainId}`).then(this.toDomainModels.bind(this));
    }

    updateDomain (customerId: number, domain: DomainDataType): Promise<Domain> {
        return this.client.patchPromise(`customers/${customerId}/domains/${domain.id}`, domain).then(this.toDomainModel.bind(this));
    }

    createDomain (customerId: number, domain: DomainDataType): Promise<Domain> {
        return this.client.postPromise(`customers/${customerId}/domains`, domain).then(this.toDomainModel.bind(this));
    }

    createDomainCrawls (customerId: number, domainId: number): Promise<void> {
        return this.client.postPromise(`customers/${customerId}/domains/${domainId}/crawls`, {});
    }

    private toUserModels (models: Array<User>): Array<User> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toUserModel(models[i]);
        }
        return models;
    }

    private toUserModel (model: User): User {
        return new User(model);
    }

    private toDomainModels (models: Array<Domain>): Array<Domain> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toDomainModel(models[i]);
        }
        return models;
    }

    private toDomainModel (model: Domain): Domain {
        return new Domain(model);
    }

    private toCustomerModels (models: Array<Customer>): Array<Customer> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toCustomerModel(models[i]);
        }
        return models;
    }

    private toCustomerModel (model: Customer): Customer {
        return new Customer(model);
    }
}
