import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessibilityCheckModel } from 'app/services/api/support/models/accessibility-check-model';
import { DialogService, FormBuilderSelectAcModule, LayoutModule, ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BackendAccessibilityChecksRepo } from 'app/services/api/support/admin/backend-accessibility-checks.repo';
import { AccessibilityChecksHelperService } from 'app/blocks/helpers/accessibility/accessibility-checks-helper.service';
import { cloneDeep } from 'lodash';
import { FormsModule, NgForm } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MonIconsPipe } from 'app/filters/mon-icons.pipe';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MonsidoDirectiveModule } from 'app/directives/directives.module';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

const summernoteClass = 'summernote-on-page';

@Component({
    selector: 'mon-accessibility-check',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        FormsModule,
        NgbTooltipModule,
        MonIconsPipe,
        NgxSummernoteModule,
        FormBuilderSelectAcModule,
        MonsidoDirectiveModule,
    ],
    templateUrl: './accessibility-check.component.html',
    styleUrls: ['./accessibility-check.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibilityCheckComponent implements OnInit, AfterViewInit {

    @Input() check: AccessibilityCheckModel;

    checkToUpdate: AccessibilityCheckModel;
    onSave: boolean = false;
    summernoteShow: boolean = false;
    levels: {name: string, value: number}[] = [];
    tags: {name: string, value: string, group: string}[] = [];
    responsibilities: {name: string, value: string}[] = [];
    impactLevels: {name: string, value: string}[] = [];
    guidelines: {name: string, value: string}[] = [];
    successCriteria: {name: string, value: string}[] = [];
    htmlElements: {name: string, value: string}[] = [];

    summernoteConfig: Record<string, number> = {
        height: 180,
    };

    constructor (
        private translateService: TranslateService,
        private backendAccessibilityChecksRepo: BackendAccessibilityChecksRepo,
        private toastService: ToastService,
        private accessibilityChecksHelperService: AccessibilityChecksHelperService,
        private dialogService: DialogService,
        private cdref: ChangeDetectorRef,
    ) {}

    ngOnInit (): void {
        if (this.check) {
            this.checkToUpdate = cloneDeep(this.check);
            this.onSave = false;
            if (!Array.isArray(this.checkToUpdate.tags)) {
                this.checkToUpdate.tags = [];
            }
            if (!Array.isArray(this.checkToUpdate.responsibilities)) {
                this.checkToUpdate.responsibilities = [];
            }
            if (!Array.isArray(this.checkToUpdate.others)) {
                this.checkToUpdate.others = [];
            }

            this.summernoteShow = false;
            this.levels = this.accessibilityChecksHelperService.getLevels();
            this.tags = this.accessibilityChecksHelperService.getTags();
            this.responsibilities = this.accessibilityChecksHelperService.getResponsibilities();
            this.impactLevels = this.accessibilityChecksHelperService.getImpactLevels();
            this.guidelines = this.accessibilityChecksHelperService.getGuidelines();
            this.successCriteria = this.accessibilityChecksHelperService.getSuccessCriteria();
            this.htmlElements = this.accessibilityChecksHelperService.getHTMLElements();

            if (!this.checkToUpdate.impact) {
                this.checkToUpdate.impact = this.impactLevels[0].value;
            }
        }
    }

    ngAfterViewInit (): void {
        this.summernoteShow = true;
        document.body.classList.add(summernoteClass);
        this.cdref.detectChanges();
        if (this.checkToUpdate) {
            this.hackyDropdownsSetUp();
        }
    }

    destroy (): void {
        document.body.classList.remove(summernoteClass);
    }

    hasOtherHeadline (): boolean {
        return typeof this.checkToUpdate.other_headline === 'string' && this.checkToUpdate.other_headline.length > 0;
    }

    addOther (): void {
        this.checkToUpdate.others.push({ title: '', description: '' });
    }

    removeOther (index: number): void {
        this.checkToUpdate.others.splice(index, 1);
    }

    submit (form: NgForm): void {
        if (this.onSave || !form.valid) {
            return;
        }

        if (!this.check || !(this.check.id > 0)) {
            return;
        }

        this.onSave = true;

        this.backendAccessibilityChecksRepo
            .update(this.checkToUpdate)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Accessibility check saved'));
                    this.dialogService.closeAll();
                },
                () => {
                    this.toastService.error(this.translateService.getString('We were unable to save the Accessibility Check Page'));
                    this.onSave = false;
                },
            );
    }

    // We need to set the values of mon-form-field-select-ac
    // like that, otherwise they are turned to undefined
    // we do not know why, but that happens only here
    // maybe a combination of overlay and onPush
    private hackyDropdownsSetUp (): void {
        const { difficulty, tags, responsibilities, html_element } = this.checkToUpdate;

        setTimeout(() => {
            this.checkToUpdate.difficulty = difficulty;
            this.checkToUpdate.tags = tags;
            this.checkToUpdate.responsibilities = responsibilities;
            this.checkToUpdate.html_element = html_element;
            this.cdref.detectChanges();
        }, 1000);
    }

}
