 <table class="table" monSelectAllCheckbox>
     <thead>
        <tr>
            <th>{{ 'Domains' | translate }}</th>
            <th>
                <input type="checkbox">
                {{ 'Visible' | translate }}
            </th>
            <th>
                <input type="checkbox">
                {{ 'Send report' | translate }}
            </th>
        </tr>
     </thead>
     <tbody>
        <ng-container *ngFor="let domain of domains">
            <tr>
                <td>{{ domain.title }}</td>
                <td>
                    <input type="checkbox"
                           [disabled]="user.customer_admin"
                           [checked]="isDomainVisible(domain)"
                           (click)="setDomainVisible(domain)">
                </td>
                <td>
                    <input type="checkbox"
                           [disabled]="!isDomainVisible(domain)"
                           [checked]="hasSendReport(domain)"
                           (click)="setDomainSendReport(domain)"
                    >
                </td>
            </tr>
            <tr *ngFor="let domainGroup of domain.domain_groups">
                <td>
                    <span class="visibility-45 mr-1" [ngClass]="['DOMAIN_GROUPS', 'ICON']"></span>
                    <span>{{ domainGroup.title }}</span>
                </td>
                <td>
                    <input type="checkbox"
                           [disabled]="user.customer_admin"
                           [checked]="isDomainGroupVisible(domainGroup)"
                           (click)="setDomainGroupVisible(domainGroup)"
                    >
                </td>
                <td>
                    <input type="checkbox"
                           [disabled]="!isDomainGroupVisible(domainGroup)"
                           [checked]="hasSendReportGroup(domainGroup)"
                           (click)="setDomainGroupSendReport(domainGroup)"
                    >
                </td>
            </tr>
        </ng-container>
     </tbody>
 </table>