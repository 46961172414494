<div class="table-container-body">
    <table class="table table-fixed table-hover">
        <thead>
            <tr>
                <th translate>Key</th>
                <th translate class="text-right">Value</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Canonical Redirect</td>
                <td class="text-right request-word-wrapper">
                    {{ scan.canonical_redirect }}
                </td>
            </tr>
            <tr>
                <td>Redirect</td>
                <td class="text-right request-word-wrapper">
                    {{ scan.redirect }}
                </td>
            </tr>
            <tr *ngIf="scan.redirect">
                <td>Redirects</td>
                <td class="text-right request-word-wrapper">
                    <div>{{ url }}</div>
                    <div *ngFor="let redirect of scan.redirects">
                        {{ redirect }}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
