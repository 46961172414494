import moment from 'moment/moment';
import {
    DomainGroupMembersEntity,
    DomainUsersEntity, EmailNotifications,
    UserAgreementsEntity,
    UserContactInfo, UserCustomer,
    UserInterface, UserSettings,
    UserSocialMedia,
} from '@monsido/modules/models/api/interfaces/user.interface';
export class BackendUserModel implements UserInterface {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    locale: string;
    timezone?: string | null;
    contact_info?: UserContactInfo | null;
    social_media: UserSocialMedia;
    role_id?: null;
    title?: null;
    description?: null;
    phone?: null;
    created_at: string;
    updated_at: string;
    last_login_at?: string | null;
    user_agreements?: UserAgreementsEntity[] | null;
    labels?: null[] | null;
    customer?: UserCustomer;
    customer_admin?: boolean;
    customer_id?: number;
    domain_users?: DomainUsersEntity[] | null;
    domain_group_members?: DomainGroupMembersEntity[] | null;
    settings?: UserSettings;
    email_notifications?: EmailNotifications;
    association_level?: number;
    can_sudo?: boolean;
    api_key?: null;
    constructor (params: UserInterface) {
        this.id = params.id;
        this.email = params.email;
        this.first_name = params.first_name;
        this.last_name = params.last_name;
        this.locale = params.locale;
        this.timezone = params.timezone;
        this.contact_info = params.contact_info;
        this.social_media = params.social_media;
        this.role_id = params.role_id;
        this.title = params.title;
        this.description = params.description;
        this.phone = params.phone;
        this.user_agreements = params.user_agreements;
        this.labels = params.labels;
        this.customer = params.customer;
        this.customer_admin = params.customer_admin;
        this.customer_id = params.customer_id;
        this.domain_users = params.domain_users;
        this.domain_group_members = params.domain_group_members;
        this.settings = params.settings;
        this.email_notifications = params.email_notifications;
        this.association_level = params.association_level;
        this.can_sudo = params.can_sudo;
        this.api_key = params.api_key;

        this.updated_at = moment(params.updated_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
        this.created_at = moment(params.created_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
        this.last_login_at = moment(params.last_login_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
    }
}
