<div class="col-lg-48 outer-l-none inner-l-none">
    <mon-panel-header
            pHeader="{{'Users for this customer' | translate}}"
            subHeader="{{'This account has ' | translate}} {{ users?.total }} {{ 'users right now' | translate}}">
        <div class="d-flex align-items-center">
            <div class="mr-4">
                <button class="btn btn-secondary py-2 px-3 mr-1"
                        ngbTooltip="{{'CSV import users' | translate}}"
                        [attr.aria-label]="'CSV import users' | translate"
                        data-test="csv-import-users-button"
                        (click)="importUsers.emit()">
                    <span class="fa-lg" [ngClass]="['ACTIONS', 'IMPORT'] | monIcons"></span>
                </button>
                <button class="btn btn-secondary py-2 px-3"
                        type="button"
                        (click)="createUser.emit()"
                        ngbTooltip="{{'Add new user' | translate}}"
                        data-test="add-new-user-button"
                        [attr.aria-label]="'Add new user' | translate">
                    <span class="fas fa-plus"></span>
                    <span class="fa-lg" [ngClass]="['USERS', 'SINGLE_ICON'] | monIcons" aria-hidden="true"></span>
                </button>
            </div>
            <div>
                <mon-table-search [model]="search" [minSearchLength]="1" (getPage)="onGetParamsChanged('search', $event)" data-test="table-search"></mon-table-search>
            </div>
        </div>
    </mon-panel-header>
    <div class="card-body">
        <div class="col-md-48 col-lg-48 col-xs-48">
            <mon-table-container
                    [collection]="users"
                    (pageChangeEvent)="onGetParamsChanged('page', $event)"
                    (perPageChange)="onGetParamsChanged('pageSize', $event)"
                    class="table-vertial-align"
                    [loading]="loading">

                <div class="table-container-body">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="col-sm-12 col-md-12 col-lg-10" translate>User</th>
                            <th class="col-sm-8 col-md-7 col-lg-4" translate>Latest login</th>
                            <th class="col-sm-4 col-md-4 col-lg-2" translate>Role</th>
                            <th class="text-right col-sm-5 col-md-5 col-lg-4" translate></th>
                            <th class="text-right col-sm-5 col-md-5 col-lg-4" translate *ngIf="['admin', 'reseller_admin', 'reseller'] | monRole"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of users">
                            <td>
                                <mon-email-avatar [user]="user"></mon-email-avatar>
                            </td>
                            <td>
                                <mon-user-date [twoLines]="true" [date]="user.last_login_at"></mon-user-date>
                            </td>
                            <td>
                                <span *ngIf="user.customer_admin">
                                    {{ 'Admin' | translate }}
                                </span>
                            </td>
                            <td class="text-right">
                                <mon-table-dropdown data-test="action-button">
                                    <li role="menuitem" class="cursor-pointer" (click)="onEditUser(user)" data-test="edit-button">
                                        <a>
                                            <span [ngClass]="['ACTIONS', 'EDIT'] | monIcons" class="mr-1"></span>
                                            <span translate>Edit</span>
                                        </a>
                                    </li>
                                    <li role="menuitem"
                                        monConfirm="{{'Are you sure you want to delete this customer-user?' | translate}}"
                                        (monConfirmAction)="onDeleteUser(user)"
                                        data-test="delete-user"
                                        class="cursor-pointer">
                                        <a>
                                            <span [ngClass]="['ACTIONS', 'DELETE'] | monIcons" class="mr-1"></span>
                                            <span translate>Delete</span>
                                        </a>
                                    </li>
                                </mon-table-dropdown>
                            </td>
                            <td class="text-right">
                                <mon-sudo-button [user]="user" [customer]="customer" (sudoEvent)="onSudo($event)" data-test="sudo-button">
                                </mon-sudo-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <hr class="line-thickness-4 outer-t-none"/>
            </mon-table-container>
        </div>
    </div>
</div>
