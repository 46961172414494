import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { ModulesModule } from '@monsido/modules/modules.module';
import { LoaderModule } from '@monsido/core/loader/loader.module';

import { RollbarModule } from '../ng2/external/rollbar/rollbar.module';
import { RollbarService } from '../ng2/external/rollbar/rollbar.service';
import { environment } from '../environments/environment';

import { FormsModule, NgForm } from '@angular/forms';
import { FormBuilderDynamicModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonEventModule } from '@monsido/services/mon-event/mon-event.module';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from '@monsido/services/timepicker-adapter/timepicker-adapter.service';
import { CommonServicesModule } from './common/services/common-services.module';
import { MonSrefDirective } from './directives/mon-sref.directive';
import { MonIconsPipe } from './filters/mon-icons.pipe';
import { UIRouter, UIRouterModule } from '@uirouter/angular';
import { DOMAIN_STATES } from './pages/admin/domains/domains.route';
import { LAYOUT_STATES } from './layout/layout.route';
import { SOURCE_CODE_EXCLUDE_STATES } from './pages/admin/source-code-excludes/source-code-exclude.route';
import { BACKEND_ADMIN_STATES } from './pages/backend_admin/backend-admin.route';
import { BACKEND_ADMIN_DOMAINS_STATES } from './pages/backend_admin/backend-admin-domains/domains.route';
import { BACKEND_ADMIN_CUSTOMERS_STATES } from './pages/backend_admin/customers/customers.route';
import { BACKEND_ADMIN_RESELLERS_STATES } from './pages/backend_admin/resellers/resellers.route';
import { BACKEND_ADMIN_USERS_STATES } from './pages/backend_admin/users/users.route';
import { ADMIN_STATES } from './pages/admin/admin.route';
import { CUSTOMERS_STATES } from './pages/admin/customers/customers.route';
import { LOGIN_STATES } from './pages/login/login.route';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

const devRollbarService = {
    provide: ErrorHandler,
    useClass: RollbarService,
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        LoaderModule,
        BrowserModule,
        FormsModule,
        MonEventModule,
        ModulesModule,
        FormBuilderDynamicModule,
        MonSrefDirective,
        RollbarModule.forRoot({
            accessToken: environment.rollBarId,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: { environment: environment.env },
        }),
        CommonServicesModule,
        MonIconsPipe,
        UIRouterModule.forRoot({ states: [
            ...LAYOUT_STATES,
        ], config: (router: UIRouter) => {
            router.urlService.rules.initial({ state: 'base.agreement_selector.index' });
            router.urlService.rules.otherwise({ state: 'base.agreement_selector.index' });
        } }),
        UIRouterModule.forChild({ states: [
            ...ADMIN_STATES,
            ...LOGIN_STATES,
            ...BACKEND_ADMIN_USERS_STATES,
            ...BACKEND_ADMIN_RESELLERS_STATES,
            ...BACKEND_ADMIN_CUSTOMERS_STATES,
            ...BACKEND_ADMIN_DOMAINS_STATES,
            ...BACKEND_ADMIN_STATES,
            ...SOURCE_CODE_EXCLUDE_STATES,
            ...DOMAIN_STATES,
            ...CUSTOMERS_STATES,
        ] }),
    ],
    providers: [
        // NgForm should be removed when all the forms related components are upgraded
        NgForm,
        // NB! Do not use the 'RollbarService' in development as it blocks error messages.
        // This is probably the most unsatisfying solution I have had to implement.
        !environment.production ? ErrorHandler : devRollbarService,
        {
            provide: NgbTimeAdapter,
            useClass: NgbTimeStringAdapter,
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
