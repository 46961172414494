import { Component, OnInit } from '@angular/core';
import { CrawlQueueService } from './crawl-queue.service';
import { SessionService } from '@monsido/core/session/session.service';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { ParamService } from '@monsido/core/param/param.service';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { Domain } from '@monsido/modules/models/api/interfaces/crawl-queue.interface';

@Component({
    selector: 'mon-crawl-queue',
    templateUrl: 'crawl-queue.html',
})
export class CrawlQueueComponent extends BaseApiComponent implements OnInit {
    search: string;
    crawls: CrawlQueue[] = [];
    orgCrawls: CrawlQueue[] = [];
    loading: boolean;
    isAdmin: boolean = false;
    constructor (
        $location: UrlService,
        private crawlQueueService: CrawlQueueService,
        private sessionService: SessionService,
        private paramService: ParamService,
    ) {
        super($location);
    }

    ngOnInit (): void {
        this.urlParams = [
            {
                name: 'search',
                type: 'string',
                default: null,
                propertyName: 'search',
            },
        ];
        this.loadLocations();
        this.startAutoReload();
        this.isAdmin = this.sessionService.hasRole('admin');
    }

    goToDomain (domain: Domain): void {
        this.crawlQueueService.goToDomain(domain);
    }

    // TODO: Find an alternative, instead of relying on lifecycle hooks. https://zube.io/monsido/monsido/c/21921
    ngOnDestroy (): void {
        this.crawlQueueService.destroyTimer();
    }

    onSearch (search: string): void {
        this.search = search;
        this.paramService.setParams({
            search: search,
        });
        this.setCrawls();
    }

    getPage (noSpinner?: boolean): void {
        this.crawlQueueService.getAllCrawlQueue().then(
            (data) => {
                this.orgCrawls = data.slice();
                this.setCrawls();
                this.loading = false;
            },
            (res) => {
                if (!noSpinner) {
                    this.loading = res.status === -1 && res.xhrStatus === 'abort';
                }
            },
        );
    }

    startAutoReload (): void {
        this.crawlQueueService.timeout = setTimeout(() => {
            this.getPage(true);
            this.startAutoReload();
        }, 30000);
    }

    stopCrawl (crawl: CrawlQueue): void {
        this.crawlQueueService.stopCrawl(crawl.domain.id).then(
            () => {
                this.getPage();
            },
            () => {},
        );
    }

    openCrawl (e: MouseEvent, crawl: CrawlQueue): void {
        e.preventDefault();
        this.crawlQueueService.openDialog(crawl, () => {
            this.getPage();
        });
    }

    private setCrawls (): void {
        this.crawls = this.crawlQueueService.getCrawls(this.orgCrawls, this.search);
    }
}
