<form name="form" #planForm="ngForm" class="form-horizontal plan-edit-form" (ngSubmit)="planForm.valid && submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['PLANS', 'ICON']"
            header="{{'New plan' | translate }}"
            *ngIf="!localPlan.id">
        </mon-page-header>
        <mon-page-header [monIcon]="['PLANS', 'ICON']"
            header="{{'Edit plan' | translate }}"
            *ngIf="localPlan.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Plan details
                </h3>
            </div>
            <div class="card-body">
                <mon-form-field-input [(ngModel)]="localPlan.name"
                    required
                    #plan_name="ngModel"
                    monLabel="{{ 'Name' | translate }}"
                    monPlaceholder="{{ 'Name' | translate }}"
                    [type]="'text'"
                    [name]="'name'">
                </mon-form-field-input>
                <mon-form-field-errors
                    [showError]="plan_name.control.touched && plan_name.control.invalid"
                    [errors]="plan_name.control.errors"></mon-form-field-errors>
                <mon-form-field-input [(ngModel)]="localPlan.code"
                    required
                    #plan_code="ngModel"
                    monLabel="{{ 'Code' | translate }}"
                    monPlaceholder="{{ 'Code' | translate }}"
                    [type]="'text'"
                    [name]="'code'">
                </mon-form-field-input>
                <mon-form-field-errors
                    [showError]="plan_code.control.touched && plan_code.control.invalid"
                    [errors]="plan_code.control.errors"></mon-form-field-errors>
                <mon-form-field-select [monName]="'group'"
                    [(model)]="localPlan.group"
                    [monOptions]="groups"
                    monReturnValue="id"
                    monLabel="{{ 'Group' | translate }}"
                    monPlaceholder="{{ 'Select a group' | translate }}"
                    monRequired="true"
                    [monNoAutoComplete]="true">
                </mon-form-field-select>
                <mon-form-field-select [monName]="'type'"
                    [(model)]="localPlan.type"
                    [monOptions]="types"
                    monReturnValue="value"
                    monLabel="{{ 'Type' | translate }}"
                    monPlaceholder="{{ 'Select a type' | translate }}"
                    monRequired="true"
                    [monNoAutoComplete]="true">
                </mon-form-field-select>
                <mon-form-field-checkbox [(model)]="localPlan.active"
                    monLabel="{{'Active' | translate}}"
                    [monName]="'active'">
                </mon-form-field-checkbox>
            </div>
        </div>
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Prices
                </h3>
            </div>
            <div class="card-body">
                <div class="row" *ngFor="let month of prices">
                    <div class="col-8 col-sm-8"><span>{{ month | translate}}</span></div>
                    <div class="col-40 col-sm-10 price-column">
                        <mon-form-field-input [(ngModel)]="localPlan.price[month].dkk"
                            #plan_price="ngModel"
                            monLabel="{{ 'DKK' | translate }}"
                            monPlaceholder="{{ 'DKK' | translate }}"
                            [type]="'number'"
                            [name]="month + '-DKK'"
                            [monId]="month + '-DKK'"
                            [min]="0"
                            [monMin]="0">
                        </mon-form-field-input>
                        <mon-form-field-errors
                            [showError]="plan_price.control.touched && plan_price.control.invalid"
                            [errors]="plan_price.control.errors"></mon-form-field-errors>
                    </div>
                    <div class="col-40 col-sm-10 price-column">
                        <mon-form-field-input [(ngModel)]="localPlan.price[month].usd"
                            #plan_price_usd="ngModel"
                            monLabel="{{ 'USD' | translate }}"
                            monPlaceholder="{{ 'USD' | translate }}"
                            [type]="'number'"
                            [name]="month + '-USD'"
                            [monId]="month + '-USD'"
                            [min]="0"
                            [monMin]="0">
                        </mon-form-field-input>
                        <mon-form-field-errors
                            [showError]="plan_price_usd.control.touched && plan_price_usd.control.invalid"
                            [errors]="plan_price_usd.control.errors"></mon-form-field-errors>
                    </div>
                </div>
            </div>
        </div>
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Unique traits
                </h3>
            </div>
            <div class="card-body p-0">
                <div class="unique-trait-section" id="plan_trait_scan_limits">
                    <h4 class="unique-trait-section-header" translate>Scan limits</h4>
                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="max_domains">{{ 'max_domains' | monTranslatePlanTraits  }}</label>
                            <mon-form-field-input [(ngModel)]="localPlan.unique_traits.max_domains"
                                class="pl-3"
                                monId="max_domains"
                                monPlaceholder="{{ 'Max. domains' | translate }}"
                                [type]="'number'"
                                [name]="'max_domains'"
                                [min]="0"
                                [monMin]="0">
                            </mon-form-field-input>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="max_scanned_pages">{{ 'max_scanned_pages' | monTranslatePlanTraits }}</label>
                            <mon-form-field-input [(ngModel)]="localPlan.unique_traits.max_scanned_pages"
                                class="pl-3"
                                monId="max_scanned_pages"
                                monPlaceholder="{{ 'Max. scanned pages' | translate }}"
                                [type]="'number'"
                                [name]="'max_scanned_pages'"
                                [min]="0"
                                [monMin]="0">
                            </mon-form-field-input>
                        </div>
                    </div>
                </div>
                <div class="unique-trait-section" id="plan_trait_scan_features">
                    <h4 class="unique-trait-section-header" translate>Scan features</h4>
                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="scan_interval">{{ 'scan_interval' | monTranslatePlanTraits }}</label>
                            <mon-form-field-select
                                class="pl-3"
                                [monName]="'scan_interval'"
                                monId="scan_interval"
                                [monReturnValue]="'value'"
                                [monOptions]="scanFrequenciesOptions"
                                [(model)]="localPlan.unique_traits.scan_interval">
                            </mon-form-field-select>
                        </div>
                        <div class="mon-form-field-inline-container">
                           <label for="custom_scan_interval">{{ 'custom_scan_interval' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.custom_scan_interval"
                                                     monId="custom_scan_interval"
                                                     [monName]="'custom_scan_interval'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="on_demand_scans">{{ 'on_demand_scans' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.on_demand_scans"
                                monId="on_demand_scans"
                                [monName]="'on_demand_scans'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="single_page_scan_any_url">{{ 'single_page_scan_any_url' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.single_page_scan_any_url"
                                monId="single_page_scan_any_url"
                                [monName]="'single_page_scan_any_url'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="image_metadata">{{ 'image_metadata' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.image_metadata"
                                monId="image_metadata"
                                [monName]="'image_metadata'">
                            </mon-form-field-checkbox>
                        </div>
                    </div>
                </div>
                <div class="unique-trait-section" id="plan_trait_modules">
                    <h4 class="unique-trait-section-header" translate>Modules</h4>
                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container unique-trait-section-sub-header">
                            <label for="accessibility">{{'accessibility' | translate}}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.accessibility"
                                [monId]="'accessibility'"
                                [monName]="'accessibility'"
                                (change)="onAccessibilityModelChanged(localPlan.unique_traits.accessibility)">
                            </mon-form-field-checkbox>
                        </div>

                        <div class="unique-trait-section-sub-body" *ngIf="localPlan.unique_traits.accessibility">
                            <div class="mon-form-field-inline-container">
                                <label for="mobile_guidelines">{{'mobile_guidelines' | monTranslatePlanTraits}}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.mobile_guidelines"
                                    [monId]="'mobile_guidelines'"
                                    [monName]="'mobile_guidelines'"
                                    monHelpText="{{ 'Only applicable if Browser service Version 2 (Puppeteer) has been enabled for the customer' | translate }}">
                                </mon-form-field-checkbox>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="enable_PDF_clarity">{{ 'clarity_pdf_accessibility' | monTranslatePlanTraits}}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.clarity_pdf_accessibility"
                                    [monId]="'enable_PDF_clarity'"
                                    [monName]="'enable_PDF_clarity'">
                                </mon-form-field-checkbox>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="enable_PDF_remediation">{{'clarity_pdf_remediation' | monTranslatePlanTraits}}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.clarity_pdf_remediation"
                                    [monId]="'enable_PDF_remediation'"
                                    [monName]="'enable_PDF_remediation'">
                                </mon-form-field-checkbox>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="accessibility_summary">{{'accessibility_summary' | monTranslatePlanTraits}}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.accessibility_summary"
                                                         [monId]="'accessibility_summary'"
                                                         monName="accessibility_summary">
                                </mon-form-field-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="unique-trait-section-body">
                        <div class="unique-trait-module-label">Quality Assurance</div>
                        <div class="unique-trait-section-sub-body">
                            <div class="mon-form-field-inline-container">
                                <label for="qa_links">{{ 'qa_links' | monTranslatePlanTraits }}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.qa_links"
                                             [monId]="'qa_links'"
                                             monName="qa_links">
                                </mon-form-field-checkbox>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="qa_links">{{ 'qa_spellcheck' | monTranslatePlanTraits }}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.qa_spellcheck"
                                                         [monId]="'qa_spellcheck'"
                                                         monName="qa_spellcheck">
                                </mon-form-field-checkbox>
                            </div>

                            <div class="mon-form-field-inline-container">
                                <label for="unique_traits_spell_check_engine_priority">
                                       {{ 'spell_check_engine_priority' | monTranslatePlanTraits }}
                                </label>
                                <div class="flex-fill pl-3">
                                    <mon-form-field-select [monMultiple]="true"
                                                           monReturnValue="value"
                                                           monPlaceholder="{{ 'Select one or multiple spellcheck dictionary preferences' | translate }}"
                                                           [monOptions]="spellCheckEngineOptions"
                                                           [(model)]="localPlan.unique_traits.spell_check_engine_priority"
                                                           (changeEvent)="onSelectionChange()"
                                                           [monName]="'unique_traits_spell_check_engine_priority'"
                                                           id="unique_traits_spell_check_engine_priority">
                                    </mon-form-field-select>
                                </div>
                            </div>

                            <div class="mon-form-field-inline-container">
                                <label for="readability">{{ 'readability' | monTranslatePlanTraits }}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.readability"
                                    [monId]="'readability'"
                                    [monName]="'readability'">
                                </mon-form-field-checkbox>
                            </div>

                            <div class="mon-form-field-inline-container">
                                <label for="qa_summary">{{ 'qa_summary' | monTranslatePlanTraits }}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.qa_summary"
                                             [monId]="'qa_summary'"
                                             monName="qa_summary">
                                </mon-form-field-checkbox>
                            </div>

                        </div>
                    </div>

                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="policies_all">{{ 'policies_all' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.policies_all"
                                monId="policies_all"
                                monName="policies_all">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="seo_all">{{ 'seo_all' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.seo_all"
                                monId="seo_all"
                                monName="seo_all">
                            </mon-form-field-checkbox>
                        </div>
                    </div>

                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container unique-trait-section-sub-header">
                            <label for="statistics">{{ 'statistics' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.statistics"
                                monId="statistics"
                                [monName]="'statistics'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="unique-trait-section-sub-body" *ngIf="localPlan.unique_traits.statistics">
                            <div class="mon-form-field-inline-container">
                                <label for="domain_group_statistics">{{ 'domain_group_statistics' | monTranslatePlanTraits }}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.domain_group_statistics"
                                    monId="domain_group_statistics"
                                    [monName]="'domain_group_statistics'">
                                </mon-form-field-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="data_protection">{{ 'data_protection' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.data_protection"
                                monId="data_protection"
                                [monName]="'data_protection'">
                            </mon-form-field-checkbox>
                        </div>

                        <div class="mon-form-field-inline-container">
                            <label for="cookie_banner">{{ 'cookie_banner' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.cookie_banner"
                                monId="cookie_banner"
                                [monName]="'cookie_banner'">
                            </mon-form-field-checkbox>
                        </div>

                        <div class="mon-form-field-inline-container">
                            <label for="inventory_all">{{ 'inventory_all' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.inventory_all"
                                monId="inventory_all"
                                monName="inventory_all">
                            </mon-form-field-checkbox>
                        </div>
                    </div>

                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container unique-trait-section-sub-header">
                            <label for="heartbeat">{{ 'heartbeat' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.heartbeat"
                                monId="heartbeat"
                                [monName]="'heartbeat'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="unique-trait-section-sub-body" *ngIf="localPlan.unique_traits.heartbeat">
                            <div class="mon-form-field-inline-container">
                                <label for="unique_traits_uptime_intervals">
                                    {{ 'uptime_intervals' | monTranslatePlanTraits }}
                                </label>
                                <div class="flex-fill pl-3">
                                    <mon-form-field-select [monMultiple]="true"
                                                           monReturnValue="value"
                                                           monPlaceholder="{{ '   Select one or multiple interval options' | translate }}"
                                                           [monOptions]="intervalOptions"
                                                           [(model)]="localPlan.unique_traits.uptime_intervals"
                                                           (changeEvent)="onSelectionChange()"
                                                           [monName]="'unique_traits_uptime_intervals'"
                                                           id="unique_traits_uptime_intervals">
                                    </mon-form-field-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container unique-trait-section-sub-header">
                            <label for="enable_performance_tracking">{{ 'performance_tracking' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.performance_tracking"
                                monId="enable_performance_tracking"
                                [monName]="'enable_performance_tracking'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="unique-trait-section-sub-body" *ngIf="localPlan.unique_traits.performance_tracking">
                            <div class="mon-form-field-inline-container">
                                <label for="unique_traits_performance_tracking_allowed_frequencies">
                                    {{ 'performance_tracking_allowed_frequencies' | monTranslatePlanTraits }}
                                </label>
                                <div class="flex-fill pl-3">
                                    <mon-form-field-select [monMultiple]="true"
                                        monReturnValue="value"
                                        monPlaceholder="{{ '   Select one or multiple performance frequency options' | translate }}"
                                        [monOptions]="performanceFrequencies"
                                        [(model)]="localPlan.unique_traits.performance_tracking_allowed_frequencies"
                                        [monName]="'unique_traits_performance_tracking_allowed_frequencies'"
                                        id="unique_traits_performance_tracking_allowed_frequencies">
                                    </mon-form-field-select>
                                </div>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="performance_tracking_max_page_profiles">{{ 'performance_tracking_max_page_profiles' | monTranslatePlanTraits }}</label>
                                <mon-form-field-input [(ngModel)]="localPlan.unique_traits.performance_tracking_max_page_profiles"
                                    class="pl-3"
                                    monPlaceholder="{{ 'performance_tracking_max_page_profiles' | monTranslatePlanTraits }}"
                                    [type]="'number'"
                                    [name]="'performance_tracking_max_page_profiles'"
                                    [monMin]="0"
                                    [min]="0"
                                    monId="performance_tracking_max_page_profiles">
                                </mon-form-field-input>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="performance_tracking_max_pages">{{ 'performance_tracking_max_pages' | monTranslatePlanTraits }}</label>
                                <mon-form-field-input [(ngModel)]="localPlan.unique_traits.performance_tracking_max_pages"
                                    class="pl-3"
                                    monPlaceholder="{{ 'performance_tracking_max_pages' | monTranslatePlanTraits }}"
                                    [type]="'number'"
                                    [name]="'performance_tracking_max_pages'"
                                    [monMin]="0"
                                    [min]="0"
                                    monId="performance_tracking_max_pages">
                                </mon-form-field-input>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="performance_tracking_max_profiles">{{ 'performance_tracking_max_profiles' | monTranslatePlanTraits }}</label>
                                <mon-form-field-input [(ngModel)]="localPlan.unique_traits.performance_tracking_max_profiles"
                                    class="pl-3"
                                    monPlaceholder="{{ 'performance_tracking_max_profiles' | monTranslatePlanTraits }}"
                                    monId="performance_tracking_max_profiles"
                                    [type]="'number'"
                                    [name]="'performance_tracking_max_profiles'"
                                    [monMin]="0"
                                    [min]="0">
                                </mon-form-field-input>
                            </div>
                            <div class="mon-form-field-inline-container">
                                <label for="enable_performance_on_demand_scan">{{ 'performance_tracking_on_demand_scan' | monTranslatePlanTraits }}</label>
                                <mon-form-field-checkbox [(model)]="localPlan.unique_traits.performance_tracking_on_demand_scan"
                                                    monId="enable_performance_on_demand_scan"
                                                    [monName]="'enable_performance_on_demand_scan'">
                                </mon-form-field-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="prioritized_content_all">{{ 'prioritized_content_all' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.prioritized_content_all"
                                monId="prioritized_content_all"
                                monName="prioritized_content_all">
                            </mon-form-field-checkbox>
                        </div>

                        <div class="mon-form-field-inline-container">
                            <label for="history_center_all">{{ 'history_center_all' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.history_center_all"
                                monId="history_center_all"
                                monName="history_center_all">
                            </mon-form-field-checkbox>
                        </div>

                        <div class="mon-form-field-inline-container">
                            <label for="report_center_all">{{ 'report_center_all' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.report_center_all"
                                monId="report_center_all"
                                monName="report_center_all">
                            </mon-form-field-checkbox>
                        </div>

                        <div class="mon-form-field-inline-container">
                            <label for="enable_Monsido_PageAssist">{{ 'page_assist' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.page_assist"
                                monId="enable_Monsido_PageAssist"
                                [monName]="'enable_Monsido_PageAssist'">
                            </mon-form-field-checkbox>
                        </div>

                        <div class="mon-form-field-inline-container">
                            <label for="enable_PageCorrect">{{ 'page_fix' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.page_fix"
                                monId="enable_PageCorrect"
                                [monName]="'enable_PageCorrect'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="enable_AdvancedAnalytics">{{ 'advanced_analytics' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.advanced_analytics"
                                monId="enable_AdvancedAnalytics"
                                [monName]="'enable_AdvancedAnalytics'">
                            </mon-form-field-checkbox>
                        </div>
                    </div>
                </div>

                <div class="unique-trait-section" id="plan_trait_heatmaps">
                    <h4 class="unique-trait-section-header" translate>Heatmaps</h4>
                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="enable_click_heatmap">{{ 'heatmap_click' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.heatmap_click"
                                monId="enable_click_heatmap"
                                [monName]="'enable_click_heatmap'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="enable_movement_heatmap">{{ 'heatmap_movement' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.heatmap_movement"
                                                     monId="enable_movement_heatmap"
                                                     [monName]="'enable_movement_heatmap'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="enable_scroll_heatmap">{{ 'heatmap_scroll' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.heatmap_scroll"
                                monId="enable_scroll_heatmap"
                                [monName]="'enable_scroll_heatmap'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="heatmap_track_number_of_sessions">{{ 'heatmaps_monthly_sessions' | monTranslatePlanTraits }}</label>
                            <mon-form-field-input [(ngModel)]="localPlan.unique_traits.heatmaps_monthly_sessions"
                                class="pl-3"
                                monPlaceholder="{{ 'heatmaps_monthly_sessions' | monTranslatePlanTraits }}"
                                [type]="'number'"
                                [name]="'heatmap_track_number_of_sessions'"
                                monId="heatmap_track_number_of_sessions"
                                [min]="0"
                                [monMin]="0">
                            </mon-form-field-input>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="heatmap_track_number_of_pages">{{ 'heatmaps_tracked_number_of_pages' | monTranslatePlanTraits }}</label>
                            <mon-form-field-input [(ngModel)]="localPlan.unique_traits.heatmaps_tracked_number_of_pages"
                                class="pl-3"
                                monPlaceholder="{{ 'heatmaps_tracked_number_of_pages' | monTranslatePlanTraits }}"
                                [type]="'number'"
                                [name]="'heatmap_track_number_of_pages'"
                                monId="heatmap_track_number_of_pages"
                                [min]="0"
                                [monMin]="0">
                            </mon-form-field-input>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="heatmaps_traffic_percentage">
                                {{ 'heatmaps_traffic_percentage' | monTranslatePlanTraits }}
                            </label>
                            <div class="flex-fill pl-3">
                                <mon-form-field-input
                                    class="pl-3"
                                    monId="heatmaps_traffic_percentage"
                                    monName="unique_traits_heatmaps_traffic_percentage"
                                    name="unique_traits_heatmaps_traffic_percentage"
                                    type="number"
                                    #unique_traits_heatmaps_traffic_percentage="ngModel"
                                    monPlaceholder="{{ 'heatmaps_traffic_percentage' | monTranslatePlanTraits }}"
                                    [(ngModel)]="localPlan.unique_traits.heatmaps_traffic_percentage"
                                    [monMax]="100"
                                    [monMin]="0" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="unique-trait-section" id="plan_trait_ui_features">
                    <h4 class="unique-trait-section-header" translate>UI features</h4>
                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="data_export">{{ 'data_export' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.data_export"
                                                     monId="data_export"
                                                     monName="data_export">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="monsido_score">{{ 'monsido_score' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.monsido_score"
                                monId="monsido_score"
                                monName="monsido_score">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="page_details">{{ 'page_details' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.page_details"
                                                     monId="page_details"
                                                     monName="page_details">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="script_setup_guide">{{ 'script_setup_guide' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.script_setup_guide"
                                                     monId="script_setup_guide"
                                                     monName="script_setup_guide">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="quick_search">{{ 'quick_search' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.quick_search"
                                                     monId="quick_search"
                                                     monName="quick_search">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="account_settings">{{ 'account_settings' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.account_settings"
                                                     monId="account_settings"
                                                     monName="account_settings">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="dashboard_important_notifications">{{ 'dashboard_important_notifications' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.dashboard_important_notifications"
                                                     monId="dashboard_important_notifications"
                                                     monName="dashboard_important_notifications">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="general_shortcuts">{{ 'general_shortcuts' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.general_shortcuts"
                                                     monId="general_shortcuts"
                                                     monName="general_shortcuts">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="enable_source_code_excludes">{{ 'source_code_excludes' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.source_code_excludes"
                                monId="enable_source_code_excludes"
                                [monName]="'enable_source_code_excludes'">
                            </mon-form-field-checkbox>
                        </div>
                    </div>
                </div>

                <div class="unique-trait-section" id="plan_trait_customer_support">
                    <h4 class="unique-trait-section-header" translate>Customer support</h4>
                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="general_book_training">{{ 'general_book_training' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.general_book_training"
                                                     monId="general_book_training"
                                                     monName="general_book_training">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="general_chat_support">{{ 'general_chat_support' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.general_chat_support"
                                                     monId="general_chat_support"
                                                     monName="general_chat_support">
                            </mon-form-field-checkbox>
                        </div>
                    </div>
                </div>

                <div class="unique-trait-section" id="plan_trait_other">
                    <h4 class="unique-trait-section-header" translate>Other</h4>
                    <div class="unique-trait-section-body">
                        <div class="mon-form-field-inline-container">
                            <label for="multiple_user_agreements">{{ 'multiple_user_agreements' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.multiple_user_agreements"
                                                     monId="multiple_user_agreements"
                                                     monName="multiple_user_agreements">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="enable_API_users">{{ 'api_users' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.api_users"
                                monId="enable_API_users"
                                [monName]="'enable_API_users'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="paying">{{ 'paying' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.paying"
                                monId="paying"
                                [monName]="'paying'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="enable_closed_caption">{{ 'closed_caption' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.closed_caption"
                                monId="enable_closed_caption"
                                [monName]="'enable_closed_caption'">
                            </mon-form-field-checkbox>
                        </div>
                        <div class="mon-form-field-inline-container">
                            <label for="nag_for_upgrade">{{ 'nag_for_upgrade' | monTranslatePlanTraits }}</label>
                            <mon-form-field-checkbox [(model)]="localPlan.unique_traits.nag_for_upgrade"
                                monId="nag_for_upgrade"
                                [monName]="'nag_for_upgrade'">
                            </mon-form-field-checkbox>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-secondary" [disabled]="saving || !canSave()" type="submit" data-test="save-button">
            <span [hidden]="saving" translate>Save</span>
            <mon-icon [hidden]="!saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
        </button>
    </div>
</form>
