import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { ControlContainer, NgForm } from '@angular/forms';
import { UserForm } from '../user-form';
import { StringService } from '@monsido/tools/string.service';

@Component({
    selector: 'mon-form-reseller-user-basic',
    templateUrl: 'reseller.basic.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormResellerAdminUserBasicComponent {
    @Input() form: NgForm;

    private userValue: User;
    @Output() userChange = new EventEmitter();
    @Input()
    get user (): UserForm {
        return this.userValue;
    }

    set user (val: UserForm) {
        this.userValue = val;
        this.userChange.emit(this.userValue);
    }

    isAdmin: boolean = false;

    constructor () {}

    regexEscape (input: string): string {
        return StringService.escapeRegExp(input);
    }
}
