<table class="table">
    <thead>
    <tr>
        <th>{{ 'Domains' | translate }}</th>
        <th>
            <input type="checkbox"
                   id="visible_check_all"
                   class="me-2 text-v-middle"
                   [attr.aria-label]="'Select all domains' | translate"
                   (click)="toggleAllVisibleDomains()"
                   [checked]="allVisibleChecked" />
            <label for="visible_check_all" class="mb-0 text-v-middle">
                {{ 'Visible' | translate }}
            </label>
        </th>
        <th>
            <input type="checkbox"
                   id="send_report_check_all"
                   class="me-2 text-v-middle"
                   [attr.aria-label]="'Select all domains' | translate"
                   [disabled]="!hasVisibleDomains"
                   (click)="toggleAllSendReports()"
                   [checked]="allSendReportChecked" />
            <label for="send_report_check_all" class="mb-0 text-v-middle">
                {{ 'Send report' | translate }}
            </label>
        </th>
    </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let domain of domains">
            <tr>
                <td>{{ domain.title }}</td>
                <td>
                    <input type="checkbox" (click)="toggleDomainVisible(domain)" [checked]="domain.isVisible()">
                </td>
                <td>
                    <input type="checkbox"
                           [disabled]="!domain.isVisible()"
                           (click)="toggleDomainSendReport(domain)"
                           [checked]="domain.isSendReport()">
                </td>
            </tr>
            <tr *ngFor="let domainGroup of domain.domain_groups">
                <td class="text-v-middle">
                    <div class="d-flex align-items-center">
                        <div>
                            <span class="visibility-45" ng-class="['DOMAIN_GROUPS', 'ICON'] | monIcons"></span>
                        </div>
                        <div>
                            {{ domainGroup.title }}
                        </div>
                    </div>
                </td>
                <td>
                    <input type="checkbox"
                           (click)="toggleDomainGroupVisible(domainGroup)"
                           [checked]="domainGroup.isVisible()">
                </td>
                <td>
                    <input type="checkbox"
                           (click)="toggleDomainGroupSendReport(domainGroup)"
                           [disabled]="!domainGroup.isVisible()"
                           [checked]="domainGroup.isSendReport()">
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>
