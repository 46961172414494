import { Injectable } from '@angular/core';
import { CustomerRepo } from '@monsido/modules/endpoints/api/admin/customer.repo';
import { User } from '@monsido/modules/models/api/user';

@Injectable()
export class CustomerService {
    constructor (private customerRepo: CustomerRepo) {}

    updateUser (customerId: number, user: User): Promise<void> {
        return this.customerRepo.updateUser(customerId, user);
    }

    createUser (customerId: number, user: User): Promise<void> {
        return this.customerRepo.createUser(customerId, user);
    }

    deleteUser (customerId: number, userId: number): Promise<void> {
        return this.customerRepo.destroyUser(customerId, userId);
    }

    sudo (customerId: number, userId: number): void {
        this.customerRepo.sudoUser(customerId, userId).then((data) => {
            window.open(data.uri, '_blank');
        });
    }
}
