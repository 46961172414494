import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { BaseLayoutModule } from '@layout/layout.module';
import { LayoutModule, IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { HebReportComponent } from './heb-report.component';
import { HEBREPORT_STATES } from './heb-report.states';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    providers: [],
    imports: [BaseLayoutModule, UIRouterModule.forChild({ states: HEBREPORT_STATES }), LayoutModule, IconsModule, TranslateModule],
    declarations: [HebReportComponent],
})
export class HebReportModule {}
