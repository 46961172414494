import { Ng2StateDeclaration } from '@uirouter/angular';
import { DomainsComponent } from './domains/domains.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const DOMAIN_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.admin.domains',
        url: '/domains',
        abstract: true,
    },
    {
        name: 'base.admin.domains.index',
        url: '?search&page_size&page',
        params: RouteParamsConstant,
        views: {
            '^.^.^.$default': {
                component: DomainsComponent,
            },
        },
        dynamic: true,
    },
];
