import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ToastService, ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CrawlQueueRepo } from '@monsido/modules/endpoints/api/admin/crawl-queue.repo';
import { CustomerRepo } from '@monsido/modules/endpoints/api/admin/customer.repo';
import { Domain } from '@monsido/modules/models/api/domain';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-admin-crawl-details',
    templateUrl: 'crawl-details.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormAdminCrawlDetailsComponent {
    @Input() crawl: CrawlQueue;
    domain: Domain;
    loading: boolean = false;
    constructor (
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private crawlQueueRepo: CrawlQueueRepo,
        private customerRepo: CustomerRepo,
    ) {}

    ngOnInit (): void {
        this.getDomain();
    }

    getDomain (): Promise<void> {
        this.loading = true;
        return this.customerRepo
            .getDomain(this.crawl.customer.id, this.crawl.domain.id)
            .then(
                (domain) => {
                    this.domain = domain as unknown as Domain;
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    stopCrawl (): void {
        this.loading = true;
        this.crawlQueueRepo
            .stopCrawl(this.crawl.domain.id)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawl stopped'));
                    this.close();
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    rejectCrawl (reason: string): void {
        this.loading = true;
        const params = {
            reason: reason,
        };
        this.crawlQueueRepo
            .rejectCrawl(this.crawl.domain.id, params)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawl rejected'));
                    this.close();
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    releaseCrawl (reason: string): void {
        this.loading = true;
        const params = {
            reason: reason,
        };
        this.crawlQueueRepo
            .releaseCrawl(this.crawl.domain.id, params)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawl released'));
                    this.close();
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    rejectRescanCrawl (reason: string): void {
        this.loading = true;
        const params = {
            reason: reason,
        };
        this.crawlQueueRepo
            .rejectCrawl(this.crawl.domain.id, params)
            .then(
                () => {
                    return this.customerRepo.createDomainCrawls(this.crawl.customer.id, this.crawl.domain.id).then(
                        () => {
                            this.toastService.success(this.translateService.getString('Rescan started'));
                            this.close();
                        },
                        () => {},
                    );
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    close (): void {
        this.activeDialog.close(this.crawl);
    }
}
