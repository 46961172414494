import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { FormsBuilderModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { Plan } from '@monsido/modules/models/api/plan';
import { TranslateModule } from 'app/modules/translate.module';
import { PlansRepo } from 'app/services/api/admin/plans.repo';

@Component({
    selector: 'mon-form-customer-plan',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        FormsBuilderModule,
        TranslateModule,
    ],
    templateUrl: './form-customer-plan.html',
})
export class MonFormCustomerPlan implements OnInit, AfterViewInit {
    @Input() customer: CustomerInterface;
    @Output() monFormValidityChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('planForm', { static: false }) planForm: NgForm;

    planOptions: Plan[] = [];
    private formValid?: boolean;

    constructor (private plansRepo: PlansRepo) {}

    ngOnInit (): void {
        if (this.customer.id) {
            this.customer.subscription.subscription_start_at = new Date(this.customer.subscription.subscription_start_at).toISOString();
        } else {
            this.customer.subscription = { subscription_start_at: new Date().toISOString() };
        }
        this.getPlans();
    }

    ngAfterViewInit (): void {
        this.planForm.form.valueChanges.subscribe(() => {
            if (this.formValid !== this.planForm.valid) {
                this.formValid = this.planForm.valid;
                this.monFormValidityChanges.emit(this.planForm.valid);
            }
        });
    }

    choosePlan (): void {
        this.customer.subscription.plan.name = this.planOptions.find((data) => {
            return data.id === this.customer.subscription.plan_id;
        }).name;
    }

    setDate (val: string): void {
        this.customer.subscription.subscription_start_at = new Date(val).toString();
    }

    onSelectPlan (id: number): void {
        this.customer.subscription.plan_id = id;
    }

    private getPlans (): void {
        const params = {
            page_size: 0,
        };

        this.plansRepo.getAll(params).then((data) => {
            this.planOptions = data.sort(function (a, b) {
                const aUp = a.name.toUpperCase();
                const bUp = b.name.toUpperCase();
                return aUp < bUp ? -1 : aUp > bUp ? 1 : 0;
            });
        }, () => {});
    }
}
