import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { EnvironmentService } from '@monsido/services/enviroment/environment.service';
import { CoreAgreementService } from '@monsido/core/session/services/agreement.service';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { Subject, Subscription, skip } from 'rxjs';

type EnvType = 'reseller' | 'admin';

interface EnvOption {
    name: string;
    envName: string;
    url: string;
    envType: EnvType;
    accountId?: number;
    agreement?: Agreement;
    id: string;
}

@Component({
    selector: 'env-selector-component',
    templateUrl: 'env-selector.html',
    styleUrls: ['env-selector.scss'],
})
export class EnvSelectorComponent implements OnInit, OnDestroy {
    envs: EnvOption[] = [];
    env: string;
    loading = true;
    onEventChange: Subject<EnvOption> = new Subject();

    private eventSubscription: Subscription;

    constructor (
        private state: StateService,
        private sessionService: SessionService,
        private environmentService: EnvironmentService,
        private coreAgreementService: CoreAgreementService,
        private uiRouterGlobals: UIRouterGlobals,
        private transitionService: TransitionService,
    ) {
    }

    async ngOnInit (): Promise<void> {
        const resellerEnvs = await this.sessionService.getAvailableResellerEnvs();
        const adminEnvs = await this.sessionService.getAvailableAdminEnvs();

        this.onEventChange.pipe(skip(1)).subscribe((selected: EnvOption) => {
            this.onEnvChange(selected);
        });

        if (resellerEnvs.length === 0 || adminEnvs.length === 0) {
            await this.setupEnvs();
        }

        const computedResellerEnvs: EnvOption[] = [];
        for (const resellerEnv of resellerEnvs) {
            const agreements = this.coreAgreementService.getAgreementsByEnvURLCached(resellerEnv.url);
            for (const agreement of agreements) {
                computedResellerEnvs.push({
                    name: `${resellerEnv.name} - ${agreement.account.name}`,
                    accountId: agreement.account.id,
                    url: resellerEnv.url,
                    envName: resellerEnv.name,
                    envType: 'reseller',
                    id: this.getEnvId('reseller', resellerEnv.name, agreement.account.id),
                    agreement,
                });
            }
        }

        const computedAdminEnvs: EnvOption[] = [];
        for (const adminEnv of adminEnvs) {
            computedAdminEnvs.push({
                name: `Admin - ${adminEnv.name}`,
                envName: adminEnv.name,
                url: adminEnv.url,
                envType: 'admin',
                id: this.getEnvId('admin', adminEnv.name),
            });

        }

        this.envs = [
            ...computedResellerEnvs,
            ...computedAdminEnvs,
        ];
        this.setSelectedEnv();

        this.loading = false;
        this.transitionService.onSuccess({}, () => {
            this.setSelectedEnv();
        },
        );
    }

    ngOnDestroy (): void {
        this.eventSubscription?.unsubscribe();
    }

    async setupEnvs (): Promise<void> {
        await Promise.all([
            this.environmentService.setupResellerEnvs(),
            this.environmentService.setupAdminEnvs(),
        ]);
    }

    private async onEnvChange (selected: EnvOption): Promise<void> {
        const { env, accountId } = this.uiRouterGlobals.params;
        let route: string = '';
        if (!selected) {
            return;
        }
        if (
            selected.envType === 'reseller' &&
            selected.envName === env &&
            selected.accountId === accountId
        ) {
            return;
        }

        if (
            selected.envType === 'admin' &&
            selected.envName === env &&
            accountId === undefined
        ) {
            return;
        }

        if (selected.envType === 'reseller') {
            route = 'base.admin.customers.index';
            if (accountId) {
                const allowResellerRoutes = [
                    'base.admin.customers.index',
                    'base.admin.domains.index',
                    'base.admin.users.index',
                    'base.admin.crawl_queue.index',
                    'base.admin.source_code_excludes.index',
                    'base.admin.constraints_and_excludes.index',
                    'base.admin.reseller_admin.users.index',
                ];
                if (allowResellerRoutes.indexOf(this.uiRouterGlobals.current.name) > -1) {
                    route = this.uiRouterGlobals.current.name;
                }
            }
            await this.coreAgreementService.setResellerEnv({ url: selected.url, name: selected.envName }, selected.agreement);
            await this.state.go(
                route,
                { env: selected.envName, accountId: selected.agreement.account.id },
            );
        }

        if (selected.envType === 'admin') {
            route = 'base.backend_admin.customers.all';
            if (accountId === undefined) {
                const allowAdminRoutes = [
                    'base.backend_admin.customers.all',
                    'base.backend_admin.crawl_queue.index',
                    'base.backend_admin.workerqueue.index',
                    'base.backend_admin.crawlers.index',
                    'base.backend_admin.plans.index',
                    'base.backend_admin.domains.index',
                    'base.backend_admin.users.index',
                    'base.backend_admin.resellers.index',
                    'base.backend_admin.support.search',
                    'base.backend_admin.support.request-checker',
                    'base.backend_admin.support.heatmaps',
                    'base.backend_admin.admin.data_privacy_checks.index',
                    'base.backend_admin.admin.accessibility_checks.index',
                    'base.backend_admin.admin.cookies.repo',
                ];
                if (allowAdminRoutes.indexOf(this.uiRouterGlobals.current.name) > -1) {
                    route = this.uiRouterGlobals.current.name;
                }
            }
            await this.coreAgreementService.setAdminEnv({ url: selected.url, name: selected.envName });
            await this.state.go(route, { env: selected.envName });
        }
    }

    private setSelectedEnv (): void {
        const { env, accountId } = this.uiRouterGlobals.params;
        if (!accountId) {
            this.env = this.getEnvId('admin', env);
            return;
        }

        this.env = this.getEnvId('reseller', env, accountId);
    }

    private getEnvId (envType: EnvType, envName: string, accountId?: number): string {
        if (!accountId) {
            return `${envType}-${envName}`;
        }
        return `${envType}-${envName}-${accountId}`;
    }

}
