<div class="row">
    <hr class="row outer-b-none" *ngIf="!monRequestChecker"/>
    <div class="row" *ngIf="!monRequestChecker">
        <div class="col-sm-offset-8 col-sm-40">
            <h3 class="font-weight-300" translate>Form fields</h3>
            <p translate>
                Please use CSS selectors to identify form fields and buttons
            </p>
        </div>
    </div>
    <div class="col-xs-48 mb-1" *ngIf="monRequestChecker">
        <label class="col-form-label text-right col-sm-8" for="login_type_form_add_field" translate>
            Fields
        </label>
        <div class="col-sm-40">
            <button type="button"
                    class="btn btn-secondary"
                    id="login_type_form_add_field"
                    (click)="addFormField()"
                    attr.aria-label="{{'Add Custom Multistep Javascript' | translate}}">
                    <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
            </button>
        </div>
    </div>
    <div class="col-xs-48" *ngFor="let field of monLogin.form.fields; let $index = index">
        <label class="col-form-label col-sm-8"
            [ngClass]="{'text-right': monRequestChecker}"
            ngbTooltip="{{'CSS id or class selector to search for' | translate}}"
            for="domain_scan_login_form_field_selector{{$index}}">
            {{ 'Input selector*' | translate }}
        </label>
        <div class="col-sm-13">
            <mon-form-field-input
                [type]="'text'"
                [monId]="'domain_scan_login_form_field_selector{{$index}}'"
                name="domain_scan_login_form_field_selector{{$index}}"
                [monPlaceholder]="''"
                required
                #domain_scan_login_form_field_selector="ngModel"
                [(ngModel)]="monLogin.form.fields[$index].selector"></mon-form-field-input>
            <mon-form-field-errors
                [showError]="domain_scan_login_form_field_selector.control.touched && domain_scan_login_form_field_selector.control.invalid"
                [errors]="domain_scan_login_form_field_selector.control.errors"
                offset="0"
            ></mon-form-field-errors>
        </div>
        <label ngbTooltip="{{'Set a default value for selector' | translate}}"
            [ngClass]="{'text-right': monRequestChecker}"
            class="col-form-label col-sm-8"
            for="domain_scan_login_form_field_value{{$index}}">
            {{ 'Default value*' | translate }}
        </label>
        <div class="col-sm-15">
            <mon-form-field-input
                [type]="'text'"
                id="domain_scan_login_form_field_value{{$index}}"
                name="domain_scan_login_form_field_value{{$index}}"
                [monPlaceholder]="''"
                required
                #domain_scan_login_form_field_value="ngModel"
                [(ngModel)]="monLogin.form.fields[$index].value"></mon-form-field-input>
            <mon-form-field-errors
                [showError]="domain_scan_login_form_field_value.control.touched && domain_scan_login_form_field_value.control.invalid"
                [errors]="domain_scan_login_form_field_value.control.errors"
                offset="0"
            ></mon-form-field-errors>
        </div>
        <div class="col-sm-4 text-right">
            <button type="button"
                    class="btn btn-secondary"
                    (click)="removeFormField($index)">
                <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
            </button>
        </div>
    </div>
    <div class="row mb-3" *ngIf="!monRequestChecker">
        <div class="col-sm-offset-8 col-sm-40">
            <button type="button"
                    class="btn btn-secondary"
                    (click)="addFormField()">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
            </button>
        </div>
    </div>

    <div class="col-xs-48">
        <label for="domain_login_form_submit_selector"
            ngbTooltip="{{'CSS selector to find submit button' | translate}}"
            [ngClass]="{'text-right': monRequestChecker}"
            class="col-form-label col-sm-8">
            {{ 'Submit selector*' | translate }}
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                id="domain_login_form_submit_selector"
                [name]="'domain_login_form_submit_selector'"
                required
                #form_submit_selector="ngModel"
                [monPlaceholder]="''"
                [(ngModel)]="monLogin.form.submit_selector"></mon-form-field-input>
        </div>
        <mon-form-field-errors
            [showError]="form_submit_selector.control.touched && form_submit_selector.control.invalid"
            [errors]="form_submit_selector.control.errors"
        ></mon-form-field-errors>
    </div>
    <div class="col-xs-48">
        <label for="domain_login_verify_location"
            ngbTooltip="{{'URL to redirect to if form is valid' | translate}}"
            [ngClass]="{'text-right': monRequestChecker}"
            class="col-form-label col-sm-8">
            {{ 'Verify URL*' | translate }}
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                id="domain_login_verify_location"
                [name]="'domain_login_verify_location'"
                required
                #verify_location="ngModel"
                [pattern]="verifyLocationPattern"
                [monPlaceholder]="''"
                [(ngModel)]="monLogin.verify.location"></mon-form-field-input>
        </div>
        <mon-form-field-errors
            [patternMsg]="'Is not a valid url' | translate"
            [showError]="verify_location.control.touched && verify_location.control.invalid"
            [errors]="verify_location.control.errors">
        </mon-form-field-errors>
    </div>
</div>
