<form name="setup">
    <mon-form-field-select [monName]="'title'"
                           monLabel="{{ 'Title'| translate }}"
                           [(model)]="selected.title"
                           [monRequired]="true"
                           [monOptions]="options"
                           (changeEvent)="onChange($event)"
                           [monNameAttr]="'name'"
                           [monReturnValue]="'value'"
                           monPlaceholder="{{ 'Choose an option' | translate }}"
    ></mon-form-field-select>
    <mon-form-field-select [monName]="'url'"
                           monLabel="{{ 'URL'| translate }}"
                           [(model)]="selected.url"
                           [monOptions]="options"
                           (changeEvent)="onChange($event)"
                           [monNameAttr]="'name'"
                           [monReturnValue]="'value'"
                           monPlaceholder="{{ 'Choose an option' | translate }}"
    ></mon-form-field-select>
</form>
