import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { BackendAdminAccessibilityChecksComponent } from './backend-admin-accessibility-checks.component';
import { NgModule } from '@angular/core';

const BACKEND_ADMIN_ACCESSIBILITY_CHECKS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.admin.accessibility_checks',
        url: '/accessibility_checks',
        abstract: true,
        component: BaseStateComponent,
    },
    {
        name: 'base.backend_admin.admin.accessibility_checks.index',
        url: '',
        views: {
            '^.^.^.^.$default': {
                component: BackendAdminAccessibilityChecksComponent,
            },
        },
    },
];


@NgModule({
    imports: [UIRouterModule.forChild({ states: BACKEND_ADMIN_ACCESSIBILITY_CHECKS_STATES })],
})
export class BackendAdminAccessibilityChecksStateModule {}
