<form name="form" class="form-horizontal" #customerUserForm="ngForm" (ngSubmit)="!saving && customerUserForm.valid && submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']" header="{{'Edit User' | translate }}" *ngIf="user.id"></mon-page-header>
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']" header="{{'New User' | translate }}" *ngIf="!user.id"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300">{{ 'User information' | translate }}</h3>
            </div>
            <div class="card-body">
                <mon-form-customer-user-basic [user]="user" [modelGroup]="'user_basic'"></mon-form-customer-user-basic>
            </div>
        </div>
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300">{{ 'Domains' | translate }}</h3>
            </div>
            <div class="card-body">
                <mon-form-customer-user-domains [customerId]="customerId" [user]="user"></mon-form-customer-user-domains>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button type="submit" class="btn btn-secondary" [disabled]="saving || customerUserForm.invalid">
            <span *ngIf="!saving">{{ 'Save' | translate }}</span>
            <span class="fa-spin fa-fw" [ngClass]="['SPINNER', 'ICON'] | monIcons" *ngIf="saving" aria-hidden="true"></span>
        </button>
    </div>
</form>
