<form name="userForm" #userForm="ngForm" class="form-horizontal" (submit)="!saving && basicUserFormValid && userForm.valid && submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']" header="{{'Edit User' | translate }}" *ngIf="user.id"></mon-page-header>
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']" header="{{'New User' | translate }}" *ngIf="!user.id"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>User information</h3>
            </div>
            <div class="card-body">
                <mon-form-backend-admin-user-basic (isFormValid)="onUserFormValidityChange($event)" [user]="newUser"></mon-form-backend-admin-user-basic>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button type="submit" class="btn btn-secondary" [disabled]="userForm.invalid || saving || !basicUserFormValid" >
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" [class]="spinnerClass" aria-hidden="true"></span>
        </button>
    </div>
</form>
