import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import { FormUserComponent } from '@monsido/forms/user/user.component';
import { LayoutModule, MonHttpPrefixModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { SessionModule } from '@monsido/core/session/session.module';
import { FormUserBasicComponent } from '@monsido/forms/user/steps/basic/basic.component';
import { FormBackendAdminDataPrivacyCheckComponent } from './backend_admin/data-privacy-check/data-privacy-check.component';
import {
    FormBuilderValidationModule,
    FormBuilderCheckboxModule,
    FormBuilderSelectModule,
    FormBuilderInputModule,
    TextareaModule,
    MonSwitchModule,
    FormBuilderDynamicModule,
    MonSelectAllCheckboxModule,
    IconsModule,
    PipesModule as ASCPipesModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormResellerAdminUserComponent } from './reseller-admin/user/user.component';
import { FormResellerAdminUserBasicComponent } from './reseller-admin/user/basic/reseller.basic.component';
import { TableModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormBackendAdminPlanComponent } from './backend_admin/plan/plan.component';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { FormAdminCrawlDetailsComponent } from './reseller-admin/crawl-details/crawl-details.component';
import { UIRouterModule } from '@uirouter/angular';
import { NgbDropdownModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormBackAdminCrawlDetailsComponent } from './backend_admin/crawl-details/crawl-details.component';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { FormBackendAdminUserComponent } from '@monsido/forms/backend_admin/customer-user/user.component';
import { FormBackendAdminCustomerUserBasicComponent } from '@monsido/forms/backend_admin/customer-user/basic/basic.component';
import { FormDomainComponent } from '@monsido/forms/domain/domain.component';
import { FormDomainDetailComponent } from '@monsido/forms/domain/steps/detail/detail.component';
import { ServicesModule } from '@monsido/services/services.module';
import { FormDomainURLRewriteComponent } from './backend_admin/domain/rewrite-url/rewrite-url.component';
import { FormBackendAdminPageAssistComponent } from './backend_admin/page-assist/page-assist.component';
import { FormDomainUsersComponent } from '@monsido/forms/domain/steps/users/users.components';
import { PageFixDomainSettingsComponent } from './backend_admin/page-fix-domain-settings/page-fix-domain-settings.component';
import { FormPageAssistCustomNavigationCollectionComponent } from './backend_admin/page-assist/custom-navigation/custom-navigation.component';
import { FormPageAssistCustomNavigationComponent } from './page-assist-custom-navigation/page-assist-custom-navigation.component';
import { FormImportDictionaryComponent } from './import-dictionary/import-dictionary.component';
import { FormImportConfirmedSpellingErrorsComponent } from './import-confirmed-spelling-errors/import-confirmed-spelling-errors.component';
import { ImportDomainsModule } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.module';
import { ScanModule } from '@monsido/forms/domain/steps/scan/scan.module';
import { FeaturesModule } from '@monsido/forms/domain/steps/features/features.module';
import { CustomerModule } from './backend_admin/customer/customer.module';
import { MonsidoDirectiveModule } from 'app/directives/directives.module';
import { FormBackendAdminCustomerUserDomainsComponent } from './backend_admin/customer-user/domains/domains.component';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [
        CommonModule,
        AngularFormModule,
        IconsModule,
        LayoutModule,
        SessionModule,
        TranslateModule,
        FormBuilderValidationModule,
        FormBuilderInputModule,
        FormBuilderSelectModule,
        FormBuilderCheckboxModule,
        MonSwitchModule,
        TableModule,
        NgxSummernoteModule,
        ConfirmModule,
        UIRouterModule,
        NgbDropdownModule,
        PipesModule,
        ASCPipesModule,
        TextareaModule,
        ServicesModule,
        NgbTooltipModule,
        NgbTimepickerModule,
        FormBuilderDynamicModule,
        MonHttpPrefixModule,
        MonSelectAllCheckboxModule,
        ImportDomainsModule,
        ScanModule,
        FeaturesModule,
        CustomerModule,
        MonsidoDirectiveModule,
        FormBackendAdminCustomerUserDomainsComponent,
    ],
    exports: [
        FormUserComponent,
        FormBackendAdminPlanComponent,
        FormResellerAdminUserComponent,
        FormBackendAdminDataPrivacyCheckComponent,
        FormAdminCrawlDetailsComponent,
        FormBackAdminCrawlDetailsComponent,
        FormBackendAdminUserComponent,
        FormBackendAdminCustomerUserBasicComponent,
        FormDomainComponent,
        FormDomainDetailComponent,
        FormDomainURLRewriteComponent,
        FormBackendAdminPageAssistComponent,
        FormDomainUsersComponent,
        PageFixDomainSettingsComponent,
        FormPageAssistCustomNavigationCollectionComponent,
        FormPageAssistCustomNavigationComponent,
        FormImportConfirmedSpellingErrorsComponent,
    ],
    declarations: [
        FormUserComponent,
        FormUserBasicComponent,
        FormBackendAdminPlanComponent,
        FormResellerAdminUserComponent,
        FormResellerAdminUserBasicComponent,
        FormBackendAdminDataPrivacyCheckComponent,
        FormAdminCrawlDetailsComponent,
        FormBackAdminCrawlDetailsComponent,
        FormBackendAdminUserComponent,
        FormBackendAdminCustomerUserBasicComponent,
        FormDomainComponent,
        FormDomainDetailComponent,
        FormDomainURLRewriteComponent,
        FormBackendAdminPageAssistComponent,
        FormDomainUsersComponent,
        PageFixDomainSettingsComponent,
        FormPageAssistCustomNavigationCollectionComponent,
        FormPageAssistCustomNavigationComponent,
        FormImportDictionaryComponent,
        FormImportConfirmedSpellingErrorsComponent,
    ],
    providers: [],
})
export class FormsModule {}
