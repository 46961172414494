import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';
import { TranslateService } from 'app/services/translate/translate.service';

export class Job {
    jobsInQueue: Array<number>;
    jobsInProgress: Array<number>;
    constructor (job: JobInterface, private translateService: TranslateService) {
        Object.assign(this, job);
        this.jobsInQueue = [];
        this.jobsInProgress = [];
    }

    hasJob (ref: number): boolean {
        return this.hasJobInProgress(ref) || this.hasJobInQueue(ref);
    }

    jobTooltip (ref: number): string {
        if (this.hasJobInProgress(ref)) {
            return this.translateService.getString('There is a job running');
        } else if (this.hasJobInQueue(ref)) {
            return this.translateService.getString('There is a job in queue');
        }
    }

    jobCssClass (ref: number, defaultClass: string): string {
        if (this.hasJobInProgress(ref)) {
            return 'fas fa-spinner fa-spin';
        } else if (this.hasJobInQueue(ref)) {
            return 'fas fa-sync-alt fa-spin';
        } else {
            return defaultClass || '';
        }
    }

    hasJobInQueue (ref: number): boolean {
        return this.jobsInQueue.indexOf(ref) !== -1;
    }

    hasJobInProgress (ref: number): boolean {
        return this.jobsInProgress.indexOf(ref) !== -1;
    }

    setJobInProgress (ref: number): void {
        this.setJob(ref, 'inProgress');
    }

    setJobInQueue (ref: number): void {
        this.setJob(ref, 'queue');
    }

    setJobComplete (ref: number): void {
        const queueIndex = this.jobsInQueue.indexOf(ref);
        const inProgressIndex = this.jobsInProgress.indexOf(ref);
        if (queueIndex !== -1) {
            this.jobsInQueue.splice(queueIndex, 1);
        }
        if (inProgressIndex !== -1) {
            this.jobsInProgress.splice(inProgressIndex, 1);
        }
    }

    watchJobHandle (job: JobInterface, ref: number): void {
        switch (job.state) {
            case 'queued':
                this.setJobInQueue(ref);
                break;
            case 'running':
                this.setJobInProgress(ref);
                break;
            case 'failed':
            case 'completed':
                this.setJobComplete(ref);
                break;
        }
    }

    private setJob (ref: number, type: string): void {
        const addArray = type === 'queue' ? this.jobsInQueue : this.jobsInProgress;
        const removeArray = type === 'queue' ? this.jobsInProgress : this.jobsInQueue;
        const addIndex = addArray.indexOf(ref);
        const removeIndex = removeArray.indexOf(ref);

        if (addIndex === -1) {
            addArray.push(ref);
        }
        if (removeIndex !== -1) {
            removeArray.splice(removeIndex, 1);
        }
    }
}
