<mon-page-header [monIcon]="['SOURCE_CODE_EXCLUDES', 'ICON']" header="{{'Source code excludes' | translate}}">
    <content>
        <div class="row">
            <div class="col-xs-48 text-right">
                <button class="btn btn-marine-blue btn-border-radius" (click)="openDialog()" translate>
                    Add Source code exclude
                </button>
            </div>
        </div>
    </content>
</mon-page-header>
<div class="card monsido-panel">
    <div class="card-body">
        <mon-table-container
                [collection]="sourceCodeExcludes"
                (pageChangeEvent)="onPageChange($event)"
                (perPageChange)="onPageSizeChange($event)"
                class="table-vertial-align"
                [loading]="loading">
            <div class="table-container-body">
                <table class="table table-hover table-fixed" summary="{{'Source code excludes' | translate}}">
                    <thead>
                    <tr>
                        <th class="col-sm-8 col-md-6" translate>Owner</th>
                        <th class="col-sm-32 col-md-34" translate>Description</th>
                        <th class="col-sm-8 col-md-6"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let sourceCodeExclude of sourceCodeExcludes">
                        <td class="text-left">
                            {{sourceCodeExclude.owner_type}}
                        </td>
                        <td class="text-left">
                            {{sourceCodeExclude.short_description}}
                        </td>
                        <td class="text-right">
                            <button type="button"
                                    class="btn btn-secondary me-1"
                                    (click)="openDialog(sourceCodeExclude)"
                                    translate>
                                Edit
                            </button>
                            <button type="button"
                                    class="btn btn-secondary"
                                    monConfirm="{{'Are you sure you want to delete this source exclude?' | translate}}"
                                    (monConfirmAction)="deleteSourceCodeExclude(sourceCodeExclude)"
                                    translate>
                                Delete
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </div>
</div>
