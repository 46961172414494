<div ngModelGroup="domainDetail">
<mon-form-field-input
        [name]="'title'"
        required
        #title="ngModel"
        [(ngModel)]="monDomain.title"
        [monLabel]="'Title' | translate"
        [monPlaceholder]="'Title' | translate"
        [type]="'text'"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="title.control.touched && title.control.invalid"
        [errors]="title.control.errors"
    ></mon-form-field-errors>
    <mon-form-field-input
        monHttpPrefix
        [name]="'url'"
        required
        #url="ngModel"
        [(ngModel)]="monDomain.url"
        [monLabel]="'URL' | translate"
        [monPlaceholder]="'URL' | translate"
        [type]="'text'"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="url.control.touched && url.control.invalid"
        [errors]="url.control.errors"
    ></mon-form-field-errors>

    <mon-form-field-select-timezones
        id="timezone"
        [(model)]="monDomain.timezone"
        [monReturnValue]="monDomain.timezone"
        name="timezone"
        [monLabel]="'Timezone' | translate"
        [monHelpTxt]="'Choose a timezone' | translate">
    </mon-form-field-select-timezones>

    <div class="row outer-t-medium"></div>

    <hr class="row" />

    <mon-form-field-select
        [monName]="'cms'"
        [(model)]="monDomain.cms"
        [monOptions]="cmses"
        [monLabel]="'CMS' | translate"
        (changeEvent)="onCmsChange($event)"
        [monReturnValue]="'id'"
        [monPlaceholder]="'None' | translate"
        [monAllowClear]="true">
    </mon-form-field-select>

    <div class="form-group row mt-2"
         *ngIf="showCmsUrl()">
        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_url">
            <span translate>CMS url</span>
            <span
                class="outer-l-smurf fas fa-question-circle"
                [attr.aria-hidden]="true"
                [ngbTooltip]="'The base url to the CMS page' | translate"
                placement="top">
            </span>
            *
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                [name]="'domain_cms_extra_url'"
                id="domain_cms_extra_url"
                #domain_cms_extra_url="ngModel"
                [(ngModel)]="monDomain.cms_extra[getUrl()]"
                required
                [monPlaceholder]="'CMS url' | translate"></mon-form-field-input>
        </div>

        <mon-form-field-errors
            [showError]="domain_cms_extra_url.control.touched && domain_cms_extra_url.control.invalid"
            [errors]="domain_cms_extra_url.control.errors"
        ></mon-form-field-errors>
    </div>

    <div class="form-group row mt-2"
         *ngIf="showSubdomain()">
        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_subdomain">
            <span translate>Subdomain</span>
            <span
                class="outer-l-smurf fas fa-question-circle"
                [attr.aria-hidden]="true"
                [ngbTooltip]="'The subdomain url to the CMS page' | translate"
                placement="top"></span>
            <span *ngIf="isSubdomainRequired()">*</span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_subdomain"
                [name]="'domain_cms_extra_subdomain'"
                #domain_cms_extra_subdomain="ngModel"
                [(ngModel)]="monDomain.cms_extra['subdomain']"
                [required]="isSubdomainRequired()"
                [monPlaceholder]="'Subdomain url' | translate"></mon-form-field-input>
        </div>
        <mon-form-field-errors
            [showError]="domain_cms_extra_subdomain.control.touched && domain_cms_extra_subdomain.control.invalid"
            [errors]="domain_cms_extra_subdomain.control.errors"
        ></mon-form-field-errors>
    </div>

    <div class="form-group row mt-2" *ngIf="showCmsPath()">
        <label class="col-form-label text-right text-bold col-sm-8"
               for="domain_cms_extra_subdomain">
            <span translate>CMS Path</span>
            <span class="outer-l-smurf fas fa-question-circle"
                  [attr.aria-hidden]="true"
                  [ngbTooltip]="'https://domain.tld/#{cms_path}/entries/#{section.value}/#{id.value}'">
            </span>
            <span>*</span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input [type]="'text'"
                                  id="domain_cms_extra_cms_path"
                                  [name]="'domain_cms_extra_cms_path'"
                                  #domain_cms_extra_cms_path="ngModel"
                                  [(ngModel)]="monDomain.cms_extra['cms_path']"
                                  [required]="showCmsPath()"
                                  [monPlaceholder]="'CMS Path' | translate">
            </mon-form-field-input>
        </div>
        <mon-form-field-errors
            [showError]="domain_cms_extra_cms_path.control.touched && domain_cms_extra_cms_path.control.invalid"
            [errors]="domain_cms_extra_cms_path.control.errors"
        ></mon-form-field-errors>
    </div>

    <div class="form-group row mt-2" *ngIf="isKonform()">
        <label class="col-form-label text-right text-bold col-sm-8"
               for="domain_cms_extra_language_id">
            <span translate>Language id</span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_language_id"
                [monPlaceholder]="'Language ID' | translate"
                [name]="'domain_cms_extra_language_id'"
                [(ngModel)]="monDomain.cms_extra.language_id"
            ></mon-form-field-input>
        </div>
    </div>

    <div class="form-group row mt-2" *ngIf="isCmsTemplate()">
        <label class="col-form-label text-right text-bold col-sm-8"
               for="domain_cms_extra_language_id">
        </label>
        <div class="col-sm-40">
            <span translate>CMS template is configured for this domain</span>
        </div>
    </div>

    <div class="form-group row mt-2" *ngIf="isKonform()">
        <label class="col-form-label text-right text-bold col-sm-8"
               for="domain_cms_extra_areadynamicpage">
            <span translate>Area dynamic page</span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_areadynamicpage"
                [monPlaceholder]="'Area dynamic page' | translate"
                [name]="'domain_cms_extra_areadynamicpage'"
                [(ngModel)]="monDomain.cms_extra.areadynamicpage"
            ></mon-form-field-input>
        </div>
    </div>

    <div class="form-group row mt-2" *ngIf="isKonform()">
        <label class="col-form-label text-right text-bold col-sm-8"
               for="domain_cms_extra_newspage">
            <span translate>Newspage</span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_newspage"
                [monPlaceholder]="'Newspage' | translate"
                [name]="'domain_cms_extra_newspage'"
                [(ngModel)]="monDomain.cms_extra.newspage"
            ></mon-form-field-input>
        </div>
    </div>

    <div class="form-group row mt-2" *ngIf="isKonform()">
        <label class="col-form-label text-right text-bold col-sm-8"
               for="domain_cms_extra_employeepage">
            <span translate>Employeepage</span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_employeepage"
                [monPlaceholder]="'Employeepage' | translate"
                [name]="'domain_cms_extra_employeepage'"
                [(ngModel)]="monDomain.cms_extra.employeepage"
            ></mon-form-field-input>
        </div>
    </div>

    <div class="form-group row mt-2" *ngIf="showOptionalCmsUrl()">
        <label class="col-form-label text-right text-bold col-sm-8"
               for="domain_cms_extra_base_url"
               translate>
            CMS url
            <span class="outer-l-smurf fas fa-question-circle"
                  [attr.aria-hidden]="true"
                  [ngbTooltip]="'The optional base url to the CMS page' | translate"
                  placement="top">
            </span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_base_url"
                [name]="'domain_cms_extra_base_url'"
                [(ngModel)]="monDomain.cms_extra['base_url']"
                [monPlaceholder]="'CMS url' | translate"></mon-form-field-input>
        </div>
    </div>

    <div class="form-group row mt-2"
         *ngIf="showEPIServerPath()">
        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_episerver_path">
            <span translate>EPI Server Path</span>
            <span
                class="outer-l-smurf fas fa-question-circle"
                [attr.aria-hidden]="true"
                placement="top"
                [ngbTooltip]="'The optional epi server path' | translate">
            </span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_episerver_path"
                [name]="'domain_cms_extra_episerver_path'"
                [(ngModel)]="monDomain.cms_extra.episerver_path"
                [monPlaceholder]="'EPIServer' | translate" ></mon-form-field-input>
        </div>
    </div>

    <div class="form-group row mt-2"
         *ngIf="showContentIDTag()">
        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_contentid_tag">
            <span translate>Content id tag</span>
            <span
                class="outer-l-smurf fas fa-question-circle"
                [attr.aria-hidden]="true"
                [ngbTooltip]="'Ex: data-currentpage, or data-pageid, or CurrentPageLink' | translate"
                placement="top">
            </span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_contentid_tag"
                [name]="'domain_cms_extra_contentid_tag'"
                [(ngModel)]="monDomain.cms_extra.contentid_tag"
                [monPlaceholder]="'Content ID tag' | translate"></mon-form-field-input>
        </div>
    </div>

    <div class="form-group row mt-2"
         *ngIf="showLanguageIDTag()">
        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_language_tag">
            <span translate>Language tag</span>
            <span
                class="outer-l-smurf fas fa-question-circle"
                [attr.aria-hidden]="true"
                [ngbTooltip]="'Ex: lang or CurrentPageLanguage' | translate"
                placement="top">
            </span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_language_tag"
                [name]="'domain_cms_extra_language_tag'"
                [(ngModel)]="monDomain.cms_extra.language_tag"
                [monPlaceholder]="'Language tag' | translate"
            ></mon-form-field-input>
        </div>
    </div>

    <mon-form-field-input
        [name]="'domain_cms_template_path'"
        [(ngModel)]="monDomain.cms_extra['path_template']"
        [monLabel]="'Path template' | translate"
        [monPlaceholder]="getTemplatePlaceholder()"
        [type]="'text'"
        *ngIf="showTemplatePath()">
    </mon-form-field-input>

    <div class="form-group row" *ngIf="showSpecialKey()">
        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_special_key">
            <span translate>Meta name</span>
            <span class="ml-1 fas fa-question-circle"
                  [attr.aria-hidden]="true"
                  [ngbTooltip]="'The name of a meta entry (page metadata)' | translate"
                  placement="top"></span>
            *
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                [name]="'domain_cms_extra_special_key'"
                id="domain_cms_extra_special_key"
                [(ngModel)]="monDomain.cms_extra.special_key"
                [monPlaceholder]="'Meta name' | translate"></mon-form-field-input>
        </div>

        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_url">
            <span translate>URL template</span>
        </label>
        <div class="col-sm-40 pt-1">
            <mon-form-field-input
                monHttpPrefix
                [type]="'text'"
                [name]="'domain_cms_extra_url'"
                [(ngModel)]="monDomain.cms_extra.url"
                [monPlaceholder]="'https://domain.name/path/<%= key %>' | translate">
            </mon-form-field-input>
        </div>
    </div>

    <mon-form-field-select
        [(model)]="monDomain.cms_extra.url_postfix"
        monLabel="{{ 'Mode' | translate }}"
        [monReturnValue]="'value'" *ngIf="isSquizCms()"
        [monName]="'cms_extra.url_postfix'"
        [monOptions]="squizCmsOptions">
    </mon-form-field-select>

    <mon-form-field-select
        [(model)]="monDomain.cms_extra.url_structure"
        [monLabel]="'URL Structure' | translate"
        [monReturnValue]="'value'"
        *ngIf="isJoomlaCms()"
        [monName]="'cms_extra.url_structure'"
        [monPlaceholder]="'Choose an option' | translate"
        [monOptions]="JoomlaCmsOptions">
    </mon-form-field-select>

    <mon-form-field-select
        [(model)]="monDomain.cms_extra.version"
        [monName]="'cms_extra.version'"
        [monLabel]="'URL format' | translate"
        [monPlaceholder]="'Choose an option' | translate"
        monHelpText="{{ 'Standard for http(s)://edit.domain.tld/inz/<path>. Alternative for http(s)://domain.tld/<path>/edit.' | translate }}"
        [monReturnValue]="'value'"
        *ngIf="isPloneCms()"
        [monOptions]="ploneCmsOptions">
    </mon-form-field-select>

    <div class="form-group row" *ngIf="showPrefixPath()">
        <label
            class="col-form-label text-right text-bold col-sm-8"
            for="domain_cms_extra_prefix_path" translate>
            Prefix path<span
            class="outer-l-smurf fas fa-question-circle"
            [attr.aria-hidden]="true"
            [ngbTooltip]="'The subpath/subfolder the wordpress installation is under' | translate"
            placement="top"></span>
        </label>
        <div class="col-sm-40">
            <mon-form-field-input
                [type]="'text'"
                id="domain_cms_extra_prefix_path"
                [name]="'domain_cms_extra_prefix_path'"
                [(ngModel)]="monDomain.cms_extra['prefix_path']"
                [monPlaceholder]="'Prefix path' | translate" >
            </mon-form-field-input>
        </div>
    </div>

    <div class="mt-2" *ngIf="isAemCms()">
        <mon-form-field-input
            [type]="'text'"
            [monLabel]="'CMS subdomain' | translate"
            [monPlaceholder]="'CMS subdomain' | translate"
            [name]="'domain_cms_extra_cms_subdomain'"
            #domain_cms_extra_cms_subdomain="ngModel"
            [(ngModel)]="monDomain.cms_extra.cms_subdomain"
            required>
        </mon-form-field-input>
        <mon-form-field-errors
            [showError]="domain_cms_extra_cms_subdomain.control.touched && domain_cms_extra_cms_subdomain.control.invalid"
            [errors]="domain_cms_extra_cms_subdomain.control.errors"
        ></mon-form-field-errors>
    </div>

    <div class="mt-2" *ngIf="isAemCms()">
        <mon-form-field-input
            [type]="'text'"
            [monLabel]="'Default language' | translate"
            [monPlaceholder]="'Default language' | translate"
            [name]="'domain_cms_extra_default_language'"
            [(ngModel)]="monDomain.cms_extra.default_language"></mon-form-field-input>
    </div>

    <div class="mt-2" *ngIf="isAemCms()">
        <mon-form-field-input
            [type]="'text'"
            [monLabel]="'Site' | translate"
            [monPlaceholder]="'Site' | translate"
            [name]="'domain_cms_extra_site'"
            [(ngModel)]="monDomain.cms_extra.site"></mon-form-field-input>
    </div>

    <hr class="row" />

    <mon-form-field-select [monName]="'language'"
                           *ngIf="!loadingLanguages"
                           [(model)]="monDomain.language"
                           [monLabel]="'language' | translate"
                           [monPlaceholder]="'language' | translate"
                           [monOptions]="languages"
                           [monReturnValue]="'code'"
                           [monHelpText]="'Optionally, you can select the primary language for the domain. If you choose English (UK), any text detected as English will be regarded as British English.' | translate"
                           [monAllowClear]="true">
    </mon-form-field-select>
    <div class="form-group">
        <label class="col-form-label col-sm-8"
               for="accessibility_source_code_exclude_ids"
               translate>
            Source code excludes
        </label>
        <div class="select-grey col-sm-40">
            <mon-form-field-select
                id="accessibility_source_code_exclude_ids"
                [(model)]="domainSourceCodeExcludes"
                [monMultiple]="true"
                [monName]="'accessibility_source_code_exclude_ids'"
                [monReturnValue]="'id'"
                [monNameAttr]="'short_description'"
                [monOptions]="sourceCodeExcludes"
                (changeEvent)="onSCEChange($event)"
            ></mon-form-field-select>
        </div>
    </div>
    <mon-form-field-textarea
        [(model)]="monDomain.notes"
        [name]="'notes'"
        [label]="'Notes' | translate"
        [helpTxt]="'This is only visible to other reseller users' | translate"
        [placeholder]="'Notes' | translate">
    </mon-form-field-textarea>
</div>
