import { Ng2StateDeclaration } from '@uirouter/angular';
import { SearchPageComponent } from '@monsido/pages/backend-admin/support/search/search.component';

export const SEARCH_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.support.search',
        url: '/search?search',
        views: {
            '^.^.^.$default': {
                component: SearchPageComponent,
            },
        },
        dynamic: true,
    },
];
