import { Injectable } from '@angular/core';

import { DomainGroupModel } from './domain-group.model';
import { DomainModel } from './domain-model';
import { DomainGroupDataType, DomainDataType } from 'types/domain';

@Injectable({
    providedIn: 'root',
})
export class DomainImportService {

    createDomainGroup (params: DomainGroupDataType): DomainGroupModel {
        return new DomainGroupModel(params);
    }
    createDomain (params: DomainDataType): DomainModel {
        return new DomainModel(params);
    }
}
