export class StringService {
    static capitalize (word: string): string {
        if (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
    }

    static camelize (str: string): string {
        const arr = str.split('_');
        const capital = arr.map((item, index) => (index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item));

        return capital.join('');
    }

    static removeWhiteSpaces (input: string): string {
        return input.replace(/\s+/g, '');
    }

    static escapeRegExp (input: string | number): string {
        if (typeof input === 'number') {
            return String(input).replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
        return input ? input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : '';
    }
}
