import { Domain } from '@monsido/modules/models/api/domain';
import { ApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/api-client';
import { Injectable } from '@angular/core';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { DomainDataType } from 'types/domain';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';

@Injectable({
    providedIn: 'root',
})
export class DomainRepo {
    constructor (private apiClient: ApiClient) {}

    getAll (params?: PlainHttpParams, headers?: HttpHeaders): Promise<CollectionInterface<Domain>> {
        return this.apiClient
            .getPromise('domains', createDefaultHttpParams(params, headers))
            .then(data => this.toModels(data));
    }

    private toModels (data: CollectionInterface<DomainDataType>): CollectionInterface<Domain> {
        for (let i = 0; i < data.length; i++) {
            data[i] = new Domain(data[i]);
        }

        return data as CollectionInterface<Domain>;
    }
}
