import { Injectable } from '@angular/core';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

@Injectable()
export class MonRunLoadingService {
    constructor (private ng2MonEventsService: MonEventService, private monLoading: MonLoadingService) {}
    initializeApp () {
        return new Promise<any>((resolve: any, reject: any) => {
            // @ts-ignore
            this.ng2MonEventsService.addListener('beforeLogin', (index) => {
                this.monLoading.show();
                return index;
            });

            // @ts-ignore
            this.ng2MonEventsService.addListener('finishLogin', (index) => {
                this.monLoading.hide();
                return index;
            });

          
            return resolve();
        });
    }
}
