import { Component, Input } from '@angular/core';
import { ScansInterface } from '@monsido/modules/models/api/interfaces/scans.interface';

@Component({
    selector: 'mon-request-checker-links-component',
    templateUrl: 'links.html',
    styleUrls: ['./../../request-checker.scss'],
})

export class LinksComponent {
    @Input() url: string;
    @Input() scan: ScansInterface;
    constructor () {}
}
