<mon-page-header [monIconDirect]="'faFileMinus'"
                 header="{{'Constraints and excludes' | translate}}"
                 data-test="constraints-page-header">
</mon-page-header>

<section *ngIf="constraints$ | async as constraintsData">
    <div class="table-top d-flex mb-2">
        <div class="d-flex flex-grow-1">
            <h2 translate data-test="path-constraints-heading">
                Path constraints
            </h2>
        </div>
        <div class="d-flex align-right">
            <button
                class="btn btn-marine-blue"
                (click)="addEntry(entryType.constraint)"
                translate
                data-test="add-path-constraint-button">
                Add path constraint
            </button>
        </div>
    </div>

    <div class="card monsido-panel">
        <div class="card-body">
            <div class="row">
               <div class="col-md-48 col-lg-48 col-sm-48">
                    <mon-table-container
                        (pageChangeEvent)="onPageChange($event, entryType.constraint)"
                        (perPageChange)="onPageSizeChange($event, entryType.constraint)"
                        [collection]="constraintsData"
                        class="table-vertial-align"
                        [loading]="loadingConstraints | async"
                        data-test="constraints-table-container">
                        <div class="table-container-body">
                            <table class="table table-hover table-fixed">
                                <thead>
                                <tr>
                                    <th scope="col" class="col-sm-2" translate>CMS</th>
                                    <th scope="col" class="col-sm-7" translate>Name</th>
                                    <th scope="col" class="text-right col-sm-3"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let constraintData of constraintsData">
                                    <tr>
                                        <td class="text-v-middle">
                                            {{ cmsesById[constraintData.default_for_cms] }}
                                        </td>
                                        <td class="text-ellipsis text-v-middle">
                                            {{ constraintData.name }}
                                        </td>

                                        <td class="text-right text-v-middle">
                                            <button
                                                class="btn btn-secondary"
                                                (click)="editEntry(constraintData, entryType.constraint)"
                                                translate
                                                data-test="edit-button">
                                                Edit
                                            </button>
                                            <button
                                                class="btn btn-secondary ml-1"
                                                (click)="deleteEntry(constraintData, entryType.constraint)"
                                                translate
                                                data-test="delete-button">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </mon-table-container>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="linksExcluded$ | async as linksExcludedData">
    <div class="table-top d-flex mt-10 mb-2">
        <div class="d-flex flex-grow-1">
            <h2 translate>
                Link excludes
            </h2>
        </div>
        <div class="d-flex align-right">
            <button
                class="btn btn-marine-blue"
                (click)="addEntry(entryType.link)"
                translate
                data-test="add-link-exclude-button">
                Add link exclude
            </button>
        </div>
    </div>

    <div class="card monsido-panel">
        <div class="card-body">
            <div class="row">
               <div class="col-md-48 col-lg-48 col-sm-48">
                    <mon-table-container
                        (pageChangeEvent)="onPageChange($event, entryType.link)"
                        (perPageChange)="onPageSizeChange($event, entryType.link)"
                        [collection]="linksExcludedData"
                        class="table-vertial-align"
                        [loading]="loadingLinksExcluded | async"
                        data-test="link-exclude-table-container">
                        <div class="table-container-body">
                            <table class="table table-hover table-fixed">
                                <thead>
                                <tr>
                                    <th scope="col" class="col-sm-2" translate>CMS</th>
                                    <th scope="col" class="col-sm-7" translate>Name</th>
                                    <th scope="col" class="text-right col-sm-3"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let linkExcluded of linksExcludedData">
                                    <tr>
                                        <td class="text-v-middle">
                                            {{ cmsesById[linkExcluded.default_for_cms] }}
                                        </td>
                                        <td class="text-ellipsis text-v-middle">
                                            {{ linkExcluded.name }}
                                        </td>

                                        <td class="text-right text-v-middle">
                                            <button
                                                class="btn btn-secondary"
                                                (click)="editEntry(linkExcluded, entryType.link)"
                                                translate
                                                data-test="edit-button">
                                                Edit
                                            </button>
                                            <button
                                                class="btn btn-secondary ml-1"
                                                (click)="deleteEntry(linkExcluded, entryType.link)"
                                                translate
                                                data-test="delete-button">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </mon-table-container>
                </div>
            </div>
        </div>
    </div>
</section>
