import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormBuilderSelectModule,
    LayoutModule,
    IconsModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { CsvImportButtonComponent } from './components/import-button/import-button.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormCsvImportComponent } from './components/import-form/import-form.component';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    declarations: [CsvImportButtonComponent, FormCsvImportComponent],
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        FormsModule,
        FormBuilderSelectModule,
        PipesModule,
        NgbTooltipModule,
        IconsModule,
    ],
    exports: [CsvImportButtonComponent],
})
export class CsvModule {}
