<form class="form-horizontal" name="form" #pageCorrectForm="ngForm" (ngSubmit)="isValid && submit()" novalidate>
    <div class="mon-dialog-header">
        <div class="mt-6">
            <mon-page-header header="{{'PageCorrect settings' | translate }}">
            </mon-page-header>
        </div>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel my-6 mx-6">
            <div class="card-header">
                <div class="row mt-3">
                    <div class="col-sm-10 text-right">
                        <label for="page_correct_admin_only" class="col-form-label text-bold pr-1" translate>
                            Admin only
                        </label>
                    <mon-icon [icon]="['ACTIONS', 'STUCK']" [ngbTooltip]="'Should admin be the only one to set PageCorrect Settings' | translate"></mon-icon>
                    </div>
                    <div class="col-sm-38 p-0">
                        <mon-switch [(ngModel)]="settings.admin_only"
                                    monLabel="page_correct_admin_only"
                                    name="page_correct_admin_only">
                        </mon-switch>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <p class="mt-0 text-grey" translate>
                    Enable PageCorrect features below. Disabled features will not be shown on your dashboard.
                </p>
                <div class="row mt-3">
                    <div class="col-sm-10 text-right">
                        <label for="page_correct_spelling_error" class="col-form-label text-bold pr-1" translate>
                            Spelling
                        </label>
                        <mon-icon [icon]="['ACTIONS', 'STUCK']" [ngbTooltip]="'Enable PageCorrect for Misspellings' | translate"></mon-icon>
                    </div>
                    <div class="col-sm-38">
                        <mon-switch [(ngModel)]="settings.checks.spelling.value"
                                    monLabel="page_correct_spelling_error"
                                    name="page_correct_spelling_error"
                                    (modelChange)='updateValid()'>
                        </mon-switch>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="customer.plan_traits.accessibility && domain.features.accessibility">
                    <div class="col-sm-10 text-right">
                        <label for="page_correct_accessibility_source_code" class="col-form-label text-bold pr-1" translate>
                            Accessibility Source Code
                        </label>
                        <mon-icon [icon]="['ACTIONS', 'STUCK']" [ngbTooltip]="'Enable PageCorrect for Accessibility Source Codes' | translate"></mon-icon>
                    </div>
                    <div class="col-sm-38">
                        <mon-switch [(ngModel)]="settings.checks.accessibility_source_code.value"
                                    monLabel="page_correct_accessibility_source_code"
                                    name="page_correct_accessibility_source_code"
                                    (modelChange)='updateValid()'>
                        </mon-switch>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="customer.plan_traits.accessibility && domain.features.accessibility">
                    <div class="col-sm-10 text-right">
                        <label for="page_correct_accessibility_check" class="col-form-label text-bold pr-1" translate>
                            Accessibility Check
                        </label>
                    <mon-icon [icon]="['ACTIONS', 'STUCK']" [ngbTooltip]="'Enable PagedCorrect for Accessibility Checks' | translate"></mon-icon>
                    </div>
                    <div class="col-sm-38">
                        <mon-switch [(ngModel)]="settings.checks.accessibility_check.value"
                                    monLabel="page_correct_accessibility_check"
                                    name="page_correct_accessibility_check"
                                    (modelChange)='updateValid()'>
                        </mon-switch>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-10 text-right">
                        <label for="page_correct_link" class="col-form-label text-bold pr-1" translate>Links</label>
                    <mon-icon [icon]="['ACTIONS', 'STUCK']" [ngbTooltip]="'Enable PageCorrect for Broken Links' | translate"></mon-icon>
                    </div>
                    <div class="col-sm-38">
                        <mon-switch [(ngModel)]="settings.checks.link.value"
                                    monLabel="page_correct_link"
                                    name="page_correct_link"
                                    (modelChange)='updateValid()'>
                        </mon-switch>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-10 text-right">
                        <label for="page_correct_custom" class="col-form-label text-bold pr-1" translate>Custom</label>
                        <mon-icon [icon]="['ACTIONS', 'STUCK']" [ngbTooltip]="'Enable Custom PageCorrect Settings' | translate"></mon-icon>
                    </div>
                    <div class="col-sm-38">
                        <mon-switch [(ngModel)]="settings.checks.custom.value"
                                    monLabel="'page_correct_custom' | translate"
                                    name="page_correct_custom"
                                    (modelChange)='updateValid()'>
                        </mon-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button [disabled]="saving || !isValid" type="submit" class="btn btn-secondary">
            <span *ngIf="!saving"> {{saveToAPI ? 'Save' : 'Ok'}} </span>
            <mon-icon [icon]="['SPINNER', 'ICON']" [spin]="true" *ngIf="saving"></mon-icon>
        </button>
    </div>
</form>
