import { NgModule } from '@angular/core';
import { BaseLayoutModule } from '@layout/layout.module';
import { UIRouterModule } from '@uirouter/angular';
import { LayoutModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CommonModule } from '@angular/common';
import { RESELLER_ADMIN_USERS_STATES } from './users.state';
import { ResellerAdminUsersComponent } from './users.component';
import { ResellerAdminUsersService } from './users.service';
import { ParamModule } from '@monsido/core/param/param.module';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { ServicesModule } from '@monsido/services/services.module';
import { ResellerAdminUsersRepo } from '@monsido/modules/endpoints/api/admin/reseller-admin-users.repo';
import {
    MonAvatarModule,
    TableModule,
    IconsModule,
    TableSearchModule,
    DialogService,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    providers: [ResellerAdminUsersService, ResellerAdminUsersRepo, DialogService],
    imports: [
        ParamModule,
        BaseLayoutModule,
        UIRouterModule.forChild({ states: RESELLER_ADMIN_USERS_STATES }),
        LayoutModule,
        CommonModule,
        MonAvatarModule,
        ApiModule,
        TranslateModule,
        PipesModule,
        ServicesModule,
        TableModule,
        ConfirmModule,
        TableSearchModule,
        IconsModule,
    ],
    declarations: [ResellerAdminUsersComponent],
})
export class ResellerAdminUsersModule {}
