import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { PageAssistSettingsNavigation, PageAssistSettingsCustomNavigation } from 'types/domain';

@Injectable({
    providedIn: 'root',
})
export class PageAssistService {
    private _navigations: PageAssistSettingsNavigation[] = [];
    private _navigationIndexes: Record<number, Record<number, number>> = [];

    constructor () {}

    customNavigationObs: BehaviorSubject<number | null> = new BehaviorSubject(null);

    // Navigation START

    get navigations (): PageAssistSettingsNavigation[] {
        return this._navigations;
    }

    set navigations (navigations: PageAssistSettingsNavigation[]) {
        this._navigations = navigations;
        this.setNavigationsIndexValues();
    }

    addNavigation (): void {
        this.navigations = [
            ...this.navigations,
            {
                title: '',
                mainselector: '',
                selectors: [''],
                custom_navigation: [],
            },
        ];
    }

    removeNavigation (index: number): void {
        this.navigations = this.navigations.splice(index, 1);
    }

    /**
     * Add a new selector to the Navigation Item
     */
    addNavigationSelector (navigationIndex: number): void {
        this.navigations[navigationIndex].selectors.push('');
    }

    /**
     * Remove selector of the Navigation Item
     */
    removeNavigationSelector (navigationIndex: number, selectorIndex: number): void {
        this.navigations[navigationIndex].selectors.splice(selectorIndex, 1);
    }

    // Navigation END

    // Custom Navigation START

    /**
     * Get the nested index value of the active custom navigation Element
     */
    getCustomNavigationNestIndex (navigationIndex: number, customNavigation: PageAssistSettingsCustomNavigation): number {
        let index = 1;
        let navItem = customNavigation;

        while (navItem) {
            index++;
            navItem = this.getCustomNavigationParent(navigationIndex, navItem);
        }

        return index;
    }

    getCustomNavigations (navigationIndex: number): PageAssistSettingsCustomNavigation[] {
        const index = this._navigationIndexes[navigationIndex];

        if (!index) {
            return [];
        }

        return Object
            .entries(index)
            .map(([, value]) => this.navigations[navigationIndex].custom_navigation[value]);
    }

    addCustomNavigation (navigationIndex: number, customNavigation: PageAssistSettingsCustomNavigation): void {
        this.navigations[navigationIndex].custom_navigation.push(
            customNavigation || {
                url: '',
                title: '',
                target: '',
                mainselector: '',
                selectors: [''],
                parent_index: null,
            },
        );

        this.setNavigationsIndexValues();
    }

    updateCustomNavigation (navigationIndex: number, customNavigationIndex: number, customNavigation: PageAssistSettingsCustomNavigation): void {
        const index = this._navigationIndexes[navigationIndex][customNavigationIndex];
        this.navigations[navigationIndex].custom_navigation[index] = customNavigation;

        this.customNavigationObs.next(navigationIndex);
    }

    /**
     * Remove Custom Navigation & all of its child items
     */
    removeCustomNavigation (navigationIndex: number, customNavigationIndex: number): void {
        const index = this._navigationIndexes[navigationIndex][customNavigationIndex];
        const indexes = [
            index,
            ...this.getAllCustomNavigationChildrenIndexes(navigationIndex, index),
        ];

        _.orderBy(indexes, Number, ['desc']).forEach(indexValue => {
            if (!(indexValue > -1)) {
                return;
            }

            const customNavigations = this.navigations[navigationIndex].custom_navigation;

            customNavigations.forEach(customNavigation => {
                if (customNavigation.parent_index > indexValue) {
                    customNavigation.parent_index--;
                }
            });

            customNavigations.splice(indexValue, 1);
        });

        this.setNavigationsIndexValues();
    }

    getCustomNavigationIndex (navigationIndex: number, customNavigation: PageAssistSettingsCustomNavigation): number {
        const stringifiedCustomNavigation = JSON.stringify(customNavigation);

        return _.findIndex(
            this.navigations[navigationIndex].custom_navigation,
            customNav => JSON.stringify(customNav) === stringifiedCustomNavigation,
        );
    }

    // Custom Navigation END

    /**
     * Get the Parent Custom Navigation Object. Will be undefined if index is unavailable.
     */
    protected getCustomNavigationParent (
        navigationIndex: number,
        customNavigation: PageAssistSettingsCustomNavigation,
    ): PageAssistSettingsCustomNavigation | undefined {
        return this.navigations[navigationIndex].custom_navigation[customNavigation.parent_index];
    }

    /**
     * Check if parent for Custom Navigation is defined.
     */
    protected hasCustomNavigationParent (navigationIndex: number, customNavigation: PageAssistSettingsCustomNavigation): boolean {
        return !!this.getCustomNavigationParent(navigationIndex, customNavigation);
    }

    /**
     * Set the Original Custom Navigation Index Values, so that we always can find back to the original index Position
     */
    protected setNavigationsIndexValues (): void {
        this._navigationIndexes = {};

        this.navigations.forEach((navigation, navigationIndex) => {
            let index = 0;
            this._navigationIndexes[navigationIndex] = {};

            navigation.custom_navigation.forEach((customNavigation, customNavIndex) => {
                if (customNavigation.parent_index !== null) {
                    return;
                }

                this._navigationIndexes[navigationIndex][index++] = customNavIndex;

                const childrenIndexes = this.getAllCustomNavigationChildrenIndexes(navigationIndex, customNavIndex);

                childrenIndexes.forEach(childIndex => {
                    this._navigationIndexes[navigationIndex][index++] = childIndex;
                });
            });
        });

        this.customNavigationObs.next(null);
    }

    /**
     * Get all Custom Navigation Children Indexes (Original Index values)
     */
    protected getAllCustomNavigationChildrenIndexes (navigationIndex: number, customNavigationIndex: number): number[] {
        let indexes: number[] = [];

        this.navigations[navigationIndex].custom_navigation?.forEach((customNavigation, index) => {
            if (customNavigation.parent_index !== customNavigationIndex) {
                return;
            }

            indexes = indexes.concat(
                index,
                this.getAllCustomNavigationChildrenIndexes(navigationIndex, index),
            );
        });

        return indexes;
    }
}
