<form name="form" #importCookiesForm="ngForm" class="form-horizontal">
    <ng-container *ngIf="(stepObs | async) as step">
        <div class="mon-dialog-header">
            <mon-page-header header="{{'Import Cookies' | translate }}"></mon-page-header>
        </div>
        <div class="mon-dialog-body-with-footer" >
            <div class="card monsido-panel mx-6 mt-6" *ngIf="step === 1">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Upload CSV</h3>
                </div>
                <div class="card-body">
                    <mon-form-cookies-upload (nextFile)="onUploadDone($event)"></mon-form-cookies-upload>
                </div>
            </div>
            
            <div class="card monsido-panel mx-6 mt-6" *ngIf="step === 2">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Select import</h3>
                </div>
                <div class="card-body">
                    <mon-form-cookie-setup [data]="data" (update)="onUpdateSelected($event)"></mon-form-cookie-setup>
                </div>
            </div>
            
            <div class="card monsido-panel mx-6 mt-6" *ngIf="step === 3">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Setup language</h3>
                </div>
                
                <div class="card monsido-panel mx-6 mt-6">
                    <div class="card-header">
                        <h3 class="font-weight-300" translate>Language</h3>
                    </div>
                    <div class="card-body">
                        <form name="language">
                            <mon-form-field-select
                                monName="langauge"
                                [(model)]="language"
                                monLabel="{{ 'Language' | translate}}"
                                [monAllowClear]="false"
                                monPlaceholder="{{ 'Select language' | translate }}"
                                [monOptions]="typeDropdownLanguages"
                                [monReturnValue]="'value'">
                            </mon-form-field-select>
                        </form>
                    </div>
                </div>
            </div>
            
            <div class="card monsido-panel mx-6 mt-6" *ngIf="step === 4">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Confirm</h3>
                </div>
                <div class="card-body">
                    Import {{ this.cookiesForCreate.length }} cookies and update {{ this.cookiesForUpdate.length }} cookies? <br>
                    Language selected is {{ this.language }}

                </div>
            </div>
        </div>
        <div class="mon-dialog-footer text-right">
            <button
                class="btn btn-default" [disabled]="saving || importCookiesForm.invalid"
                *ngIf="step === 2"
                (click)="incrementStep()"
                translate>
                Next
            </button>
            <button
                class="btn btn-default" [disabled]="saving || importCookiesForm.invalid"
                *ngIf="step === 3"
                (click)="prepareImport()"
                translate>
                Next
            </button>
            <button class="btn btn-default" (click)='submit()' *ngIf="step === 4">
                <span *ngIf="!saving" translate>Import Cookies</span>
                <mon-icon *ngIf="saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
            </button>
        </div>
    </ng-container>
</form>
