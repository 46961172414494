<mon-page-header [monIcon]="['CRAWL_QUEUE', 'ICON']"
                 header="{{'Crawl queue' | translate}}"
                 subHeader="{{'Overview of all crawls that are in progress and in queue' | translate}}"
                 data-test="crawl-page-header">
</mon-page-header>

<div class="row">
    <div class="hidden-xs col-xs-offset-32 col-sm-16 col-md-offset-36 col-md-12 mb-2">
        <mon-table-search (getPage)="onSearch($event)" [model]="search" [minSearchLength]="1" data-test="crawl-search"></mon-table-search>
    </div>
</div>
<div class="card monsido-panel">
    <div class="card-body">
        <div class="row">
           <div class="col-md-48 col-lg-48 col-sm-48">
                <mon-table-container [collection]="crawls" class="table-vertial-align" [loading]="loading" data-test="crawl-table-container">
                    <div class="table-container-body">
                        <table class="table table-hover table-fixed">
                            <thead>
                            <tr>
                                <th class="col-sm-2" translate>#</th>
                                <th class="col-sm-7" translate>Domain</th>
                                <th class="col-sm-8" translate>Customer</th>
                                <th class="text-center col-sm-5" translate>Status</th>
                                <th class="text-center col-sm-5" translate>Crawl started</th>
                                <th class="text-center col-sm-5" translate>Crawl runtime</th>
                                <th class="text-center col-sm-5" translate>Scanned links</th>
                                <th class="text-center col-sm-4" translate>Remaining links</th>
                                <th class="text-center col-sm-4" translate>Scanned pages</th>
                                <th class="text-right col-sm-3"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let crawl of crawls">
                                <tr>
                                    <td class="text-v-middle">
                                        {{ crawl.domain.id }}
                                    </td>
                                    <td class="text-ellipsis text-v-middle">
                                        <mon-url-title (goTo)="goToDomain(crawl.domain)"
                                                       [title]="crawl.domain.title"
                                                       [url]="crawl.domain.url"
                                                       [active]="true"
                                                       [useButton]="true">
                                        </mon-url-title>
                                    </td>
                                    <td class="text-ellipsis text-v-middle">
                                        <a class="borderless btn btn-link inner-none" uiSref="base.admin.customers.show"
                                        [uiParams]="{customer_id: crawl.customer.id}" data-test="crawl-customer-name">
                                            {{crawl.customer.name}}
                                        </a>
                                    </td>
                                    <td class="text-center text-v-middle text-ellipsis capitalize-first-letter">
                                        {{crawl.state}}
                                    </td>
                                    <td class="text-center text-v-middle">
                                        <mon-user-date [twoLines]="true" [date]="crawl.queued_at"></mon-user-date>
                                    </td>
                                    <td class="text-center text-v-middle">
                                        <span [ngbTooltip]="crawl.queued_at | amDateFormat:'LL'"> {{crawl.queued_at | amTimeAgo}}</span>
                                    </td>
                                    <td class="text-center text-v-middle">{{crawl.scanned_count}}</td>
                                    <td class="text-center text-v-middle">{{crawl.new_links}}</td>
                                    <td class="text-center text-v-middle">{{crawl.page_count}}</td>
                                    <td class="text-right text-v-middle">
                                        <button monConfirm="{{'Confirm stop crawl?'}}"
                                                (monConfirmAction)="stopCrawl(crawl)"
                                                class="btn btn-secondary"
                                                *ngIf="crawl.state == 'STARTED' || crawl.state == 'QUEUED' || crawl.state == 'BACKOFF' || crawl.state == 'RETRY'"
                                                translate>
                                            Stop
                                        </button>
                                        <button (click)="openCrawl($event, crawl)"
                                                class="btn btn-secondary"
                                                *ngIf="crawl.state == 'ON-HOLD' || crawl.state == 'SUPPORT'"
                                                translate>
                                            Review
                                        </button>
                                    </td>
                                </tr>
                                <tr class="selected">
                                    <td colspan="11" class="text-ellipsis" title="{{crawl.details}}">
                                            <mon-icon *ngIf="crawl.crawling == true"
                                                      [styles]="{'margin-right': '10px'}"
                                                      [icon]="['SPINNER', 'SYNC']"
                                                      [spin]="true">
                                            </mon-icon>
                                        {{crawl.details}}
                                    </td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </mon-table-container>
            </div>
        </div>
    </div>
</div>
