import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'monTimeDiff',
})
export class TimeDiffPipe implements PipeTransform {
    transform (input: string): string {
        return this.result(input);
    }

    private lessThanTen (val: number): string | number {
        return val < 10 ? '0' + val : val;
    }

    private result (time: string): string {
        const tmpStart = new Date(time).getTime();
        const tmpEnd = Date.now();
        let diff = tmpEnd - tmpStart;

        diff = diff / 1000 / 60 / 60;
        const hours = Math.floor(diff);
        diff = (diff - hours) * 60;
        const minutes = Math.floor(diff);
        diff = (diff - minutes) * 60;
        const seconds = Math.floor(diff);

        return this.lessThanTen(hours) + ':' + this.lessThanTen(minutes) + ':' + this.lessThanTen(seconds);
    }
}
