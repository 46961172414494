<mon-form-field-input
    required
    #first_name="ngModel"
    [(ngModel)]="user.first_name"
    [name]="'first_name'"
    monLabel="{{ 'First name' | translate }}"
    monPlaceholder="{{ 'First name' | translate }}"
    [type]="'text'">
</mon-form-field-input>
<mon-form-field-errors
    [showError]="first_name.control.touched && first_name.control.invalid"
    [errors]="first_name.control.errors"
></mon-form-field-errors>

<mon-form-field-input
    [(ngModel)]="user.last_name"
    [name]="'last_name'"
    monLabel="{{ 'Last name' | translate }}"
    monPlaceholder="{{ 'Last name' | translate }}"
    [type]="'text'">
</mon-form-field-input>

<mon-form-field-select-timezones
    monLabel="{{ 'Timezones' | translate }}"
    monHelpTxt="{{ 'Choose a timezone' | translate }}"
    [(model)]="user.timezone"
    monReturnValue="name">
</mon-form-field-select-timezones>

<mon-form-field-input
    [(ngModel)]="user.email"
    required
    monEmailValidator
    #email="ngModel"
    [name]="'email'"
    monLabel="{{ 'Email' | translate }}"
    monPlaceholder="{{ 'Email' | translate }}"
    [type]="'email'">
</mon-form-field-input>
<mon-form-field-errors
    [showError]="email.control.touched && email.control.invalid"
    [errors]="email.control.errors">
</mon-form-field-errors>

<mon-form-field-select
    *ngIf="isAdmin"
    [monName]="'locale'"
    monReturnValue="code"
    [monOptions]="supportedLanguages"
    monLabel="{{ 'Language' | translate}}"
    monPlaceholder="{{ 'Language' | translate}}"
    [(model)]="user.locale"
    [monNoAutoComplete]="true">
</mon-form-field-select>

<ng-container *ngIf="user.id">
    <mon-form-field-input
        [(ngModel)]="user.password"
        [name]="'password'"
        monLabel="{{ 'Password' | translate }}"
        monPlaceholder="{{ 'Password' | translate }}"
        [type]="'password'">
    </mon-form-field-input>

    <mon-form-field-input
        [(ngModel)]="user.password_confirmation"
        [name]="'password_confirmation'"
        #password_confirmation="ngModel"
        [pattern]="regexEscape(user.password)"
        monLabel="{{ 'Password confirmation' | translate }}"
        monPlaceholder="{{ 'Password confirmation' | translate }}"
        [type]="'password'">
    </mon-form-field-input>
    <mon-form-field-errors
        [patternMsg]="'Passwords do not match | translate'"
        [showError]="password_confirmation.control.touched && password_confirmation.control.invalid"
        [errors]="password_confirmation.control.errors">
    </mon-form-field-errors>
</ng-container>
