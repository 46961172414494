import { Injectable } from '@angular/core';

export interface AnchorTarget {
    readonly name: string;
    readonly value: string;
}

@Injectable({
    providedIn: 'root',
})
export class PageAssistCustomNavigationService {
    targets: readonly AnchorTarget[] = [
        {
            name: '_blank',
            value: '_blank',
        },
        {
            name: '_self',
            value: '_self',
        },
        {
            name: '_parent',
            value: '_parent',
        },
        {
            name: '_top',
            value: '_top',
        },
        {
            name: 'framename',
            value: 'framename',
        },
    ];

    get defaultTabValue (): string {
        return this.targets[0].value;
    }
}
