<div class="dialog-content-header" *ngIf="reseller">
    <mon-page-header [monIcon]="['CUSTOMERS', 'ICON']" header="{{'Customers for' | translate}} {{reseller.name}}">
        <content>
            <button class="btn btn-secondary pull-right outer-t-small"
                    (click)="createCustomer()"
                    data-test="new-customer"
                    translate
            >
                New Customer
            </button>
        </content>
    </mon-page-header>
</div>
<div class="headerInnerL full-height ">
    <mon-tabs
            [minSearchLength]="1"
            [search]="search"
            (onSearch)="onSearch($event)"
            [showSearch]="true"
            data-test="search-input">
    </mon-tabs>
    <mon-table-container
        [collection]="customers"
        (pageChangeEvent)="onPageChange($event)"
        (perPageChange)="onPageSizeChange($event)"
        class="table-vertial-align"
        [loading]="loading">
        <div class="table-container-body">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th class="col-sm-12 col-md-12 col-lg-10" translate>Name</th>
                    <th class="col-sm-4 col-md-3 col-lg-2" translate>Plan</th>
                    <th class="text-right col-sm-5 col-md-5 col-lg-4"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let customer of customers">
                    <td><a uiSref="base.backend_admin.customers.show" [uiParams]="{customer_id: customer.id}" data-test="customer-name">{{customer.name}} {{
                        customer.id }}</a></td>
                    <td>
                        {{ customer.subscription.plan.name || 'No plan' }}
                    </td>
                    <td class="text-right">
                        <button type="button" class="cursor-pointer btn btn-secondary"
                            uiSref="base.backend_admin.customers.show"
                            [uiParams]="{customer_id: customer.id}"
                            data-test="customer-details-button">
                            {{'Customer details' | translate}}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mon-table-container>
</div>
