export const PLAN = {
    PLAN_GROUPS: [
        {
            id: 4,
            name: 'SchoolMessenger',
        },
        {
            id: 5,
            name: 'Mainstreethost',
        },
        {
            id: 6,
            name: 'North Dakota',
        },
        {
            id: 8,
            name: 'State of North Carolina',
        },
        {
            id: 9,
            name: 'CivicPlus',
        },
        {
            id: 3,
            name: 'Gabbart',
        },
        {
            id: 2,
            name: 'Monsido Group',
        },
        {
            id: 1,
            name: 'Other Resellers Group',
        },
    ],
    PLAN_TYPES: [
        {
            name: 'Paying',
            value: 'paying',
        },
        {
            name: 'Demo',
            value: 'demo',
        },
        {
            name: 'Lite',
            value: 'light',
        },
    ],
    PRICES: ['12m', '1m'],
    INTERVAL_OPTIONS: [
        {
            name: '1 minute',
            value: 60,
        },
        {
            name: '5 minutes',
            value: 300,
        },
    ],
};
