<div class="table-container-body">
    <table class="table table-fixed table-hover">
        <thead>
        <tr>
            <th translate>Key</th>
            <th translate class="text-right">Value</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let header of scan.response_headers">
            <td>{{ header.name }}</td>
            <td class="text-right request-word-wrapper">
                {{ header.value }}
            </td>
        </tr>
        </tbody>
    </table>
</div>
