export const CHECKER_TABS = {
    base: {
        icon: 'fas fa-server',
        value: 'base-info',
        name: 'Base info',
    },
    redirect: {
        icon: 'fas fa-link',
        value: 'redirect',
        name: 'Redirect',
    },
    advance: {
        icon: 'fas fa-list',
        value: 'advance',
        name: 'Advance',
    },
    html: {
        icon: 'fas fa-code',
        value: 'html',
        name: 'HTML',
    },
    article: {
        icon: 'fas fa-newspaper',
        value: 'article',
        name: 'Article',
    },
    header: {
        icon: 'fas fa-heading',
        value: 'heading',
        name: 'Response headings',
    },
    cookies: {
        icon: 'fas fa-cookie',
        value: 'cookies',
        name: 'Cookies',
    },
    links: {
        icon: 'fas fa-link',
        value: 'links',
        name: 'Links',
    },
    accessibility: {
        icon: 'fas fa-universal-access',
        value: 'accessibility',
        name: 'Accessibility',
    },
    texts: {
        icon: 'fas fa-language',
        value: 'texts',
        name: 'Texts',
    },
    readability: {
        icon: 'fas fa-eye',
        value: 'readability',
        name: 'Readability',
    },
};

