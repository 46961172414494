import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StepType } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.service';
import { ImportCsvService } from '@monsido/modules/csv/services/import-csv.service';
import { Papa, ParseResult } from 'ngx-papaparse';
import { NgxSelectOption } from 'ngx-select-ex';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';

export type CsvPropType = 'path_constraints' | 'link_excludes';

@Component({
    selector: 'mon-csv-import-form',
    templateUrl: './import-form.component.html',
    styleUrls: ['./import-form.component.scss'],
})
export class FormCsvImportComponent implements OnInit {
    @Input() monProp: CsvPropType;
    @Input() monHeader: string;
    @Input() monSelectLabel: string;
    @Input() monConfirmText: string;

    saving: boolean;
    stepObs: Observable<StepType>;
    options: Record<string, string>[];
    selected: { [name: string]: string };
    confirmText: string;
    data: ParseResult;

    constructor (private importCsvService: ImportCsvService, private papa: Papa, private activeDialog: ActiveDialog) {}

    ngOnInit (): void {
        this.saving = false;
        this.selected = { [this.monProp]: null };
        this.options = [];
        this.stepObs = this.importCsvService.getStepObs();
    }

    incrementStep (): void {
        this.importCsvService.setStep((this.importCsvService.getCurrentStep() + 1) as StepType);
    }

    submit (): void {
        this.saving = true;
        this.data.data.shift();

        const submitData = [];

        for (let i = 0; i < this.data.data.length; i++) {
            submitData.push({ [this.monProp]: this.data.data[i][this.selected[this.monProp]] as string });
        }

        this.close(submitData);
    }

    newBulkFile (event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        const file: File = element.files[0];

        if (file) {
            this.papa.parse(file, {
                complete: (result) => {
                    this.data = result;
                    this.options = this.importCsvService.setupOptions(this.data);
                    this.selected = this.importCsvService.setupSelected(this.options, this.monProp);
                    this.monConfirmText = this.importCsvService.getConfirmText(this.data);
                    setTimeout(() => {
                        this.importCsvService.setStep(2);
                    });
                },
            });
        }
    }

    close (data: Record<string, string>[]): void {
        this.activeDialog.close(data);
    }

    updateSelected (): void {}

    onChange (event: NgxSelectOption): void {
        this.selected[this.monProp] = event.value as string;
    }
}
