import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OauthService } from '@monsido/oauth/oauth.service';

@NgModule({
    imports: [HttpClientModule],
    exports: [],
    declarations: [],
    providers: [OauthService],
})
export class OauthModule {}
