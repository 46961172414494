import { MeContactInfo, MeInterface, MeSocialMedia } from '@monsido/modules/models/api/interfaces/me.interface';

export class Me implements MeInterface {
    constructor (me?: MeInterface) {
        Object.assign(this, me);
    }

    id: number;
    email: string;
    first_name: string;
    last_name: string;
    locale: string;
    timezone?: string;
    contact_info: MeContactInfo;
    social_media: MeSocialMedia;
    title?: string;
    description?: string;
    phone?: string;
    otp: boolean;
    created_at: string;
    updated_at: string;
    last_login_at: string;
    reseller_admin: boolean;
    labels?: string[] | string;
    name: string;
    initials: string;
    auth_user_password?: string;
    password?: string;
    password_confirmation?: string;
    super_admin: boolean;
}
