import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseControllerComponent } from './base-controller/base-controller.component';


export const LAYOUT_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base',
        url: '',
        data: { anonymous_access: false },
        component: BaseControllerComponent,
    },
];
