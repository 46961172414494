import { Injectable } from '@angular/core';
import { BackendHeatmapsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-heatmaps.repo';
import { SummaryParamsInterface } from '@monsido/pages/backend-admin/support/heatmaps/summary-params.interface';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { Domain } from '@monsido/modules/models/api/domain';
import { HeatmapSummaryInterface } from '@monsido/modules/models/api/interfaces/heatmap-summary.interface';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeatmapsService {
    constructor (private backendHeatmapsRepo: BackendHeatmapsRepo, private backendDomainsRepo: BackendDomainsRepo) {}

    getSummaryUrl (customerId: number, domainId: number, params: SummaryParamsInterface): Promise<HeatmapSummaryInterface[]> {
        return this.backendHeatmapsRepo.getUrlSummary(customerId, domainId, params);
    }

    getDomains (domainId: string): Promise<Domain[]> {
        const params: Record<string, string> = {};

        if (domainId != null) {
            params.search = domainId;
        }

        return this.backendDomainsRepo.getDomains(params, new HttpHeaders({ noParseSearch: 'true' }));
    }

    getDomains$ (domainId: string): Observable<Domain[]> {
        const params: Record<string, string> = {};

        if (domainId != null) {
            params.search = domainId;
        }

        return this.backendDomainsRepo.getDomains$(params, new HttpHeaders({ noParseSearch: 'true' }));
    }
}
