import { Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { User } from '@monsido/modules/models/api/user';
import { ResellerAdminUsersService } from './users.service';
import { ParamService } from '@monsido/core/param/param.service';
import { HttpParams } from '@angular/common/http';
@Component({
    selector: 'mon-reseller-admin-users',
    templateUrl: 'users.html',
})
export class ResellerAdminUsersComponent extends BaseApiComponent implements OnInit {
    users: User[] = [];
    search: string;
    constructor (private resellerAdminUsersService: ResellerAdminUsersService, private paramService: ParamService, $location: UrlService) {
        super($location);
    }

    ngOnInit (): void {
        this.loadLocations();
    }

    getPage (): void {
        const params: Record<string, string | number> = {
            page: this.page,
            page_size: this.pageSize,
        };

        if (this.search) {
            params.search = this.search;
        }

        this.paramService.setParams({
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        });
        this.loading = true;
        this.resellerAdminUsersService.getAll(params as unknown as HttpParams).then(
            (users: User[]) => {
                this.users = users;
                this.loading = false;
            },
            (res) => {
                this.loading = res.status === -1 && res.xhrStatus === 'abort';
            },
        );
    }

    editUser (e: MouseEvent, user: User): void {
        e.preventDefault();
        this.resellerAdminUsersService.editUser(user, () => {
            this.getPage();
        });
    }

    deleteUser (user: User): void {
        this.resellerAdminUsersService.deleteUser(user.id).then(() => {
            this.getPage();
        });
    }

    createUser (e: MouseEvent): void {
        e.preventDefault();
        this.resellerAdminUsersService.createUser(() => {
            this.getPage();
        });
    }
}
