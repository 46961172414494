export const CRAWL = {
    STATE: {
        RETRY: 'RETRY',
        ON_HOLD: 'ON-HOLD',
        QUEUED: 'QUEUED',
        SUPPORT: 'SUPPORT',
        COOKIE_SCAN: 'COOKIE_SCAN',
    },

    TAB: {
        ALL: 'all',
        ACTIVE: 'active',
        ON_HOLD: 'on-hold',
        RETRY: 'retry',
        QUEUED: 'queued',
        SUPPORT: 'support',
    },
};
