import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
    selector: 'mon-base-disposable-menu',
    standalone: true,
    imports: [CommonModule],
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDisposableMenuComponent implements OnDestroy {
    protected destroy$ = new Subject<void>();

    ngOnDestroy (): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
