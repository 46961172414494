import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '@monsido/modules/models/api/customer';
import { IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { ResellersRepo } from 'app/services/api/backend_admin/resellers-repo';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-pdf-clarity',
    standalone: true,
    imports: [CommonModule, TranslateModule, IconsModule, FormsModule, NgbModalModule],
    templateUrl: './pdf-clarity.component.html',
})
export class FormPdfClarityComponent implements OnInit {
    @Input() customer: Customer;

    tokens: number;
    title: string;

    constructor (
        private resellersRepo: ResellersRepo,
        private translateService: TranslateService,
        private activeModal: NgbActiveModal,
    ) {}

    ngOnInit (): void {
        this.tokens = 0;
        this.title = this.translateService.getString('Add tokens for [[customer]]', { customer: this.customer.name });
    }

    submit (): void {
        const params = {
            tokens: this.tokens,
        };

        this.resellersRepo
            .createCustomerPDFClarityReport(
                this.customer.reseller.id,
                this.customer.id,
                params,
            )
            .then((customer: CustomerInterface) => {
                this.close(customer);
            });
    }

    close (customer: CustomerInterface): void {
        this.activeModal.close(customer);
    }

    cancel (value: string): void {
        this.activeModal.dismiss(value);
    }
}
