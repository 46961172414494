import { Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { User } from '@monsido/modules/models/api/user';
import { UsersService } from '@monsido/pages/admin/users/users.service';
import { ParamService } from '@monsido/core/param/param.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'mon-users',
    templateUrl: 'users.html',
})
export class UsersComponent extends BaseApiComponent implements OnInit {
    users: User[];
    // TODO remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    urlParams: any;

    constructor ($location: UrlService, private usersService: UsersService, private paramService: ParamService) {
        super($location);
    }

    ngOnInit (): void {
        this.loadLocations();
    }

    getPage (): void {
        this.loading = true;

        const params: Record<string, string | number> = {
            page: this.page,
            page_size: this.pageSize,
        };

        if (this.search) {
            params.search = this.search;
        }

        this.paramService.setParams({
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        });

        this.usersService
            .getPage(params as unknown as HttpParams)
            .then((users: User[]) => {
                this.users = users;
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
