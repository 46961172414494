import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { HeatmapSummary } from '@monsido/modules/models/api/heatmap-summary';
import { HeatmapSummaryInterface } from '@monsido/modules/models/api/interfaces/heatmap-summary.interface';

@Injectable()
export class BackendHeatmapsRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    getUrlSummary (customerId: number, domainId: number, params?: HttpParams): Promise<HeatmapSummaryInterface[]> {
        return this.adminClient
            .getPromise(`customers/${customerId}/domains/${domainId}/heatmaps/url_summary`, {
                params,
            })
            .then(this.toModels.bind(this));
    }

    private toModels (models: HeatmapSummary[]): HeatmapSummary[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    private toModel (model: HeatmapSummary): HeatmapSummary {
        return new HeatmapSummary(model);
    }
}
