import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { PlansComponent } from './plans.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const PLANS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.plans',
        url: '/plans',
        abstract: true,
        component: BaseStateComponent,
    },
    {
        name: 'base.backend_admin.plans.index',
        url: '?search&page_size&page',
        params: RouteParamsConstant,
        views: {
            '^.^.^.$default': {
                component: PlansComponent,
            },
        },
        dynamic: true,
    },
];
