import { Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { Injectable } from '@angular/core';
import { PlansService } from './plans.service';
import { ParamService } from '@monsido/core/param/param.service';
import { ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Plan } from '@monsido/modules/models/api/plan';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-plans',
    templateUrl: 'plans.html',
})
@Injectable()
export class PlansComponent extends BaseApiComponent implements OnInit {
    plans: Plan[] = [];
    constructor (
        private toastService: ToastService,
        private translateService: TranslateService,
        private plansService: PlansService,
        private paramService: ParamService,
        $location: UrlService,
    ) {
        super($location);
    }

    ngOnInit (): void {
        this.loadLocations();
    }

    getPage (): void {
        const params: Record<string, string | number> = {
            page: this.page,
            page_size: this.pageSize,
        };

        if (this.search) {
            params.search = this.search;
        }

        this.paramService.setParams({
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        });
        this.loading = true;
        this.plansService.getAll(params).then(
            (data: Plan[]) => {
                this.loading = false;
                this.plans = this.plansService.sortPlans(data);
            },
            (res) => {
                this.loading = res.status === -1 && res.xhrStatus === 'abort';
            },
        );
    }

    groupName (groupId: number): string {
        return this.plansService.groupName(groupId);
    }

    createPlan (e: MouseEvent): void {
        e.preventDefault();
        this.plansService.createPlan(() => {
            this.getPage();
        });
    }

    editPlan (e: MouseEvent, plan: Plan): void {
        e.preventDefault();
        this.plansService.editPlan(plan, (args, forceUpdate) => {
            if (forceUpdate) {
                this.getPage();
            }
        });
    }

    onDeletePlan (plan: Plan, result: number): void {
        if (Number(plan.id) === Number(result)) {
            this.plansService.deletePlan(plan.id).then(() => {
                this.getPage();
            });
        } else {
            this.toastService.warning(this.translateService.getString('Wrong Answer'));
        }
    }
}
