import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService, LayoutModule, ToastService, TableModule, MonAvatarModule, TableSearchModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CustomerRepo } from '../../../../../../services/api/admin/customer-repo';
import { CommonModule } from '@angular/common';
import { SudoButtonComponent } from '@layout/buttons/sudo/sudo-button.component';
import { Customer } from '@monsido/modules/models/api/customer';
import { DEFAULTS } from '@monsido/core/constants/defaults.constant';
import { UserForm } from '@monsido/forms/user/user-form';
import { FormCustomerUserComponent } from '../../../../../../forms/form-customer-user/form-customer-user.component';
import { cloneDeep } from 'lodash';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { MonIconsPipe } from '../../../../../../filters/mon-icons.pipe';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

export type UsersTabGetParamsChangePayload = {
    page: number;
    pageSize: number;
    search: string;
}

@Component({
    selector: 'mon-users-tab',
    templateUrl: 'users-tab.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        TableModule,
        TableSearchModule,
        MonAvatarModule,
        SudoButtonComponent,
        ConfirmModule,
        MonIconsPipe,
        PipesModule,
        NgbTooltipModule,
    ],
})
export class UsersTabComponent implements OnInit {
    @Input() customer: Customer | null = null;
    @Input() search = '';
    @Input() page = 1;
    @Input() pageSize = DEFAULTS.API.PAGE_SIZE;
    @Input() users: CollectionInterface<UserForm> = [];
    @Input() loading = false;

    @Output() getPageEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() createUser: EventEmitter<void> = new EventEmitter<void>();
    @Output() importUsers: EventEmitter<void> = new EventEmitter<void>();
    @Output() getParamsChange: EventEmitter<UsersTabGetParamsChangePayload> = new EventEmitter<UsersTabGetParamsChangePayload>();

    constructor (
        private dialogService: DialogService,
        private customerRepo: CustomerRepo,
        private notificationService: ToastService,
        private translateService: TranslateService,
    ) {}

    ngOnInit (): void {
        if (this.customer) {
            this.getPage();
        }
    }

    onEditUser (user: UserForm): void {
        const dialogRef = this.dialogService.open(FormCustomerUserComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-md',
            cb: () => {
                this.getPage();
            },
        });

        dialogRef.componentInstance.user = cloneDeep(user);
        if (this.customer) {
            dialogRef.componentInstance.customerId = this.customer.id;
        }
    }

    onGetParamsChanged<T extends keyof UsersTabGetParamsChangePayload> (type: T, value: UsersTabGetParamsChangePayload[T]): void {
        const payload: UsersTabGetParamsChangePayload = {
            page: this.page,
            pageSize: this.pageSize,
            search: this.search,
        };

        if (type === 'search' || type === 'pageSize') {
            payload.page = 1;
        }

        payload[type] = value;

        this.getParamsChange.emit(payload);
    }

    async onDeleteUser (user: UserForm): Promise<void> {
        await this.customerRepo.destroyUser(this.customer.id, user.id);
        this.notificationService.success(this.translateService.getString('User deleted'));
        this.getPage();
    }

    async onSudo (onSudoPayload: { customerId: number, user: UserForm }): Promise<void> {
        const { user } = onSudoPayload;
        if (user) {
            await this.customerRepo.sudoUser(this.customer.id, user.id);
        }
    }

    getPage (): void {
        this.getPageEvent.emit();
    }
}
