<div class="table-container-body">
    <table class="table table-fixed table-hover">
        <thead>
            <tr>
                <th class="col-42" translate>Url</th>
                <th class="col-3 text-right" translate>Excluded</th>
                <th class="col-3 text-right" translate>Added as internal</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let link of scan.links">
                <td class="text-wrap">{{ link.url }}</td>
                <td class="text-right">{{ link.excluded }}</td>
                <td class="text-right">{{ link.added_as_internal }}</td>
            </tr>
        </tbody>
    </table>
</div>
