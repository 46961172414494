<form name="form" class="form-horizontal" #form="ngForm" (ngSubmit)="submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['PLANS', 'ICON']"
                         header="{{'New cookie' | translate }}"
                         *ngIf="!cookie.id">
        </mon-page-header>
        <mon-page-header [monIcon]="['PLANS', 'ICON']"
                         header="{{'Edit cookie' | translate }}"
                         *ngIf="cookie.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Cookie
                </h3>
            </div>
            <div class="card-body">
                <mon-form-field-input [(ngModel)]="cookie.name"
                                      monLabel="{{ 'Name' | translate }}"
                                      monPlaceholder="{{ 'Name' | translate }}"
                                      [type]="'text'"
                                      [name]="'name'"
                                      required
                                      [useCustomError]="false">
                </mon-form-field-input>
                <mon-form-field-input [(ngModel)]="cookie.platform"
                                      monLabel="{{ 'Platform' | translate }}"
                                      monPlaceholder="{{ 'Platform' | translate }}"
                                      [type]="'text'"
                                      [name]="'platform'"
                                     required
                                      [useCustomError]="false">
                </mon-form-field-input>
                <mon-form-field-select
                    [monName]="'type'"
                    [(model)]="cookie.type"
                    monLabel="{{ 'Type' | translate}}"
                    [monAllowClear]="false"
                    monPlaceholder="{{ 'Select type' | translate }}"
                    [monOptions]="typeDropdownOptions"
                    [monReturnValue]="'value'"
                    [monRequired]="true">
                </mon-form-field-select>
            </div>
        </div>
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Descriptions
                </h3>
            </div>
            <div class="card-body">
                <div *ngFor="let description of cookie.descriptions; let $index = index">
                    <div class="row">
                        <mon-form-field-textarea
                            [(model)]="description.text"
                            name="'description_{{$index}}'"
                            [label]="'Description' | translate"
                            [placeholder]="'Description' | translate"
                            [ngRequired]="true"
                            [rows]="6">
                        </mon-form-field-textarea>
                    </div>
                    <div class="row">
                        <div class="col-sm-30">
                            <mon-form-field-select
                                monName="description_languages_{{$index}}"
                                [(model)]="description.language"
                                monLabel="{{ 'Language' | translate}}"
                                [monAllowClear]="false"
                                monPlaceholder="{{ 'Select language' | translate }}"
                                [monOptions]="typeDropdownLanguages"
                                [monReturnValue]="'value'">
                            </mon-form-field-select>
                        </div>
                        <div class="col-sm-4 pr-6 text-right">
                            <button type="button"
                                    class="btn btn-default"
                                    (click)="removeDescription($index)">
                                <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                            </button>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-sm-40 col-sm-offset-8">
                        <button type="button" class="btn btn-default"(click)="addDescription()">
                            <span class="fas fa-plus text-link"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Expirations
                </h3>
            </div>
            <div class="card-body">
                <div *ngFor="let expiration of cookie.expirations; let $index = index">
                    <div class="row">
                        <mon-form-field-textarea
                            [(model)]="expiration.text"
                            name="'expiration_{{$index}}'"
                            [label]="'Expiration' | translate"
                            [placeholder]="'Expirations' | translate"
                            [ngRequired]="true"
                            [rows]="6">
                        </mon-form-field-textarea>
                    </div>
                    <div class="row">
                        <div class="col-sm-30">
                            <mon-form-field-select
                                monName="expiration_languages_{{$index}}"
                                [(model)]="expiration.language"
                                monLabel="{{ 'Language' | translate}}"
                                [monAllowClear]="false"
                                monPlaceholder="{{ 'Select language' | translate }}"
                                [monOptions]="typeDropdownLanguages"
                                [monReturnValue]="'value'">
                            </mon-form-field-select>
                        </div>
                        <div class="col-sm-4 pr-6 text-right">
                            <button type="button"
                                    class="btn btn-default"
                                    (click)="removeExpiration($index)">
                                <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                            </button>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-sm-40 col-sm-offset-8">
                        <button type="button" class="btn btn-default"(click)="addExpiration()">
                            <span class="fas fa-plus text-link"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-default" [disabled]="saving || form.invalid" type="submit" data-test="save-button">
            <span [hidden]="saving" translate>Save</span>
            <mon-icon [hidden]="!saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
        </button>
    </div>
</form>
