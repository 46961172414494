<form #form="ngForm" class="form-horizontal" (ngSubmit)="!saving && submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['PARTNERS', 'ICON']" [header]="'New partner' | translate" *ngIf="!reseller.id"></mon-page-header>
        <mon-page-header [monIcon]="['PARTNERS', 'ICON']" [header]="'Edit partner' | translate" *ngIf="reseller.id"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300">Partner details</h3>
            </div>
            <div class="card-body">
                <mon-form-field-input #name="ngModel" [(ngModel)]="reseller.name" name="name" [monLabel]="'Name' | translate" [monPlaceholder]="'Name' | translate" type="text" required></mon-form-field-input>
                <mon-form-field-errors
                    [showError]="name.touched && name.invalid"
                    [errors]="name.errors"
                ></mon-form-field-errors>
                <mon-form-field-input #address="ngModel" [(ngModel)]="reseller.address" name="address" [monLabel]="'Address' | translate" [monPlaceholder]="'Address' | translate" type="text" required></mon-form-field-input>
                <mon-form-field-errors
                    [showError]="address.touched && address.invalid"
                    [errors]="address.errors"
                ></mon-form-field-errors>
                <mon-form-field-input #zipcode="ngModel" [(ngModel)]="reseller.zipcode" name="zipcode" [monLabel]="'Zipcode' | translate" [monPlaceholder]="'Zipcode' | translate" type="text" required></mon-form-field-input>
                <mon-form-field-errors
                    [showError]="zipcode.touched && zipcode.invalid"
                    [errors]="zipcode.errors"
                ></mon-form-field-errors>
                <mon-form-field-input #city="ngModel" [(ngModel)]="reseller.city" name="city" [monLabel]="'City' | translate" [monPlaceholder]="'City' | translate" type="text" required></mon-form-field-input>
                <mon-form-field-errors
                    [showError]="city.touched && city.invalid"
                    [errors]="city.errors"
                ></mon-form-field-errors>
                <mon-form-field-input [(ngModel)]="reseller.state" name="state" [monLabel]="'State' | translate" [monPlaceholder]="'State' | translate" type="text"></mon-form-field-input>
                <mon-form-field-input #country="ngModel" [(ngModel)]="reseller.country" name="country" required [monLabel]="'Country' | translate" [monPlaceholder]="'Country' | translate" type="text"></mon-form-field-input>
                <mon-form-field-errors
                    [showError]="country.touched && country.invalid"
                    [errors]="country.errors"
                ></mon-form-field-errors>
                <mon-form-field-input #phone_number="ngModel" [(ngModel)]="reseller.phone_number" name="phone_number" [monLabel]="'Phone number' | translate" [monPlaceholder]="'Phone number' | translate" type="text" required></mon-form-field-input>
                <mon-form-field-errors
                    [showError]="phone_number.touched && phone_number.invalid"
                    [errors]="phone_number.errors"
                ></mon-form-field-errors>
                <ng-container *ngIf="reseller.settings">
                    <mon-form-field-input [(ngModel)]="reseller.settings.training_request_link"
                                        name="training_request_link"
                                        (ngChange)="isValidTrainingLink(reseller?.settings.training_request_link)"
                                        monLabel="{{ 'Custom URL/email for training booking' | translate }}"
                                        monPlaceholder="{{ 'Input URL or email here. If left blank, we use the default training booking link.' | translate }}"
                                        type="text">
                    </mon-form-field-input>
                        <div class="block col-sm-40 col-sm-offset-8 invalid-feedback"
                        *ngIf="!validTrainingLink && reseller.settings.training_request_link !== ''">
                        <span class="help-block" translate>
                            Insert a valid URL/email
                        </span>
                    </div>
                    <mon-form-field-input #help_center_link="ngModel" [(ngModel)]="reseller.settings.help_center_link"
                    validUrl
                    name="help_center_link"
                    [monLabel]="'Custom URL for help center' | translate"
                    [monPlaceholder]="'Input URL here. If left blank, we use the default help center link.' | translate"
                    type="text">
                    </mon-form-field-input>
                    <div class="block col-sm-40 col-sm-offset-8 invalid-feedback"
                        *ngIf="help_center_link.touched && help_center_link.errors.validUrl">
                        <span class="help-block" translate>
                            Insert a valid URL
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-secondary" [disabled]="saving || form.invalid" type="submit" data-test="save-button">
            <span *ngIf="!saving" translate>Save</span>
            <span class="fa-spin fa-fw"
                  [ngClass]="['SPINNER', 'ICON'] | monIcons"
                  aria-hidden="true"
                  *ngIf="saving">
            </span>
        </button>
    </div>
</form>
