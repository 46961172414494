import { Injectable } from '@angular/core';
import { BackendVerticalsRepoService } from '@monsido/modules/endpoints/api/backend_admin/backend-verticals-repo.service';
import { Observable } from 'rxjs';
import { Verticals, VerticalsType } from '../verticals.type';
import { TranslateService } from 'app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class VerticalsService {
    dictionary: Record<VerticalsType, string>;

    constructor (
        private backendAdminVerticalsRepo: BackendVerticalsRepoService,
        private translateService: TranslateService,
    ) {
        this.dictionary = this.getDictionary();
        this.translateService.onLanguageChange$().subscribe(() => {
            this.dictionary = this.getDictionary();
        });
    }

    getVerticals (): Observable<Array<VerticalsType>> {
        return this.backendAdminVerticalsRepo.get();
    }

    private getDictionary (): Record<VerticalsType, string> {
        return {
            [Verticals.FINANCIAL_SERVICES]: this.translateService.getString('Financial services'),
            [Verticals.RETAIL_WHOLESALE]: this.translateService.getString('Retail wholesale'),
            [Verticals.CONSTRUCTION_INDUSTRIAL]: this.translateService.getString('Construction industrial'),
            [Verticals.BUSINESS_SERVICES]: this.translateService.getString('Business services'),
            [Verticals.GOVERNMENT]: this.translateService.getString('Government'),
            [Verticals.NON_PROFIT]: this.translateService.getString('Non profit'),
            [Verticals.EDUCATION]: this.translateService.getString('Education'),
            [Verticals.LAW_ENFORCEMENT]: this.translateService.getString('Law enforcement'),
            [Verticals.CONSUMER_SERVICES]: this.translateService.getString('Consumer services'),
            [Verticals.ENTERTAINMENT_LEISURE]: this.translateService.getString('Entertainment leisure'),
            [Verticals.HEALTHCARE]: this.translateService.getString('Healthcare'),
            [Verticals.LEGAL]: this.translateService.getString('Legal'),
            [Verticals.MEDIA]: this.translateService.getString('Media'),
            [Verticals.REAL_ESTATE]: this.translateService.getString('Real estate'),
            [Verticals.TECHNOLOGY]: this.translateService.getString('Technology'),
            [Verticals.UTILITIES_NATURAL_RESOURCES]: this.translateService.getString('Utilities natural resources'),
            [Verticals.PROFESSIONAL_SERVICES]: this.translateService.getString('Professional services'),
            [Verticals.LOCAL_GOVERNMENT]: this.translateService.getString('Local government'),
            [Verticals.OTHER]: this.translateService.getString('Other'),
        };
    }

    getTranslatedVerticalsMap (): Record<VerticalsType, string> {
        return this.dictionary;
    }
}
