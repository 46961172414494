import {
    CookieInterface,
    CookieType,
    DescriptionsEntityOrExpirationsEntity,
} from '@monsido/modules/models/support/interfaces/cookie.interface';

export class Cookie implements CookieInterface {
    id?: number;
    platform: string;
    name: string;
    type: CookieType;
    descriptions?: DescriptionsEntityOrExpirationsEntity[] | null;
    expirations?: DescriptionsEntityOrExpirationsEntity[] | null;

    constructor(cookie?: CookieInterface) {
        Object.assign(this, cookie);
    }
}
