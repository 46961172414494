import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { ControlContainer, NgForm } from '@angular/forms';
import { BackendAdminUserForm } from '../user-form';
import { Domain } from '@monsido/modules/models/api/domain';
import { DomainGroupMembersEntity, DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { DomainGroupDataType } from 'types/domain';
import { LayoutModule, MonSelectAllCheckboxModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CommonModule } from '@angular/common';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-form-backend-admin-customer-user-domains',
    templateUrl: 'domains.html',
    standalone: true,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    imports: [
        TranslateModule,
        LayoutModule,
        CommonModule,
        MonSelectAllCheckboxModule,
    ],
})
export class FormBackendAdminCustomerUserDomainsComponent implements OnInit, OnChanges {
    @Input() form: NgForm;

    private userValue: User;
    @Output() userChange = new EventEmitter();
    @Input()
    get user (): BackendAdminUserForm {
        return this.userValue;
    }

    set user (val: BackendAdminUserForm) {
        this.userValue = val;
        this.userChange.emit(this.userValue);
    }

    @Input() domains: Domain[];

    constructor () {}

    ngOnInit (): void {
        this.user.domain_users = this.user.domain_users || [];
        this.user.domain_group_members = this.user.domain_group_members || [];
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes?.domains?.currentValue?.length) {
            const domains = changes.domains.currentValue;
            for (let i = 0; i < domains.length; i++) {
                const domain = domains[i];
                this.setDomain(domain);
                for (let j = 0; j < domain.domain_groups.length; j++) {
                    const domainGroup = domain.domain_groups[j];
                    this.setDomainGroup(domainGroup);
                }
            }
        }
    }

    setDomain (domain: Domain): void {
        const index = this.getIndexOfUserDomain(domain);
        if (index === -1) {
            this.user.domain_users.push({
                visible: false,
                send_report: false,
                domain_id: domain.id,
            } as DomainUsersEntity);
        }
    }

    setDomainGroup (domain: DomainGroupDataType): void {
        const index = this.getIndexOfUserDomainGroup(domain);
        if (index === -1) {
            this.user.domain_group_members.push({
                visible: false,
                send_report: false,
                domain_group_id: domain.id,
            } as DomainGroupMembersEntity);
        }
    }

    hasSendReport (domain: Domain): boolean {
        const domains = this.user.domain_users;
        for (let i = 0; i < domains.length; i++) {
            const val = domains[i].domain_id;
            if (val && val === domain.id) {
                return domains[i].visible && domains[i].send_report;
            }
        }
    }

    hasSendReportGroup (domainGroup: DomainGroupDataType): boolean {
        const domains = this.user.domain_group_members;
        for (let i = 0; i < domains.length; i++) {
            const val = domains[i].domain_group_id;
            if (val && val === domainGroup.id) {
                return domains[i].visible && domains[i].send_report;
            }
        }
    }

    isDomainVisible (domain: Domain): boolean {
        if (Boolean(this.user.customer_admin) === false) {
            const domains = this.user.domain_users;
            for (let i = 0; i < domains.length; i++) {
                if (domains[i].domain_id === domain.id) {
                    return domains[i].visible;
                }
            }
            return false;
        }
        return true;
    }

    isDomainGroupVisible (domainGroup: DomainGroupDataType): boolean {
        if (Boolean(this.user.customer_admin) === false) {
            const domains = this.user.domain_group_members;
            for (let i = 0; i < domains.length; i++) {
                if (domains[i].domain_group_id === domainGroup.id) {
                    return domains[i].visible;
                }
            }
            return false;
        }
        return true;
    }

    setDomainVisible (domain: Domain): void {
        const index = this.getIndexOfUserDomain(domain);
        if (index !== -1) {
            this.user.domain_users[index].visible = !this.isDomainVisible(domain);
        }
    }

    setDomainGroupVisible (domainGroup: DomainGroupDataType): void {
        const index = this.getIndexOfUserDomainGroup(domainGroup);
        if (index !== -1) {
            this.user.domain_group_members[index].visible = !this.isDomainGroupVisible(domainGroup);
        }
    }

    setDomainSendReport (domain: Domain): void {
        const index = this.getIndexOfUserDomain(domain);
        if (index !== -1) {
            this.user.domain_users[index].send_report = !this.hasSendReport(domain);
        }
    }

    setDomainGroupSendReport (domainGroup: DomainGroupDataType): void {
        const index = this.getIndexOfUserDomainGroup(domainGroup);
        if (index !== -1) {
            this.user.domain_group_members[index].send_report = !this.hasSendReportGroup(domainGroup);
        }
    }

    getIndexOfUserDomain (domain: Domain | DomainUsersEntity): number {
        for (let i = 0; i < this.user.domain_users.length; i++) {
            if (this.user.domain_users[i].domain_id === domain.id) {
                return i;
            }
        }
        return -1;
    }

    getIndexOfUserDomainGroup (domain: DomainGroupDataType | DomainGroupMembersEntity): number {
        for (let i = 0; i < this.user.domain_group_members.length; i++) {
            if (this.user.domain_group_members[i].domain_group_id === domain.id) {
                return i;
            }
        }
        return -1;
    }
}
