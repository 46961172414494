import { Ng2StateDeclaration } from '@uirouter/angular';
import { ResellersComponent } from './resellers.component';
import { BackendAdminResellerShowComponent } from './show/backend-admin-reseller-show/backend-admin-reseller-show.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const BACKEND_ADMIN_RESELLERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.resellers',
        url: '/partners',
        abstract: true,
    },
    {
        name: 'base.backend_admin.resellers.index',
        url: '?search=&page_size=10&page=1',
        params: RouteParamsConstant,
        views: {
            '^.^.^.$default': {
                component: ResellersComponent,
            },
        },
    },
    {
        name: 'base.backend_admin.resellers.show',
        url: '/:reseller_id',
        views: {
            '^.^.^.$default': {
                component: BackendAdminResellerShowComponent,
            },
        },
    },
];
