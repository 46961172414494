import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageAssistSettingsCustomNavigation, PageAssistSettingsNavigation } from 'types/domain';
import { PageAssistService } from '../page-assist.service';
import { DialogService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormPageAssistCustomNavigationComponent } from '../../../page-assist-custom-navigation/page-assist-custom-navigation.component';

export interface FormPageAssistCustomNavigationCreateOutput {
    customNavigation: PageAssistSettingsCustomNavigation;
}

export interface FormPageAssistCustomNavigationEditOutput {
    index: number;
    customNavigation: PageAssistSettingsCustomNavigation;
}

export interface FormPageAssistCustomNavigationDeleteOutput {
    index: number;
}

@Component({
    selector: 'mon-form-page-assist-custom-navigation-collection',
    templateUrl: 'custom-navigation.html',
})
export class FormPageAssistCustomNavigationCollectionComponent implements OnInit {
    @Input() monNavigationIndex: number;

    @Output() monOnEdit: EventEmitter<FormPageAssistCustomNavigationEditOutput> = new EventEmitter<FormPageAssistCustomNavigationEditOutput>();
    @Output() monOnCreate: EventEmitter<FormPageAssistCustomNavigationCreateOutput> = new EventEmitter<FormPageAssistCustomNavigationCreateOutput>();
    @Output() monOnDelete: EventEmitter<FormPageAssistCustomNavigationDeleteOutput> = new EventEmitter<FormPageAssistCustomNavigationDeleteOutput>();

    customNavigations: PageAssistSettingsNavigation[];

    constructor (private pageAssistService: PageAssistService, private dialogService: DialogService) {}

    ngOnInit (): void {
        this.setupWatchers();
    }

    setupWatchers (): void {
        this.pageAssistService.customNavigationObs.subscribe((navigationIndex) => {
            if (this.monNavigationIndex === navigationIndex || navigationIndex === null) {
                this.customNavigations = this.pageAssistService.getCustomNavigations(this.monNavigationIndex);
            }
        });
    }

    create (customNavigation?: PageAssistSettingsCustomNavigation): void {
        let index = -1;
        if (customNavigation) {
            index = this.pageAssistService.getCustomNavigationIndex(this.monNavigationIndex, customNavigation);
        }
        if (index < 0) {
            index = null;
        }
        this.openDialog(index, null, (dialogCustomNavigation) => {
            if (dialogCustomNavigation) {
                this.monOnCreate.emit({ customNavigation: dialogCustomNavigation });
            }
        });
    }

    edit (customNavigation: PageAssistSettingsCustomNavigation, index: number): void {
        this.openDialog(null, customNavigation, (navigation) => {
            if (navigation) {
                this.monOnEdit.emit({
                    customNavigation: navigation,
                    index: index,
                });
            }
        });
    }

    delete (index: number): void {
        this.monOnDelete.emit({ index });
    }

    getNestClass (customNavigationItem: PageAssistSettingsCustomNavigation): string {
        return 'nest-' + this.pageAssistService.getCustomNavigationNestIndex(this.monNavigationIndex, customNavigationItem);
    }

    private openDialog (
        index: number | null,
        customNavigation: PageAssistSettingsCustomNavigation | null,
        cb: (customNavigation: PageAssistSettingsCustomNavigation) => unknown,
    ): void {
        const dialogRef = this.dialogService.open(FormPageAssistCustomNavigationComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-md',
            cb,
        });
        dialogRef.componentInstance.monCustomNavigation = customNavigation;
        dialogRef.componentInstance.monParentId = index;
    }
}
