import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TableModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Customer } from '@monsido/modules/models/api/customer';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { FormPdfClarityComponent } from '@monsido/forms/pdf-clarity/pdf-clarity.component';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-backend-admin-customers-table',
    standalone: true,
    imports: [CommonModule, UIRouterModule, TranslateModule, TableModule, NgbModalModule],
    templateUrl: './table.component.html',
})
export class BackendAdminCustomersTableComponent {
    @Input() customers: Customer[];

    @Output() monGetPageEvent: EventEmitter<void> = new EventEmitter();

    constructor (
        private modalService: NgbModal,
    ) {}

    addClarityTokens (customer: Customer): void {
        const modalRef = this.modalService.open(FormPdfClarityComponent);
        modalRef.componentInstance.customer = customer;

        modalRef.result.then(() => {
            this.getPage();
        }, noop);
    }

    getPage (): void {
        this.monGetPageEvent.emit();
    }

    stopPropagation (event: Event): void {
        event.stopPropagation();
    }
}
