<mon-page-header [monIcon]="['PLANS', 'ICON']"  header="{{'Plans' | translate}}">
        <div class="text-right d-flex justify-content-end">
            <div class="col-sm-16 col-md-14 p-0">
                <button class="btn btn-secondary mt-2 mb-4" (click)="createPlan($event)" data-test="new-plan-button">{{'New plan' | translate}}</button>
                <mon-table-search
                        [minSearchLength]="1"
                        [model]="search"
                        (getPage)="onSearch($event)"
                        data-test="search-input">
                </mon-table-search>
            </div>
        </div>
</mon-page-header>

<div class="card monsido-panel">
    <div class="card-body">
        <mon-table-container [collection]="plans"
                             (pageChangeEvent)="onPageChange($event)"
                             (perPageChange)="onPageSizeChange($event)"
                             class="table-vertial-align"
                             [loading]="loading">
            <div class="table-container-body">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="col-sm-12 col-md-12 col-lg-10" translate>Name</th>
                        <th class="col-sm-12 col-md-12 col-lg-10" translate>Group</th>
                        <th translate class="text-center col-sm-5 col-md-4 col-lg-4">Status</th>
                        <th class="text-right col-sm-5 col-md-5 col-lg-4"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let plan of plans">
                            <td>{{ plan.name }}</td>
                            <td>{{ groupName(plan.group) }}</td>
                            <td class="text-center">
                                <mon-icon [icon]="['PLANS', 'ACTIVE']" *ngIf="plan.active"></mon-icon>
                            </td>
                            <td class="text-right">
                                <mon-table-dropdown data-test="action-button">
                                    <li ngbDropdownItem role="menuitem">
                                        <a href (click)="editPlan($event, plan)" data-test="edit-button" >
                                            <mon-icon class="mr-1" [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                                            <span translate>Edit Plan</span>
                                        </a>
                                    </li>
                                    <li ngbDropdownItem role="menuitem">
                                       <a href monPrompt="{{ 'To confirm deletion, please input the id of the plan' | translate}} ( id: {{plan.id}})"
                                        (monPromptAction)="onDeletePlan(plan, $event)" data-test="delete-button" >
                                            <mon-icon class="mr-1" [icon]="['ACTIONS', 'DELETE']"></mon-icon>
                                            <span translate>Delete plan</span>
                                        </a>
                                    </li>
                                </mon-table-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </div>
</div>
