import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { HebReportComponent } from './heb-report.component';

export const hebReport: Ng2StateDeclaration = {
    name: 'base.backend_admin.heb_report',
    url: '/heb_report',
    abstract: true,
    component: BaseStateComponent,
};

export const hebReportIndex = {
    name: 'base.backend_admin.heb_report.index',
    url: '',
    views: {
        '^.^.^.$default': {
            component: HebReportComponent,
        },
    },
};

export const HEBREPORT_STATES = [hebReport, hebReportIndex];
