import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlainHttpParams } from '@monsido/http/params';
import { createDefaultHttpParams, ApiClient } from '@monsido/modules/endpoints/api/api-client';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityChecksRepo {

    constructor (private apiClient: ApiClient) { }

    getAll (data: PlainHttpParams, headers?: HttpHeaders): Promise<unknown> {
        const params = createDefaultHttpParams(data, headers);
        return this.apiClient.getPromise('accessibility_checks', params);
    }
}
