import { NgModule } from '@angular/core';
import { AdminModule } from '@monsido/pages/admin/admin.module';
import { BackendAdminModule } from '@monsido/pages/backend-admin/backend-admin.module';
import { BackendAdminCrawlQueueModule } from './backend-admin/crawl-queue/crawl-queue.module';
import { ResellerAdminModule } from './reseller_admin/reseller-admin.module';
import { ResellerModule } from '@monsido/pages/reseller/reseller.module';
import { BackendAdminAccessibilityChecksStateModule } from 'app/pages/backend_admin/backend-admin-accessibility-checks/backend-admin-accessibility-checks.state';

@NgModule({
    imports: [
        ResellerModule,
        AdminModule,
        BackendAdminModule,
        ResellerAdminModule,
        BackendAdminCrawlQueueModule,
        BackendAdminAccessibilityChecksStateModule,
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class PagesModule {}
