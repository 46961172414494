import { Injectable } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { Domain } from '@monsido/modules/models/api/domain';
import { ApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/api-client';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { Customer } from '@monsido/modules/models/api/customer';
import { UnsavedUser } from '@monsido/modules/models/api/interfaces/user.interface';

export type GetAllCustomersParams = {
    page: number,
    page_size: number,
    search: string,
    plan_type?: string
}

@Injectable({
    providedIn: 'root',
})
export class CustomerRepo {

    constructor (private apiClient: ApiClient) { }

    public get (id: number): Promise<Customer> {
        const params = {};
        return this.apiClient.getPromise('customers/' + id, params);
    }

    public getAll (data: GetAllCustomersParams, headers: Record<string, string> = {}): Promise<Customer[]> {
        const params = createDefaultHttpParams(data, headers);
        return this.apiClient.getPromise('customers', params);
    }

    public create (customer: Customer): Promise<Customer> {
        const params = {};
        return this.apiClient.postPromise('customers', customer, params);
    }

    public update (customer: Customer): Promise<Customer> {
        const params = {};
        return this.apiClient.patchPromise('customers/' + customer.id, customer, params);
    }

    public destroy (id: number, plainParams?: PlainHttpParams, headers?: HttpHeaders): Promise<void> {
        const params = createDefaultHttpParams(plainParams, headers);
        return this.apiClient.deletePromise('customers/' + id, params);
    }

    /** Domains**/

    public createDomain (customerId: number, domain: Domain): Promise<Domain> {
        const params = {};
        return this.apiClient.postPromise('customers/' + customerId + '/domains', domain, params).then(res => this.toDomainModel(res));
    }

    public updateDomain (customerId: number, domain: Domain): Promise<Domain> {
        const params = {};
        return this.apiClient.patchPromise('/customers/' + customerId + '/domains/' + domain.id, domain, params).then(res => this.toDomainModel(res));
    }

    public getAllDomains (customerId: number, data?: PlainHttpParams, headers?: HttpHeaders): Promise<Domain[]> {
        const params = createDefaultHttpParams(data, headers);
        return this.apiClient.getPromise('customers/' + customerId + '/domains', params).then(res => this.toDomainModels(res));
    }

    public getDomain (customerId: number, domainId: number): Promise<Domain> {
        const params = {};
        return this.apiClient.getPromise('customers/' + customerId + '/domains/' + domainId, params).then(res => this.toDomainModel(res));
    }

    public destroyDomain (customerId: number, domainId: number): Promise<void> {
        const params = {};
        return this.apiClient.deletePromise('customers/' + customerId + '/domains/' + domainId, params);
    }

    public getDomainCrawls (options: {customerId: number, domainId: number, data: PlainHttpParams}): Promise<unknown> {
        const { customerId, domainId, data } = options;
        const params = createDefaultHttpParams(data);
        return this.apiClient.getPromise('customers/' + customerId + '/domains/' + domainId + '/crawls', params);
    }

    public createDomainCrawls (customerId: number, domainId: number): Promise<unknown> {
        const params = {};
        return this.apiClient.postPromise('customers/' + customerId + '/domains/' + domainId + '/crawls', {}, params);
    }

    /** users **/
    public getAllUsers (customerId: number, data: PlainHttpParams): Promise<User[]> {
        const params = createDefaultHttpParams(data);
        return this.apiClient.getPromise('customers/' + customerId + '/users', params).then(res => this.toUserModels(res));
    }

    public createUser (customerId: number, user: UnsavedUser, headers: HttpHeaders): Promise<User> {
        const params = createDefaultHttpParams(null, headers);
        return this.apiClient.postPromise('customers/' + customerId + '/users', user, params).then(res => this.toUserModel(res));
    }

    public updateUser (customerId: number, user: User): Promise<User> {
        const params = {};
        return this.apiClient.patchPromise('customers/' + customerId + '/users/' + user.id, user, params).then(res => this.toUserModel(res));
    }

    public destroyUser (customerId: number, userId: number, headers: HttpHeaders = null): Promise<void> {
        const params = createDefaultHttpParams(null, headers);
        return this.apiClient.deletePromise('customers/' + customerId + '/users/' + userId, params);
    }

    public sudoUser (customerId: number, userId: number): Promise<void> {
        const params = {};
        return this.apiClient.postPromise('customers/' + customerId + '/users/' + userId + '/sudo', params).then((res) => {
            window.open(res.uri, '_blank');
        });
    }

    protected toDomainModels (models: Domain[]): Domain[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toDomainModel(models[i]);
        }
        return models;
    }

    protected toDomainModel (model: Domain): Domain {
        return new Domain(model);
    }

    protected toUserModels (models: User[]): User[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toUserModel(models[i]);
        }
        return models;
    }

    protected toUserModel (model: User): User {
        // eslint-disable-next-line new-cap
        return new User(model);
    }
}
