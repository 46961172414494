import { ModuleWithProviders, NgModule } from '@angular/core';
import { Configuration } from 'rollbar';
import { RollbarService, ROLLBAR_CONFIGURATION } from './rollbar.service';

@NgModule({
    providers: [RollbarService],
})
export class RollbarModule {
    static forRoot (config: Configuration): ModuleWithProviders<RollbarModule> {
        return {
            ngModule: RollbarModule,
            providers: [{ provide: ROLLBAR_CONFIGURATION, useValue: config }],
        };
    }
}
