<div class="table-container-body">
    <table class="table table-fixed table-hover">
        <thead>
        <tr>
            <th translate>Key</th>
            <th translate class="text-right">Value</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Title</td>
            <td class="text-right">
                {{ scan.title }}
            </td>
        </tr>
        <tr>
            <td>URL</td>
            <td class="text-right request-word-wrapper">
                {{ url }}
            </td>
        </tr>
        <tr>
            <td>Response url</td>
            <td class="text-right request-word-wrapper">
                {{ scan.response_url }}
            </td>
        </tr>
        <tr>
            <td>Response code</td>
            <td class="text-right">
                {{ scan.response_code }}
            </td>
        </tr>
        <tr>
            <td>Response message</td>
            <td class="text-right">
                {{ scan.response_message }}
            </td>
        </tr>
        <tr>
            <td>Response time</td>
            <td class="text-right">
                {{ scan.response_time }}
            </td>
        </tr>
        </tbody>
    </table>
</div>
