import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UIRouter, UrlService } from '@uirouter/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { JobService } from '@monsido/modules/job/job.service';
import { OauthService } from '@monsido/oauth/oauth.service';
import { BasicService } from 'app/core/run/basic.service';
import { SetupRun } from './app/core/run/setup.run';


if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((platformRef) => {
    // Intialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
        const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
        urlService.deferIntercept();
        const oauthService = platformRef.injector.get(OauthService);

        // Instruct UIRouter to listen to URL changes
        function startUIRouter (): void {
            urlService.listen();
            urlService.sync();
        }

        oauthService
            .processStateParam(urlService.search().state);

        platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);

        const monEventService: MonEventService = platformRef.injector.get(MonEventService);
        const jobService: JobService = platformRef.injector.get(JobService);
        const basicService: BasicService = platformRef.injector.get(BasicService);
        basicService.run();

        const setupRun: SetupRun = platformRef.injector.get(SetupRun);
        setupRun.run();

        monEventService.addListener('afterBaseLayout', function () {
            jobService.init();
        });
    })
    .catch(err => console.error(err));
