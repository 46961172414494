<fieldset name="basic" [ngModelGroup]="modelGroup">
    <mon-form-field-input
        required
        [(ngModel)]="user.first_name"
        #first_name="ngModel"
        name="first_name"
        monLabel="{{ 'First name' | translate }}"
        monPlaceholder="{{ 'First name' | translate }}"
        type="text">
    </mon-form-field-input>
    <mon-form-field-errors-ac [formElementControl]="first_name.control">
    </mon-form-field-errors-ac>

    <mon-form-field-input [(ngModel)]="user.last_name"
                          monLabel="{{ 'Last name' | translate }}"
                          name="last_name"
                          monPlaceholder="{{ 'Last name' | translate }}"
                          type="text">
    </mon-form-field-input>

    <mon-form-field-select-timezones monReturnValue="name"
                                     [(model)]="user.timezone"
                                     name="timezone"
                                     monLabel="{{ 'Timezones' | translate }}"
                                     monHelpTxt="{{'Choose a timezone' | translate}}">
    </mon-form-field-select-timezones>

    <mon-form-field-input
        required
        name="email"
        [(ngModel)]="user.email"
        #email="ngModel"
        monLabel="{{ 'Email' | translate }}"
        monPlaceholder="{{ 'Email' | translate }}"
        [pattern]="emailPattern"
        type="email">
    </mon-form-field-input>

    <mon-form-field-errors-ac
        [patternMsg]="'Not valid email' | translate"
        [formElementControl]="email.control">
    </mon-form-field-errors-ac>

    <mon-form-field-checkbox name="customer_admin" [(model)]="user.customer_admin"
                             monName="customer_admin"
                             monLabel="{{'Customer admin' | translate}}">
    </mon-form-field-checkbox>
    <mon-form-field-select monName="locale"
                           [(model)]="user.locale"
                           monLabel="{{ 'language' | translate }}"
                           monPlaceholder="{{ 'language' | translate }}"
                           [monOptions]="supportedLanguages"
                           monReturnValue="code"
                           name="locale"
                           *ngIf="isAdmin"
    >
    </mon-form-field-select>

    <mon-form-field-input [(ngModel)]="user.password"
                          monLabel="{{ 'Password' | translate }}"
                          monPlaceholder="{{ 'Password' | translate }}"
                          monHelpText="{{'If not filled, a password will be auto generated' | translate}}"
                          type="password"
                          name="password"
                          *ngIf="user.id">
    </mon-form-field-input>

    <ng-container *ngIf="user.id">
        <mon-form-field-input [(ngModel)]="user.password_confirmation"
                              #password_confirmation="ngModel"
                              pattern="{{ user.password | monEscapeRegExp }}"
                              monLabel="{{ 'Password confirmation' | translate }}"
                              monPlaceholder="{{ 'Password confirmation' | translate }}"
                              name="password_confirmation"
                              type="password">
        </mon-form-field-input>
        <mon-form-field-errors-ac
            [patternMsg]="'Password confirmation is not the same as password' | translate"
            [formElementControl]="password_confirmation.control">
        </mon-form-field-errors-ac>
    </ng-container>

    <div class="row" *ngIf="!user.id">
        <div class="col-sm-11 pr-0">
            <mon-switch
                [(ngModel)]="user.send_welcome_email"
                class="inline-block mt-2 mr-3 pull-right"
                monLabel="send_welcome_email"
                name="send_welcome_email"
            ></mon-switch>
        </div>
        <div class="col-sm-37 pl-2 mt-3">
            <label for="send_welcome_email" class="font-weight-400 no-padding" translate>
                Send password mail on creation
            </label>
        </div>
    </div>
</fieldset>
