<mon-page-header [monIcon]="['DATA_PRIVACY_CHECKS', 'ICON']"
                 header="{{'Data Privacy Checks' | translate}}"
                 subHeader="{{checks.length}} {{checkStatus}} {{'checks. Overview of approved and pending data privacy checks' | translate}}">
    <div class="d-flex justify-content-end">
        <mon-table-search
                [minSearchLength]="3"
                placeholderTxt="{{'Search...' | translate}}"
                [model]="search"
                (getPage)="onSearchChecks($event)"
                data-test="search-input">
        </mon-table-search>
    </div>
</mon-page-header>

<mon-tabs slot="mon-panel-tabs"
          [options]="tabs"
          [status]="activeTab"
          (onStatus)="tabChange($event)">
</mon-tabs>
<mon-panel [showHeader]="false">
    <div slot="mon-panel-body">
        <div class="card-body">
            <mon-spinner [loading]="loading">
                <div class="table-container-body">
                    <table class="table table-hover table-fixed">
                        <thead>
                            <tr>
                                <th translate>Name</th>
                                <th class="col-md-6" translate>Group</th>
                                <th class="col-md-6" translate>Regions</th>
                                <th class="col-md-6" translate>Regulations</th>
                                <th class="col-md-6" translate>Priority</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let check of checks">
                                <td class="text-grey">
                                    <span [innerHtml]="check.name"></span><br/>
                                </td>
                                <td class="text-grey">
                                    {{getGroupName(check.group)}}
                                </td>
                                <td class="text-grey">
                                    {{getRegionNames(check.regions)}}
                                </td>
                                <td class="text-grey">
                                    <span [innerHtml]="check.regulations"></span><br/>
                                </td>
                                <td class="text-grey">
                                    {{getPriorityLevel(check)}}
                                </td>
                                <td class="text-right">
                                    <mon-table-dropdown data-test="action-button">
                                        <li ngbDropdownItem role="menuitem">
                                            <a (click)="editCheck($event, check)" role="button" href>
                                                <mon-icon class="mr-1" [icon]="['ACTIONS', 'EDIT']" data-test="edit-button"></mon-icon>
                                                <span translate>Edit check</span>
                                            </a>
                                        </li>
                                        <li ngbDropdownItem role="menuitem" *ngIf="!check.approved">
                                            <a (click)="updateApprovalstatus(check)" href='#' data-test="approve-button">
                                                <mon-icon class="mr-1" [icon]="['ACTIONS', 'APPROVE']"></mon-icon>
                                                <span translate>Approve check</span>
                                            </a>
                                        </li>
                                    </mon-table-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mon-spinner>
        </div>
    </div>
</mon-panel>
