/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Params } from './params';
import { Injectable } from '@angular/core';
import { Config } from '@monsido/http/config';

@Injectable()
export class MonHttpClientService extends HttpClient {
    private urlPrefix: string;
    private shouldCancelAllRequests$ = new Subject<void>();
    config: Config;

    get onCancelRequests (): Observable<void> {
        return this.shouldCancelAllRequests$.asObservable();
    }

    constructor (handler: HttpHandler) {
        super(handler);
        this.setUrlPrefix();
        this.setConfig();
    }

    public cancelRequests (): void {
        this.shouldCancelAllRequests$.next();
    }

    public setConfig (config?: Config): void {
        config = config || {
            headers: new HttpHeaders(),
        };
        this.config = config;
    }

    public setUrlPrefix (url: string = ''): void {
        this.urlPrefix = url;
    }

    public putObservable (url: string, body: any, options: Params = {}): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return super.put(this.urlPrefix + url, body, {
            ...this.config,
            ...options,
        } as any);
    }

    public putPromise (url: string, body: any, options: Params = {}): Promise<any> {
        return this.putObservable(url, body, options)
            .toPromise()
            .catch((err) => {
                return Promise.reject(err || 'Server error');
            });
    }

    public patchObservable (url: string, body: any, options: Params = {}): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return super.patch(this.urlPrefix + url, body, {
            ...this.config,
            ...options,
        } as any);
    }

    public patchPromise (url: string, body: any, options: Params = {}): Promise<any> {
        return this.patchObservable(url, body, options)
            .toPromise()
            .catch((err) => {
                return Promise.reject(err.error || 'Server error');
            });
    }

    public getObservable<T> (url: string, options: Params = {}, overrideUrlPrefix?: string): Observable<T> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        let urlPrefix = this.urlPrefix;
        if (overrideUrlPrefix) {
            urlPrefix = overrideUrlPrefix;
        }

        return super.get<T>(urlPrefix + url, {
            ...this.config,
            ...options,
        } as any) as Observable<T>;
    }

    public getPromise (url: string, options: Params = {}, overrideUrlPrefix?: string): Promise<any> {
        return this.getObservable(url, options, overrideUrlPrefix)
            .toPromise()
            .catch((err) => {
                return Promise.reject(err || 'Server error');
            });
    }

    public postObservable (url: string, body: any, options: Params = {}): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }

        return super.post(this.urlPrefix + url, body, {
            ...this.config,
            ...options,
        } as any);
    }

    public postPromise (url: string, body: any, options: Params = {}): Promise<any> {
        return this.postObservable(url, body, options)
            .toPromise()
            .catch((err) => {
                return Promise.reject(err || 'Server error');
            });
    }

    public deleteObservable (url: string, options: Params = {}): Observable<any> {
        if (options && options.headers) {
            options.headers = this.setHeaders(options.headers);
        }
        return super.delete(this.urlPrefix + url, {
            ...this.config,
            ...options,
        } as any);
    }

    public deletePromise (url: string, options: Params = {}): Promise<any> {
        return this.deleteObservable(url, options)
            .toPromise()
            .catch((err) => {
                return Promise.reject(err.error || 'Server error');
            });
    }

    protected setHeader (name: string, value: string): void {
        this.config.headers = this.config.headers.set(name, value);
    }

    protected setHeaders (optHeaders: HttpHeaders | Record<string, string>): HttpHeaders {
        let headers: HttpHeaders = this.config.headers;
        let newHeaders: [string, string][] = [];

        if (optHeaders instanceof HttpHeaders) {
            newHeaders = optHeaders.keys().map(key => [key, optHeaders.get(key)]);
        } else {
            newHeaders = Object.keys(optHeaders).map(key => [key, optHeaders[key]]);
        }

        for (const [key, value] of newHeaders) {
            if (headers.has(key)) {
                headers = headers.delete(key);
            }
            if (value !== null) {
                headers = headers.append(key, value);
            }
        }

        return headers;
    }
}
