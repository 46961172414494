<mon-page-header [monIcon]="['ACCESSIBILITY_CHECKS', 'ICON']"
             header="{{'Accessibility checks' | translate}}"
             subHeader="{{'Overview of all existing accessibility checks' | translate}}">
</mon-page-header>

<div class="align-tabs-right">
    <mon-filter-button [showFiltersBtn]="true" [(isFiltersOpen)]="isFiltersOpen"></mon-filter-button>
</div>

<mon-panel [showHeader]="false">
    <div class="card-body" slot="mon-panel-body">
        <mon-table-container [collection]="checks"
                         [loading]="loading"
                         (pageChangeEvent)="onPageChange($event)"
                         (perPageChange)="onPageSizeChange($event)">
            <div class="table-container-body">
                <div class="table-container-top">
                    <div class="outer-b merge-r-36 merge-l-36 merge-t-10">
                        <mon-table-filters
                            [open]="isFiltersOpen"
                            [filters]="filters"
                            (filtersChange)="updateFilters($event)"
                            (openChange)="filterOpenChanges($event)">
                        </mon-table-filters>
                    </div>
                </div>
                <table class="table table-hover table-fixed" summary="{{'Accessibility checks' | translate}}">
                    <thead>
                    <tr>
                        <th class="col-md-4" translate>Check ID</th>
                        <th translate>Name</th>
                        <th class="col-md-4" translate>Impact</th>
                        <th class="col-md-4" translate>Difficulty</th>
                        <th class="col-md-4" translate>Responsibilities</th>
                        <th class="col-md-6" translate>Tags</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let check of checks" (click)="openDialog(check)" class="cursor-pointer">
                        <td class="text-grey">
                            {{check.check_id}}
                        </td>
                        <td class="text-grey">
                            <span [innerHTML]="check.name"></span><br/>
                            <span>{{check.friendly_name}}</span>
                        </td>
                        <td class="text-grey">
                            {{ check.impactName }}
                        </td>
                        <td class="text-grey">
                            {{check.priorityName}}
                        </td>
                        <td>
                            <span class="text-grey mr-1 inline-block"
                                *ngFor="let responsibility of check.responsibilitiesNames">
                                {{responsibility.name}}
                            </span>
                        </td>
                        <td>
                            <span class="label label-default mr-1 inline-block"
                                *ngFor="let tag of check.tagsNames">
                                {{tag.name}}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </div>
</mon-panel>
