import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { WorkerQueueComponent } from '@monsido/pages/backend-admin/worker-queue/worker-queue.component';

export const workerQueue: Ng2StateDeclaration = {
    name: 'base.backend_admin.workerqueue',
    url: '/workerqueue',
    abstract: true,
    component: BaseStateComponent,
};

export const workerQueueIndex = {
    name: 'base.backend_admin.workerqueue.index',
    url: '',
    views: {
        '^.^.^.$default': {
            component: WorkerQueueComponent,
        },
    },
};

export const WORKERQUEUE_STATES = [workerQueue, workerQueueIndex];
