<table class="table table-fixed table-hover">
    <thead>
    <tr>
        <th class="col-sm-8 col-lg-10" translate>Name</th>
        <th class="col-sm-6 col-lg-7" translate>Partner</th>
        <th class="col-sm-8 col-lg-9" translate>Plan</th>
        <th class="col-sm-4 col-lg-4" translate>Users</th>
        <th class="col-sm-4 col-lg-4" translate>Domains</th>
        <th class="col-sm-6 col-lg-5" translate>PDF Clarity tokens</th>
        <th class="col-sm-6 col-lg-5"></th>
        <th class="col-sm-6 col-lg-4 text-right"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let customer of customers">
        <td class="text-ellipsis">
            <a
                class="cursor-pointer"
                uiSref="base.backend_admin.customers.show"
                [uiParams]="{customer_id: customer.id}"
                data-test="customer-name"
            >
                {{ customer.name }}
            </a>
        </td>
        <td class="text-ellipsis">{{ customer.reseller.name }}</td>
        <td class="text-ellipsis">{{ customer.subscription.plan.name || 'No plan' }}</td>
        <td>{{ customer.users_count | number}}</td>
        <td>{{ customer.domains_count | number}}</td>
        <td>{{ customer.subscription.clarity_tokens | number }}</td>
        <td class="text-ellipsis">
            <span *ngIf="customer.account_suspended">
                {{ 'Suspended' | translate }}
            </span>
        </td>
        <td class="text-right" (click)="stopPropagation($event)">
            <mon-table-dropdown data-test="action-button">
                <li>
                    <a
                        class="cursor-pointer"
                        uiSref="base.backend_admin.customers.show"
                        [uiParams]="{customer_id: customer.id}"
                        data-test="customer-details-button"
                    >
                        {{ 'Customer details' | translate }}
                    </a>
                </li>
                <li *ngIf="customer.plan_traits.clarity_pdf_accessibility">
                    <a
                        class="cursor-pointer"
                        (click)="addClarityTokens(customer)"
                        data-test="add-clarity-tokens-button"
                    >
                        {{ 'Add Clarity Tokens' | translate }}
                    </a>
                </li>
            </mon-table-dropdown>
        </td>
    </tr>
    </tbody>
</table>
