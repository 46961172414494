import { Injectable } from '@angular/core';
import { ApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/api-client';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';

@Injectable()
export class SpellingToolRepo {
    constructor (private client: ApiClient) {}

    getAll (params?: PlainHttpParams, headers?: HttpHeaders): Promise<LanguageInterface[]> {
        return this.client.getPromise('spelling_tool/languages', createDefaultHttpParams(params, headers));
    }
}
