<mon-page-header [monIcon]="['DOMAINS', 'ICON']" header="{{'Domains' | translate}}">

</mon-page-header>
<div class="row mb-3">
    <div class="col-xs-48 col-sm-32 col-md-36"></div>
    <div class="hidden-xs col-sm-16 col-md-12">
        <mon-table-search (getPage)="getPage($event)" [model]="search" [minSearchLength]="1"></mon-table-search>
    </div>
</div>

<div class="card monsido-panel">
    <div class="card-body">
        <div class="row">
            <div class="col-md-48 col-lg-48 col-xs-48">
                <mon-table-container
                        [collection]="domains"
                        (pageChangeEvent)="onPageChange($event)"
                        (perPageChange)="onPageSizeChange($event)"
                        class="table-vertial-align"
                        [loading]="loading">
                    <div class="table-container-body">
                        <mon-domains-table [domains]="domains" (getPage)="getPage()" [showCustomer]="true"></mon-domains-table>
                    </div>
                </mon-table-container>
            </div>
        </div>
    </div>
</div>
