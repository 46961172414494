import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { createDefaultHttpParams } from '@monsido/modules/endpoints/api/support-api-client';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';

@Injectable()
export class BackendJobRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    get (jid: string, params?: PlainHttpParams, headers?: HttpHeaders): Promise<JobInterface> {
        return this.adminClient.getPromise(`jobs/${jid}`, createDefaultHttpParams(params, headers));
    }
}
