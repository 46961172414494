import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ToastService, ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Domain } from '@monsido/modules/models/api/domain';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { merge } from 'lodash';
import { BackendCrawlQueueRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-crawl-queue.repo';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-backend-admin-crawl-details',
    templateUrl: 'crawl-details.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormBackAdminCrawlDetailsComponent {
    @Input() crawl: CrawlQueue;
    domain: Domain;
    loading: boolean = false;
    form: NgForm;
    hideFeatures: string[] = ['nag_for_upgrade'];
    crawldetails: Record<string, unknown>;
    onSave: boolean;
    constructor (
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private backendDomainsRepo: BackendDomainsRepo,
        private backendCrawlQueueRepo: BackendCrawlQueueRepo,
    ) {}

    ngOnInit (): void {
        this.getDomain();
        this.crawldetails = merge({}, this.crawl || {});
        this.crawldetails.support_link = this.crawldetails.support_link || false;
    }

    getDomain (): Promise<void> {
        this.loading = true;
        return this.backendDomainsRepo
            .get(this.crawl.domain.customer_id, this.crawl.domain.id)
            .then(
                (domain: Domain) => {
                    this.domain = domain;
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    stopCrawl (): void {
        this.loading = true;
        this.backendCrawlQueueRepo
            .stopCrawl(this.crawl.domain.id)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawl stopped'));
                    this.close();
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    rejectCrawl (reason: string): void {
        this.loading = true;
        const params = {
            reason: reason,
        };
        this.backendCrawlQueueRepo
            .rejectCrawl(this.crawl.domain.id, params)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawl rejected'));
                    this.close();
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    releaseCrawl (reason: string): void {
        this.loading = true;
        const params = {
            reason: reason,
        };
        this.backendCrawlQueueRepo
            .releaseCrawl(this.crawl.domain.id, params)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawl released'));
                    this.close();
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    rejectRescanCrawl (reason: string): void {
        this.loading = true;
        const params = {
            reason: reason,
        };
        this.backendCrawlQueueRepo
            .rejectCrawl(this.crawl.domain.id, params)
            .then(
                () => {
                    return this.backendCrawlQueueRepo.startCrawl(this.crawl.customer.id, this.crawl.domain.id).then(
                        () => {
                            this.toastService.success(this.translateService.getString('Rescan started'));
                            this.close();
                        },
                        () => {},
                    );
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    close (): void {
        this.activeDialog.close(this.crawl);
    }

    markCrawlForSupport (): void {
        this.loading = true;
        const params = {
            state: 'support',
        };
        this.backendCrawlQueueRepo
            .support(this.crawl.domain.id, params)
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Status changed to support'));
                    this.close();
                },
                () => {},
            )
            .finally(() => {
                this.loading = false;
            });
    }

    saveSupportLink (): void {
        this.onSave = true;
        const params = {
            support_link: this.crawl.support_link,
        };
        this.backendCrawlQueueRepo
            .support(this.crawl.domain.id, params)
            .then(
                () => {
                    this.crawldetails = merge({}, this.crawl || {});
                },
                () => {},
            )
            .finally(() => {
                this.onSave = false;
                this.loading = false;
            });
    }

    saveComment (): void {
        this.onSave = true;
        const params = {
            id: this.domain.id,
            customer_id: this.domain.customer_id,
            notes: this.domain.notes,
        };

        this.backendDomainsRepo
            .update(this.domain.customer_id, this.domain.id, params)
            .then(
                (domain: Domain) => {
                    this.domain = domain;
                },
                () => {},
            )
            .finally(() => {
                this.onSave = false;
            });
    }
}
