import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { AdminRouteService } from './admin-route.service';

export const ADMIN_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.admin',
        url: '/{env}/{accountId}',
        abstract: true,
        data: { anonymous_access: false, sidebar: 'admin' },
        resolve: [
            {
                token: 'validateAdmin',
                deps: [Transition, AdminRouteService],
                resolveFn: async (transition: Transition, adminRouteService: AdminRouteService): Promise<void> => {
                    await adminRouteService.init(transition.params().accountId);
                },
            },
        ],
    },
];
