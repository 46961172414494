<form name="importConfirmedSpellingErrorsForm" #importSpelling="ngForm" class="form-horizontal" novalidate>
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['USERS', 'CONFIRMED']" header="{{'Import Confirmed Spelling Errors' | translate }}"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <p class="h3 font-weight-300" translate>Link For Your Confirmed Spelling Errors</p>
            </div>
            <div class="card-body">
                <mon-form-field-input monHttpPrefix
                                      [(ngModel)]="csvUrl"
                                      monLabel="{{ 'Link For Your Confirmed Spelling Errors' | translate }}"
                                      monPlaceholder="{{ 'Link For Your Confirmed Spelling Errors' | translate }}"
                                      required
                                      #confirmed_spelling_errors="ngModel"
                                      [name]="'link_for_your_confirmed_spelling_errors'">
                </mon-form-field-input>
                <mon-form-field-errors
                    [showError]="confirmed_spelling_errors.control.touched && confirmed_spelling_errors.control.invalid"
                    [errors]="confirmed_spelling_errors.control.errors">
                </mon-form-field-errors>

                <mon-form-field-select [monName]="'language'"
                                      [(model)]="selectedLanguage"
                                      monLabel="{{ 'language' | translate }}"
                                      monPlaceholder="{{ 'language' | translate }}"
                                      [monOptions]="supportedLanguages"
                                      [monReturnValue]="'code'"
                                      [monAllowClear]="true">
                </mon-form-field-select>
            </div>
        </div>

        <div class="mon-dialog-footer text-right">
            <button type="button"
                    class="btn btn-secondary"
                    [disabled]='!csvUrl || importSpelling.invalid'
                    (click)="submitConfirmedSpellingErrors()">
                    <span translate>Submit</span>
            </button>
        </div>
    </div>
</form>
