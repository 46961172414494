import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LinkExcludedInterface, PathConstraintInterface } from '../constraints-and-excludes.interface';
import { NgForm } from '@angular/forms';
import { PathConstraintsModel } from '../constraints-and-excludes-models/path-constraint.model';
import { LinkExcludeModel } from '../constraints-and-excludes-models/link-exclude.model';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CMS } from '@monsido/core/constants/cms.constant';

@Component({
    selector: 'mon-constraints-and-excludes-editor',
    templateUrl: './constraints-and-excludes-editor.component.html',
    styleUrls: ['./constraints-and-excludes-editor.component.less'],
})
export class ConstraintsAndExcludesEditorComponent implements OnInit {

    @Input() entry: PathConstraintInterface | LinkExcludedInterface;
    @ViewChild('constraintForm', { static: false }) constraintForm: NgForm;
    @ViewChild('linkForm', { static: false }) linkForm: NgForm;

    selectedForm?: NgForm;
    entryAsPathConstraint?: PathConstraintInterface;
    entryAsLinkExclude?: LinkExcludedInterface;
    cmses = CMS;

    constructor (
        private activeDialog: ActiveDialog,
    ) { }

    // Setting values through dialogRef.componentInstance triggers only ngOnInit, not ngOnChanges
    ngOnInit (): void {
        if (this.entry instanceof PathConstraintsModel) {
            this.entryAsPathConstraint = this.entry;
            setTimeout(() => this.selectedForm = this.constraintForm);
        }
        if (this.entry instanceof LinkExcludeModel) {
            this.entryAsLinkExclude = this.entry;
            setTimeout(() => this.selectedForm = this.linkForm);
        }
    }

    submit (form: NgForm): void {
        if (form.valid) {
            this.activeDialog.close(this.entry);
        }
    }

}
