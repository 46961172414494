import { CoreAgreementService } from '@monsido/core/session/services/agreement.service';
import { Component, OnInit } from '@angular/core';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { EnvInterface } from '@monsido/core/session/interface/env.interface';
import { SessionService } from '@monsido/core/session/session.service';
import { EnvironmentService } from '@monsido/services/enviroment/environment.service';
import { StateService } from '@uirouter/core';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';

interface AgreementsList {
    env: EnvInterface;
    agreements: Agreement[];
}

@Component({
    selector: 'agreement-picker-component',
    templateUrl: 'agreement-picker.component.html',
})

export class AgreementPickerComponent implements OnInit {
    apiResellerEnvs: EnvInterface[] = [];
    apiAdminEnvs: EnvInterface[] = [];
    resellerAgreements: AgreementsList[] = [];

    constructor (
        private sessionService: SessionService,
        private environmentService: EnvironmentService,
        private coreAgreementService: CoreAgreementService,
        private state: StateService,
        private loadingService: MonLoadingService) {
    }

    async ngOnInit (): Promise<void> {
        this.loadingService.show();
        await this.coreAgreementService.clearAgreement();
        this.apiAdminEnvs = await this.sessionService.getAvailableAdminEnvs();
        this.apiResellerEnvs = await this.sessionService.getAvailableResellerEnvs();
        if (this.apiAdminEnvs.length === 0) {
            await this.setupAdminEnvs();
        }
        if (this.apiResellerEnvs.length === 0) {
            await this.setupResellerEnvs();
        }
        await this.setResellerAgreements();
        await this.shouldRedirect();
        this.loadingService.hide();
    }

    async shouldRedirect (): Promise<void> {
        if (this.resellerAgreements.length === 1 && this.resellerAgreements[0].agreements.length === 1 && this.apiAdminEnvs.length === 0) {
            await this.selectAgreement(this.resellerAgreements[0].env, this.resellerAgreements[0].agreements[0]);
        }
    }

    async setupResellerEnvs (): Promise<void> {
        await this.environmentService.setupResellerEnvs();
        this.apiResellerEnvs = await this.sessionService.getAvailableResellerEnvs();
    }

    async setupAdminEnvs (): Promise<void> {
        await this.environmentService.setupAdminEnvs();
        this.apiAdminEnvs = await this.sessionService.getAvailableAdminEnvs();
    }

    async selectAgreement (env: EnvInterface, agreement: Agreement): Promise<void> {
        await this.coreAgreementService.setResellerEnv(env, agreement);
        await this.state.go('base.admin.customers.index', { env: env.name, accountId: agreement.account.id });
    }

    async selectAdminEnv (env: EnvInterface): Promise<void> {
        await this.coreAgreementService.setAdminEnv(env);
        await this.state.go('base.backend_admin.customers.all', { env: env.name });
    }

    async setResellerAgreements (): Promise<void> {
        for (const apiResellerEnv of this.apiResellerEnvs) {
            const agreements = await this.coreAgreementService.getResellerAgreementsByEnvURL(apiResellerEnv.url);
            this.resellerAgreements.push({ env: apiResellerEnv, agreements });
        }
    }
}
