import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService } from '@monsido/core/storage/local-storage.service';
import { OauthService } from '@monsido/oauth/oauth.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { EnvInterface } from '@monsido/core/session/interface/env.interface';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Account } from '@monsido/modules/models/api/account';
import { Me } from '@monsido/modules/models/api/me';
import { TranslationHelper } from 'app/blocks/translation/translation-helper';
import { LANGUAGE_CONSTANT } from '../constants/languages.constant';
import { MeRepo } from '@monsido/modules/endpoints/api/admin/me.repo';
import { ErrorHandlerService } from 'app/blocks/helpers/errorHandler/error-handler.service';
import { environment } from 'environments/environment';
import { EventsTypeEnum } from '@monsido/services/mon-event/events.type';
import { EnvironmentService } from '@monsido/services/enviroment/environment.service';
import { GENERAL } from '../constants/general.constant';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';
import { HttpClient } from '@angular/common/http';

type LoginDataType = Record<string, string>;

@Injectable({
    providedIn: 'root',
})
export class SessionService implements OnDestroy {
    resellerEnvs: EnvInterface[] = [];
    adminEnvs: EnvInterface[] = [];
    me: Me;
    adminMeCollection: Me[];
    roles: string[];
    agreement: Agreement = null;
    agreements: BehaviorSubject<Agreement[]> | null = new BehaviorSubject(null);
    account: Account = null;
    private listenerIds: number[] = [];
    private resellerEnvInitialised = false;
    private adminEnvInitialised = false;
    private environmentsInitialised: Promise<void>;
    private environmentInitialisedResolver: () => void;

    private superAdmin = new BehaviorSubject<boolean | undefined>(undefined);
    superAdmin$ = this.superAdmin.asObservable();

    constructor (
        private localStorageService: LocalStorageService,
        private oauthService: OauthService,
        private monEventService: MonEventService,
        private translationHelper: TranslationHelper,
        private meRepo: MeRepo,
        private errorHandlerService: ErrorHandlerService,
        private environmentService: EnvironmentService,
        private globalHelperService: GlobalHelperService,
        private httpClient: HttpClient,
    ) {
        this.listenerIds.push(
            this.monEventService.addListener(EventsTypeEnum.setSessionAvailableAdminEnvs, (data: EnvInterface[]) => this.setAvailableAdminEnvs(data)),
            this.monEventService.addListener(EventsTypeEnum.setSessionAvailableResellerEnvs, (data: EnvInterface[]) => this.setAvailableResellerEnvs(data)),
            this.monEventService.addListener(EventsTypeEnum.signOut, () => {
                this.signOut();
            }),
        );
        this.environmentsInitialised = new Promise(resolve => {
            this.environmentInitialisedResolver = (): void => {
                if (this.resellerEnvInitialised && this.adminEnvInitialised) {
                    resolve();
                }
            };
        });
    }

    ngOnDestroy (): void {
        this.listenerIds.forEach(id => this.monEventService.remove(id));
    }

    login (data: LoginDataType): Promise<Me> {
        const scope = data.scope || '';
        this.localStorageService.set('roles', scope.split(' ').toString());
        this.roles = scope.split(' ');
        return this.loadUser();
    }

    isLoggedIn (): boolean {
        return this.oauthService.isAuthenticated();
    }

    logout (): void {
        this.me = null;
        this.translationHelper.pickLanguage(LANGUAGE_CONSTANT.en.code);
        this.oauthService.clear();
        this.localStorageService.set('roles', '');
    }
    afterLogin (user: Me): void {
        this.me = user;
        this.translationHelper.pickLanguage(user.locale);
        this.me.name = [user.first_name, user.last_name].join(' ');
        this.roles = (this.localStorageService.get('roles') || []) as string[];

        this.me.initials = [
            user.first_name.charAt(0).toUpperCase(),
            user.last_name ? user.last_name.charAt(0).toUpperCase() : '',
        ].join(' ');
    }

    async loadUser (): Promise<Me> {

        await this.monEventService.run(EventsTypeEnum.beforeLoadUser);

        try {
            this.me = await this.meRepo.get();
        } catch (error) {
            this.handleLoginError(error);
            return error;
        }

        this.afterLogin(this.me);
        try {
            await Promise.all([
                this.environmentService.setupAdminEnvs(),
                this.environmentService.setupResellerEnvs(),
            ]);
        } catch (e) {
            this.handleLoginError(e);
        }

        await this.environmentsInitialised;
        return this.me;
    }

    getAuthorizeUrl (): string {
        return this.oauthService.getAuthUrlAndSetStateParam(environment.monsidoPath, environment.oauthClientId, environment.oauthScopes);
    }

    getAuthToken (): string | false {
        return this.oauthService.getToken();
    }

    getUser (): Promise<void | Me> {
        if (this.isUserLoaded()) {
            return Promise.resolve(this.me);
        }

        return this.loadUser().then(
            data => data,
            this.errorHandlerService.noopError,
        );
    }

    resetAgreements (): void {
        this.agreements = new BehaviorSubject<Agreement[]>([]);
    }

    isBackendAdmin (): boolean {
        return this.adminEnvs?.length > 0;
    }

    isResellerAdmin (): boolean {
        return this.agreement?.admin;
    }

    private setSuperAdmin (): void {
        const url = this.getSelectedApi();
        const env = this.adminEnvs?.find(it => it.url === url);
        this.superAdmin.next(env?.me?.super_admin);
    }

    hasRole (role: string): boolean {
        return this.me !== undefined && this.me !== null && this.roles.indexOf(role) !== -1;
    }

    setSelectedApi (api: string): Promise<void[] | void> {
        this.localStorageService.set('selectedApi', api);
        this.setSuperAdmin();

        return this.monEventService.run('setAPI');
    }

    getSelectedApi (): string {
        if (this.localStorageService.get('selectedApi')) {
            return this.localStorageService.get('selectedApi');
        } else {
            return this.oauthService.getApiPath();
        }
    }

    async getAvailableResellerEnvs (): Promise<EnvInterface[]> {
        await this.environmentsInitialised;
        return this.resellerEnvs;
    }

    async getAvailableAdminEnvs (): Promise<EnvInterface[]> {
        await this.environmentsInitialised;
        return this.adminEnvs;
    }

    isUserLoaded (): boolean {
        return !(this.me === undefined || this.me === null);
    }

    async hasAccessToResellerEnv (name: string): Promise<boolean> {
        await this.environmentsInitialised;
        return this.resellerEnvs.find((env: EnvInterface) => env.name === name) !== undefined;
    }

    async hasAccessToAdminEnv (name: string): Promise<boolean> {
        await this.environmentsInitialised;
        return this.adminEnvs.find((env: EnvInterface) => env.name === name) !== undefined;
    }

    async signOut (): Promise<void> {
        const token = this.getAuthToken();

        const bodyParams = {
            token: token,
            client_id: GENERAL.oauthClientId,
            client_secret: GENERAL.oauthClientSecret,
        };

        const path = GENERAL.monsidoPath;

        await firstValueFrom(this.httpClient.post(`${path}oauth/revoke.json`, bodyParams));
        await this.monEventService.run('logout');
        const window = this.globalHelperService.getWindowObject();
        const loc = window.location;
        const baseUrl = loc.protocol + '//' + loc.host + loc.pathname;

        window.onbeforeunload = null;
        if (typeof window.sessionStorage.clear === 'function') {
            window.sessionStorage.clear();
        }

        window.location.href = path + 'logout?token=' + token + '&redirect_uri=' + encodeURIComponent(baseUrl) + 'auth';
    }

    private setAvailableResellerEnvs (envs: EnvInterface[]): void {
        this.resellerEnvs = envs || [];
        this.resellerEnvInitialised = true;
        this.environmentInitialisedResolver();
    }

    private setAvailableAdminEnvs (envs: EnvInterface[]): void {
        this.adminEnvs = envs;
        this.adminEnvInitialised = true;
        this.environmentInitialisedResolver();

        this.setSuperAdmin();
    }

    private handleLoginError (e: Error): void {
        this.errorHandlerService.noopError(e);
        this.logout();
    }
}
