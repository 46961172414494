<form name="profile_form" class="form-horizontal" #profileForm="ngForm" (ngSubmit)="profileForm.valid && submit()">
    <div class="mon-dialog-header">
        <mon-page-header
            [monIcon]="['PROFILE', 'ICON']"
            header="{{ 'My profile' | translate }}"
        />
    </div>
    <div class="mon-dialog-body-with-footer" *ngIf="profile">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>User information</h3>
            </div>
            <div class="card-body">
                <mon-form-field-input
                    name="email"
                    #email="ngModel"
                    [(ngModel)]="profile.email"
                    monLabel="{{ 'Email' | translate }}"
                    monPlaceholder="{{ 'Email' | translate }}"
                    type="email"
                    [pattern]="emailPattern"
                />
                <mon-form-field-errors-ac
                    patternMsg="{{ 'Not valid email' | translate }}"
                    [formElementControl]="email.control"
                />
                <mon-form-field-input
                    name="first_name"
                    [(ngModel)]="profile.first_name"
                    monLabel="{{ 'First name' | translate }}"
                    monPlaceholder="{{ 'First name' | translate }}"
                    type="text"
                />
                <mon-form-field-input
                    name="last_name"
                    [(ngModel)]="profile.last_name"
                    monLabel="{{ 'Last name' | translate }}"
                    monPlaceholder="{{ 'Last name' | translate }}"
                    type="text"
                />
                <mon-form-field-input
                    name="phone"
                    [(ngModel)]="profile.contact_info.phone"
                    monLabel="{{ 'Phone' | translate }}"
                    monPlaceholder="{{ 'Phone' | translate }}"
                    type="text"
                />
                <mon-form-field-input
                    name="industry"
                    [(ngModel)]="profile.contact_info.industry"
                    monLabel="{{ 'Industry' | translate }}"
                    monPlaceholder="{{ 'Industry' | translate }}"
                    type="text"
                />
                <hr/>
                <mon-form-field-input
                    name="current_password"
                    [(ngModel)]="profile.auth_user_password"
                    monLabel="{{ 'Current password' | translate }}"
                    monPlaceholder="{{ 'Current password' | translate }}"
                    type="password"
                />
                <mon-form-field-input
                    name="password"
                    #password="ngModel"
                    [(ngModel)]="profile.password"
                    monLabel="{{ 'New password' | translate }}"
                    monPlaceholder="{{ 'New password' | translate }}"
                    type="password"
                />
                <mon-form-field-input
                    name="password_confirmation"
                    #password_confirmation="ngModel"
                    pattern="{{ profile.password | monEscapeRegExp }}"
                    [(ngModel)]="profile.password_confirmation"
                    monLabel="{{ 'Repeat new password' | translate }}"
                    monPlaceholder="{{ 'Repeat new password' | translate }}"
                    type="password"
                />
                <mon-form-field-errors-ac
                    patternMsg="{{ 'Passwords do not match' | translate }}"
                    [formElementControl]="password_confirmation.control"
                />
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer">
        <div class="col-md-48 text-right">
            <button class="btn btn-secondary" [disabled]="saving || profileForm.invalid" type="submit">
                <span *ngIf="!saving">{{'Save' | translate}}</span>
                <span *ngIf="saving">
                    <mon-icon [icon]="['SPINNER', 'ICON']" [spin]="true" />
                </span>
            </button>
        </div>
    </div>
</form>
