<mon-page-header [monIcon]="['CUSTOMERS', 'ICON']" header="{{'Customers' | translate}}">
    <div>
        <button class="btn btn-secondary pull-right outer-t-small"
                data-test="create-customer-button"
                (click)="createCustomer()">{{ 'New Customer' | translate }}</button>
    </div>
</mon-page-header>
<mon-panel [minSearchLength]="1"
           [search]="search"
           (onSearch)="onSearch($event)"
           [showSearch]="true"
           [showHeader]="false"
           [tabOptions]="tabs"
           [activeTab]="activeTab"
           (onStatus)="onTabChange($event)">
    <div class="card-body" slot="mon-panel-body">
        <mon-table-container [collection]="customers"
                         (pageChangeEvent)="onPageChange($event)"
                         (perPageChange)="onPageSizeChange($event)"
                         class="table-vertial-align"
                         [loading]="loading">
            <div class="table-container-body">
                <mon-table-customers [customers]="customers"></mon-table-customers>
            </div>
        </mon-table-container>
    </div>
</mon-panel>
