import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Params } from '@monsido/http/params';
import { Me } from '@monsido/modules/models/api/me';

@Injectable()
export class MeRepo {
    constructor (private client: ApiClient) {}

    get (options?: Params, urlPrefix?: string): Promise<Me> {
        return this.client.getPromise('me', options, urlPrefix).then(this.toMeModel);
    }

    update (data: Me, options?: Params): Promise<Me> {
        return this.client.patchPromise('me', data, options).then(this.toMeModel);
    }

    private toMeModel (model: Me): Me {
        return new Me(model);
    }
}
