<div class="col-lg-48 outer-l-none inner-l-none">
    <mon-panel-header pHeader="{{'Subscription for this customer' | translate}}">
    </mon-panel-header>

    <div class="card-body">
        <div class="col-48 font-size-1-1">
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" data-test="account-status">
                    {{'Account status' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    <span *ngIf="customer.account_suspended" class="text-danger">{{ 'Suspended' | translate }}</span>
                    <span *ngIf="!customer.account_suspended">{{ 'Active' | translate }}</span>
                </div>
            </div>
            <div class="row" *ngIf="customer.plan_type === 'demo'">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'Estimated deletion date' | translate}}:
                    <span class="ml-1 fas fa-question-circle"
                          aria-hidden="true"
                          placement="right"
                          tooltip-class="deletion-date-tooltip"
                          ngbTooltip="{{ 'Demo accounts are auto-deleted after 90 days without login activity. Log in to the account to extend the lifetime by an additional 90 days. Both regular and Sudo logins are accepted. We auto-delete accounts through a scheduled, weekly job in the database. For the same reason, the estimated deletion date is not completely exact but rather an approximation.' | translate }}">
                    </span>
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    <span>{{ customer?.expected_deletion_date | date:'longDate' }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'Subscription' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey" data-test="subscriptio-plan-name">
                    {{ customer.subscription.plan.name || ('Empty' | translate) }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" data-test="subscription-start-date">
                    {{'Subscription start date' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    <mon-user-date
                        [twoLines]="false"
                        [date]="customer.subscription.subscription_start_at">
                    </mon-user-date>
                </div>
            </div>
    
            <ng-container *ngIf="customer?.eula?.category">
                <div class="row">
                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                        EULA:
                    </div>
                    <div class="col-sm-26 col-lg-36 text-grey">
                        {{ eulaName }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                        EULA Accepted:
                    </div>
                    <div class="col-sm-26 col-lg-36 text-grey" data-test="eula">
                        <div [ngSwitch]="customer.eula.accepted">
                            <mon-icon *ngSwitchCase="true" [icon]="['CUSTOMERS', 'PLAN_TRAITS', 'ACTIVE']" data-test="eula-true"></mon-icon>
                            <mon-icon *ngSwitchCase="false" [icon]="['CUSTOMERS', 'PLAN_TRAITS', 'INACTIVE']" data-test="eula-false"></mon-icon>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="customer.eula.accepted">
                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                        EULA Accepted by:
                    </div>
                    <div class="col-sm-26 col-lg-36 text-grey" data-test="eula-user-email">
                        {{ customer.eula.user_email }}
                    </div>
                </div>
            </ng-container>
            
            
            <div class="row" *ngIf="customer.plan_traits.clarity_pdf_accessibility">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                    Clarity Tokens:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.subscription.clarity_tokens | number }}
                </div>
            </div>

            <div class="row" *ngIf="customer.subscription.assigned_csm">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                    Assigned CSM:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.subscription.assigned_csm }}
                </div>
            </div>

            <div class="row" *ngIf="customer.subscription.renewal_date">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                    Renewal date:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    <mon-user-date
                        [twoLines]="false"
                        [date]="customer.subscription.renewal_date">
                    </mon-user-date>
                </div>
            </div>

            <div class="row" *ngIf="customer.subscription.account_owner">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                    Account owner:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.subscription.account_owner }}
                </div>
            </div>

            <div class="row" *ngIf="customer.subscription.account_tier">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                    Account tier:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.subscription.account_tier }}
                </div>
            </div>

            <div class="row" *ngIf="customer.subscription.account_type">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                    Account type:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.subscription.account_type }}
                </div>
            </div>

            <div class="row" *ngIf="customer.subscription.engagement_level">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                    Engagement level:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.subscription.engagement_level }}
                </div>
            </div>

        </div>
    </div>

    <mon-panel-header pHeader="{{ 'Subscription overrides' | translate }}" *ngIf="customer.subscription">
    </mon-panel-header>

    <div class="card-body" *ngIf="customer.subscription">
        <ng-container *ngFor="let plan of customer.subscription.plan_overrides | keyvalue">
            <div class="row" *ngIf="plan.value !== null && plan.value !== undefined">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    <span>{{ plan.key | monTranslatePlanTraits }}:</span>
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    <div [ngSwitch]="plan.value">
                        <mon-icon *ngSwitchCase="true" [icon]="['CUSTOMERS', 'PLAN_TRAITS', 'ACTIVE']"></mon-icon>
                        <mon-icon *ngSwitchCase="false" [icon]="['CUSTOMERS', 'PLAN_TRAITS', 'INACTIVE']"></mon-icon>
                        <span *ngSwitchDefault>{{ plan.value }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <mon-panel-header pHeader="{{'Customer information' | translate}}">
    </mon-panel-header>

    <div class="card-body">
        <div class="col-md-48 col-lg-48 col-sm-48 font-size-1-1">
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'Name' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36  text-grey">
                    {{ customer.name || ('Empty' | translate) }}
                </div>
            </div>
            <div class="row" *ngIf="showCustomerId">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'ID' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36  text-grey">
                    {{ customer.id || ('Empty' | translate) }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'Address' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.address || ('Empty' | translate) }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'Zip code and city' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.zipcode || ('Empty' | translate) }} -
                    {{ customer.city || ('Empty' | translate) }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'State' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.state || ('Empty' | translate) }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                    {{'Country' | translate}}:
                </div>
                <div class="col-sm-26 col-lg-36 text-grey">
                    {{ customer.country || ('Empty' | translate) }}
                </div>
            </div>
        </div>
    </div>
</div>
