import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import {
    FormsBuilderModule,
    IconsModule,
    DialogModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormBackendAdminCustomerComponent } from './customer.component';
import { PlanModule } from './steps/plan/plan.module';
import { LayoutModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { OverrideModule } from './steps/override/override.module';
import { FormBackendAdminCustomerDetailComponent } from './steps/detail/detail.component';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [
        CommonModule,
        AngularFormModule,
        IconsModule,
        TranslateModule,
        PlanModule,
        FormsBuilderModule,
        LayoutModule,
        OverrideModule,
        DialogModule,
        FormBackendAdminCustomerDetailComponent,
    ],
    exports: [FormBackendAdminCustomerComponent],
    declarations: [FormBackendAdminCustomerComponent],
    providers: [],
})
export class CustomerModule {}
