import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { AgreementPickerComponent } from '@monsido/pages/reseller/agreement-picker/agreement-picker.component';
import { RESELLER_STATES } from '@monsido/pages/reseller/reseller.state';
import { CommonModule } from '@angular/common';
import { JiraSearchModule } from "@monsido/pages/reseller/jira-search/search.module";

@NgModule({
    imports: [CommonModule, UIRouterModule.forChild({ states: RESELLER_STATES }), JiraSearchModule],
    exports: [],
    declarations: [AgreementPickerComponent],
    providers: [],
})
export class ResellerModule {
}
