/**
 * The function is used to mark a string literal as translation key for translation extractor.
 * It was needed due to there are places we can't provide a translation service (non-Angular classes)
 * By the time of the creation of this function, angular-gettext-tools was being used as
 * a translation extractor.
 *
 * For example: Given the following statement
 * getString('Action')
 * The string 'Action' will be extracted as a translation key.
 *
 * Note: This function does not do any actual translating, one need to provide a proper
 * translate function for that.
 */
export const getString = (input: string): string => input;
