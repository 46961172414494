import { Component, Input, OnInit } from '@angular/core';
import { CustomerRepo } from '../../../../services/api/admin/customer-repo';
import { DomainGroupModel } from './domain-import/domain-group.model';
import { DomainImportService } from './domain-import/domain-import.service';
import { DomainModel } from './domain-import/domain-model';
import { HttpHeaders } from '@angular/common/http';
import { uniqBy } from 'lodash';
import { CommonModule } from '@angular/common';
import { DomainGroupMembersEntity, DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { UserImportSelectedModel } from '../setup/setup.component';
import { TranslateModule } from 'app/modules/translate.module';

type DomainModelWithDomainGroupModel = DomainModel & {
    domain_groups: DomainGroupModel[];
}

@Component({
    selector: 'mon-form-user-import-domains',
    standalone: true,
    templateUrl: './domains.component.html',
    imports: [
        CommonModule,
        TranslateModule,
    ],
})
export class FormUserImportDomainsComponent implements OnInit {
    @Input() customerId: number;
    @Input() selected: UserImportSelectedModel = {
        domains: [],
        domainGroups: [],
    };

    domains: DomainModelWithDomainGroupModel[] = [];
    flattenedDomains: (DomainModelWithDomainGroupModel | DomainGroupModel)[] = [];

    allVisibleChecked = false;
    allSendReportChecked = false;
    hasVisibleDomains = false;

    constructor (
        private customerRepo: CustomerRepo,
        private domainImportService: DomainImportService,
    ) {}

    async ngOnInit (): Promise<void> {
        if (!this.selected.domains) {
            this.selected.domains = [];
        }
        if (!this.selected.domainGroups) {
            this.selected.domainGroups = [];
        }
        await this.getDomains();
        this.setAllVisibleChecked();
        this.setAllSendReportChecked();
        this.setHasVisibleDomains();
    }

    toggleAllVisibleDomains (): void {
        this.setAllVisibleChecked();
        const visibility = !this.allVisibleChecked;
        for (const domain of this.flattenedDomains) {
            if (domain.domainType === 'Domain') {
                this.toggleDomainVisible(domain, visibility);
            } else {
                this.toggleDomainGroupVisible(domain, visibility);
            }
        }

        this.setAllVisibleChecked();
        this.setAllSendReportChecked();
    }

    toggleDomainVisible (domain: DomainModel | DomainGroupModel, value?: boolean): void {
        const index = this.selected.domains.findIndex(selectedDomain => selectedDomain.domain_id === domain.id);
        domain.toggleVisible(value);

        if (index > -1) {
            this.selected.domains[index].visible = domain.isVisible();
            this.selected.domains[index].send_report = domain.isSendReport();
        }

        this.setAllVisibleChecked();
        this.setAllSendReportChecked();
        this.setHasVisibleDomains();
    }

    toggleDomainGroupVisible (domain: DomainModel | DomainGroupModel, value?: boolean): void {
        const index = this.selected.domainGroups.findIndex(selectedDomain => selectedDomain.domain_group_id === domain.id);
        domain.toggleVisible(value);
        if (index > -1) {
            this.selected.domainGroups[index].visible = domain.isVisible();
            this.selected.domainGroups[index].send_report = domain.isSendReport();
        }

        this.setAllVisibleChecked();
        this.setAllSendReportChecked();
        this.setHasVisibleDomains();
    }

    toggleAllSendReports (): void {
        const sendReport = !this.allSendReportChecked;
        for (const domain of this.flattenedDomains) {
            if (domain.domainType === 'Domain') {
                this.toggleDomainSendReport(domain, sendReport);
            } else {
                this.toggleDomainGroupSendReport(domain, sendReport);
            }
        }
    }

    toggleDomainSendReport (domain: DomainModel | DomainGroupModel, value?: boolean): void {
        if (domain.isVisible()) {
            const index = this.selected.domains.findIndex(selectedDomain => selectedDomain.domain_id === domain.id);
            domain.toggleSendReport(value);

            if (index > -1) {
                this.selected.domains[index].send_report = domain.isSendReport();
            }
        }
    }

    toggleDomainGroupSendReport (domain: DomainModel | DomainGroupModel, value?: boolean): void {
        if (domain.isVisible()) {
            const index = this.selected.domainGroups.findIndex(selectedDomain => selectedDomain.domain_group_id === domain.id);
            domain.toggleSendReport(value);
            if (index > -1) {
                this.selected.domainGroups[index].send_report = domain.isSendReport();
            }
        }
    }

    private async getDomains (): Promise<void> {
        const domains = await this.customerRepo.getAllDomains(this.customerId, { page_size: 0 }, {} as HttpHeaders);
        this.domains = domains.map(domain => {
            domain.domain_groups = domain.domain_groups.map(group => {
                return new DomainGroupModel(this.domainImportService.createDomainGroup(group));
            });
            return this.domainImportService.createDomain(domain) as DomainModelWithDomainGroupModel;
        });

        this.flattenedDomains = this.domains.map(domain => {
            return [domain, ...domain.domain_groups.map(domainGroup => new DomainGroupModel(domainGroup))];
        }).flat();

        this.selected.domains = uniqBy(this.getExtendedSelectedDomains(), 'domain_id');
        this.selected.domainGroups = uniqBy(this.getExtendedSelectedDomainGroups(), 'domain_group_id');
    }

    private getExtendedSelectedDomains (): DomainUsersEntity[] {
        return (this.selected.domains || []).concat(this.flattenedDomains.filter(domain => {
            return domain.domainType === 'Domain';
        }).map(domain => {
            return {
                domain_id: domain.id,
                visible: false,
                send_report: false,
            };
        }));
    }

    private getExtendedSelectedDomainGroups (): DomainGroupMembersEntity[] {
        return (this.selected.domainGroups || []).concat(this.flattenedDomains.filter(domain => {
            return domain.domainType === 'DomainGroup';
        }).map(group => {
            return {
                domain_group_id: group.id,
                visible: false,
                send_report: false,
            };
        }));
    }

    private setAllVisibleChecked (): void {
        this.allVisibleChecked = this.flattenedDomains.every(domain => {
            return domain.isVisible();
        });
    }

    private setAllSendReportChecked (): void {
        const visibleDomains = this.flattenedDomains.filter(domain => {
            return domain.isVisible();
        });

        this.allSendReportChecked = visibleDomains.every(domain => domain.isSendReport());
    }

    private setHasVisibleDomains (): void {
        this.hasVisibleDomains = this.flattenedDomains.some(domain => domain.isVisible());
    }
}
