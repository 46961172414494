import { Component, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Papa, ParseResult } from 'ngx-papaparse';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'mon-form-user-upload',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './form-user-upload.component.html',
})
export class FormUserUploadComponent {
    @Input() data?: ParseResult;
    @Input() step: number;
    @Output() dataChange = new EventEmitter<ParseResult>;
    @Output() stepChange = new EventEmitter<number>;
    @ViewChild('inputField') fileInput: ElementRef<HTMLInputElement>;

    constructor (private papa: Papa) { }

    openFileDialog (): void {
        this.fileInput.nativeElement.click();
    }

    newBulkFile (event: Event): void {
        event.preventDefault();
        const csvData = (event.target as HTMLInputElement).files[0];

        if (csvData) {
            this.papa.parse(
                csvData,
                {
                    skipEmptyLines: true,
                    complete: (data) => {
                        this.data = data;
                        this.step = 2;
                        this.dataChange.emit(this.data);
                        this.stepChange.emit(this.step);
                    },
                },
            );
        }
    }
}
