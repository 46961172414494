import { Injectable } from '@angular/core';
import { Domain } from '@monsido/modules/models/api/domain';

@Injectable()
export class DomainService {
    hasExtraUrl (domain: Domain): boolean {
        if (domain.cms === 'custom') {
            if (
                domain.cms_extra.base_url == null && // If property is null or undefined.
                domain.cms_extra.url
            ) {
                return true;
            }
            return false;
        }
    }
}
