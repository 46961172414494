import { DefaultPathConstraintInterface } from '../default-constraints-and-excludes.interface';


export class DefaultPathConstraintsModel implements DefaultPathConstraintInterface {

    constraint: string = '';
    tags: string[] = [];

    constructor (entry: DefaultPathConstraintInterface, cms: string) {
        this.constraint = entry.constraint;
        this.tags = ['default', cms];
    }

}
