<div class="row">
    <div class="col-sm-offset-8 col-sm-40" *ngIf="!monRequestChecker">
        <h3 class="font-weight-300" translate>Basic Auth</h3>
    </div>
    <div class="col-xs-48">
        <div class="form-group">
            <label class="col-form-label col-sm-8" for="basic_auth_username" translate [ngClass]="{ 'text-right': monRequestChecker }">Username</label>
            <div class="col-sm-40">
                <mon-form-field-input
                       [type]="'text'"
                       [name]="'basic_auth_username'"
                       required
                       #basic_auth_username="ngModel"
                       [monPlaceholder]="''"
                       [monId]="'basic_auth_username'"
                       [(ngModel)]="monLogin.basic_auth.username"
                ></mon-form-field-input>
            </div>
            <mon-form-field-errors
                [showError]="basic_auth_username.control.touched && basic_auth_username.control.invalid"
                [errors]="basic_auth_username.control.errors"
            ></mon-form-field-errors>
        </div>
    </div>
    <div class="col-xs-48">
        <div class="form-group">
            <label class="col-form-label col-sm-8" for="basic_auth_password" translate  [ngClass]="{ 'text-right': monRequestChecker }">Password</label>
            <div class="col-sm-40">
                <mon-form-field-input
                       [type]="'password'"
                       [name]="'basic_auth_password'"
                       [monPlaceholder]="''"
                       required
                       #basic_auth_password="ngModel"
                       [monId]="'basic_auth_password'"
                       [(ngModel)]="monLogin.basic_auth.password"
                ></mon-form-field-input>
            </div>
            <mon-form-field-errors
                [showError]="basic_auth_password.control.touched && basic_auth_password.control.invalid"
                [errors]="basic_auth_password.control.errors"
            ></mon-form-field-errors>
        </div>
    </div>
    <div class="col-sm-offset-8 col-sm-40" *ngIf="!monRequestChecker">
        <h3 class="font-weight-300" translate>Hosts</h3>
    </div>
    <div class="col-xs-48 mb-1" *ngIf="monRequestChecker">
            <label class="col-form-label col-sm-8 text-right" for="basic_auth_password" translate>Hosts</label>
            <div class="col-sm-40">
                <button type="button" class="btn btn-secondary" (click)="addHost()">
                    <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                </button>
            </div>
    </div>
    <div class="col-xs-48">
        <div class="form-group" *ngFor="let host of monLogin.basic_auth.hosts; let $index = index; trackBy: trackById">
            <label class="col-form-label col-sm-8" for="basic_auth_hostname{{$index}}" translate [ngClass]="{ 'text-right': monRequestChecker }">Host*</label>
            <div class="col-sm-36">
                <mon-form-field-input
                        [type]="'text'"
                        name="basic_auth_hostname{{$index}}"
                        [monId]="'basic_auth_hostname'+$index"
                        [monPlaceholder]="''"
                        #basic_auth_host="ngModel"
                        required
                        [(ngModel)]="monLogin.basic_auth.hosts[$index]"
                        (ngModelChange)="isValidHostname(monLogin.basic_auth.hosts, $index)">
                </mon-form-field-input>
                <span class="text-danger"
                      *ngIf="!validHostname[$index] && basic_auth_host.control.touched"
                      translate>
                      Insert a valid host name
                </span>
            </div>
            <div class="col-sm-4 text-right">
                <button type="button"
                        class="btn btn-secondary"
                        [disabled]="monLogin.basic_auth.hosts.length === 1"
                        (click)="removeHost($index)">
                    <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-offset-8 col-sm-40" *ngIf="!monRequestChecker">
        <button type="button" class="btn btn-secondary" (click)="addHost()">
            <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
        </button>
    </div>
    <div class="col-sm-offset-8 col-sm-40 text-danger" *ngIf="monLogin.basic_auth.hosts.length === 0">
        <p translate>Hosts cannot be empty</p>
    </div>
</div>
