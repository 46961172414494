<form name="setup">
    <mon-form-field-select [monName]="'name'"
                           monLabel="{{ 'Name'| translate }}"
                           [(model)]="selected.name"
                           [monRequired]="true"
                           [monOptions]="options"
                           (changeEvent)="onChange($event)"
                           [monNameAttr]="'name'"
                           [monReturnValue]="'value'"
                           monPlaceholder="{{ 'Choose an option' | translate }}"
    ></mon-form-field-select>
    <mon-form-field-select [monName]="'platform'"
                           monLabel="{{ 'Platform'| translate }}"
                           [(model)]="selected.platform"
                           [monOptions]="options"
                           (changeEvent)="onChange($event)"
                           [monNameAttr]="'name'"
                           [monReturnValue]="'value'"
                           monPlaceholder="{{ 'Choose an option' | translate }}"
    ></mon-form-field-select>
    <mon-form-field-select [monName]="'category'"
                           monLabel="{{ 'Category'| translate }}"
                           [(model)]="selected.category"
                           [monOptions]="options"
                           (changeEvent)="onChange($event)"
                           [monNameAttr]="'name'"
                           [monReturnValue]="'value'"
                           monPlaceholder="{{ 'Choose an option' | translate }}"
    ></mon-form-field-select>
    <mon-form-field-select [monName]="'description'"
                           monLabel="{{ 'Description'| translate }}"
                           [(model)]="selected.description"
                           [monOptions]="options"
                           (changeEvent)="onChange($event)"
                           [monNameAttr]="'name'"
                           [monReturnValue]="'value'"
                           monPlaceholder="{{ 'Choose an option' | translate }}"
    ></mon-form-field-select>
    <mon-form-field-select [monName]="'retention'"
                           monLabel="{{ 'Retention'| translate }}"
                           [(model)]="selected.retention"
                           [monOptions]="options"
                           (changeEvent)="onChange($event)"
                           [monNameAttr]="'name'"
                           [monReturnValue]="'value'"
                           monPlaceholder="{{ 'Choose an option' | translate }}"
    ></mon-form-field-select>
</form>
