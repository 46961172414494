import { Ng2StateDeclaration } from '@uirouter/angular';
import { CustomersComponent } from './customers.component';
import { DomainShowComponent } from 'app/pages/common/domain-show/domain-show.component';
import { CustomerShowComponent } from './customer-show/customer-show.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const CUSTOMERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.admin.customers',
        url: '/customers',
        abstract: true,
    },
    {
        name: 'base.admin.customers.index',
        url: '?search&page_size&page&plan_type',
        params: {
            ...RouteParamsConstant,
            plan_type: {
                value: 'all',
                dynamic: true,
            },
        },
        views: {
            '^.^.^.$default': {
                component: CustomersComponent,
            },
        },
        dynamic: true,
    },
    {
        name: 'base.admin.customers.show',
        url: '/:customer_id',
        views: {
            '^.^.^.$default': {
                component: CustomerShowComponent,
            },
        },
        dynamic: true,
    },
    {
        name: 'base.admin.customers.info',
        url: '/:customer_id/info',
        abstract: true,
    },
    {
        name: 'base.admin.customers.info.domain',
        url: '/domain/:domain_id',
        views: {
            '^.^.^.^.$default': {
                component: DomainShowComponent,
            },
        },
    },
];
