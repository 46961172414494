<table class="table table-hover">
    <thead>
    <tr>
        <th class="col-sm-12 col-md-12 col-lg-10" translate>Name</th>
        <th class="col-sm-12 col-md-12 col-lg-10" translate>Plan</th>
        <th class="col-sm-12 col-md-12 col-lg-10" translate>Users</th>
        <th class="col-sm-12 col-md-12 col-lg-10" translate>Domains</th>
        <th class="col-sm-5 col-md-4 col-lg-4"></th>
        <th class="col-sm-5 col-md-5 col-lg-4 text-right"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let customer of customers">
        <td><a uiSref="base.admin.customers.show" [uiParams]="{customer_id: customer.id}" data-test="customer-name">{{customer.name}}</a></td>
        <td >
            {{ customer.subscription.plan.name || 'No plan' }}
        </td>
        <td>{{ customer.users_count |number}}</td>
        <td>{{ customer.domains_count |number}}</td>
        <td>
            <span *ngIf="customer.account_suspended">
                {{ 'Suspended' | translate }}
            </span>
        </td>
        <td class="text-right" (click)="stopPropagation($event)">
            <a role="button" class="cursor-pointer btn btn-secondary" uiSref="base.admin.customers.show" [uiParams]="{customer_id: customer.id}" data-test="customer-details">
                {{'Customer details' | translate}}
            </a>
        </td>
    </tr>
    </tbody>
</table>
