<form name="userForms" #userForm="ngForm" class="form-horizontal" (ngSubmit)="userForm.valid && submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']"
                         header="{{'New customer-user' | translate }}"
                         *ngIf="!user.id">
        </mon-page-header>
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']"
                         header="{{'Edit customer-user' | translate }}"
                         *ngIf="user.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>User information</h3>
            </div>
            <div class="card-body">
                <mon-form-backend-admin-customer-user-basic [user]="formUser" [form]="userForm"></mon-form-backend-admin-customer-user-basic>
            </div>
        </div>
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Domains</h3>
            </div>
            <div class="card-body">
                <mon-table-container [collection]="domains"
                                     (pageChangeEvent)="onPageChange($event)"
                                     (perPageChange)="onPageSizeChange($event)"
                                     class="table-vertial-align"
                                     [loading]="domainLoading"
                                     [noResultMessage]="'No result'">
                    <div class="table-container-body">
                        <mon-form-backend-admin-customer-user-domains [user]="formUser" [domains]="domains"></mon-form-backend-admin-customer-user-domains>
                    </div>
                </mon-table-container>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer">
        <div class="col-md-48 text-right">
            <button class="btn btn-secondary" [disabled]="saving || userForm.invalid" type="submit" data-test="save-button">
                <span [hidden]="saving" translate>Save</span>
                <span [hidden]="!saving">
                    <mon-icon [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
                </span>
            </button>
        </div>
    </div>
</form>
