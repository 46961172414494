import { AccessibilityChecksHelperService } from 'app/blocks/helpers/accessibility/accessibility-checks-helper.service';
import { Injectable } from '@angular/core';
import { AccessibilityCheckModel, AccessibilityCheckType } from './accessibility-check-model';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityCheckModelService {
    constructor (private accessibilityChecksHelperService: AccessibilityChecksHelperService) {}

    createAccessibilityCheck (params: AccessibilityCheckType): AccessibilityCheckModel {
        return new AccessibilityCheckModel(params, this.accessibilityChecksHelperService);
    }
}


