import { NgForm } from '@angular/forms';

export class BaseForm {
    resetFormState (form: NgForm): void {
        if (!form) {
            return;
        }

        form.form.markAsPristine();
        form.form.markAsUntouched();
    }
}
