<form name="form" #userImportSetupForm="ngForm">
    <mon-form-field-select
        [monLabel]="'Email'| translate"
        [(model)]="selected.emailIndex"
        [monOptions]="options"
        [monNameAttr]="'name'"
        [monName]="'email'"
        [monReturnValue]="'value'"
        [monPlaceholder]="'Choose an option' | translate"
        [monAllowClear]="true"
    ></mon-form-field-select>

    <mon-form-field-select
        [monLabel]="'First name'| translate"
        [(model)]="selected.firstNameIndex"
        [monOptions]="options"
        [monNameAttr]="'name'"
        [monName]="'firstname'"
        [monReturnValue]="'value'"
        [monPlaceholder]="'Choose an option' | translate"
        [monAllowClear]="true"
    ></mon-form-field-select>

    <mon-form-field-select
        [monLabel]="'Last name'| translate"
        [(model)]="selected.lastNameIndex"
        [monOptions]="options"
        [monNameAttr]="'name'"
        [monName]="'lastname'"
        [monReturnValue]="'value'"
        [monPlaceholder]="'Choose an option' | translate"
        [monAllowClear]="true"
    ></mon-form-field-select>

    <mon-form-field-select
        [monLabel]="'Password'| translate"
        [(model)]="selected.passwordIndex"
        [monOptions]="options"
        [monNameAttr]="'name'"
        [monName]="'password'"
        [monHelpText]="'If none selected - a password will be generated' | translate"
        [monReturnValue]="'value'"
        [monPlaceholder]="'Choose an option' | translate"
        [monAllowClear]="true"
    ></mon-form-field-select>

    <mon-form-field-select
        [monLabel]="'Is administrator'| translate"
        [(model)]="selected.isAdminIndex"
        [monOptions]="options"
        [monNameAttr]="'name'"
        [monName]="'is_admin'"
        [monReturnValue]="'value'"
        [monPlaceholder]="'Choose an option' | translate"
        [monHelpText]="'Use Yes or No' | translate"
        [monAllowClear]="true"
    ></mon-form-field-select>
</form>
