import { Injectable } from '@angular/core';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Params } from '@monsido/http/params';

@Injectable()
export class AgreementsRepo {

    constructor (private client: ApiClient) {
    }

    getAll (options?: Params, urlPrefix?: string): Promise<Agreement[]> {
        return this.client.getPromise('agreements', options, urlPrefix).then(this.toModel.bind(this));
    }

    private toModel (model: Agreement[]): Agreement[] {
        return model.map(m => new Agreement(m));
    }
}
