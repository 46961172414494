<span id="linkOpensInNewTab" hidden translate>Link opens in a new tab</span>
<div id="menu" class="fixed-top">
    <mon-menu></mon-menu>
</div>
<div id="page-content">
    <div id="sidebar-wrapper" *ngIf="withSidebar">
        <div id="env-selector-wrapper">
            <env-selector-component></env-selector-component>
        </div>
        <div id="sidebar" [ngSwitch]="sidebar">
            <mon-side-menu-admin *ngSwitchCase="'admin'"></mon-side-menu-admin>
            <mon-side-menu-reseller-settings *ngSwitchCase="'reseller_setting'"></mon-side-menu-reseller-settings>
            <mon-side-menu-backend-admin *ngSwitchCase="'backend-admin'"></mon-side-menu-backend-admin>
            <mon-side-menu-backend-admin-settings *ngSwitchCase="'backend-admin_settings'"></mon-side-menu-backend-admin-settings>
        </div>
    </div>
    <div class="content" [ngClass]="{'hide-sidebar': !withSidebar}" >
        <div class="container-fluid" ui-view>
        </div>
    </div>
</div>
<mon-dialog-container></mon-dialog-container>
<mon-overlay-wrapper></mon-overlay-wrapper>
