import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ScansInterface, TextsInterface } from '@monsido/modules/models/api/interfaces/scans.interface';
import { TranslateService } from 'app/services/translate/translate.service';

interface FormattedTextsInterface extends Pick<TextsInterface, 'texts'> {
    title: string
}
@Component({
    selector: 'mon-request-checker-texts-component',
    templateUrl: './texts.component.html',
    styleUrls: ['./texts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextsComponent implements OnInit {
    @Input() url: string;
    @Input() scan: ScansInterface;

    tableData: FormattedTextsInterface[] = [];

    constructor (private translateService: TranslateService) {}

    ngOnInit (): void {
        const keyTranslationTable = {
            original: this.translateService.getString('Original'),
            readability: this.translateService.getString('Readability'),
            spelling: this.translateService.getString('Spelling'),
        };

        for (const type of this.scan.texts) {
            this.tableData.push({
                title: keyTranslationTable[type.key],
                texts: type.texts,
            });
        }

        this.tableData.reverse();
    }
}
