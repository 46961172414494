import { Component, OnInit } from '@angular/core';
import { OauthService } from '@monsido/oauth/oauth.service';

@Component({
    selector: 'mon-worker-queue',
    templateUrl: 'worker-queue.html',
})
export class WorkerQueueComponent implements OnInit {
    path: string;
    constructor (private oauthService: OauthService) {}

    ngOnInit (): void {
        this.path = this.oauthService.getApiPath() + '/sidekiq';
    }
}
