import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';

@Pipe({
    name: 'monRole',
})
export class RolePipe implements PipeTransform {
    constructor (private sessionService: SessionService) {}
    transform (roles: string[]): boolean {
        if (!this.sessionService.roles) {
            return false;
        }
        for (let i = 0; i < roles.length; i++) {
            if (this.sessionService.roles.indexOf(roles[i]) !== -1) {
                return true;
            }
        }
        return false;
    }
}
