import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { LayoutModule, MonPromptService, TableModule, TableSearchModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { UrlService } from '@uirouter/core';
import { Reseller } from '@monsido/modules/models/api/interfaces/user.interface';
import { BackendAdminDomainsRepo } from 'app/services/api/backend_admin/backend-admin-domains-repo';
import { Domain } from '@monsido/modules/models/api/domain';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { BackendAdminDomainsModule } from '@monsido/pages/backend-admin/domains/domains.module';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { HttpHeaders } from '@angular/common/http';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-reseller-domains-tab',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        TableSearchModule,
        TableModule,
        BackendAdminDomainsModule,
        ConfirmModule,
    ],
    templateUrl: './reseller-domains-tab.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResellerDomainsTabComponent extends BaseApiComponent implements OnInit {

    @Input() reseller: Reseller;

    collection: CollectionInterface<Domain>;
    selectedDomains: Domain[] = [];
    loading = false;

    constructor (
        $location: UrlService,
        private promptService: MonPromptService,
        private backendAdminDomainsRepo: BackendAdminDomainsRepo,
        private translateService: TranslateService,
        private cdref: ChangeDetectorRef,
    ) {
        super($location);
    }

    ngOnInit (): void {
        this.collection = [];
        if (this.reseller) {
            this.getPage();
        }
    }

    onSearchChanged (searchText: string): void {
        this.onSearch(searchText);
    }

    getPage (): void {
        const params: Record<string, string | number> = {
            page: this.page,
            page_size: this.pageSize,
            reseller_id: this.reseller.id,
        };
        if (this.search) {
            params.search = this.search;
        }
        this.loading = true;
        this.backendAdminDomainsRepo.getAll(params).then((data) => {
            this.collection = data;
            this.loading = false;
        }, (res) => {
            this.loading = res.status === -1 && res.xhrStatus === 'abort';
        })
            .finally(() => {
                this.cdref.detectChanges();
            });
    }

    deleteDomains (): void {
        const chain: Promise<void>[] = [];
        const headers = new HttpHeaders({
            noGlobal: 'true',
        });
        this.selectedDomains.forEach((domain) => {
            chain.push(this.backendAdminDomainsRepo.destroy(domain.customer_id, domain.id, {}, headers));
        });

        Promise.all(chain).finally(() => {
            this.promptService.alert(this.translateService.getString('Selected domains have been deleted'));
            setTimeout(() => {
                this.selectedDomains.length = 0;
                this.page = 1;
                this.getPage();
            }, 200);
        });
    }

}
