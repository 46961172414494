import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import {
    FormBuilderInputModule,
    LayoutModule,
    IconsModule,
    FormBuilderValidationModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { JiraSearchService } from '@monsido/pages/reseller/jira-search/search.service';
import { JiraSearchPageComponent } from '@monsido/pages/reseller/jira-search/search.component';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [
        AngularFormModule,
        CommonModule,
        FormBuilderInputModule,
        FormBuilderValidationModule,
        IconsModule,
        LayoutModule,
        TranslateModule,
    ],
    providers: [JiraSearchService],
    declarations: [JiraSearchPageComponent],
})
export class JiraSearchModule {}
