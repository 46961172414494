import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserAgreementsEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { User } from '@monsido/modules/models/api/user';
import { TableModule, IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CommonModule } from '@angular/common';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-sudo-button',
    templateUrl: 'sudo-button.html',
    standalone: true,
    imports: [CommonModule, TableModule, TranslateModule, IconsModule],
})
export class SudoButtonComponent {
    @Output() sudoEvent = new EventEmitter();
    @Input() user: User;
    @Input() customer: CustomerInterface;

    constructor () {}

    customerAgreements (): UserAgreementsEntity[] {
        if (this.user && Array.isArray(this.user.user_agreements)) {
            return this.user.user_agreements.filter((agreement) => {
                return agreement.account && agreement.account.type === 'Customer';
            });
        }

        return [];
    }

    sudo (customerId: number, user: User): void {
        this.sudoEvent.emit({ customerId, user });
    }
}
