import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { DialogOptions, DialogService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';
import { SideMenuAdminComponent } from '../sidemenu/menues/side-menu-admin/side-menu-admin.component';
import { SideMenuResellerSettingsComponent } from '../sidemenu/menues/side-menu-reseller-settings/side-menu-reseller-settings.component';
import { SideMenuBackendAdminComponent } from '../sidemenu/menues/side-menu-backend-admin/side-menu-backend-admin.component';
import { SideMenuBackendAdminSettingsComponent } from '../sidemenu/menues/side-menu-backend-admin-settings/side-menu-backend-admin-settings.component';
import { EnvSelectorModule } from '@monsido/modules/env-selector/env-selector.module';
import { DialogOptionsAdapter } from '@monsido/core/modules/dialog-container/dialog-container.component';
import { UIRouterModule } from '@uirouter/angular';
import { MenuComponent } from '../menu/menu.component';
import { DialogContainerModule } from '@monsido/core/modules/dialog-container/dialog-container.module';
import { OverlayWrapperModule } from '@monsido/modules/overlay-wrapper/overlay-wrapper.module';
@Component({
    selector: 'mon-base-controller',
    standalone: true,
    imports: [
        CommonModule,
        SideMenuAdminComponent,
        SideMenuResellerSettingsComponent,
        SideMenuBackendAdminComponent,
        SideMenuBackendAdminSettingsComponent,
        EnvSelectorModule,
        UIRouterModule,
        MenuComponent,
        DialogContainerModule,
        OverlayWrapperModule,
    ],
    templateUrl: './base-controller.component.html',
    styleUrls: ['./base-controller.component.scss'],
})
export class BaseControllerComponent implements OnInit {
    constructor (
        private monEventService: MonEventService,
        private dialogService: DialogService,
        private uiRouterGlobals: UIRouterGlobals,
        private transitionService: TransitionService,
    ) {}

    sidebar: string;
    withSidebar: boolean;

    ngOnInit (): void {

        this.monEventService.addListener(MON_EVENTS.CLOSE_ALL_DIALOGS, (index: number) => {
            this.dialogService.closeAll();
            return index;
        });

        this.monEventService.addListener(MON_EVENTS.LOAD_DIALOG, (dialogOptionsAdapter: DialogOptionsAdapter<unknown>) => {
            const { component, dialogOptions = {}, beforeCloseCb, data = {} } = dialogOptionsAdapter;

            const dialogOptionsCopy: DialogOptions = {
                ...dialogOptions,
                manualFocus: true,
                parentSelector: '#common-dialog-wrapper',
            };

            const dialogRef = this.dialogService.open(component, dialogOptionsCopy);
            if (dialogRef) {
                if (beforeCloseCb) {
                    dialogRef.setBeforeClosedCallback(beforeCloseCb);
                }

                for (const [key, value] of Object.entries(data)) {
                    if (dialogRef.componentInstance && typeof dialogRef.componentInstance === 'object') {
                        (dialogRef.componentInstance as Record<string, unknown>)[key] = value;
                    }
                }
            }
            return dialogRef as unknown as number;
        });

        const stateObj = this.uiRouterGlobals.current.data;
        this.handleSubMenu(stateObj);

        this.transitionService.onSuccess({}, (transition) => {
            const toState = transition.to();
            this.handleSubMenu(toState.data);
        });
        this.monEventService.run('afterBaseLayout');
    }

    private handleSubMenu (data: {sidebar: string}): void {
        this.sidebar = data.sidebar;
        this.withSidebar = data.sidebar !== undefined;
    }
}
