<form name="planForm" #planForm="ngForm">
    <div class="row">
        <div class="col-sm-offset-2 col-sm-40">
            <h5 translate>Subscription start date</h5>
            <div class="row pl-3">
                <mon-form-field-datepicker
                    datePickerInputName="subscription_start"
                    [ngRequired]="true"
                    [(model)]="customer.subscription.subscription_start_at"
                    (modelChange)="setDate($event)"></mon-form-field-datepicker>
            </div>
            <h5>{{ 'Subscription' | translate }}</h5>
            <mon-form-field-select-ac name="plan_id"
                                           monName="plan_id"
                                           required
                                           [monOptions]="planOptions"
                                           monPlaceholder="{{ 'Subscriptions' | translate }}"
                                           monReturnValue="id"
                                           [(ngModel)]="customer.subscription.plan_id"
                                           (modelChange)="onSelectPlan($event)">
            </mon-form-field-select-ac>
            <h5>{{ 'Account status' | translate }}</h5>
            <mon-form-field-checkbox monName="account_suspended"
                                     [(model)]="customer.account_suspended"
                                     monLabel="{{'Account suspended' | translate}}">
            </mon-form-field-checkbox>

        </div>
    </div>
</form>
