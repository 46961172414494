import { ParamDeclaration } from '@uirouter/core';

export const RouteParamsConstant: Record<string,ParamDeclaration> = {
    search: {
        value: '',
        dynamic: true,
    },
    page_size: {
        value: '10',
        dynamic: true,
    },
    page: {
        value: '1',
        dynamic: true,
    },
};
