import { Injectable } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { OauthService } from '@monsido/oauth/oauth.service';
import { Agreement } from '@monsido/modules/models/api/agreement';

@Injectable({ providedIn: 'root' })
export class SetupRun {
    constructor (
        private monEventsService: MonEventService,
        private apiClient: ApiClient,
        private oauthService: OauthService,
    ) {}

    run (): void {
        this.monEventsService.addListener('setAgreement', (agreement: Agreement) => {
            this.apiClient.config.headers.set('Monsido-Account-Id', String(agreement.account.id));
        });

        this.monEventsService.addListener('logout', () => {
            this.apiClient.config.headers.delete('Authorization');
            this.apiClient.config.headers.delete('Monsido-Account-Id');
            this.oauthService.clear();
        });
    }
}
