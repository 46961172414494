<form name="basicForm" #basicForm="ngForm" *ngIf="user">
    <mon-form-field-input
        name="first_name"
        #first_name="ngModel"
        required
        [(ngModel)]="user.first_name"
        monLabel="{{ 'First name' | translate }}"
        monPlaceholder="{{ 'First name' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [errors]="first_name.errors"
        [showError]="first_name.touched && first_name.invalid">
    </mon-form-field-errors>

    <mon-form-field-input
        name="last_name"
        [(ngModel)]="user.last_name"
        monLabel="{{ 'Last name' | translate }}"
        monPlaceholder="{{ 'Last name' | translate }}"
        type="text"></mon-form-field-input>

    <mon-form-field-input name="email"
                          required
                          pattern="{{ emailValidator }}"
                          #email="ngModel"
                          [(ngModel)]="user.email"
                          monLabel="{{ 'Email' | translate }}"
                          monPlaceholder="{{ 'Email' | translate }}"
                          type="email">
    </mon-form-field-input>
    <mon-form-field-errors
        [patternMsg]="'Not valid email' | translate"
        [errors]="email.errors"
        [showError]="email.touched && email.invalid">
    </mon-form-field-errors>
    <mon-form-field-select monName="locale"
                           [(model)]="user.locale"
                           monLabel="{{ 'language' | translate }}"
                           monPlaceholder="{{ 'language' | translate }}"
                           [monOptions]="supportedLanguages"
                           monReturnValue="code"
                           *ngIf="isAdmin">
    </mon-form-field-select>
    <mon-form-field-input *ngIf="user.id"
                          [(ngModel)]="user.password"
                          name="user.password"
                          monLabel="{{ 'Password' | translate }}"
                          monPlaceholder="{{ 'Password' | translate }}"
                          type="password">
    </mon-form-field-input>
    <mon-form-field-input *ngIf="user.id"
                          [(ngModel)]="user.password_confirmation"
                          name="user.password_confirmation"
                          monLabel="{{ 'Password confirmation' | translate }}"
                          monPlaceholder="{{ 'Password confirmation' | translate }}"
                          type="password">
    </mon-form-field-input>
    <div class="row" *ngIf="!user.id">
        <div class="col-sm-11 pr-0">
            <mon-switch
                [(ngModel)]="user.send_welcome_email"
                class="inline-block mt-2 mr-3 pull-right"
                monLabel="send_welcome_email"
                name="send_welcome_email"
            ></mon-switch>
        </div>
        <div class="col-sm-37 pl-2 mt-3">
            <label for="send_welcome_email" class="font-weight-400 no-padding" translate>
                Send password mail on creation
            </label>
        </div>
    </div>
</form>
