import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { User } from '@monsido/modules/models/api/user';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ResellerAdminUsersRepo {
    constructor (private client: ApiClient) {}

    getAll (params: HttpParams): Promise<User[]> {
        params = params || ({} as HttpParams);
        return this.client.getPromise('admin/users', { params }).then(this.toModels.bind(this));
    }

    get (id: number): Promise<User> {
        return this.client.getPromise(`admin/users/${id}`).then(this.toModels.bind(this));
    }

    create (user: User): Promise<User> {
        return this.client.postPromise('admin/users', user).then(this.toModels.bind(this));
    }

    update (userId: number, user: User): Promise<User> {
        return this.client.patchPromise(`admin/users/${userId}`, user).then(this.toModels.bind(this));
    }

    delete (userId: number): Promise<void> {
        return this.client.deletePromise(`admin/users/${userId}`);
    }

    private toModels (models: User[]): User[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    private toModel (model: User): User {
        return new User(model);
    }
}
