import { NgModule } from '@angular/core';
import { RolePipe } from '@monsido/core/pipes/role/role.pipe';
import { FilterByStringPipe } from '@monsido/core/pipes/filterByString/filterByString.pipe';
import { RemoveWhiteSpace } from './removeWhiteSpace/removeWhiteSpace.pipe';
import { InverseColorCode } from './inverseColorCode/inverseColorCode.pipe';
import { TimeDiffPipe } from './timeDiff/timeDiff.pipe';
import { UnderscorelessPipe } from './underscoreless/underscoreless.pipe';
import { IsBooleanPipe } from './isBoolean/isBoolean.pipe';
import { TranslatePlanTraits } from './translatePlanTraits/translatePlanTraits.pipe';
import { TranslateReadabilityPipe } from '@monsido/core/pipes/translateReadability/translateReadability.pipe';
import { ToStringPipe } from './toString/to-string.pipe';
@NgModule({
    imports: [],
    exports: [
        RolePipe,
        FilterByStringPipe,
        RemoveWhiteSpace,
        InverseColorCode,
        TimeDiffPipe,
        UnderscorelessPipe,
        IsBooleanPipe,
        TranslatePlanTraits,
        TranslateReadabilityPipe,
        ToStringPipe,
    ],
    declarations: [
        RolePipe,
        FilterByStringPipe,
        RemoveWhiteSpace,
        InverseColorCode,
        TimeDiffPipe,
        UnderscorelessPipe,
        IsBooleanPipe,
        TranslatePlanTraits,
        TranslateReadabilityPipe,
        ToStringPipe,
    ],
    providers: [],
})
export class PipesModule {}
