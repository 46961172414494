<form name="domainFormname" #domainForm="ngForm" class="form-horizontal" (ngSubmit)="!saving && domainForm.valid && submit()" *ngIf="formDomain">
    <div class="mon-dialog-header">
        <mon-page-header
            [monIcon]="['DOMAINS', 'ICON']"
            header="{{'Edit Domain' | translate }}"
            *ngIf="formDomain.id">
        </mon-page-header>
        <mon-page-header
            [monIcon]="['DOMAINS', 'ICON']"
            header="{{'New Domain' | translate }}" *ngIf="!formDomain.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Domain detail</h3>
            </div>
            <div class="card-body" *ngIf="monCustomer">
                <mon-form-domain-detail
                    [monDomain]="formDomain"
                    [monCustomer]="monCustomer"
                    [monForm]="domainForm"></mon-form-domain-detail>
            </div>
        </div>
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Scan</h3>
            </div>
            <div class="card-body">
                <mon-form-domain-scan
                    #domainScanForm
                    *ngIf="formDomain && monCustomer"
                    [monCustomer]="monCustomer"
                    [monDomain]="formDomain"
                    [monForm]="domainForm"
                    [monIsBackendAdmin]="monIsBackendAdmin"></mon-form-domain-scan>
            </div>
        </div>
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Features</h3>
            </div>
            <div class="card-body">
                <mon-form-domain-features #domainFeaturesForm [monDomain]="formDomain" [monCustomer]="monCustomer" [monForm]="domainForm" [monIsBackendAdmin]="monIsBackendAdmin" *ngIf="monCustomer"></mon-form-domain-features>
            </div>
        </div>
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Users</h3>
            </div>
            <div class="card-body">
                <mon-form-domain-users *ngIf="formDomain" #domainUsersForm [monDomain]="formDomain" [monUsers]="users"></mon-form-domain-users>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button type="submit" class="btn btn-secondary" [disabled]="domainForm.invalid || domainScanForm?.form.invalid || domainFeaturesForm?.form.invalid || domainUsersForm?.form.invalid || saving" data-test="save-button">
            <span [hidden]="saving" translate>Save</span>
            <mon-icon *ngIf="saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
        </button>
    </div>
</form>
