<form name="form" class="form-horizontal" #dataPrivacyForm="ngForm" (ngSubmit)="submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['DATA_PRIVACY_CHECKS', 'ICON']"
                         header="{{'Edit Data Privacy Check' | translate }}"
                         subHeader="{{check.name}}"
                         *ngIf="check.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Check details
                </h3>
            </div>
            <div class="card-body">
                <div class="form-group mr-2">
                    <mon-form-field-input [name]="'name'"
                                          [(ngModel)]="check.name"
                                          monLabel="{{ 'Name' | translate }}"
                                          monPlaceholder="{{ 'Name' | translate }}"
                                          [type]="'text'">
                    </mon-form-field-input>
                </div>

               <div class="form-group mr-2">
                   <mon-form-field-select [monName]="'group'"
                                          [(model)]="check.group"
                                          [monOptions]="groups"
                                          monReturnValue="value"
                                          monLabel="{{ 'Group' | translate }}"
                                          monPlaceholder="{{ 'Add group' | translate }}"
                                          monRequired="true"
                                          [monNoAutoComplete]="true">
                    </mon-form-field-select>
               </div>

                <div class="form-group mr-2">
                    <mon-form-field-select [monMultiple]="true"
                                           monReturnValue="value"
                                           monPlaceholder="{{ '   Add regions' | translate }}"
                                           monLabel="{{ 'Regions' | translate }}"
                                           [monOptions]="regions"
                                           [(model)]="check.regions"
                                           [monName]="'regions'">
                    </mon-form-field-select>
                </div>

               <div class="form-group mr-2">
                   <mon-form-field-select [monMultiple]="true"
                                          [monOptions]="regulations"
                                          [(model)]="check.regulations"
                                           monLabel="{{ 'Regulations' | translate }}"
                                           monReturnValue="value"
                                           monPlaceholder="{{ '   Add Data Privacy Regulations' | translate }}"
                                           [monName]="'regulations'">
                    </mon-form-field-select>
               </div>

                <div class="form-group mr-3">
                    <div class="row">
                        <label class="col-sm-offset-8 col-sm-40 mr-3" translate>Priority</label>
                        <div class="col-sm-offset-8 col-sm-40">
                            <div class="d-flex align-items-center flex-wrap">
                                <div *ngFor="let priority of priorityLevels"
                                     class="cursor-pointer">
                                    <input type="radio"
                                           class="pull-left mt-1"
                                           name="priority-level"
                                           [(ngModel)]="check.priority"
                                           [value]="priority.value"/>
                                    <label class="pull-left pl-2 pr-2 inner-t-smurf font-weight-400">
                                        {{priority.name}}
                                    </label>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group mr-2">
                    <div class="row">
                        <label class="col-form-label col-sm-8" for="description" translate>Description*</label>
                        <div class="col-sm-40">
                            <div [ngxSummernote]="summerNoteConfig" [(ngModel)]="check.description" [required]="true" name='Description' id="summernote_description"></div>
                        </div>
                    </div>
                </div>

                <div class="form-group mr-2">
                    <div class="row">
                        <label class="col-form-label col-sm-8" for="how_to_mitigate" translate>How to Mitigate*</label>
                        <div class="col-sm-40">
                            <div [ngxSummernote]="summerNoteConfig" [(ngModel)]="check.how_to_mitigate" [required]="true" name='how_to_mitigate' id="summernote_how_to_mitigate"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-secondary" [disabled]="onSave || !canSave()" data-test="save-button">
            <span [hidden]="onSave" translate>Save</span>
            <mon-icon [hidden]="!onSave" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
        </button>
    </div>
</form>
