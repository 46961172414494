import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Customer } from '@monsido/modules/models/api/customer';
import { Domain } from '@monsido/modules/models/api/domain';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { SessionService } from '@monsido/core/session/session.service';
import { StateService } from '@uirouter/core';

@Injectable()
export class SearchService {
    constructor (
        private customerRepo: BackendCustomerRepo,
        private domainRepo: BackendDomainsRepo,
        private sessionService: SessionService,
        private state: StateService,
    ) {}

    searchDomain (searchString: string, url: string): Promise<Domain[]> {
        return this.domainRepo.getDomains(
            { search: searchString },
            new HttpHeaders({ noGlobal: 'true', noParseSearch: 'true' }),
            url + '/admin/api/',
        );
    }

    searchCustomer (searchString: string, url: string): Promise<Customer[]> {
        return this.customerRepo.getAll(
            { search: searchString },
            new HttpHeaders({ noGlobal: 'true', noParseSearch: 'true' }),
            url + '/admin/api/',
        );
    }

    selectEnv (url: string): Promise<void[] | void> {
        return this.sessionService.setSelectedApi(url);
    }

    async redirectToCustomer (customer: Customer, url: string, envName: string): Promise<void> {
        await this.selectEnv(url);
        await this.state.reload();
        this.state.go('base.backend_admin.customers.show', {
            customer_id: customer.id,
            env: envName,
        });
    }

    async redirectToDomain (domain: Domain, url: string, envName: string): Promise<void> {
        await this.selectEnv(url);
        await this.state.reload();
        this.state.go('base.backend_admin.customers.info.domain', {
            customer_id: domain.customer.id,
            domain_id: domain.id,
            env: envName,
        });
    }
}
