<form name="importDictionaryForm" class="form-horizontal" #importDictionary="ngForm" (ngSubmit)='submitDictionary()'>
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['USERS', 'DICTIONARY']" header="{{'Import Dictionary' | translate }}"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <p class="h3 font-weight-300" translate>Link For Your Dictionary</p>
            </div>
            <div class="card-body">
                <mon-form-field-input monHttpPrefix
                                      [(ngModel)]="csv_url"
                                      monLabel="{{ 'Link For Your Dictionary' | translate }}"
                                      monPlaceholder="{{ 'Link For Your Dictionary' | translate }}"
                                      [type]="'text'"
                                      #link_dictionary="ngModel"
                                      required
                                      [name]="'link_for_your_dictionary'">
                </mon-form-field-input>
                <mon-form-field-errors
                    [showError]="link_dictionary.control.touched && link_dictionary.control.invalid"
                    [errors]="link_dictionary.control.errors">
                </mon-form-field-errors>

                <mon-form-field-select [monName]="'language'"
                                       [(model)]="selectedLanguage"
                                       monLabel="{{ 'language' | translate }}"
                                       monPlaceholder="{{ 'language' | translate }}"
                                       [monOptions]="supportedLanguages"
                                       [monReturnValue]="'code'"
                                       [monAllowClear]="true">
                </mon-form-field-select>
            </div>
        </div>

        <div class="mon-dialog-footer text-right">
            <button type="submit"
                    [disabled]="importDictionary.invalid"
                    class="btn btn-secondary">
                <span translate>Submit</span>
            </button>
        </div>
    </div>
</form>
