import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { DataPrivacyChecksComponent } from './data-privacy-checks.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const DATA_PRIVACY_CHECKS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.admin.data_privacy_checks',
        url: '/data_privacy_checks',
        abstract: true,
        component: BaseStateComponent,
    },
    {
        name: 'base.backend_admin.admin.data_privacy_checks.index',
        url: '/?search&approved',
        params: {
            search: RouteParamsConstant.search,
            approved: {
                value: 'true',
                dynamic: true,
            },
        },
        views: {
            '^.^.^.^.$default': {
                component: DataPrivacyChecksComponent,
            },
        },
        dynamic: true,
    },
];
