<form name="form" class="form-horizontal" (ngSubmit)="submit()">
    <div class="mon-dialog-header">
        <mon-page-header header="{{'Export cookies' | translate }}">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Params
                </h3>
            </div>
            <div class="card-body">
                <mon-form-field-input [(ngModel)]="params.platform"
                                      monLabel="{{ 'Platform' | translate }}"
                                      monPlaceholder="{{ 'Platform' | translate }}"
                                      [type]="'text'"
                                      [name]="'platform'">
                </mon-form-field-input>
                
                <mon-form-field-select
                    monName="type"
                    [(model)]="params.type"
                    monLabel="{{ 'Type' | translate}}"
                    [monAllowClear]="true"
                    monPlaceholder="{{ 'Select type' | translate }}"
                    [monOptions]="typeDropdownOptions"
                    [monReturnValue]="'value'">
                </mon-form-field-select>
                
                <mon-form-field-select
                    monName="language"
                    [(model)]="params.language"
                    monLabel="{{ 'Language' | translate}}"
                    [monAllowClear]="true"
                    monPlaceholder="{{ 'Select language' | translate }}"
                    [monOptions]="typeDropdownLanguages"
                    [monReturnValue]="'value'">
                </mon-form-field-select>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-default" [disabled]="exporting" type="submit">
            <span [hidden]="exporting" translate>Export</span>
            <mon-icon [hidden]="!exporting" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
        </button>
    </div>
</form>
