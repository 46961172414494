import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ToastService, ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { Customer } from '@monsido/modules/models/api/customer';
import { Domain } from '@monsido/modules/models/api/domain';
import { PageAssistSettings, PageAssistSettingsNavigation } from 'types/domain';
import { merge } from 'lodash';
import { PageAssistService } from './page-assist.service';
import {
    FormPageAssistCustomNavigationCreateOutput,
    FormPageAssistCustomNavigationEditOutput,
    FormPageAssistCustomNavigationDeleteOutput,
} from './custom-navigation/custom-navigation.component';
import { BaseForm } from '@monsido/forms/base-form';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-page-assist',
    templateUrl: 'page-assist.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    providers: [PageAssistService],
})
export class FormBackendAdminPageAssistComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() domain: Domain = new Domain();
    @Input() saveToAPI: boolean;
    @Input() customer: Customer;
    @ViewChild('domainToolbarSettingsForm', { static: false })
        domainToolbarSettingsForm: NgForm;

    form: NgForm;
    model: PageAssistSettings;
    saving: boolean = false;
    isValid: boolean = true;

    get navigations (): PageAssistSettingsNavigation[] {
        return this.getNavigations();
    }

    constructor (
        private activeDialog: ActiveDialog,
        private backendDomainsRepo: BackendDomainsRepo,
        private toastService: ToastService,
        private pageAssistService: PageAssistService,
        private translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit (): void {
        this.saving = false;
        this.saveToAPI = Boolean(this.saveToAPI || false);
        this.model = merge({}, this.domain.page_assist_settings);
        this.setupPageAssistSettings();
    }

    ngAfterViewInit (): void {
        if (this.domainToolbarSettingsForm) {
            setTimeout(() => this.resetFormState(this.domainToolbarSettingsForm));
            this.activeDialog.setForm(this.domainToolbarSettingsForm);
        }
    }

    getNavigations (): PageAssistSettingsNavigation[] {
        return this.pageAssistService.navigations;
    }

    removeNavigation (navigationIndex: number): void {
        this.pageAssistService.removeNavigation(navigationIndex);
        this.setDirtyStatus();
    }

    addNavigation (): void {
        this.pageAssistService.addNavigation();
        this.setDirtyStatus();
    }

    addElementToHide (): void {
        this.model.elements_to_hide.push('');
        this.setDirtyStatus();
    }

    removeElementToHide (index: number): void {
        this.model.elements_to_hide.splice(index, 1);
        this.setDirtyStatus();
    }

    addSelector (navigationIndex: number): void {
        this.pageAssistService.addNavigationSelector(navigationIndex);
        this.setDirtyStatus();
    }

    removeSelector (navigationIndex: number, selectorIndex: number): void {
        this.pageAssistService.removeNavigationSelector(navigationIndex, selectorIndex);
        this.setDirtyStatus();
    }

    // Custom Navigation START

    onCreateCustomNavigation (payload: FormPageAssistCustomNavigationCreateOutput, navigationIndex: number): void {
        const { customNavigation } = payload;
        this.pageAssistService.addCustomNavigation(navigationIndex, customNavigation);
        this.setDirtyStatus();
    }

    onEditCustomNavigation (payload: FormPageAssistCustomNavigationEditOutput, navigationIndex: number): void {
        const { index, customNavigation } = payload;
        this.pageAssistService.updateCustomNavigation(navigationIndex, index, customNavigation);
        this.setDirtyStatus();
    }

    onDeleteCustomNavigation (payload: FormPageAssistCustomNavigationDeleteOutput, navigationIndex: number): void {
        const { index } = payload;
        this.pageAssistService.removeCustomNavigation(navigationIndex, index);
        this.setDirtyStatus();
    }

    // Custom Navigation END

    exportJSON (): void {
        const uri = encodeURIComponent(JSON.stringify(this.setupSettings(merge({}, this.model))));
        const link = document.createElement('a');
        link.setAttribute('href', 'data:text/json;charset=UTF-8,' + uri);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', this.domain.url + '_pa_script.json');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    importJSON (event: Event): void {
        const reader = new FileReader();
        const files = (event.target as EventTarget & { files: FileList }).files;

        reader.onload = (): void => {
            this.model = JSON.parse(reader.result as string);
            this.setupPageAssistSettings();
        };
        reader.readAsText(files[0]);
    }

    // PROTECTED

    close (model: PageAssistSettings): void {
        this.domainToolbarSettingsForm.control.markAsPristine();
        this.activeDialog.close(model);
    }

    private setDirtyStatus (): void {
        const modelCopy = merge({}, this.model);
        modelCopy.navigation = this.pageAssistService.navigations;

        if (JSON.stringify(modelCopy) === JSON.stringify(this.domain.page_assist_settings)) {
            this.domainToolbarSettingsForm.control.markAsPristine();
        } else {
            this.domainToolbarSettingsForm.control.markAsDirty();
        }
    }

    private setupSettings (model: PageAssistSettings): PageAssistSettings {
        model.main = '';
        model.elements_to_hide = model.elements_to_hide.filter(Boolean);
        model.navigation = model.navigation
            .map((navigation) => {
                navigation.selectors = navigation.selectors.filter(Boolean);
                return navigation;
            })
            .filter((navigation) => {
                return (
                    typeof navigation.title === 'string' &&
                    navigation.title.length > 0 &&
                    (navigation.selectors.length > 0 || navigation.custom_navigation.length > 0)
                );
            });
        return model;
    }

    private setupPageAssistSettings (): void {
        if (!this.model.elements_to_hide || this.model.elements_to_hide.length === 0) {
            this.model.elements_to_hide = [''];
        }

        if (!this.model.navigation || this.model.navigation.length === 0) {
            this.model.navigation = [
                {
                    title: '',
                    mainselector: '',
                    selectors: [''],
                    custom_navigation: [],
                },
            ];
        }

        this.pageAssistService.navigations = this.model.navigation;
    }

    identify (index: number): string {
        return `id-${index}`;
    }

    submit (): void {
        if (this.saveToAPI) {
            this.backendDomainsRepo
                .update(
                    this.customer.id,
                    this.domain.id,
                    {
                        id: this.domain.id,
                        page_assist_settings: this.model,
                    })
                .then(
                    () => {
                        this.toastService.success(this.translateService.getString(' PageAssist Settings Updated'));
                        const pageAssistSettings: PageAssistSettings = merge({}, this.model);
                        this.resetFormState(this.domainToolbarSettingsForm);
                        this.close(pageAssistSettings);
                    },
                    () => {},
                )
                .finally(() => {
                    this.saving = false;
                });
        } else {
            this.close(this.model);
        }
    }
}
