import { OnInit, Component, Input } from '@angular/core';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';
import { PlainHttpParams } from '@monsido/http/params';
import { ToastService, ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BackendSpellingToolRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-spelling-tool-repo';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-import-confirmed-spelling-errors',
    templateUrl: './import-confirmed-spelling-errors.html',
})
export class FormImportConfirmedSpellingErrorsComponent implements OnInit {
    @Input() monCustomerId: number;

    csvUrl = '';
    selectedLanguage = '';
    supportedLanguages: LanguageInterface[] = [];

    constructor (
        private spellingToolRepo: BackendSpellingToolRepo,
        private backendCustomerRepo: BackendCustomerRepo,
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
    ) {}

    async ngOnInit (): Promise<void> {
        try {
            this.supportedLanguages = await this.spellingToolRepo.getAll();
        } catch (e) {
            this.supportedLanguages = [];
        }
    }

    async submitConfirmedSpellingErrors (): Promise<void> {
        const confirmedSpellingErrors: PlainHttpParams = {
            csv_url: this.csvUrl,
        };

        if (this.selectedLanguage) {
            confirmedSpellingErrors.language = this.selectedLanguage;
        }

        const job = await this.backendCustomerRepo.importSpellingErrors(this.monCustomerId, confirmedSpellingErrors);

        this.toastService.info(this.translateService.getString('Confirmed Spelling Errors Job is put in queue'));
        this.close(job);
    }

    setCsvUrl (evt: Event): void {
        const target = evt.target as HTMLInputElement;
        this.csvUrl = target?.value;
    }

    close (job: JobInterface): void {
        this.activeDialog.close(job);
    }
}
