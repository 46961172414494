<mon-page-header [monIcon]="['PARTNERS', 'ICON']" header="{{'Partner:' | translate}} {{ reseller?.name }}"></mon-page-header>

<mon-tabs [status]="activeTab"
              [options]="tabs"
              [showSearch]="false"
              (onStatus)="onTabChange($event)">
</mon-tabs>

<mon-panel [showHeader]="false">
    <div class="card-body" slot="mon-panel-body">
        <div class="row">
            <div class="col-xs-48">
                <mon-backend-admin-reseller-user-tab *ngIf="activeTab === 'users'" [reseller]="reseller"></mon-backend-admin-reseller-user-tab>
                <mon-reseller-domains-tab *ngIf="activeTab === 'domains'" [reseller]="reseller"></mon-reseller-domains-tab>
                <mon-partner-customers *ngIf="activeTab === 'customers'" [reseller]="reseller"></mon-partner-customers>
            </div>
        </div>
    </div>
</mon-panel>
