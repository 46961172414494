import { Component, EventEmitter, Output } from '@angular/core';
import { Papa, ParseResult } from 'ngx-papaparse';
import { ImportCookiesService } from '@monsido/pages/backend-admin/cookies/repo/forms/import/import-cookies.service';

@Component({
    selector: 'mon-form-cookies-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.less'],
})
export class FormCookiesUploadComponent {
    @Output() nextFile: EventEmitter<ParseResult> = new EventEmitter<ParseResult>();

    constructor (private papa: Papa, private importCookiesService: ImportCookiesService) {}

    newBulkFile (event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        const file: File = element.files[0];

        if (file) {
            this.papa.parse(file, {
                complete: (result) => {
                    this.nextFile.emit(result);
                    setTimeout(() => {
                        this.importCookiesService.setStep(2);
                    });
                },
            });
        }
    }
}
