import { Component, OnInit, Input } from '@angular/core';
import { FormLoginType } from 'types/domain';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'mon-form-domain-scan-login-types-form',
    templateUrl: 'form.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainScanLoginTypesFormComponent implements OnInit {
    @Input() monLogin: FormLoginType;
    @Input() monForm: NgForm;
    @Input() monRequestChecker: boolean = false;

    // eslint-disable-next-line no-useless-escape
    verifyLocationPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    ngOnInit (): void {
        this.monLogin = this.monLogin || { type: 'form' };
        if (this.monLogin.form === null || typeof this.monLogin.form !== 'object') {
            this.monLogin.form = {};
        }

        if (!Array.isArray(this.monLogin.form.fields) || this.monLogin.form.fields.length === 0) {
            this.monLogin.form.fields = [
                {
                    selector: '',
                    value: '',
                },
            ];
        }
        if (!this.monLogin.verify || !this.monLogin.verify.location) {
            this.monLogin.verify = {
                location: '',
            };
        }
    }

    addFormField (): void {
        this.monLogin.form.fields.push({
            selector: '',
            value: '',
        });
    }

    removeFormField (index: number): void {
        this.monLogin.form.fields.splice(index, 1);
    }
}
