import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    DialogService,
    LayoutModule,
    TableModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { ParamService } from '@monsido/core/param/param.service';
import { MonIconsService } from '../../../blocks/service/mon-icons.service';
import { Customer } from '@monsido/modules/models/api/customer';
import { CustomerRepo, GetAllCustomersParams } from 'app/services/api/admin/customer-repo';
import { FormCustomerComponent } from 'app/forms/customer/form-customer/form-customer.component';
import { TableCustomersComponent } from './table-customers/table-customers.component';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-customers',
    templateUrl: 'customers.html',
    standalone: true,
    imports: [LayoutModule, TranslateModule, TableModule, TableCustomersComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomersComponent extends BaseApiComponent implements OnInit {
    customers: Customer[] = [];

    tabs: Record<string, string>[] = [];
    activeTab = 'all';
    constructor (
        urlService: UrlService,
        private paramService: ParamService,
        private customerRepo: CustomerRepo,
        private monIconsService: MonIconsService,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private cdRef: ChangeDetectorRef,
    ) {
        super(urlService);
        this.tabs = [
            {
                name: this.translateService.getString('All'),
                faIcon: this.monIconsService.getIcon(['CUSTOMERS', 'ICON']),
                value: 'all',
            },
            {
                name: this.translateService.getString('Customers'),
                faIcon: this.monIconsService.getIcon(['CUSTOMERS', 'ICON']),
                value: 'paying',
            },
            {
                name: this.translateService.getString('Demo'),
                faIcon: this.monIconsService.getIcon(['CUSTOMERS', 'ICON']),
                value: 'demo',
            },
        ];
    }

    ngOnInit (): void {
        this.urlParams.push(
            {
                name: 'plan_type',
                type: 'string',
                default: 'all',
                propertyName: 'activeTab',
            },
        );
        this.loadLocations();
    }

    async getPage (): Promise<void> {
        this.paramService.setParams({
            search: this.search,
            page_size: this.pageSize,
            page: this.page,
            plan_type: this.activeTab,
        });
        await this.getCustomers();
    }

    async getCustomers (): Promise<void> {
        this.loading = true;
        const params: GetAllCustomersParams = {
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        };

        if (this.activeTab && this.activeTab !== 'all') {
            params.plan_type = this.activeTab;
        }

        try {
            this.customers = await this.customerRepo.getAll(params, { noParseSearch: 'true' });
        } finally {
            this.loading = false;
            this.cdRef.markForCheck();
        }
    }

    createCustomer (): void {
        this.dialogService.open(FormCustomerComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => {
                this.getPage();
            },
        });
    }
}
