export const environment = {
    env: 'staging',
    production: true,
    rollBarId: '6cc3200ad9594700b33b11d945d02914',
    supportPath: 'https://support.staging.monsido.com/',
    apiEnvs: [
        {
            name: 'ST1',
            url: 'https://staging.monsido.com',
        },
    ],
    oauthClientId: '60436bc95ea277061266028b5d864b987a5a5748c5d04aea524d935c7e0469fa',
    oauthClientSecret: '3b27fe3028ecea37e6d21633f32d8b01eb4ad7a369710713f7d1868806955d3d',
    monsidoPath: 'https://login.staging.monsido.com/',
    oauthScopes: 'reseller admin reseller_admin super_admin',
    monsidoResellers: [
        {
            name: 'Monsido1',
            id: 1,
        },
    ],
};
