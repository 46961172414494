<mon-page-header [monIcon]="['USERS', 'ICON']" header="{{'Users' | translate}}">
</mon-page-header>
<mon-panel
        [minSearchLength]="1"
        [search]="search"
        [showHeader]="false"
        (onSearch)="onSearch($event)"
        [showSearch]="true" >
    <div class="card-body" slot="mon-panel-body">
        <mon-table-container
                [collection]="users"
                (pageChangeEvent)="onPageChange($event)"
                (perPageChange)="onPageSizeChange($event)"
                class="table-vertial-align"
                [loading]="loading">
            <div class="table-container-top">
                <button class="btn btn-secondary"
                        [disabled]="selectedUsers.length === 0"
                        monConfirm="{{'Deleting users will remove the user from both it\'s corresponding reseller and customer' | translate}}"
                        (monConfirmAction)="selectedUsers.length > 0 && deleteUsers()"
                        translate>
                    Delete selected users
                </button>
            </div>
            <div class="table-container-body">
                <mon-backend-admin-users-table
                                        [users]="users"
                                        (getPage)="getPage()"
                                        [(selectedUsers)]="selectedUsers">
                </mon-backend-admin-users-table>
            </div>
        </mon-table-container>
    </div>
</mon-panel>
