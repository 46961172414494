import { NgModule } from '@angular/core';
import { BackendAdminCrawlersModule } from '@monsido/pages/backend-admin/crawlers/crawler.module';
import { BackendAdminCustomersModule } from '@monsido/pages/backend-admin/customers/customers.module';
import { BackendAdminDomainsModule } from '@monsido/pages/backend-admin/domains/domains.module';
import { CookiesModule } from '@monsido/pages/backend-admin/cookies/cookies.module';
import { DataPrivacyChecksModule } from './data-privacy-checks/data-privacy-checks.module';
import { PlansModule } from './plans/plans.module';
import { SupportModule } from '@monsido/pages/backend-admin/support/support.module';
import { WorkerQueueModule } from '@monsido/pages/backend-admin/worker-queue/worker-queue.module';
import { FormBackendAdminCustomerDetailComponent } from '@monsido/forms/backend_admin/customer/steps/detail/detail.component';
import { FormBackendAdminCustomerUserDomainsComponent } from '@monsido/forms/backend_admin/customer-user/domains/domains.component';
import { HebReportModule } from './heb-report/heb-report.module';

@NgModule({
    imports: [
        BackendAdminCrawlersModule,
        BackendAdminCustomersModule,
        BackendAdminDomainsModule,
        CookiesModule,
        DataPrivacyChecksModule,
        PlansModule,
        SupportModule,
        WorkerQueueModule,
        HebReportModule,
        FormBackendAdminCustomerUserDomainsComponent,
        FormBackendAdminCustomerDetailComponent,
    ],
    declarations: [],
    exports: [
        FormBackendAdminCustomerUserDomainsComponent,
        FormBackendAdminCustomerDetailComponent,
    ],
    providers: [],
})
export class BackendAdminModule {}
