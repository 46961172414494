import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {
    constructor () {}

    get (key: string): unknown {
        return JSON.parse(window.sessionStorage.getItem(key));
    }

    set (key: string, data: string | Record<string, unknown>): void {
        window.sessionStorage.setItem(key, JSON.stringify(data));
    }

    remove (key: string): void {
        window.sessionStorage.removeItem(key);
    }
}
