<form name="setup">
    <table class="table table-hover">
        <thead>
            <tr>
                <th>{{ 'First name' | translate }}</th>
                <th>{{ 'Last name' | translate }}</th>
                <th>{{ 'Email' | translate }}</th>
                <th>{{ 'Password' | translate }}</th>
                <th>{{ 'Is admin' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let user of data?.data; let $index = index">
            <tr *ngIf="$index">
                <td>
                    {{ user[selected.firstNameIndex]}}
                </td>
                <td>
                    {{ user[selected.lastNameIndex]}}
                </td>
                <td>
                    {{ user[selected.emailIndex]}}
                </td>
                <td>
                    <span *ngIf="user[selected.passwordIndex]">
                        {{ user[selected.passwordIndex] }}
                    </span>
                    <span *ngIf="!user[selected.passwordIndex]">
                        {{ 'Auto generated' | translate }}
                    </span>
                </td>
                <td>
                    <span *ngIf="user[selected.isAdminIndex] === 'Yes'">
                        <span class="fal fa-check"></span>
                    </span>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</form>
