import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { BackendAdminCrawlersComponent } from '@monsido/pages/backend-admin/crawlers/crawler.component';

export const CRAWLERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.crawlers.index',
        url: '',
        views: {
            '^.^.^.$default': {
                component: BackendAdminCrawlersComponent,
            },
        },
        dynamic: true,
    },
    {
        name: 'base.backend_admin.crawlers',
        url: '/crawlers',
        abstract: true,
        component: BaseStateComponent,
    },
];
