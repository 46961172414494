import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionService } from '@monsido/core/session/session.service';
import { Me } from '@monsido/modules/models/api/me';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';
import { AvatarModule, DialogService, IconsModule, MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { GENERAL } from '@monsido/core/constants/general.constant';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormProfileComponent } from 'app/forms/profile/profile.component';
import { UIRouterModule } from '@uirouter/angular';
import { BaseDisposableMenuComponent } from '../base-disposable-menu/base-disposable-menu.component';
import { takeUntil } from 'rxjs';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-menu',
    standalone: true,
    imports: [
        CommonModule,
        IconsModule,
        TranslateModule,
        NgbTooltipModule,
        UIRouterModule,
        AvatarModule,
    ],
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends BaseDisposableMenuComponent implements OnInit {
    showResellerAdminSettings = false;
    showBackendAdminSettings = false;
    user: Me = null;

    constructor (
        private sessionService: SessionService,
        private monEventService: MonEventService,
        private uiRouterGlobals: UIRouterGlobals,
        private transitionService: TransitionService,
        private translateService: TranslateService,
        private monPromptService: MonPromptService,
        private dialogService: DialogService,
        private cdRef: ChangeDetectorRef,
    ) {
        super();
    }

    async ngOnInit (): Promise<void> {

        this.monEventService.addListener(
            'setAgreement',
            () => this.setAdminSettingsDisplayStatus(),
        );

        this.monEventService.addListener(
            'clearAgreement',
            () => this.setAdminSettingsDisplayStatus(),
        );

        this.transitionService.onSuccess({}, transition => {
            const params = transition.params('to');
            if (params) {
                this.setAdminSettingsDisplayStatus(params.env, params.accountId);
            }
        });

        await this.reloadUser();
        this.setAdminSettingsDisplayStatus();
    }

    setAdminSettingsDisplayStatus (env: string = null, accountId: string | number = null): void {
        env = env ?? this.uiRouterGlobals.params.env;
        accountId = accountId ?? this.uiRouterGlobals.params.accountId;

        const isResellerPath = Boolean(env && accountId);

        this.sessionService.superAdmin$
            .pipe(takeUntil(this.destroy$))
            .subscribe((flag) => {
                this.showBackendAdminSettings = Boolean(env && !accountId && flag);
            });

        this.showResellerAdminSettings = isResellerPath && this.sessionService.isResellerAdmin();
        this.cdRef.markForCheck();
    }

    async signOut (event: Event): Promise<void> {
        event.preventDefault();
        event.stopImmediatePropagation();
        await this.sessionService.signOut();
    }

    showProfile (): void {
        this.dialogService.open(FormProfileComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb: () => {
                this.reloadUser();
            },
        });
    }

    async openVersion (event: Event): Promise<void> {
        event.preventDefault();
        event.stopImmediatePropagation();

        const options = {
            title: this.translateService.getString('About Monsido Partner Platform'),
        };

        const message =
            this.translateService.getString('Version [[version]]', { version: GENERAL.version }) +
            '\n\n' +
            this.translateService.getString('Copyright 2020 Monsido. All rights reserved.');

        await this.monPromptService.alert(message, options);
    }

    async reloadUser (): Promise<void> {
        this.user = await this.sessionService.loadUser();
        this.cdRef.markForCheck();
    }
}
