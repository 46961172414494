import { Injectable } from '@angular/core';
import { TRANSLATIONS } from '@monsido/core/constants/translations.constant';
import { LanguageType } from '@monsido_services/services/mon-translate/dist/translate.interface';
import { TranslateService } from 'app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class TranslationHelper {
    constructor (private translateService: TranslateService) {}

    pickLanguage (language: string): void {
        const trans: LanguageType = (TRANSLATIONS[language] || this.translateService.baseLanguage) as LanguageType;
        this.translateService.setCurrentLanguage(trans);
    }
}
