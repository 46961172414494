<div class="sidebar-nav-wrapper">
    <ul class="sidebar-nav">
        <li *ngIf="agreement" class="separator"><h4 class="text-eclipse">{{ agreement.account.name }}</h4></li>
        <ng-container *ngFor="let entry of menu; let i = index">
            <li [ngClass]="{'has-sub': entry.subs, 'open': entry.open}" *ngIf="entry.basic === true">
                <a [ngClass]="{'active': isSubentryActive(entry.base)}" uiSref="{{entry.href}}" *ngIf="!entry.subs">
                    <i class="fa-fw" [ngClass]="entry.icon"></i> {{ entry.title }}
                </a>
                <a [ngClass]="{'active': isSubentryActive(entry.base)}" (click)="menuClick(i)" *ngIf="entry.subs">
                    <i class="fa-fw" [ngClass]="entry.icon"></i> {{ entry.title }}
                </a>
                <ul class="sub" *ngIf="entry.subs && entry.open">
                    <li *ngFor="let sub of entry.subs">
                        <a uiSref="{{sub.href}}" uiSrefActive="active">
                            <i class="fa-fw" [ngClass]="sub.icon"></i> {{ sub.title }}
                        </a>
                    </li>
                </ul>
            </li>
        </ng-container>
        <li class="separator" *ngIf="showBackend"><h4>Tools</h4></li>
        <ng-container *ngFor="let entry of menu; let i = index">
            <li [ngClass]="{'has-sub': entry.subs, 'open': entry.open}" *ngIf="showBackend && entry.tools == true">
                <a [ngClass]="{'active': isSubentryActive(entry.base)}" uiSref="{{entry.href}}" *ngIf="!entry.subs">
                    <i class="fa-fw" [ngClass]="entry.icon"></i> {{ entry.title }}
                </a>
                <a [ngClass]="{'active': isSubentryActive(entry.base)}" (click)="menuClick(i)" *ngIf="entry.subs">
                    <i class="fa-fw" [ngClass]="entry.icon"></i> {{ entry.title }}
                </a>
                <ul class="sub" *ngIf="entry.subs && entry.open">
                    <li *ngFor="let sub of entry.subs">
                        <a uiSref="{{sub.href}}" uiSrefActive="active" >
                            <i class="fa-fw" [ngClass]="sub.icon"></i> {{ sub.title }}
                        </a>
                    </li>
                </ul>
            </li>
        </ng-container> 
        <li class="separator" *ngIf="showBackend"><h4>Backend admin</h4></li>
        <ng-container *ngFor="let entry of menu; let i = index" >
            <li [ngClass]="{'has-sub': entry.subs, 'open': entry.open}" *ngIf="showBackend && entry.backend == true">
                <a [ngClass]="{'active': isSubentryActive(entry.base)}" uiSref="{{entry.href}}" *ngIf="!entry.subs">
                    <i class="fa-fw" [ngClass]="entry.icon"></i> {{ entry.title }}
                </a>
                <a [ngClass]="{'active': isSubentryActive(entry.base)}" (click)="menuClick(i)" *ngIf="entry.subs">
                    <i class="fa-fw" [ngClass]="entry.icon"></i> {{ entry.title }}
                </a>
                <ul class="sub" *ngIf="entry.subs && entry.open">
                    <li *ngFor="let sub of entry.subs">
                        <a uiSref="{{sub.href}}" uiSrefActive="active" >
                            <i class="fa-fw" [ngClass]="sub.icon"></i> {{ sub.title }}
                        </a>
                    </li>
                </ul>
            </li>
        </ng-container>
    </ul>
</div>
