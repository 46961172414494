import { Injectable } from '@angular/core';
import { SupportApiClient } from '../support-api-client';
import { HttpParams } from '@angular/common/http';
import { DataPrivacyCheckInterface } from '@monsido/modules/models/api/interfaces/data-privacy-check.interface';

@Injectable()
export class BackendDataPrivacyCheckRepo {
    constructor (private supportApiClient: SupportApiClient) {}

    getAll (params: HttpParams): Promise<DataPrivacyCheckInterface[]> {
        params = params || new HttpParams();
        return this.supportApiClient.getPromise('admin/info_types', { params });
    }

    updateCheck (check: DataPrivacyCheckInterface): Promise<void> {
        return this.supportApiClient.patchPromise(`admin/info_types/${check.id}`, check);
    }
}
