export const CUSTOMER_TABS = {
    users: {
        icon: 'fas fa-users',
        value: 'users',
        name: 'Users',
    },
    domains: {
        icon: 'fas fa-globe',
        value: 'domains',
        name: 'Domains',
    },
    defaultTab: {
        icon: 'fas fa-building',
        value: 'customer-info',
        name: 'Customer and subscription information',
    },
};

