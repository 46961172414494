import { Ng2StateDeclaration } from '@uirouter/angular';
import { RequestCheckerPageComponent } from '@monsido/pages/backend-admin/support/request-checker/request-checker.component';

export const REQEUST_CHECKER_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.support.request-checker',
        url: '/request-checker?url&enable_javascript&js_render_service&accessibility&source_code_excludes&login&link_excludes&additional_internal_urls&readability_test',
        views: {
            '^.^.^.$default': {
                component: RequestCheckerPageComponent,
            },
        },
        dynamic: true,
    },
];
