import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LoaderService } from '@monsido/core/loader/loader.service';
import { EndpointsModule } from '@monsido/modules/endpoints/endpoints.module';

import { OauthModule } from '@monsido/oauth/oauth.module';
import { CoreModule } from '@monsido/core/core.module';
import { PagesModule } from '@monsido/pages/pages.module';

export function initApp (appLoadService: LoaderService) {
    return (): Promise<void> => appLoadService.initializeApp();
}

@NgModule({
    imports: [EndpointsModule, OauthModule, CoreModule, PagesModule],
    exports: [],
    declarations: [],
    providers: [
        LoaderService,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [LoaderService],
            multi: true,
        },
    ],
})
export class LoaderModule {}
