import { NgModule } from '@angular/core';

import { ApiClient, apiClientFactory } from './api-client';

import { HTTP_INTERCEPTORS, HttpHandler } from '@angular/common/http';
import { ResponseInterceptor } from './interceptors/response-interceptor';

import { SearchInterceptor } from './interceptors/search-interceptor';
import { MonHttpClientModule } from '@monsido/http/http.module';
import { CustomerRepo } from '@monsido/modules/endpoints/api/admin/customer.repo';
import { UsersRepo } from '@monsido/modules/endpoints/api/admin/users.repo';
import { BackendCrawlerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-crawler.repo';
import { BackendAdminApiClient } from './backend-admin-api-client';
import { SupportApiClient } from './support-api-client';
import { CrawlQueueRepo } from '@monsido/modules/endpoints/api/admin/crawl-queue.repo';
import { ResellerAdminUsersRepo } from '@monsido/modules/endpoints/api/admin/reseller-admin-users.repo';
import { BackendPlansRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-plans.repo';
import { ErrorInterceptor } from '@monsido/modules/endpoints/api/interceptors/error-interceptor';
import { BackendHeatmapsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-heatmaps.repo';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { BackendCrawlQueueRepo } from './backend_admin/backend-crawl-queue.repo';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { MeRepo } from '@monsido/modules/endpoints/api/admin/me.repo';
import { AccessibilitySourceCodeExcludeRepo } from '@monsido/modules/endpoints/api/admin/accessibility/accessibility-source-code-exclude.repo';
import { SpellingToolRepo } from '@monsido/modules/endpoints/api/admin/spelling-tool.repo';
import { BackendUserRepo } from './backend_admin/backend-user-repo';
import { CookiesRepo } from '@monsido/modules/endpoints/api/support/cookies.repo';
import { BackendResellerScans } from '@monsido/modules/endpoints/api/backend_admin/backend-scans.repo';
import { AgreementsRepo } from '@monsido/modules/endpoints/api/admin/agreements.repo';
import { AccountRepo } from '@monsido/modules/endpoints/api/admin/account.repo';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { BackendHebReportRepo } from './backend_admin/backend-heb-report.repo';


@NgModule({
    imports: [MonHttpClientModule],
    exports: [],
    declarations: [],
    providers: [
        AccessibilitySourceCodeExcludeRepo,
        AgreementsRepo,
        AccountRepo,
        BackendCrawlerRepo,
        BackendCrawlQueueRepo,
        BackendHebReportRepo,
        BackendCustomerRepo,
        BackendDomainsRepo,
        BackendResellerScans,
        BackendHeatmapsRepo,
        BackendPlansRepo,
        BackendUserRepo,
        CookiesRepo,
        CrawlQueueRepo,
        CustomerRepo,
        MeRepo,
        ResellerAdminUsersRepo,
        SpellingToolRepo,
        UsersRepo,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SearchInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: ApiClient,
            useFactory: apiClientFactory,
            deps: [HttpHandler],
        },
        {
            provide: BackendAdminApiClient,
            useFactory: apiClientFactory,
            deps: [HttpHandler],
        },
        {
            provide: SupportApiClient,
            useFactory: apiClientFactory,
            deps: [HttpHandler],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
})
export class ApiModule {}
