import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormCsvImportComponent, CsvPropType } from '@monsido/modules/csv/components/import-form/import-form.component';
import { ImportCsvService } from '@monsido/modules/csv/services/import-csv.service';

@Component({
    selector: 'mon-csv-import-button',
    templateUrl: './import-button.component.html',
    styleUrls: ['./import-button.component.scss'],
})
export class CsvImportButtonComponent {
    @Input() monProp: CsvPropType;
    @Input() monHeader: string;
    @Input() monSelectLabel: string;
    @Output() closeChange: EventEmitter<unknown> = new EventEmitter<unknown>();

    constructor (private dialogService: DialogService, private importCsvService: ImportCsvService) {}

    importCsv (): void {
        const dialogRef = this.dialogService.open(FormCsvImportComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (data) => {
                this.importCsvService.resetSteps();
                this.closeChange.emit({ data, prop: this.monProp });
            },
        });

        dialogRef.componentInstance.monProp = this.monProp;
        dialogRef.componentInstance.monHeader = this.monHeader;
        dialogRef.componentInstance.monSelectLabel = this.monSelectLabel;
    }
}
