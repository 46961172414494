<mon-page-header [monIcon]="['USERS', 'ICON']"
                 header="{{'Our Users' | translate}}">
    <div class="text-right">
        <button class="btn btn-secondary mb-3" (click)="createUser($event)" type="button" translate>+ Add new user</button>
        <mon-table-search
                [minSearchLength]="3"
                placeholderTxt="{{'Search...' | translate}}"
                [model]="search"
                (getPage)="onSearch($event)"
                data-test="search-input">
        </mon-table-search>
    </div>
</mon-page-header>
<div class="card monsido-panel">
    <div class="card-body">
        <mon-table-container (pageChangeEvent)="onPageChange($event)"
                             (perPageChange)="onPageSizeChange($event)"
                             [collection]="users"
                             class="table-vertial-align"
                             [loading]="loading">
            <div class="table-container-body">
            <table class="table table-fixed table-hover">
                <thead>
                <tr>
                    <th class="col-sm-12 col-md-12 col-lg-10" translate>User</th>
                    <th class="col-sm-8 col-md-7 col-lg-4" translate>Latest login</th>
                    <th class="col-sm-4 col-md-4 col-lg-2" translate>Role</th>
                    <th class="text-right col-sm-5 col-md-5 col-lg-4" translate></th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>
                            <mon-email-avatar [user]="user"></mon-email-avatar>
                        </td>
                        <td>
                            <mon-user-date [twoLines]="true" [date]="user.last_login_at"></mon-user-date>
                        </td>
                        <td>
                            <span *ngIf="user.reseller_admin" translate>
                                Partner admin
                            </span>
                        </td>
                        <td class="text-right">
                            <mon-table-dropdown>
                                <li ngbDropdownItem role="menuitem">
                                    <a href role="button" (click)="editUser($event, user)" data-test="edit-button">
                                        <mon-icon [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                                        <span class="ml-1" translate>Edit</span>
                                    </a>
                                </li>
                                <li ngbDropdownItem role="menuitem">
                                    <a monConfirm="{{'Are you sure you want to delete this user?' | translate}}"
                                    (monConfirmAction)="deleteUser(user)"
                                    class="cursor-pointer"
                                    data-test="delete-button">
                                        <mon-icon [icon]="['ACTIONS', 'DELETE']"></mon-icon>
                                        <span class="ml-1" translate>Delete</span>
                                    </a>
                                </li>
                            </mon-table-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </mon-table-container>
    </div>
</div>
