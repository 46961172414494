import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'monUnderscoreless',
})
export class UnderscorelessPipe implements PipeTransform {
    transform (input: string): string {
        if (!input) {
            return '';
        }
        return input.replace(/_/g, ' ');
    }
}
