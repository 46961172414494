import { Ng2StateDeclaration } from '@uirouter/angular';

export const BACKEND_ADMIN_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin',
        url: '/{env}/backend_admin',
        abstract: true,
        data: { anonymous_access: false, sidebar: 'backend-admin', requiredRoles: ['admin'] },
    },
    {
        name: 'base.backend_admin.admin',
        url: '/admin',
        abstract: true,
        data: { anonymous_access: false, sidebar: 'backend-admin_settings', requiredRoles: ['admin'] },
    },

];
