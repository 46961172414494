import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { LANGUAGE_CONSTANT } from '@monsido/core/constants/languages.constant';
import { SessionService } from '@monsido/core/session/session.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { StringService } from '@monsido/tools/string.service';
import { UserForm } from '@monsido/forms/user/user-form';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';

@Component({
    selector: 'mon-form-user-basic',
    templateUrl: 'basic.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormUserBasicComponent implements OnInit {
    @Input() form: NgForm;

    private userValue: User;
    @Output() userChange = new EventEmitter();
    @Input()
    get user (): UserForm {
        return this.userValue;
    }

    set user (val: UserForm) {
        this.userValue = val;
        this.userChange.emit(this.userValue);
    }

    isAdmin: boolean = false;

    supportedLanguages: LanguageInterface[];

    constructor (private sessionService: SessionService) {}

    ngOnInit (): void {
        this.supportedLanguages = [LANGUAGE_CONSTANT.en, LANGUAGE_CONSTANT.da];
        this.user.locale = this.user.locale || LANGUAGE_CONSTANT.en.code;
        this.isAdmin = this.sessionService.hasRole('admin');
    }

    regexEscape (input: string): string {
        return StringService.escapeRegExp(input);
    }
}
