<form name="userForms" #userForm="ngForm" class="form-horizontal" (ngSubmit)="!saving && userForm.valid && submit()" >
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']"
                         header="{{'Edit User' | translate }}"
                         *ngIf="formUser.id">
        </mon-page-header>
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']"
                         header="{{'New User' | translate }}"
                         *ngIf="!formUser.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>User information</h3>
            </div>
            <div class="card-body">
                <mon-form-user-basic [user]="formUser" [form]="userForm"></mon-form-user-basic>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button type="submit" class="btn btn-secondary" [disabled]="userForm.invalid || saving" data-test="save-button">
            <span [hidden]="saving" translate>Save</span>
            <mon-icon [hidden]="!saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
        </button>
    </div>
</form>
