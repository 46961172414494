import { Injectable } from '@angular/core';
import { Me } from '@monsido/modules/models/api/me';
import { EnvInterface } from '@monsido/core/session/interface/env.interface';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { MeRepo } from '@monsido/modules/endpoints/api/admin/me.repo';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { EventsTypeEnum } from '../mon-event/events.type';

@Injectable()
export class EnvironmentService {

    constructor (
        private meRepo: MeRepo,
        private monEventService: MonEventService,
    ) { }

    async setupResellerEnvs (): Promise<void> {
        const apiEnvs: EnvInterface[] = [];
        let me: Me | void;
        let env: EnvInterface;
        const urls: string[] = [];
        for (let i = 0; i < environment.apiEnvs.length; i++) {
            env = environment.apiEnvs[i];
            me = await this.meRepo
                .get({ headers: new HttpHeaders({ noGlobal: 'true' }) }, env.url + '/reseller/api/')
                .catch(() => {});
            if (me) {
                apiEnvs.push(env);
                urls.push(env.url);
            }
        }
        this.monEventService.run(EventsTypeEnum.loadResellerAgreements, urls);
        const listenerId = this.monEventService.addListener(EventsTypeEnum.loadResellerAgreementsDone, () => {
            this.monEventService.run(EventsTypeEnum.setSessionAvailableResellerEnvs, apiEnvs);
            this.monEventService.remove(listenerId);
        });
    }

    async setupAdminEnvs (): Promise<void> {
        const apiEnvs: EnvInterface[] = [];
        let me: Me | void;
        let env: EnvInterface;

        for (let i = 0; i < environment.apiEnvs.length; i++) {
            env = environment.apiEnvs[i];
            me = await this.meRepo
                .get({ headers: new HttpHeaders({ noGlobal: 'true' }) }, env.url + '/admin/api/')
                .catch(() => {});
            if (me) {
                env.me = me;
                apiEnvs.push(env);
            }
        }
        this.monEventService.run(EventsTypeEnum.setSessionAvailableAdminEnvs, apiEnvs);
    }
}
