import { LinkExcludedInterface } from '../constraints-and-excludes.interface';

export class LinkExcludeModel implements LinkExcludedInterface {

    created_at: string = '';
    default_for_cms: string = '';
    id?: number;
    name: string = '';
    regex: string = '';
    updated_at: string = '';

    constructor (entry?: LinkExcludedInterface) {
        if (entry) {
            this.created_at = entry.created_at;
            this.default_for_cms = entry.default_for_cms;
            this.name = entry.name;
            this.regex = entry.regex;
            this.updated_at = entry.updated_at;

            if (entry.id != null) {
                this.id = entry.id;
            }
        }
    }

}
