import { Office365LoginType } from 'types/domain';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'mon-form-domain-scan-login-types-office365',
    templateUrl: 'office-365.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainScanLoginTypesOffice365Component implements OnInit {
    @Input() monLogin: Office365LoginType;
    @Input() monForm: NgForm;
    @Input() monRequestChecker: boolean = false;

    ngOnInit (): void {
        this.monLogin = this.monLogin || {
            type: 'office365',
        };
        if (this.monLogin.office365 === null || typeof this.monLogin.office365 !== 'object') {
            this.monLogin.office365 = {
                email: '',
                password: '',
                remember_me_step: false,
            };
        }
    }
}
