import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { ControlContainer, NgForm } from '@angular/forms';
import { uniq, map, indexOf, cloneDeep } from 'lodash';
import { CMS } from '@monsido/core/constants/cms.constant';
import { MON_CMS_OPTIONS } from '@monsido/core/constants/cms-options.constant';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';
import { ActiveRegionService } from '@monsido/services/active-region/active-region.service';
import { DomainService } from '@monsido/services/domain/domain.service';
import { AccessibilitySourceCodeExcludeRepo } from '@monsido/modules/endpoints/api/admin/accessibility/accessibility-source-code-exclude.repo';
import { AccessibilitySourceCodeExclude } from '@monsido/modules/models/api/interfaces/accessibility-source-code-exclude.interface';
import { SpellingToolRepo } from '@monsido/modules/endpoints/api/admin/spelling-tool.repo';
import { DefaultConstraintsAndExcludesService } from '@monsido/services/default-constraints-and-excludes-service/default-constraints-and-excludes.service';
import { NgxSelectOption } from 'ngx-select-ex';
import { TranslateService } from 'app/services/translate/translate.service';
import { Domain } from '@monsido/modules/models/api/domain';

interface CMSOption {
    value: string;
    name: string;
}

@Component({
    selector: 'mon-form-domain-detail',
    templateUrl: 'detail.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainDetailComponent implements OnInit {
    @Input() monDomain: Domain;
    @Input() monCustomer: CustomerInterface;
    @Input() monForm: NgForm;

    @ViewChild('domainDetailFieldsForm') public form: NgForm;

    customers: CustomerInterface[] = [];
    languages: LanguageInterface[] = [];
    sourceCodeExcludes: AccessibilitySourceCodeExclude[] = [];
    cmses = CMS;
    unknownSourceCodeExcludes: string[] = [];
    domainSourceCodeExcludes: string[] = [];
    loadingLanguages = true;
    ploneCmsOptions: CMSOption[] = [];
    squizCmsOptions: CMSOption[] = [];
    JoomlaCmsOptions: CMSOption[] = [];

    private initialCms?: string;

    constructor (
        private translateService: TranslateService,
        private activeRegionService: ActiveRegionService,
        private domainService: DomainService,
        private accessibilitySourceCodeExcludeRepo: AccessibilitySourceCodeExcludeRepo,
        private spellingToolRepo: SpellingToolRepo,
        private defaultConstraintsAndExcludesService: DefaultConstraintsAndExcludesService,
    ) {}

    async ngOnInit (): Promise<void> {
        // It is required to assign initialCms before setting up CMS options
        this.initialCms = this.monDomain.cms;
        this.domainSourceCodeExcludes = cloneDeep(this.monDomain.accessibility_source_code_excludes || []);
        this.setupLanguages();
        this.setupURL();
        this.setupSquizCmsOptions();
        this.setupJoomlaCmsOptions();
        this.setupPloneCmsOptions();
        this.setupCmsTemplateOption();

        await this.getAccessibilitySourceCodeExcludes();
        this.domainSourceCodeExcludes = cloneDeep(this.monDomain.accessibility_source_code_excludes || []);
    }

    setLanguage (): void {
        if (this.activeRegionService.isInRegion('AU')) {
            this.monDomain.language = 'en_AU';
        } else {
            this.monDomain.language = 'en_US';
        }
    }

    showCmsUrl (): boolean {
        return ['sitecore', 'omniupdate', 'publicera', 'sitefinity', 'custom', 'cascade', 'aem', 'acos'].indexOf(this.monDomain.cms) !== -1;
    }

    isSubdomainRequired (): boolean {
        return ['shopify', 'adobe', 'simsite'].indexOf(this.monDomain.cms) !== -1;
    }

    isKonform (): boolean {
        return this.monDomain.cms === 'konform';
    }

    showSubdomain (): boolean {
        return ['shopify', 'opencities', 'adobe', 'simsite'].indexOf(this.monDomain.cms) !== -1;
    }

    showEPIServerPath (): boolean {
        return ['episerver'].indexOf(this.monDomain.cms) !== -1;
    }

    showContentIDTag (): boolean {
        return ['episerver'].indexOf(this.monDomain.cms) !== -1;
    }

    showLanguageIDTag (): boolean {
        return ['episerver'].indexOf(this.monDomain.cms) !== -1;
    }

    showOptionalCmsUrl (): boolean {
        return ['drupal', 'kentico', 'episerver'].indexOf(this.monDomain.cms) !== -1;
    }

    isCmsTemplate (): boolean {
        return this.monDomain.cms === 'cms_template';
    }

    async getAccessibilitySourceCodeExcludes (): Promise<AccessibilitySourceCodeExclude[]> {
        if (Array.isArray(this.sourceCodeExcludes) && this.sourceCodeExcludes.length > 0) {
            return this.sourceCodeExcludes;
        }

        const params = {
            page_size: 0,
        };

        try {
            const excludes = await this.accessibilitySourceCodeExcludeRepo.getAll(params);
            const excludeIds = map(excludes, 'id');
            this.unknownSourceCodeExcludes = this.monDomain.accessibility_source_code_excludes.filter((sceId) => {
                return indexOf(excludeIds, sceId) === -1;
            });
            this.sourceCodeExcludes = excludes;
            return excludes;
        } catch (e) {
            return [];
        }
    }

    onCmsChange (options: NgxSelectOption[]): void {
        const cms = options[0]?.value as string;
        // We should not trigger on initial set up
        if (cms !== this.initialCms || (!cms && !this.initialCms)) {
            this.initialCms = undefined;
            this.defaultConstraintsAndExcludesService.updateCurrentCms(cms);
        }
    }

    onSCEChange (newVal: string[]): void {
        this.monDomain.accessibility_source_code_excludes = uniq(this.unknownSourceCodeExcludes.concat(map(newVal, 'value')));
    }

    showTemplatePath (): boolean {
        return ['umbraco'].indexOf(this.monDomain.cms) !== -1;
    }

    getTemplatePlaceholder (): string {
        if (['umbraco'].indexOf(this.monDomain.cms) !== -1) {
            return '/umbraco/umbraco.aspx?app=content&rightAction=editContent&id=<%= id %>#content';
        }
        return '';
    }

    getUrl (): 'base_url' | 'url' {
        if (['custom', 'episerver'].indexOf(this.monDomain.cms) !== -1) {
            return 'base_url';
        }
        return 'url';
    }

    setupURL (): void {
        if (this.domainService.hasExtraUrl(this.monDomain)) {
            this.monDomain.cms_extra.base_url = this.monDomain.cms_extra.url;
            this.monDomain.cms_extra.url = '';
        }
    }

    setupCmsTemplateOption (): Record<string, string>[] {
        const cmsTemplateObj = {
            name: 'CMS template',
            id: 'cms_template',
        };

        if (this.monDomain.cms === 'cms_template') {
            if (this.cmses.indexOf(cmsTemplateObj) === -1) {
                this.cmses.push(cmsTemplateObj);
                const cmsesWithTemplateOption = new Set(this.cmses.map(cmsOption => JSON.stringify(cmsOption)));

                return this.cmses = Array.from(cmsesWithTemplateOption).map(cmsOption => JSON.parse(cmsOption));
            }
        } else {
            if (this.cmses.some(option => option.id === 'cms_template')) {
                const cleanUpCmses = new Set(this.cmses.map(cmsOption => JSON.stringify(cmsOption)));
                this.cmses = Array.from(cleanUpCmses).map(cmsOption => JSON.parse(cmsOption));
                const cmsTemplateIndex = this.cmses.findIndex(option => option.id === cmsTemplateObj.id);
                this.cmses.splice(cmsTemplateIndex, 1);

                return this.cmses;
            }
        }
    }

    setupLanguages (): Promise<void> {
        const params = {
            'engines[]': this.monCustomer.plan_traits?.spell_check_engine_priority,
        };
        return this.spellingToolRepo.getAll(params).then((data) => {
            this.languages = data;
            this.loadingLanguages = false;
        });
    }

    showSpecialKey (): boolean {
        return 'special' === this.monDomain.cms;
    }

    isSquizCms (): boolean {
        this.setupDefaultSquizCmsOptions();
        return this.monDomain.cms === 'squiz';
    }

    setupSquizCmsOptions (): void {
        this.squizCmsOptions = [
            {
                value: MON_CMS_OPTIONS.ADMIN,
                name: this.translateService.getString('Admin'),
            },
            {
                value: MON_CMS_OPTIONS.EDITOR,
                name: this.translateService.getString('Editor'),
            },
        ];
    }

    isJoomlaCms (): boolean {
        return this.monDomain.cms === 'joomla';
    }

    setupJoomlaCmsOptions (): void {
        this.JoomlaCmsOptions = [
            {
                value: MON_CMS_OPTIONS.ORIGINAL,
                name: this.translateService.getString('Original'),
            },
            {
                value: MON_CMS_OPTIONS.ALTERNATIVE,
                name: this.translateService.getString('Alternative'),
            },
        ];
    }

    setupDefaultSquizCmsOptions (): void {
        if (this.monDomain.cms === 'squiz') {
            if (this.monDomain.cms_extra == null || this.monDomain.cms_extra.url_postfix == null) {
                this.monDomain.cms_extra = {
                    url_postfix: MON_CMS_OPTIONS.EDITOR,
                };
            }
        } else if (this.monDomain.cms !== 'squiz' && this.monDomain.cms_extra && this.monDomain.cms_extra.url_postfix) {
            delete this.monDomain.cms_extra.url_postfix;
        }
    }
    showPrefixPath (): boolean {
        return 'wordpress_multisite' === this.monDomain.cms;
    }

    isPloneCms (): boolean {
        this.setupDefaultPloneCmsOptions();
        return this.monDomain.cms === 'plone';
    }

    setupPloneCmsOptions (): void {
        this.ploneCmsOptions = [
            {
                value: MON_CMS_OPTIONS.STANDARD,
                name: this.translateService.getString('Standard'),
            },
            {
                value: MON_CMS_OPTIONS.ALTERNATIVE,
                name: this.translateService.getString('Alternative'),
            },
        ];
    }

    setupDefaultPloneCmsOptions (): void {
        if (this.monDomain.cms === 'plone') {
            if (this.monDomain.cms_extra == null || this.monDomain.cms_extra.version == null) {
                this.monDomain.cms_extra = {
                    version: MON_CMS_OPTIONS.STANDARD,
                };
            }
        } else if (this.monDomain.cms !== 'plone' && this.monDomain.cms_extra && this.monDomain.cms_extra.version) {
            delete this.monDomain.cms_extra.version;
        }
    }

    isAemCms (): boolean {
        return this.monDomain.cms === 'aem';
    }

    setUrl (evt: Event): void {
        const target = evt.target as HTMLInputElement;
        this.monDomain.url = target?.value;
    }

    setCmsExtraUrl (evt: Event): void {
        const target = evt.target as HTMLInputElement;
        this.monDomain.url = target?.value;
    }

    showCmsPath (): boolean {
        return ['craft'].indexOf(this.monDomain.cms) !== -1;
    }
}
