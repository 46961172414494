import { Injectable } from '@angular/core';
import { CrawlQueueRepo } from '@monsido/modules/endpoints/api/admin/crawl-queue.repo';
import { FormAdminCrawlDetailsComponent } from '@monsido/forms/reseller-admin/crawl-details/crawl-details.component';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { Domain } from '@monsido/modules/models/api/interfaces/crawl-queue.interface';
import { CrawlQueueCommonService } from 'app/common/services/crawl-queue-common/crawl-queue-common.service';
import { TransitionPromise } from '@uirouter/core';

@Injectable()
export class CrawlQueueService {
    search: string;
    crawls: CrawlQueue[] = [];
    timeout: ReturnType<typeof setTimeout>;

    constructor (private crawlQueueRepo: CrawlQueueRepo, private crawlQueueCommonService: CrawlQueueCommonService) {}

    goToDomain (domain: Domain): TransitionPromise {
        return this.crawlQueueCommonService.goToDomain(domain, 'base.admin.customers.info.domain');
    }

    getCrawls (crawls: CrawlQueue[], search: string): CrawlQueue[] {
        return this.crawlQueueCommonService.getCrawls(crawls, search);
    }

    destroyTimer (): void {
        clearTimeout(this.timeout);
    }

    getCrawlQueue (domainId: number): Promise<void> {
        return this.crawlQueueRepo.get(domainId);
    }

    getAllCrawlQueue (): Promise<CrawlQueue[]> {
        return this.crawlQueueRepo.getAll();
    }

    stopCrawl (domainId: number): Promise<unknown> {
        return this.crawlQueueRepo.stopCrawl(domainId);
    }

    rejectCrawl (domainId: number): Promise<void> {
        return this.crawlQueueRepo.stopCrawl(domainId);
    }

    releaseCrawl (domainId: number): Promise<void> {
        return this.crawlQueueRepo.stopCrawl(domainId);
    }

    openDialog (crawl: CrawlQueue, cb: (...args: unknown[]) => void): void {
        this.crawlQueueCommonService.openDialog(crawl, cb, {
            component: FormAdminCrawlDetailsComponent,
            classes: 'mon-dialog-size-sm',
        });
    }
}
