<mon-panel-header pHeader="{{'All partner domains' | translate}}"
                    subHeader="{{'This partner has ' | translate}} {{ collection.total }} {{ 'active domains right now' | translate}}">
    <mon-table-search (getPage)="onSearchChanged($event)" [model]="search" data-test="search-input"></mon-table-search>
</mon-panel-header>
<div class="col-lg-48 inner-l-between-small-mini">
    <div class="card-body">
        <div class="col-md-48 col-lg-48 col-xs-48">
            <mon-table-container
                    [collection]="collection"
                    (pageChangeEvent)="onPageChange($event)"
                    (perPageChange)="onPageSizeChange($event)"
                    class="table-vertial-align"
                    [loading]="loading">
                <div class="table-container-body">
                    <div class="table-container-top">
                        <button class="btn btn-secondary"
                                [disabled]="selectedDomains.length === 0"
                                monConfirm="{{'Are you sure you want to delete selected Domains?' | translate}}"
                                (monConfirmAction)="selectedDomains.length > 0 && deleteDomains()"
                                data-test="delete-selected-domains-button"
                                translate>
                            Delete selected domains
                        </button>
                    </div>
                    <mon-backend-admin-domains-table
                            [monSelectedDomains]="selectedDomains"
                            [monShowCustomer]="true"
                            [monDomains]="collection"
                            (monGetPagevent)="getPage()">
                    </mon-backend-admin-domains-table>
                    <hr class="line-thickness-4 outer-t-none"/>
                </div>
            </mon-table-container>
        </div>

    </div>
</div>
