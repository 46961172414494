<form name="internal_url">
    <div class="form-group" *ngFor="let internalUrl of monInternalUrls; let $index = index">
        <label class="col-form-label col-sm-8" for="internal_url_operator_{{$index}}" translate>Operator*</label>
        <div class="col-sm-16 pr-0">
            <mon-form-field-select
                    id="internal_url_operator_{{$index}}"
                    monName="internal_url_operator_{{$index}}"
                    [(model)]="internalUrl.operator"
                    [monOptions]="operators"
                    [monReturnValue]="'value'"
                    [monNameAttr]="'name'"
                    monPlaceholder="{{'Choose Operator' | translate}}"
                    [monAllowClear]="false"
                    [monRequired]="monInternalUrls.length > 1">
            </mon-form-field-select>
        </div>
        <label class="col-form-label col-sm-4" for="internal_url_value_{{$index}}" translate>Url*</label>
        <div class="col-sm-16">
            <mon-form-field-input
                [type]="'text'"
                id="internal_url_value_{{$index}}"
                name="internal_url_value_{{$index}}"
                [(ngModel)]="internalUrl.value"
                [required]="monInternalUrls.length > 1"
                [monPlaceholder]="''"
            ></mon-form-field-input>
        </div>
        <div class="col-sm-4 pr-6 text-right">
            <button type="button"
                    class="btn btn-secondary"
                    (click)="removeUrl($index)">
                <mon-icon [icon]="['ACTIONS', 'REMOVE']"></mon-icon>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-40 col-sm-offset-8">
            <button type="button" class="btn btn-secondary" (click)="addUrl()">
                <span class="fas fa-plus text-link"></span>
            </button>
        </div>
    </div>
</form>
