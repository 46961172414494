import { DomainBaseDataType } from 'types/domain';

export abstract class DomainBaseModel implements DomainBaseDataType {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    rules: unknown[];
    visible?: boolean = false;
    sendReport?: boolean = false;
    domainType: 'Domain' | 'DomainGroup';

    constructor (properties: DomainBaseDataType) {
        if (properties) {
            this.id = properties.id;
            this.title = properties.title;
            this.rules = properties.rules;
            this.created_at = properties.created_at;
            this.updated_at = properties.updated_at;
            if (properties.visible !== undefined) {
                this.visible = properties.visible;
            }
            if (properties.sendReport !== undefined) {
                this.sendReport = properties.sendReport;
            }
        }
    }

    get toggleVisible (): (val?: boolean) => void {
        return (val?: boolean): void => {
            if (typeof val === 'boolean') {
                this.visible = val;
            } else {
                this.visible = !this.visible;
            }
        };
    }

    get toggleSendReport (): (val?: boolean) => void {
        return (val?: boolean): void => {
            if (this.visible) {
                if (typeof val === 'boolean') {
                    this.sendReport = val;
                } else {
                    this.sendReport = !this.sendReport;
                }
            }
        };
    }

    get isVisible (): () => boolean {
        return (): boolean => {
            return this.visible;
        };
    }

    get isSendReport (): () => boolean {
        return (): boolean => {
            return this.visible && this.sendReport;
        };
    }

}
