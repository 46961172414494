import { Injectable } from '@angular/core';
import { TranslateService } from 'app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class PlanTraitService {
    intervalOptions: Record<string, string | number>[];
    spellcheckEngineOptions: Record<string, string | number>[];

    constructor (private translateService: TranslateService) {
        this.intervalOptions = [
            {
                name: this.translateService.getString('1 minute'),
                value: 60,
            },
            {
                name: this.translateService.getString('5 minutes'),
                value: 300,
            },
        ];

        this.spellcheckEngineOptions = [
            {
                name: this.translateService.getString('Language tool'),
                value: 'languagetool',
            },
            {
                name: this.translateService.getString('Microsoft'),
                value: 'microsoft',
            },
        ];
    }

    getIntervalOptions (): Record<string, string | number>[] {
        return this.intervalOptions;
    }

    getSpellCheckOptions (): Record<string, string | number | boolean>[] {
        return this.spellcheckEngineOptions;
    }
}
