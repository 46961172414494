import { Ng2StateDeclaration } from '@uirouter/angular';
import { AgreementPickerComponent } from '@monsido/pages/reseller/agreement-picker/agreement-picker.component';
import { JiraSearchPageComponent } from '@monsido/pages/reseller/jira-search/search.component';
import { BaseControllerComponent } from 'app/layout/base-controller/base-controller.component';

export const RESELLER_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.agreement_selector',
        url: '',
        abstract: true,
        component: BaseControllerComponent,
    },
    {
        name: 'base.agreement_selector.index',
        url: '',
        views: {
            '^.^.$default': {
                component: AgreementPickerComponent,
            },
        },
    },{
        name: 'base.search',
        url: '',
        abstract: true,
        component: BaseControllerComponent,
    },
    {
        name: 'base.search.index',
        url: 'search?customer_id&domain_id&jira',
        views: {
            '^.^.$default': {
                component: JiraSearchPageComponent,
            },
        },
        dynamic: true,
        data: {
            skipEnvValidation: true,
        },
    },
];
