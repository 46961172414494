import { NgModule } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [MonEventService],
})
export class MonEventModule {}
