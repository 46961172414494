import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { User } from '@monsido/modules/models/api/user';

@Injectable()
export class UsersRepo {
    constructor (private client: ApiClient) {}

    getAll (params?: HttpParams): Promise<User[]> {
        return this.client.getPromise('users', { params }).then(this.toModels.bind(this));
    }

    private toModels (models: User[]): User[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    private toModel (model: User): User {
        return new User(model);
    }
}
