import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { ResellerAdminUsersComponent } from './users.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const RESELLER_ADMIN_USERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.admin.reseller_admin.users',
        url: '/users',
        abstract: true,
        component: BaseStateComponent,
        data: { anonymous_access: false, sidebar: 'reseller_setting' },
    },
    {
        name: 'base.admin.reseller_admin.users.index',
        url: '?search&page_size&page',
        params: RouteParamsConstant,
        views: {
            '^.^.^.^.$default': {
                component: ResellerAdminUsersComponent,
            },
        },
        dynamic: true,
    },
];
