import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDomainUploadComponent } from '@monsido/forms/backend_admin/domain/import-domains/upload/upload.component';
import { FormDomainSetupComponent } from '@monsido/forms/backend_admin/domain/import-domains/setup/setup.component';
import { FormImportDomainsComponent } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.component';
import { FormsModule } from '@angular/forms';
import { FormBuilderSelectModule, LayoutModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { ScanModule } from '@monsido/forms/domain/steps/scan/scan.module';
import { FeaturesModule } from '@monsido/forms/domain/steps/features/features.module';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    declarations: [FormImportDomainsComponent, FormDomainSetupComponent, FormDomainUploadComponent],
    imports: [CommonModule, LayoutModule, TranslateModule, FormsModule, FormBuilderSelectModule, PipesModule, ScanModule, FeaturesModule],
})
export class ImportDomainsModule {}
