<form name="form" #urlForm="ngForm" class="form-horizontal"  (ngSubmit)="!onSave && urlForm.valid && submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['DOMAINS', 'REWRITE_URL']" header="{{'Domain URL Rewrite' | translate }}"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mx-6 mt-6">
            <div class="card-header d-flex align-items-center">
                <mon-icon [icon]="['DOMAINS', 'ICON']" [size]="'lg'" [styles]="{'margin-right': '5px'}"></mon-icon>
                <mon-url-title [title]="domain.title" [url]="domain.url" [active]="false"></mon-url-title>
            </div>
            <div class="card-body">
                <mon-form-field-input monHttpPrefix
                                      required
                                      [name]="'from_url'"
                                      #from_url="ngModel"
                                      [(ngModel)]="fromUrl"
                                      monLabel="{{ 'From URL' | translate }}"
                                      monPlaceholder="{{ 'From URL' | translate }}"
                                      [type]="'text'">
                </mon-form-field-input>
                <mon-form-field-errors
                    [showError]="from_url.control.touched && from_url.control.invalid"
                    [errors]="from_url.control.errors"
                ></mon-form-field-errors>
                <mon-form-field-input monHttpPrefix
                                      required
                                      #to_url="ngModel"
                                      [name]="'to_url'"
                                      [(ngModel)]="toUrl"
                                      monLabel="{{ 'To URL' | translate }}"
                                      monPlaceholder="{{ 'To URL' | translate }}"
                                      [type]="'text'">
                </mon-form-field-input>
                <mon-form-field-errors
                    [showError]="to_url.control.touched && to_url.control.invalid"
                    [errors]="to_url.control.errors"
                ></mon-form-field-errors>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button type="submit" [disabled]="onSave || urlForm.invalid" class="btn btn-secondary" data-test="save-button">
            <span *ngIf="!onSave" translate>Save</span>
            <mon-icon [icon]="['SPINNER', 'ICON']" *ngIf="onSave"></mon-icon>
        </button>
    </div>
</form>
