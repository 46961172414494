import { ConfigurableFocusTrap, ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

@Component({
    selector: 'mon-overlay-wrapper',
    templateUrl: './overlay-wrapper.component.html',
    styleUrls: ['./overlay-wrapper.component.scss'],
})
export class OverlayWrapperComponent implements OnDestroy, AfterViewInit {

    @ViewChild('wrapper') wrapper?: ElementRef<HTMLElement>;

    private focusTrap: ConfigurableFocusTrap | undefined;
    private mutationObserver: MutationObserver | undefined;
    private activeDialog: HTMLElement | undefined;
    private previouslyFocusedElements: Map<HTMLElement, HTMLElement> = new Map();

    constructor (
        private configFocusTrapFactory: ConfigurableFocusTrapFactory,
    ) { }

    ngAfterViewInit (): void {
        const wrapperNativeElement = this.wrapper?.nativeElement;
        if (wrapperNativeElement) {
            this.mutationObserver = this.startObserver(wrapperNativeElement);
        }
    }
    ngOnDestroy (): void {
        this.mutationObserver?.disconnect();
        this.focusTrap?.destroy();
    }

    private startObserver (element: HTMLElement): MutationObserver {
        const config = { childList: true };

        const callback = async (mutations: MutationRecord[]): Promise<void> => {

            this.cycleCleanup(mutations);

            if (document.activeElement !== document.body) {
                const activeElementOwner = this.activeDialog || document.body;
                this.previouslyFocusedElements.set(activeElementOwner, document.activeElement as HTMLElement);
            }

            this.focusTrap?.destroy();

            const lastDialog = this.getLastDialog();

            if (lastDialog) {
                this.activeDialog = lastDialog;
                this.focusTrap = this.configFocusTrapFactory.create(lastDialog, { defer: true });
                this.restoreFocus(lastDialog);
            } else {
                this.activeDialog = undefined;
                this.restoreFocus(document.body);
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(element, config);
        return observer;
    }

    private restoreFocus (element: HTMLElement): void {
        setTimeout(async () => {
            const elementToreturnFocus = this.previouslyFocusedElements.get(element);
            if (elementToreturnFocus && element.contains(elementToreturnFocus)) {
                elementToreturnFocus.focus();
            } else {
                if (this.focusTrap) {
                    await this.focusTrap.focusInitialElementWhenReady();
                }
            }
        }, 300);
    }

    private getLastDialog (): HTMLElement | undefined {
        const wrapperNativeElement = this.wrapper?.nativeElement;

        if (wrapperNativeElement) {
            // Angular's focus-trap creates a div with .cdk-focus-trap-anchor, as a last child.
            const children = Array.from(wrapperNativeElement.querySelectorAll(':scope > *:not(.cdk-focus-trap-anchor)'));
            return children.pop() as HTMLElement;
        }
    }

    private cycleCleanup (mutations: MutationRecord[]): void {
        const removedElements = mutations.reduce((acc, next) => {
            return acc.concat(Array.from(next.removedNodes) as HTMLElement[]);
        }, ([] as HTMLElement[]));

        removedElements.forEach(element => {
            if (this.previouslyFocusedElements.has(element)) {
                this.previouslyFocusedElements.delete(element);
            }
        });
    }

}
