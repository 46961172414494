import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';

export const LANGUAGE_CONSTANT: { [key: string]: LanguageInterface } = {
    be_BY: {
        code: 'be_BY',
        name: 'Belarusian',
    },
    ca_ES: {
        code: 'ca_ES',
        name: 'Catalan',
    },
    zh_CN: {
        code: 'zh_CN',
        name: 'Chinese',
    },
    da: {
        code: 'da',
        name: 'Danish',
    },
    da_DK: {
        code: 'da_DK',
        name: 'Danish',
    },
    nl_NL: {
        code: 'nl_NL',
        name: 'Dutch',
    },
    en: {
        code: 'en',
        name: 'English',
    },
    en_AU: {
        code: 'en_AU',
        name: 'English (Australian)',
    },
    en_CA: {
        code: 'en_CA',
        name: 'English (Canadian)',
    },
    en_GB: {
        code: 'en_GB',
        name: 'English (GB)',
    },
    en_ZA: {
        code: 'en_NZ',
        name: 'English (New Zealand)',
    },
    en_US: {
        code: 'en_US',
        name: 'English (US)',
    },
    fi_FI: {
        code: 'fi_FI',
        name: 'Finnish',
    },
    fr_FR: {
        code: 'fr_FR',
        name: 'French',
    },
    gl_ES: {
        code: 'gl_ES',
        name: 'Galician',
    },
    de_AT: {
        code: 'de_AT',
        name: 'German (Austria)',
    },
    de_DE: {
        code: 'de_DE',
        name: 'German (Germany)',
    },
    de_CH: {
        code: 'de_CH',
        name: 'German (Swiss)',
    },
    el_GR: {
        code: 'el_GR',
        name: 'Greek',
    },
    is_IS: {
        name: 'Icelandic',
        code: 'is_IS',
    },
    it_IT: {
        code: 'it_IT',
        name: 'Italian',
    },
    ja_JP: {
        name: 'Japanese',
        code: 'ja_JP',
    },
    km_KH: {
        code: 'km_KH',
        name: 'Khmer',
    },
    lt_LT: {
        code: 'lt_LT',
        name: 'Lithuanian',
    },
    ml_IN: {
        code: 'ml_IN',
        name: 'Malayalam',
    },
    pl_PL: {
        code: 'pl_PL',
        name: 'Polish',
    },
    pt_BR: {
        code: 'pt_BR',
        name: 'Portuguese (Brazil)',
    },
    pt_PT: {
        code: 'pt_PT',
        name: 'Portuguese (Portugal)',
    },
    ro_RO: {
        code: 'ro_RO',
        name: 'Romanian',
    },
    ru_RU: {
        code: 'ru_RU',
        name: 'Russian',
    },
    sk_SK: {
        code: 'sk_SK',
        name: 'Slovak',
    },
    sl_SI: {
        code: 'sl_SI',
        name: 'Slovenian',
    },
    es_ES: {
        code: 'es_ES',
        name: 'Spanish',
    },
    sv_SE: {
        code: 'sv_SE',
        name: 'Swedish',
    },
    tl_PH: {
        code: 'tl_PH',
        name: 'Tagalog',
    },
    ta_IN: {
        code: 'ta_IN',
        name: 'Tamil',
    },
    uk_UA: {
        code: 'uk_UA',
        name: 'Ukrainian',
    },
};
