import { Component, Input, OnInit } from '@angular/core';
import { Domain } from '@monsido/modules/models/api/domain';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ParseResult } from 'ngx-papaparse';
import { noop, Observable } from 'rxjs';
import { ImportDomainsService, StepType } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.service';
import { Customer } from '@monsido/modules/models/api/customer';
import { UpdateType } from '@monsido/forms/backend_admin/domain/import-domains/setup/setup.component';

@Component({
    selector: 'mon-import-domains',
    templateUrl: './import-domains.component.html',
})
export class FormImportDomainsComponent implements OnInit {
    @Input() customer: Customer;
    @Input() monIsBackendAdmin: boolean = false;

    domain: Domain;
    saving: boolean;
    data: ParseResult;
    selected: UpdateType;
    stepObs: Observable<StepType>;
    importCount$: Observable<number>;

    constructor (private activeDialog: ActiveDialog, private importDomService: ImportDomainsService) {
        this.importCount$ = this.importDomService.importCount$;
    }

    ngOnInit (): void {
        this.stepObs = this.importDomService.getStepObs();
        this.selected = { url: null, title: null };
        this.saving = false;
        this.data = null;
        this.domain = new Domain();
    }

    onUpdateSelected (event: UpdateType): void {
        this.selected = event;
    }

    onUploadDone (data: ParseResult): void {
        this.data = data;
    }

    incrementStep (): void {
        this.importDomService.setStep((this.importDomService.getCurrentStep() + 1) as StepType);
    }

    isBoolean (input: unknown): boolean {
        return typeof input === 'boolean';
    }

    submit (): void {
        this.saving = true;
        this.data.data.shift();

        const data: Pick<Domain, 'url' | 'title'>[] = [];
        const urlVal: string | number = this.selected.selected.url;
        const titleVal: string | number = this.selected.selected.title;

        for (let i = 0; i < this.data.data.length; i++) {
            data.push({
                url: this.data.data[i][urlVal],
                title: this.data.data[i][titleVal],
            });
        }
        this.close(data);
    }

    close (data: Pick<Domain, 'url' | 'title'>[]): void {
        this.importDomService.importDomains(data, this.domain, this.customer).then(() => {
            this.activeDialog.close();
        }, noop);
    }
}
