import { Injectable } from '@angular/core';
import { MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { TranslateService } from 'app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {

    constructor (private translateService: TranslateService, private monPromtService: MonPromptService) { }

    standardMsg (error: Error): void {
        this.monPromtService
            .alert(this.translateService.getString('An error has occurred - please try again. If the error continues, please contact Monsido'))
            .then(() => {}, () => {});
        // eslint-disable-next-line no-console
        console.error(error);
    }

    noopError (error: Error): void {
        // eslint-disable-next-line no-console
        console.warn(error);
    }
}
