import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { BackendUserModel } from './models/user';
import { createDefaultHttpParams } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { CollectionInterface } from '@monsido/angular-shared-components/dist/angular-shared-components/lib/interfaces/collection.interface';

@Injectable({
    providedIn: 'root',
})
export class BackendAdminUserRepo {

    constructor (private backendAdminApiClient: BackendAdminApiClient) {}

    public getAll (data: PlainHttpParams, headers: HttpHeaders): Promise<CollectionInterface<BackendUserModel>> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('users', params).then((userData: CollectionInterface<UserInterface>) => {
            return this.toModels(userData);
        });
    }

    public sudoUser (userId: number, customerId: number): Promise<void> {
        return this.backendAdminApiClient.postPromise('users/' + userId + '/sudo', {}).then((data) => {
            window.open(data.uri + '&customer_id=' + customerId, '_blank');
        });
    }

    private toModels (models: CollectionInterface<UserInterface>): CollectionInterface<BackendUserModel> {
        const { currentPage, perPage, total } = models;
        const result: BackendUserModel[] = [];
        for (let i = 0; i < models.length; i++) {
            result.push(this.toModel(models[i]));
        }
        return Object.assign(result, {
            currentPage,
            perPage,
            total,
        });
    }

    private toModel (model: UserInterface): BackendUserModel {
        return new BackendUserModel(model);
    }
}
