import { Injectable } from '@angular/core';
import { StateParams, StateService, UIRouterGlobals } from '@uirouter/angular';
import { cloneDeep } from 'lodash';

type RouterParamsType = Omit<StateParams, '$inherit'>;

@Injectable()
export class ParamService {
    private prevParams: Record<string, string | number | boolean> = {};
    constructor (
        private state: StateService,
        private uiRouterGlobals: UIRouterGlobals,
    ) {}

    getParams (): RouterParamsType {
        return this.uiRouterGlobals.params;
    }

    setParams (params: RouterParamsType): void {
        if (!params) {
            return;
        }
        Object.keys(params).forEach((key) => {
            this.uiRouterGlobals.params[key] = params[key];
        });
        this.state.go(this.uiRouterGlobals.current, this.uiRouterGlobals.params, {
            location: true,
            reload: false,
            inherit: false,
        });
        this.prevParams = cloneDeep(this.uiRouterGlobals.params);
    }

    returnPrevParam (): Record<string, string | number | boolean> {
        return this.prevParams;
    }
}
