<ng-container *ngIf="customer">
    <mon-page-header [monIcon]="['CUSTOMERS', 'ICON']" header="{{'Customer:' | translate}} {{ customer && customer.name}}" subHeader="{{'ID: '}} {{customer && customer.id}}">
        <div>
            <button class="btn btn-secondary pull-right mt-1"
                    [ngClass]="{disabled: jobs.hasJob(customer.id)}"
                    [ngbTooltip]="computedCsvImportSpellingTooltip"
                    placement='top-right'
                    [container]="'body'"
                    [attr.aria-label]="computedCsvImportSpellingTooltip"
                    (click)="importConfirmedSpellingErrors()"
                    data-test="import-confirmed-spellng-erros-button">
                <span [ngClass]="jobs.jobCssClass(customer.id, 'fas fa-language fa-lg')"></span>
            </button>
            <button class="btn btn-secondary pull-right mt-1 mr-1"
                    [ngClass]="{disabled: jobs.hasJob(customer.id)}"
                    [ngbTooltip]="computedCsvImportDictionaryTooltip"
                    placement='top'
                    [container]="'body'"
                    [attr.aria-label]="computedCsvImportDictionaryTooltip"
                    (click)="importDictionary()"
                    data-test="import-dictionary-button">
                <span [ngClass]="jobs.jobCssClass(customer.id, 'fas fa-atlas fa-lg')"></span>
            </button>
        </div>
    </mon-page-header>
    <mon-tabs [status]="activeTab"
              [options]="tabs"
              [showSearch]="false"
              (onStatus)="onTabChange($event)"
    >
        <div>
            <button class="btn btn-secondary pull-right mr-2"
                    monPrompt="To confirm deletion, please input the id of the customer ( id: {{customer.id}})"
                    (monPromptAction)="onDeleteCustomer($event)(customer)"
                    data-test="delete-customer-button">
                <mon-icon [styles]="{'margin-right': '10px'}" [icon]="['ACTIONS', 'DELETE']"></mon-icon>
                <span translate>Delete customer</span>
            </button>
            <button class="btn btn-secondary pull-right mr-2"
                    (click)="editCustomer()"
                    data-test="edit-customer-button">
                <mon-icon [styles]="{'margin-right': '10px'}" [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                <span translate>Edit customer</span>
            </button>
            <button class="btn btn-secondary pull-right mr-2"
                    *ngIf="customer.plan_traits.clarity_pdf_accessibility"
                    (click)="openToken()"
                    data-test="add-clarity-tokens-button">
                <mon-icon [styles]="{'margin-right': '10px'}" [icon]="['ACTIONS', 'ADD']"></mon-icon>
                <span translate>Add Clarity Tokens</span>
            </button>
        </div>
    </mon-tabs>
    <mon-panel [showHeader]="false">
        <div class="card-body" slot="mon-panel-body">
            <div class="row">
                <div class="col-xs-48">
                    <mon-customer-info-tab *ngIf="activeTab === 'customer-info'" [customer]="customer" data-test="customer-tab"></mon-customer-info-tab>
                    <mon-backend-admin-users-tab *ngIf="activeTab === 'users'"
                                   [customer]="customer"
                                   [page]="userPage"
                                   [pageSize]="userPageSize"
                                   [search]="userSearch"
                                   [users]="users"
                                   [loading]="userLoading"
                                   (getPageEvent)="getUsers($event)"
                                   (createUserEvent)="createUser()"
                                   data-test="user-tab"
                    ></mon-backend-admin-users-tab>
                    <mon-domains-tab *ngIf="activeTab === 'domains'"
                                   [customer]="customer"
                                   [page]="domainPage"
                                   [pageSize]="domainPageSize"
                                   [search]="domainSearch"
                                   [domains]="domains"
                                   [loading]="domainLoading"
                                   (getPageEvent)="getDomains($event)"
                                   (createDomainEvent)="createDomain()"
                                   (importDomainEvent)="importDomain()"
                                   data-test="domains-tab"
                    ></mon-domains-tab>
                </div>
            </div>
        </div>
    </mon-panel>
</ng-container>
