import { Ng2StateDeclaration } from '@uirouter/angular';
import { BackendAdminCustomerShowComponent } from '@monsido/pages/backend-admin/customers/show/show.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const BA_CUSTOMERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.customers.show',
        url: '/:customer_id/?search&page_size&page&plan_type',
        params: {
            ...RouteParamsConstant,
            plan_type: {
                value: 'customer-info',
                dynamic: true,
            },
        },
        views: {
            '^.^.^.$default': {
                component: BackendAdminCustomerShowComponent,
            },
        },
        dynamic: true,
    },
];
