<form name="form" class="form-horizontal env-form-selector">
    <mon-form-field-select
        [monName]="'computedName'"
        monReturnValue="id"
        [monOptions]="envs"
        monPlaceholder="{{ 'Select environment' | translate}}"
        (changeEvent)="onEventChange.next($event[0]?.data)"
        [(model)]="env"
        [monDisabled]="loading"
        >
    </mon-form-field-select>
</form>
