import { NgModule } from '@angular/core';
import { UsersModule } from '@monsido/pages/admin/users/users.module';
import { ConstraintsAndExcludesModule } from './constraints-and-excludes/constraints-and-excludes.module';
import { CrawlQueueModule } from './crawl-queue/crawl-queue.module';

@NgModule({
    imports: [
        UsersModule,
        CrawlQueueModule,
        ConstraintsAndExcludesModule,
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class AdminModule {}
