import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toString',
})
export class ToStringPipe implements PipeTransform {
    transform (value: unknown): string | unknown {
        if (this.isObject(value)) {
            try {
                return JSON.stringify(value);
            } catch (e) {
                return value;
            }
        }
        return value;
    }

    private isObject (type: unknown): boolean {
        return type != null && !Array.isArray(type) && typeof type === 'object';
    }
}
