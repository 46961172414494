import { Ng2StateDeclaration } from '@uirouter/angular';
import { BackendAdminCustomersComponent } from './customers.component';
import { DomainShowComponent } from 'app/pages/common/domain-show/domain-show.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const BACKEND_ADMIN_CUSTOMERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.customers',
        url: '/customers',
        abstract: true,
    },
    {
        name: 'base.backend_admin.customers.all',
        url: '/?search&page_size&page&plan_type',
        params: {
            ...RouteParamsConstant,
            plan_type: {
                value: '',
                dynamic: true,
            },
        },
        views: {
            '^.^.^.$default': {
                component: BackendAdminCustomersComponent,
            },
        },
    },
    {
        name: 'base.backend_admin.customers.info',
        url: '/:customer_id/info',
        abstract: true,
    },
    {
        name: 'base.backend_admin.customers.info.domain',
        url: '/domain/:domain_id',
        views: {
            '^.^.^.^.$default': {
                component: DomainShowComponent,
            },
        },
        resolve: [{
            token: 'isBackendAdmin',
            resolveFn: (): boolean => {
                return true;
            },
        }],
    },
];
