import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonIconsService } from 'app/blocks/service/mon-icons.service';
import { MenuItemType, SideMenuComponent } from '../../side-menu.component';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-side-menu-reseller-settings',
    standalone: true,
    imports: [CommonModule, SideMenuComponent],
    templateUrl: './side-menu-reseller-settings.component.html',
    styleUrls: ['./side-menu-reseller-settings.component.scss'],
})
export class SideMenuResellerSettingsComponent implements OnInit {
    menu: MenuItemType[];

    constructor (private translateService: TranslateService, private monIconsService: MonIconsService) {}

    ngOnInit (): void {
        this.menu = [
            {
                title: this.translateService.getString('Return to Reseller'),
                icon: this.monIconsService.getIcon(['MENUES', 'RETURN']),
                base: 'base.admin.customers',
                href: 'base.admin.customers.index',
                basic: true,
            },
            {
                title: this.translateService.getString('Users'),
                icon: this.monIconsService.getIcon(['USERS', 'ICON']),
                base: 'base.admin.reseller_admin',
                href: 'base.admin.reseller_admin.users.index',
                basic: true,
            },
        ];
    }
}
