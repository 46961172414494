import { getString } from '../../../utils/translation.utils';

export const PLAN_TRAITS = {
    SCAN_FREQUENIES: [
        {
            value: 7,
            name: getString('Weekly'),
        },
        {
            value: 14,
            name: getString('Every 2 week'),
        },
        {
            value: 28,
            name: getString('Every 4 week'),
        },
        {
            value: 56,
            name: getString('Every 8 week'),
        },
        {
            value: 84,
            name: getString('Every 12 week'),
        },
        {
            value: -1,
            name: getString('Never'),
        },
    ],
};
