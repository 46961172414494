import { Component, Input } from '@angular/core';
import { ScansInterface } from '@monsido/modules/models/api/interfaces/scans.interface';

@Component({
    selector: 'mon-request-checker-base-info-component',
    templateUrl: 'base-info.html',
    styleUrls: ['./../../request-checker.scss'],
})

export class BaseInfoComponent {
    @Input() url: string;
    @Input() scan: ScansInterface;
    constructor () {}
}
