<div class="col-lg-48 outer-l-none inner-l-none">
    <mon-panel-header pHeader="{{'All customers domains' | translate}}"
                    *ngIf="domains"
                    subHeader="{{'This account has ' | translate}} {{ domains.total }} {{ 'active domains right now' | translate}}">
        <div class="d-flex align-items-center pull-right">
            <div class="mr-4">
                <button class="btn btn-secondary py-2 px-3"
                        (click)="createDomain.emit()"
                        ngbTooltip="{{'Add new domain' | translate}}"
                        data-test="add-new-domain-button"
                        attr.aria-label="{{'Add new domain' | translate}}">
                    <span class="fas fa-plus me-1"></span>
                    <span class="fa-lg" [ngClass]="['DOMAINS', 'ICON'] | monIcons" aria-hidden="true"></span>
                </button>
            </div>
            <div>
                <mon-table-search [model]="search" [minSearchLength]="1" (getPage)="onSearchChanged($event)"></mon-table-search>
            </div>
        </div>
    </mon-panel-header>
    <div class="card-body">
        <div class="col-md-48 col-lg-48 col-xs-48">
            <mon-table-container
                    *ngIf="domains && domains.total != null"
                    [collection]="domains"
                    (pageChangeEvent)="onPageChange($event)"
                    (perPageChange)="onPageSizeChange($event)"
                    [loading]="loading"
                    class="table-vertial-align">

                <div class="table-container-body">
                    <mon-domains-table [customer]="customer" [domains]="domains" (getPage)="getPage(true)"></mon-domains-table>
                    <hr class="line-thickness-4 outer-t-none"/>
                </div>
            </mon-table-container>
        </div>
    </div>
</div>
