import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvSelectorComponent } from '@monsido/modules/env-selector/component/env-selector.component';
import { FormsModule as AngularFormModule } from '@angular/forms';
import { FormBuilderSelectModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [AngularFormModule, CommonModule, FormBuilderSelectModule, TranslateModule],
    exports: [EnvSelectorComponent],
    declarations: [EnvSelectorComponent],
    providers: [],
})
export class EnvSelectorModule {}
