<mon-page-header
    [monIcon]="['CUSTOMERS', 'ICON']"
    header="{{ 'Customers' | translate }}">
</mon-page-header>

<mon-panel
    [activeTab]="activeTab"
    [tabOptions]="tabs"
    [showSearch]="true"
    [minSearchLength]="1"
    [search]="search"
    [maxTabsDisplay]="4"
    (onSearch)="onSearch($event)"
    (onStatus)="onTabChange($event)"
>
    <div class="card-body" slot="mon-panel-body">
        <mon-table-container
            [collection]="customers"
            (pageChangeEvent)="onPageChange($event)"
            (perPageChange)="onPageSizeChange($event)"
            class="table-vertical-align"
            [loading]="loading"
        >
            <div class="table-container-body">
                <mon-backend-admin-customers-table
                    [customers]="customers"
                    (monGetPageEvent)="getPage()">
                </mon-backend-admin-customers-table>
            </div>
        </mon-table-container>
    </div>
</mon-panel>
