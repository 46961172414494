export const DATA_PRIVACY_CHECK = {
    GROUPS: [
        {
            name: 'Technical',
            value: 'technical',
        },
        {
            name: 'General Personal Data',
            value: 'general_personal_data',
        },
        {
            name: 'Banking Data',
            value: 'banking_data',
        },
        {
            name: 'Other',
            value: 'other',
        },
        {
            name: 'Sensitive Personal Data',
            value: 'sensitive_personal_data',
        },
        {
            name: 'Healthcare',
            value: 'healthcare',
        },
        {
            name: 'National ID',
            value: 'national_id',
        },
        {
            name: 'Driving Permit',
            value: 'driving_permit',
        },
        {
            name: 'Taxpayer ID',
            value: 'taxpayer_id',
        },
        {
            name: 'Product Identifier',
            value: 'product_identifier',
        },
        {
            name: 'Personal Technical',
            value: 'personal_technical',
        },
    ],
    REGIONS: [
        {
            name: 'Global',
            value: 'global',
        },
        {
            name: 'Argentina',
            value: 'argentina',
        },
        {
            name: 'Australia',
            value: 'australia',
        },
        {
            name: 'Belgium',
            value: 'belgium',
        },
        {
            name: 'Brazil',
            value: 'brazil',
        },
        {
            name: 'Canada',
            value: 'canada',
        },
        {
            name: 'Chile',
            value: 'chile',
        },
        {
            name: 'China',
            value: 'china',
        },
        {
            name: 'Colombia',
            value: 'colombia',
        },
        {
            name: 'Denmark',
            value: 'denmark',
        },
        {
            name: 'France',
            value: 'france',
        },
        {
            name: 'Finland',
            value: 'finland',
        },
        {
            name: 'Germany',
            value: 'germany',
        },
        {
            name: 'Hong Kong',
            value: 'hong_kong',
        },
        {
            name: 'India',
            value: 'india',
        },
        {
            name: 'Indonesia',
            value: 'indonesia',
        },
        {
            name: 'Israel',
            value: 'israel',
        },
        {
            name: 'Ireland',
            value: 'ireland',
        },
        {
            name: 'Italy',
            value: 'italy',
        },
        {
            name: 'Japan',
            value: 'japan',
        },
        {
            name: 'Korea',
            value: 'korea',
        },
        {
            name: 'Mexico',
            value: 'mexico',
        },
        {
            name: 'The Netherlands',
            value: 'the_netherlands',
        },
        {
            name: 'Norway',
            value: 'norway',
        },
        {
            name: 'Paraguay',
            value: 'paraguay',
        },
        {
            name: 'Peru',
            value: 'peru',
        },
        {
            name: 'Poland',
            value: 'poland',
        },
        {
            name: 'Portugal',
            value: 'portugal',
        },
        {
            name: 'Singapore',
            value: 'singapore',
        },
        {
            name: 'Spain',
            value: 'spain',
        },
        {
            name: 'Sweden',
            value: 'sweden',
        },
        {
            name: 'Taiwan',
            value: 'taiwan',
        },
        {
            name: 'Thailand',
            value: 'thailand',
        },
        {
            name: 'Turkey',
            value: 'turkey',
        },
        {
            name: 'United Kingdom',
            value: 'united_kingdom',
        },
        {
            name: 'United States',
            value: 'united_states',
        },
        {
            name: 'Uruguay',
            value: 'uruguay',
        },
        {
            name: 'Venezuela',
            value: 'venezuela',
        },
    ],
    PRIORITY_LEVELS: [
        {
            name: 'Low',
            value: 50,
        },
        {
            name: 'Medium',
            value: 100,
        },
        {
            name: 'High',
            value: 150,
        },
    ],
    REGULATIONS: [
        {
            name: 'GDPR - European Union',
            value: 'GDPR',
        },
        {
            name: 'CCPA - California',
            value: 'CCPA',
        },
        {
            name: 'APA - Australia',
            value: 'APA',
        },
        {
            name: 'SHIELD - NY Shield Act',
            value: 'SHIELD',
        },
        {
            name: 'PIPEDA - Canada',
            value: 'PIPEDA',
        },
    ],
};
