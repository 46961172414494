import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '../backend-admin-api-client';
import { Scan } from '@monsido/modules/models/api/scan';
import { DomainScanLogin } from 'types/domain';


export interface ScanOptionsInterface {
    url: string,
    enable_javascript: boolean,
    accessibility: AccessibilityTypes,
    link_excludes?: LinkExclude[],
    additional_internal_urls: AdditionalInternalUrl[],
    js_rendering_service: string,
    login?: DomainScanLogin,
    source_code_excludes?: SourceCodeExclude[]
    readability_test?: ReadabilityTest
}

export enum AccessibilityTypes {
    WCAG2A = 'WCAG2-A',
    WCAG2AA = 'WCAG2-AA',
    WCAG2AAA = 'WCAG2-AAA',
    WCAG21A = 'WCAG21-A',
    WCAG21AA = 'WCAG21-AA',
    WCAG21AAA = 'WCAG21-AAA',
    WCAG22A = 'WCAG22-A',
    WCAG22AA = 'WCAG22-AA',
    WCAG22AAA = 'WCAG22-AAA',
    QUALWEBA = 'QUALWEB-A',
    QUALWEBAA = 'QUALWEB-AA',
}

export enum ReadabilityTest {
    ARI = 'ari',
    COLEMAN_LIAU = 'coleman_liau',
    FLESCH_KINCAID_RE = 'flesch_kincaid_re',
    FLESCH_KINCAID_GL = 'flesch_kincaid_gl',
    GUNNING_FOG = 'gunning_fog',
    SMOG = 'smog',
    LIX = 'lix',
}

export type LinkExclude = {
    regex: string;
}

export type AdditionalInternalUrl = {
    operator: string,
    value: string
}

export enum SourceCodeExcludeModules {
    READABILITY = 'readability',
    SPELLING = 'spelling'
}

export type SourceCodeExclude = {
    module: SourceCodeExcludeModules
    rules: {
        operator: 'css'
        value: string
    }[]
}
export enum LoginTypes {
    NONE = 'none',
    BASIC_AUTH = 'basic_auth',
    FORM = 'form',
    CUSTOM_MULTISTEP = 'custom_multistep',
    OFFICE = 'office365',
}

@Injectable()
export class BackendResellerScans {
    constructor (private adminClient: BackendAdminApiClient) {}

    scans (options: ScanOptionsInterface): Promise<Scan> {
        return this.adminClient.postPromise('scans', options);
    }

}
