<mon-table-container [collection]="collection"
                     (pageChangeEvent)="onPageChange($event)"
                     (perPageChange)="onPageSizeChange($event)"
                     class="table-vertial-align"
                     [loading]="loading"
                     [noResultMessage]="'No result'">
    <div class="table-container-body">
        <table class="table" monSelectAllCheckbox>
            <thead>
            <tr>
                <th>{{ 'Domains' | translate }}</th>
                <th>
                    <input type="checkbox">
                    {{ 'Visible' | translate }}
                </th>
                <th>
                    <input type="checkbox">
                    {{ 'Send report' | translate }}
                </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let domain of domainsOutput">
                <tr>
                    <td>{{ domain.title }}</td>
                    <td>
                        <input type="checkbox"
                               (click)="toggleDomainVisible(domain)"
                               [disabled]="user.customer_admin"
                               [checked]="domain.visible">
                    </td>
                    <td>
                        <input type="checkbox"
                               [disabled]="!domain.visible"
                               (click)="toggleDomainSendReport(domain)"
                               [checked]="domain.sendReport">
                    </td>
                </tr>
                <ng-container *ngIf="domain.domain_groups?.length">
                    <tr *ngFor="let domainGroup of domain.domain_groups">
                        <td>
                            <span class="pull-left visibility-45" [ngClass]="['DOMAIN_GROUPS', 'ICON'] | monIcons"></span>
                            {{ domainGroup.title }}
                        </td>
                        <td>
                            <input type="checkbox"
                                   (click)="toggleGroupVisible(domainGroup)"
                                   [disabled]="user.customer_admin"
                                   [checked]="domainGroup.visible">
                        </td>
                        <td>
                            <input type="checkbox"
                                   [disabled]="!domainGroup.visible"
                                   (click)="toggleGroupSendReport(domainGroup)"
                                   [checked]="domainGroup.sendReport">
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </table>
    </div>
</mon-table-container>