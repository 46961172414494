import { NgModule } from '@angular/core';
import { LocalStorageService } from '@monsido/core/storage/local-storage.service';
import { SessionStorageService } from '@monsido/core/storage/session-storage.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [LocalStorageService, SessionStorageService],
})
export class StorageModule {}
