import { NgModule } from '@angular/core';
import { PromptDirective } from './prompt.directive';

@NgModule({
    imports: [],
    exports: [PromptDirective],
    declarations: [PromptDirective],
    providers: [],
})
export class PromptModule {}
