<form name="form" class="form-horizontal" (submit)="submit($event)" novalidate>
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['CUSTOMERS', 'ICON']" header="{{'Edit customer' | translate }}" *ngIf="customer.id"></mon-page-header>
        <mon-page-header [monIcon]="['CUSTOMERS', 'ICON']" header="{{'New customer' | translate }}" *ngIf="!customer.id"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Detail</h3>
            </div>
            <div class="card-body card-body-pd-0">
                <mon-form-customer-detail
                (monFormValidityChanges)="updateCustomerDetailValidity($event)"
                [customer]="customer"></mon-form-customer-detail>
            </div>
        </div>

        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Plan</h3>
            </div>
            <div class="card-body">
                <mon-form-customer-plan
                (monFormValidityChanges)="updateCustomerPlanValidity($event)"
                [customer]="customer"></mon-form-customer-plan>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-secondary" [disabled]="saving || !customerDetailValid || !customerPlanValid" type="submit" data-test="save-button">
            <span *ngIf="!saving">{{'Save' | translate}}</span>
            <span [ngClass]="{spinnerIconClassname: saving}" aria-hidden="true"></span>
        </button>
    </div>
</form>
