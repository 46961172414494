import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { PageAssistSettingsCustomNavigation } from 'types/domain';
import { NgForm } from '@angular/forms';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { cloneDeep } from 'lodash';
import { AnchorTarget, PageAssistCustomNavigationService } from 'app/forms/page-assist-custom-navigation/page-assist-custom-navigation.service';

@Component({
    selector: 'mon-form-page-assist-custom-navigation',
    templateUrl: 'page-assist-custom-navigation.html',
})
export class FormPageAssistCustomNavigationComponent implements OnInit, AfterViewInit {
    @Input() monParentId: number | null;
    @Input() monCustomNavigation: PageAssistSettingsCustomNavigation;

    @ViewChild('customNavigationForm', { static: false })
        customNavigationForm: NgForm;
    model: PageAssistSettingsCustomNavigation;

    constructor (
        private activeDialog: ActiveDialog,
        private pageAssistCustomNavigationService: PageAssistCustomNavigationService,
    ) {}

    ngAfterViewInit (): void {
        if (this.customNavigationForm) {
            this.activeDialog.setForm(this.customNavigationForm);
        }
    }

    ngOnInit (): void {
        if (this.monCustomNavigation) {
            this.model = cloneDeep(this.monCustomNavigation);
        } else {
            this.model = {
                url: '',
                title: '',
                target: this.pageAssistCustomNavigationService.defaultTabValue,
                mainselector: '',
                selectors: [],
                parent_index: null,
            };
        }

        if (this.model.parent_index !== null && typeof this.model.parent_index !== 'number') {
            this.model.parent_index = this.monParentId;
        }
    }

    get targets (): readonly AnchorTarget[] {
        return this.pageAssistCustomNavigationService.targets;
    }

    addSelector (): void {
        this.model.selectors.push('');
    }

    removeSelector (index: number): void {
        this.model.selectors.splice(index, 1);
    }

    submit (): void {
        this.customNavigationForm.control.markAsPristine();
        this.close(this.model);
    }

    identify (index: number): string {
        return `id-${index}`;
    }

    private close (model: PageAssistSettingsCustomNavigation): void {
        this.activeDialog.close(model);
    }
}
