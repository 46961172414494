<form name="customNavigationFormname"
      #customNavigationForm="ngForm"
      class="form-horizontal"
      (ngSubmit)="customNavigationForm.valid && submit()"
      >
<div class="mon-dialog-header">
  <mon-page-header header="{{'PageAssist Custom Navigation' | translate }}"></mon-page-header>
</div>
<div class="mon-dialog-body-with-footer bg-body">
 <div class="card monsido-panel mt-4 mx-4">
     <div class="card-body">
        <mon-form-field-input
            monLabel="{{ 'Name' | translate }}"
            [name]="'name'"
            [(ngModel)]="model.title"
            #title="ngModel"
            required
            monPlaceholder="{{'Enter the name of the Custom Navigation' | translate}}">
        </mon-form-field-input>
        <mon-form-field-errors
            [showError]="title.control.touched && title.control.invalid"
            [errors]="title.control.errors">
        </mon-form-field-errors>
        <mon-form-field-select
            id="pa_cn_target"
            monLabel="{{ 'Target' | translate}}"
            [monName]="'target'"
            [monAllowClear]="false"
            [monNameAttr]="'name'"
            [monReturnValue]="'value'"
            monPlaceholder="{{'Select Target Type of the Custom Navigation' | translate}}"
            [monOptions]="targets"
            [(model)]="model.target"
            [monRequired]="true">
        </mon-form-field-select>
        <mon-form-field-input
            monLabel="{{ 'URL' | translate }}"
            [name]="'url'"
            [type]="'url'"
            #url="ngModel"
            [(ngModel)]="model.url"
            required
            monPlaceholder="{{'Enter the URL of the Custom Navigation' | translate}}">
        </mon-form-field-input>
        <mon-form-field-errors
            [showError]="url.control.touched && url.control.invalid"
            [errors]="url.control.errors">
        </mon-form-field-errors>
        <mon-form-field-input
            monLabel="{{ 'Main Selector' | translate }}"
            [name]="'mainselector'"
            [(ngModel)]="model.mainselector"
            monPlaceholder="{{'Enter the URL of the Custom Navigation' | translate}}">
        </mon-form-field-input>
        <!-- Selectors START-->
        <div class="row" *ngFor="let selector of model.selectors; let $index = index; trackBy: identify">
            <div class="col-sm-44 pl-7">
                <mon-form-field-input
                    type="text"
                    [(ngModel)]="model.selectors[$index]"
                    [name]="'selector-' + $index"
                    monLabel="{{'Selector' | translate}}"
                    [monPlaceholder]="''"
                />
            </div>
            <div class="col-sm-4 text-right">
                <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="removeSelector($index)"
                    attr.aria-label="{{'Remove Selector' | translate}}"
                    ngbTooltip="{{'Remove Selector' | translate}}"
                    [triggers]="'focus:mouseenter'"
                >
                    <mon-icon [icon]="['ACTIONS', 'REMOVE']" />
                </button>
            </div>
        </div>
        <div>
            <button
                type="button"
                class="btn btn-secondary"
                (click)="addSelector()"
                attr.aria-label="{{'Add Selector' | translate}}"
                ngbTooltip="{{'Add Selector' | translate}}"
                [triggers]="'focus:mouseenter'"
            >
                <mon-icon [icon]="['ACTIONS', 'ADD']" />
            </button>
        </div>
        <!-- Selectors END-->
    </div>
</div>
</div>
<div class="mon-dialog-footer text-right">
    <button
        type="submit"
        class="btn btn-secondary"
        [disabled]="customNavigationForm.invalid"
        translate
    >
        Save
    </button>
</div>
</form>
