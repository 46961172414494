import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { CookieRepoComponent } from '@monsido/pages/backend-admin/cookies/repo/cookie-repo.component';

export const BACKEND_ADMIN_COOKIE_REPO: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.admin.cookies',
        url: '/cookies',
        abstract: true,
        component: BaseStateComponent,
    },
    {
        name: 'base.backend_admin.admin.cookies.repo',
        url: '/repo',
        views: {
            '^.^.^.^.$default': {
                component: CookieRepoComponent,
            },
        },
        dynamic: true,
    },
];
