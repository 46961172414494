import { HeatmapSummaryInterface } from '@monsido/modules/models/api/interfaces/heatmap-summary.interface';

export class HeatmapSummary implements HeatmapSummaryInterface {
    url: string;
    user_count: number;
    clicks: number;

    constructor (heatmap?: HeatmapSummaryInterface) {
        Object.assign(this, heatmap);
    }
}
