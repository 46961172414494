<form name="customerDetail" #customerDetail="ngForm" class="test">
    <mon-form-field-input
        name="name"
        #customerName="ngModel"
        required
        [(ngModel)]="customer.name"
        monLabel="{{ 'Name' | translate }}"
        monPlaceholder="{{ 'Name' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="customerName.touched && customerName.invalid"
        [errors]="customerName.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="address"
        #address="ngModel"
        [(ngModel)]="customer.address"
        monLabel="{{ 'Address' | translate }}"
        monPlaceholder="{{ 'Address' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="address.touched && address.invalid"
        [errors]="address.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="zipcode"
        #zipcode="ngModel"
        [(ngModel)]="customer.zipcode"
        monLabel="{{ 'Zipcode' | translate }}"
        monPlaceholder="{{ 'Zipcode' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="zipcode.touched && zipcode.invalid"
        [errors]="zipcode.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="city"
        #city="ngModel"
        [(ngModel)]="customer.city"
        monLabel="{{ 'City' | translate }}"
        monPlaceholder="{{ 'City' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="city.touched && city.invalid"
        [errors]="city.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="state"
        #state="ngModel"
        [(ngModel)]="customer.state"
        monLabel="{{ 'State' | translate }}"
        monPlaceholder="{{ 'State' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="state.touched && state.invalid"
        [errors]="state.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="phone_number"
        #phoneNumber="ngModel"
        [(ngModel)]="customer.phone_number"
        monLabel="{{ 'Phone number' | translate }}"
        monPlaceholder="{{ 'Phone number' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="phoneNumber.touched && phoneNumber.invalid"
        [errors]="phoneNumber.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="attention"
        #attention="ngModel"
        [(ngModel)]="customer.attention"
        monLabel="{{ 'Attention' | translate }}"
        monPlaceholder="{{ 'Attention' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="attention.touched && attention.invalid"
        [errors]="attention.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="country"
        #country="ngModel"
        required
        [(ngModel)]="customer.country"
        monLabel="{{ 'Country' | translate }}"
        monPlaceholder="{{ 'Country' | translate }}"
        type="text"></mon-form-field-input>
    <mon-form-field-errors
        [showError]="country.touched && country.invalid"
        [errors]="country.errors">
    </mon-form-field-errors>

    <mon-form-field-input
        name="salesforce_account_id"
        [(ngModel)]="customer.salesforce_account_id"
        monLabel="{{ 'Salesforce ID' | translate }}"
        monPlaceholder="{{ 'Salesforce ID' | translate }}"
        type="text"></mon-form-field-input>


    <mon-form-field-select-timezones id="timezone"
        [(model)]="customer.timezone"
        monLabel="{{ 'Timezone' | translate }}">
    </mon-form-field-select-timezones>

    <div class="row">
        <div class="col-sm-offset-8 col-sm-40 inner-t-smurf">
            <mon-switch
                [(ngModel)]="customer.settings.legacy_compliance"
                class="inline-block outer-r-large outer-t-smurf pull-left"
                monLabel="settings_legacy_compliance"
                name="'settings_legacy_compliance'"
            ></mon-switch>
            <label for="settings_legacy_compliance" class="font-weight-400 pl-2 mt-1 inner-t-smurf" translate>
                Use Legacy compliance on Summaries and history center
            </label>
        </div>
    </div>

</form>
