import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';

@Injectable()
export class CrawlQueueRepo {
    constructor (private client: ApiClient) {}

    get (domainId: number): Promise<void> {
        return this.client.getPromise(`crawl_queue/${domainId}`).then(this.toModels.bind(this));
    }
    getAll (): Promise<CrawlQueue[]> {
        return this.client.getPromise('crawl_queue/').then(this.toModels.bind(this));
    }

    stopCrawl (domainId: number): Promise<void> {
        return this.client.deletePromise(`crawl_queue/${domainId}`);
    }

    rejectCrawl (domainId: number, params?: HttpParams | { [param: string]: string | string[] }): Promise<void> {
        return this.client.postPromise(`crawl_queue/${domainId}/reject`, params);
    }

    releaseCrawl (domainId: number, params?: HttpParams | { [param: string]: string | string[] }): Promise<void> {
        return this.client.postPromise(`crawl_queue/${domainId}/release`, params);
    }

    private toModels (models: CrawlQueue[]): CrawlQueue[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }

        return models;
    }

    private toModel (model: CrawlQueue): CrawlQueue {
        return new CrawlQueue(model);
    }
}
