import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ArgsInterface } from '@monsido_services/services/mon-translate/dist/translate.interface';
import { TranslateService } from 'app/services/translate/translate.service';
import { Subscription } from 'rxjs';

@Directive({
    // currently disabling this because of the functionality in our extractor 'angular-gettext-cli'
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[translate],[translate-attr]',
})
export class TranslateDirective implements OnInit, OnDestroy, OnChanges {
    @Input('translate-attr')
    private attr: string;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('translate-n')
    private n: number;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('translate-plural')
    private plural: string;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('translate-args')
    private args: ArgsInterface;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('translate-context')
    private context: string;

    private subscription: Subscription;
    private msgid: string;

    constructor (
        private element: ElementRef,
        private translateService: TranslateService,
    ) {}

    ngOnInit (): void {
        if (this.attr) {
            this.msgid = this.element.nativeElement.getAttribute(this.attr);
        } else {
            this.msgid = this.element.nativeElement.innerHTML;
        }

        if (this.n && !Number.isFinite(this.n)) {
            throw new Error('Attribute \'translate-n\' must be a number');
        }

        this.subscription = this.translateService.onLanguageChange$().subscribe({
            next: () => {
                this.update();
            },
        });
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (
            this.msgid &&
            ((changes.args && !changes.args.firstChange) ||
                (changes.plural && changes.plural.firstChange) ||
                (changes.n && !changes.n.firstChange))
        ) {
            this.update();
        }
    }

    ngOnDestroy (): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected setContent (message: string): void {
        if (this.attr) {
            this.element.nativeElement.setAttribute(this.attr, message);
        } else {
            this.element.nativeElement.innerHTML = message;
        }
    }

    private update (): void {
        if (this.n && this.plural) {
            this.setContent(
                this.translateService.getPlural(
                    Number(this.n),
                    this.msgid,
                    this.plural,
                    this.args,
                    this.context,
                ),
            );
        } else {
            this.setContent(
                this.translateService.getString(
                    this.msgid,
                    this.args,
                    this.context,
                ),
            );
        }
    }
}
