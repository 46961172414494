import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayWrapperComponent } from './components/overlay-wrapper/overlay-wrapper.component';


@NgModule({
    declarations: [
        OverlayWrapperComponent,
    ],
    exports: [
        OverlayWrapperComponent,
    ],
    imports: [
        CommonModule,
        A11yModule,
    ],
})
export class OverlayWrapperModule { }
