import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Cookie } from '@monsido/modules/models/support/cookie';
import { ActiveDialog, ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CookieDescriptionLanguages, CookieInterface, CookieType } from '@monsido/modules/models/support/interfaces/cookie.interface';
import { CookiesRepo } from '@monsido/modules/endpoints/api/support/cookies.repo';
import { BaseForm } from '@monsido/forms/base-form';
import { CookieCategoriesLocalizeService } from '../../../service/cookie-categories-localize.service';
import { TranslateService } from 'app/services/translate/translate.service';

interface SelectTypeOption {
    value: CookieType;
    name: string;
}

interface SelectLanguagesOption {
    value: CookieDescriptionLanguages;
    name: CookieDescriptionLanguages;
}

@Component({
    selector: 'mon-form-backend-admin-cookie',
    templateUrl: 'cookie-form.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormCookieComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() cookie: Cookie = new Cookie();
    @ViewChild('form', { static: false }) form: NgForm;
    typeDropdownOptions: SelectTypeOption[];
    typeDropdownLanguages: SelectLanguagesOption[];
    saving: boolean = false;
    cookieCategoriesLocalize: Record<string, string>;

    constructor (
        private activeDialog: ActiveDialog,
        private cookieRepo: CookiesRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private cookieCategoriesLocalizeService: CookieCategoriesLocalizeService,

    ) {
        super();
    }
    ngAfterViewInit (): void {
        setTimeout(() => this.resetFormState(this.form));
        this.activeDialog.setForm(this.form);
    }

    ngOnInit (): void {
        this.cookieCategoriesLocalize = this.cookieCategoriesLocalizeService.CookieCategoriesLocalize();
        this.typeDropdownOptions = [
            {
                name: this.cookieCategoriesLocalize[CookieType.ESSENTIAL],
                value: CookieType.ESSENTIAL,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.BASIC],
                value: CookieType.BASIC,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.MARKETING],
                value: CookieType.MARKETING,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.ANALYTICS],
                value: CookieType.ANALYTICS,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.PERSONALISATION],
                value: CookieType.PERSONALISATION,
            },
        ];
        this.typeDropdownLanguages = [
            { value: CookieDescriptionLanguages.EN_US, name: CookieDescriptionLanguages.EN_US },
            { value: CookieDescriptionLanguages.DA_DK, name: CookieDescriptionLanguages.DA_DK },
            { value: CookieDescriptionLanguages.DE_DE, name: CookieDescriptionLanguages.DE_DE },
            { value: CookieDescriptionLanguages.ES_ES, name: CookieDescriptionLanguages.ES_ES },
            { value: CookieDescriptionLanguages.NB_NO, name: CookieDescriptionLanguages.NB_NO },
            { value: CookieDescriptionLanguages.NL_NL, name: CookieDescriptionLanguages.NL_NL },
        ];
        if (!this.cookie.id) {
            this.cookie = new Cookie(this.cookie as unknown as CookieInterface);
            this.cookie.descriptions = [{ language: CookieDescriptionLanguages.EN_US, text: '' }];
            this.cookie.expirations = [{ language: CookieDescriptionLanguages.EN_US, text: '' }];
        }
    }

    addDescription (): void {
        this.cookie.descriptions.push({
            language: CookieDescriptionLanguages.EN_US,
            text: '',
        });
    }

    removeExpiration (index: number): void {
        this.cookie.expirations.splice(index, 1);
    }

    addExpiration (): void {
        this.cookie.expirations.push({
            language: CookieDescriptionLanguages.EN_US,
            text: '',
        });
    }

    removeDescription (index: number): void {
        this.cookie.descriptions.splice(index, 1);
    }

    submit (): void {
        this.form.control.markAsPristine();
        this.saving = true;
        if (this.cookie.id) {
            this.cookieRepo.update(this.cookie).then(() => {
                this.close();
                this.toastService.success(this.translateService.getString('Cookie is updated'));
            });
        } else {
            this.cookieRepo.create(this.cookie).then(() => {
                this.close();
                this.toastService.success(this.translateService.getString('Cookie is created'));
            });
        }
    }

    private close (): void {
        this.activeDialog.close(this.cookie);
    }

}
