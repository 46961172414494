<mon-page-header [monIcon]="['USERS', 'ICON']"
                 header="{{'Users' | translate}}"
                 data-test="users-page-header">
</mon-page-header>
<div class="row mb-3">
    <div class="col-xs-48 col-sm-32 col-md-36">
    </div>
    <div class="hidden-xs col-sm-16 col-md-12">
        <mon-table-search (getPage)="onSearch($event)" [model]="search" minSearchLength="1" data-test="search-input"></mon-table-search>
    </div>
</div>

<div class="card monsido-panel">
    <div class="card-body">
        <div class="row">
            <div class="col-md-48 col-lg-48 col-xs-48">
                <mon-table-container
                        (pageChangeEvent)="onPageChange($event)"
                        (perPageChange)="onPageSizeChange($event)"
                        [collection]="users"
                        [loading]="loading"
                        class="table-vertical-align"
                        data-test="users-table-container">
                    <div class="table-container-body">
                        <mon-admin-table-users
                            [users]="users"
                            [showCustomer]="true"
                            (getPage)="getPage()"
                            data-test="admin-table-users">
                        </mon-admin-table-users>
                    </div>
                </mon-table-container>
            </div>
        </div>
    </div>
</div>
