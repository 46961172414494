import { CustomMultistepLoginType } from 'types/domain';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';

@Component({
    selector: 'mon-form-domain-scan-login-types-custom-multistep',
    templateUrl: 'custom-multistep.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainScanLoginTypesCustomMultistepComponent implements OnInit {
    @Input() monLogin: CustomMultistepLoginType;
    @Input() monForm: NgForm;
    @Input() monRequestChecker: boolean = false;

    // eslint-disable-next-line no-useless-escape
    verifyLocationPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    ngOnInit (): void {
        this.monLogin = this.monLogin || { type: 'custom_multistep' };
        if (!this.monLogin.verify) {
            this.monLogin.verify = {
                location: '',
            };
        }
        if (!this.monLogin.custom_multistep) {
            this.monLogin.custom_multistep = {
                username: '',
                password: '',
                steps: [''],
            };
        }
    }

    addStep (): void {
        this.monLogin.custom_multistep.steps.push('');
    }

    removeStep (index: number): void {
        this.monLogin.custom_multistep.steps.splice(index, 1);
    }

    trackById (index: number): number {
        return GlobalHelperService.trackById(index);
    }
}
