import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatPipe } from 'ngx-moment';

@Pipe({
    name: 'monUserDate',
    standalone: true,
})
export class MonUserDatePipe implements PipeTransform {
    constructor () {}

    transform (date: string, format: string = 'll'): string {
        const dateFormatPipe = new DateFormatPipe();
        return dateFormatPipe.transform(date, this.customFormats(format));
    }

    private customFormats (format: string): string {
        return (format === 'fullDate')
            ? 'dddd, MMMM D, YYYY'
            : format;
    }
}
