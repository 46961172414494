import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { FormBuilderInputModule, FormBuilderSelectModule, FormBuilderValidationModule, MonSwitchModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormsModule, NgForm } from '@angular/forms';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-form-customer-detail',
    standalone: true,
    imports: [
        CommonModule,
        FormBuilderInputModule,
        FormBuilderSelectModule,
        FormBuilderValidationModule,
        FormsModule,
        MonSwitchModule,
        TranslateModule,
    ],
    templateUrl: './mon-form-customer-detail.component.html',
    styleUrls: ['./mon-form-customer-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonFormCustomerDetailComponent implements AfterViewInit {

    @Input() customer: CustomerInterface;
    @Output() monFormValidityChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('customerDetail', { static: false }) customerDetail: NgForm;

    private formValid?: boolean;

    ngAfterViewInit (): void {
        this.customerDetail.form.valueChanges.subscribe(() => {
            if (this.formValid !== this.customerDetail.valid) {
                this.formValid = this.customerDetail.valid;
                this.monFormValidityChanges.emit(this.customerDetail.valid);
            }
        });
    }
}
