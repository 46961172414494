import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';

export const RESELLER_ADMIN_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.admin.reseller_admin',
        url: '/reseller_admin',
        abstract: true,
        component: BaseStateComponent,
        data: {
            sidebar: 'reseller_settings',
        },
    },
];
