import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MonOverlaySpinner } from '@monsido/services/loading/component/loading-spinner.component';

@Injectable()
export class MonLoadingService {
    private overlayRef: OverlayRef | undefined = undefined;
    constructor (private overlay: Overlay) {}
    show (): void {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({ panelClass: 'mon-loading-spinner' });
            const spinnerOverlayPortal = new ComponentPortal(MonOverlaySpinner);
            this.overlayRef.attach(spinnerOverlayPortal);
        }
    }

    hide (): void {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.addPanelClass('fade-out');
            setTimeout(() => {
                if (this.overlayRef) {
                    this.overlayRef.dispose();
                    delete this.overlayRef;
                }
            }, 500);
        }
    }
}
