import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterButtonComponent } from './filter-button.component';

@NgModule({
    imports: [CommonModule],
    exports: [FilterButtonComponent],
    declarations: [FilterButtonComponent],
    providers: [],
})
export class FilterButtonModule {}
