import { Component, Input, OnInit } from "@angular/core";
import { ScansInterface } from '@monsido/modules/models/api/interfaces/scans.interface';

@Component({
    selector: 'mon-request-checker-accessibility-component',
    templateUrl: 'accessibility.html',
    styleUrls: ['./../../request-checker.scss'],
})

export class AccessibilityResultComponent implements OnInit {
    @Input() url: string;
    @Input() scan: ScansInterface;
    tableData = new Map();
    expandRows: boolean[] = [];
    constructor () {}
    
    ngOnInit() {
        const resMap = new Map();
        for (const acc of this.scan.accessibility_errors) {
            let count = resMap.get(acc.check_id);
            if (count) {
                resMap.set(acc.check_id, ++count);
            } else {
                resMap.set(acc.check_id, 1);
            }
        }
        this.tableData = Object.fromEntries(resMap);
    }
    
    getErrors(checkId: string) {
        return this.scan.accessibility_errors.filter((errorRow) => {
            return errorRow.check_id === parseInt(checkId);
        });
    }
}
