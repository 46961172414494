<div class="table-container-body">
    <table class="table table-fixed table-hover">
        <thead>
        <tr>
            <th translate>Category</th>
            <th translate>Domain</th>
            <th translate>Expires</th>
            <th translate>Http only</th>
            <th translate>Name</th>
            <th translate>Path</th>
            <th translate>Secure</th>
            <th translate>Value</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let cookie of scan.cookies">
            <td class="request-word-wrapper">{{ cookie.category }}</td>
            <td class="request-word-wrapper">{{ cookie.domain }}</td>
            <td class="request-word-wrapper">{{ cookie.expires }}</td>
            <td class="request-word-wrapper">{{ cookie.http_only }}</td>
            <td class="request-word-wrapper">{{ cookie.name }}</td>
            <td class="request-word-wrapper">{{ cookie.path }}</td>
            <td class="request-word-wrapper">{{ cookie.secure }}</td>
            <td class="request-word-wrapper">{{ cookie.value }}</td>
            
        </tr>
        </tbody>
    </table>
</div>
