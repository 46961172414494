import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class SearchInterceptor implements HttpInterceptor {
    intercept (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let data = req.clone();
        if (data.method === 'GET') {
            if (data.params.get('search') === 'undefined' || data.params.get('search') === undefined) {
                data = data.clone({
                    params: data.params.delete('search'),
                });
            } else {
                if (data.params.get('search') === null || (typeof data.params.get('search') && data.params.get('search').length === 0)) {
                    // A dirty hack until the Backend issue is fixed - https://optimere.atlassian.net/browse/MON-5684 ,
                    // we added the check, if the URL is not related to Users
                    if (!data.url.endsWith('/users')) {
                        data = data.clone({
                            params: data.params.delete('search'),
                        });
                    }
                } else {
                    if (!req.headers.has('noParseSearch')) {
                        const search = data.params.get('search');
                        data = data.clone({
                            params: data.params.delete('search'),
                        });

                        data = data.clone({
                            params: data.params.append('search', '%' + search + '%'),
                        });
                    }
                }
            }
        }
        return next.handle(data);
    }
}
