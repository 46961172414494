import { NgModule } from '@angular/core';
import { ParamService } from '@monsido/core/param/param.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ParamService],
})
export class ParamModule {}
