import { Injectable } from '@angular/core';
import { BackendCrawlerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-crawler.repo';
import { BackendCrawlerScaleStatusInterface } from '@monsido/modules/endpoints/api/backend_admin/backend-crawler-scale-status.interface';

@Injectable()
export class BackendAdminCrawlersService {
    constructor (private backendCrawlerRepo: BackendCrawlerRepo) {}

    getPage (): Promise<unknown> {
        return this.backendCrawlerRepo.getAll();
    }

    getScaleStatus (): Promise<BackendCrawlerScaleStatusInterface> {
        return this.backendCrawlerRepo.getScaleStatus();
    }

    scaleUp (): Promise<unknown> {
        return this.backendCrawlerRepo.scaleUp();
    }

    scaleDown (): Promise<unknown> {
        return this.backendCrawlerRepo.scaleDown();
    }

    pauseCrawlers (): Promise<unknown> {
        return this.backendCrawlerRepo.pauseCrawlers();
    }

    resumeCrawlers (): Promise<unknown> {
        return this.backendCrawlerRepo.resumeCrawlers();
    }

    sortCrawlers (CrawlersArr: Record<string, string>[]): Record<string, string>[] {
        return CrawlersArr.sort((a: Record<string, string>, b: Record<string, string>) => {
            const aUp = a.host.toUpperCase();
            const bUp = b.host.toUpperCase();
            if (aUp < bUp) {
                return -1;
            } else if (aUp > bUp) {
                return 1;
            } else {
                return 0;
            }
        });
    }
}
