import { Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';

import { Customer } from '@monsido/modules/models/api/customer';
import { Domain } from '@monsido/modules/models/api/domain';
import { JiraSearchService } from '@monsido/pages/reseller/jira-search/search.service';
import { environment } from '../../../../environments/environment';

interface SearchEnv {
    name: string;
    url: string;
    customer: Customer | undefined;
    domain: Domain | undefined;
}

@Component({
    selector: 'mon-jira-search-page',
    templateUrl: 'search.html',
})
export class JiraSearchPageComponent implements OnInit {
    jira: boolean;
    envs: Array<SearchEnv> = [];
    customer: Customer | void;
    domain: Domain | void;
    searching: boolean = false;
    domain_id: number;
    customer_id: number;
    loading: boolean = false;
    constructor (private $location: UrlService, private searchService: JiraSearchService) {}

    async ngOnInit (): Promise<void> {
        this.domain_id = this.$location.search()['domain_id'];
        this.customer_id = this.$location.search()['customer_id'];
        this.jira = this.$location.search()['jira'];

        if (this.domain_id || this.customer_id) {
            await this.search();
        }
    }

    canSearch (): number {
        return this.domain_id || this.customer_id;
    }

    async goTo (url: string, envName: string, customer?: Customer, domain?: Domain): Promise<void> {
        if (domain) {
            await this.searchService.redirectToDomain(domain, url, envName);
        } else if (customer) {
            await this.searchService.redirectToCustomer(customer, url, envName);
        }
    }

    async search (): Promise<void> {
        let env: { name: string; url: string };
        this.customer = null;
        this.domain = null;
        this.envs = [];
        let singleEnv: SearchEnv;
        this.searching = true;
        for (let i = 0; i < environment.apiEnvs.length; i++) {
            env = environment.apiEnvs[i];
            singleEnv = {
                name: env.name,
                url: env.url,
                customer: undefined,
                domain: undefined,
            };
            if (!this.customer) {
                if (this.customer_id) {
                    this.customer = await this.searchService.searchCustomer(this.customer_id, env.url).catch(() => {});
                    if (this.customer) {
                        singleEnv.customer = this.customer;
                    }
                }
            }

            if (!this.domain) {
                if (this.domain_id) {
                    this.domain = await this.searchService.searchDomain(this.customer_id, this.domain_id, env.url).catch(() => {});
                    if (this.domain) {
                        singleEnv.domain = this.domain;
                    }
                }
            }
            this.envs.push(singleEnv);
        }
        this.searching = false;
    }
}
