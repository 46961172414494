import { Injectable } from '@angular/core';
import { ArgsInterface, LanguageType, TranslationStrings } from '@monsido_services/services/mon-translate/dist/translate.interface';
import { TranslateService as MonTranslateService } from '@monsido_services/services/mon-translate/dist/translate.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslateService {

    private service = new MonTranslateService();

    get baseLanguage (): LanguageType {
        return this.service.baseLanguage;
    }

    constructor () { }

    onLanguageChange$ (): Observable<string> {
        return this.service.onLanguageChange$();
    }

    getString (text: string, args: ArgsInterface = {}, context?: string): string {
        return this.service.getString(text, args, context);
    }

    getPlural (n: number, text: string, textPlural: string, args: ArgsInterface, context: string): string {
        return this.service.getPlural(n, text, textPlural, args, context);
    }

    setCurrentLanguage (lang: LanguageType): void {
        this.service.setCurrentLanguage(lang);
    }

    setStrings (language: LanguageType, strings: TranslationStrings): void {
        this.service.setStrings(language, strings);
    }
}
