<hr class="row mb-0" *ngIf="!monRequestChecker"/>
<div class="row" *ngIf="!monRequestChecker">
    <div class="col-sm-offset-8 col-sm-40">
        <h3 class="font-weight-300" translate>Office 365</h3>
    </div>
</div>
<div class="form-group">
    <label for="domain_scan_login_office365_email"
           ngbTooltip="{{'Email to login' | translate}}"
           [triggers]="'focus:mouseenter'"
           class="col-form-label col-sm-8"
           [ngClass]="{'text-right': monRequestChecker}"
           translate>
        Email
    </label>
    <div class="col-sm-40">
        <mon-form-field-input
               [monId]="'domain_scan_login_office365_email'"
               [monPlaceholder]="''"
               [name]="'domain_scan_login_office365_email'"
               [type]="'email'"
               #office365_email="ngModel"
               required
               [(ngModel)]="monLogin.office365.email"></mon-form-field-input>
    </div>
    <mon-form-field-errors
        [showError]="office365_email.control.touched && office365_email.control.invalid"
        [errors]="office365_email.control.errors">
    </mon-form-field-errors>
</div>
<div class="form-group">
    <label for="domain_scan_login_office365_password"
           ngbTooltip="{{'Password to login' | translate}}"
           [triggers]="'focus:mouseenter'"
           class="col-form-label col-sm-8"
           [ngClass]="{'text-right': monRequestChecker}"
           translate>
        Password
    </label>
    <div class="col-sm-40">
        <mon-form-field-input
            [monId]="'domain_scan_login_office365_password'"
            [monPlaceholder]="''"
            [name]="'domain_scan_login_office365_password'"
            [type]="'password'"
            #office365_password="ngModel"
            required
            [(ngModel)]="monLogin.office365.password"></mon-form-field-input>
    </div>
    <mon-form-field-errors
        [showError]="office365_password.control.touched && office365_password.control.invalid"
        [errors]="office365_password.control.errors">
    </mon-form-field-errors>
</div>
<div class="row">
    <div class="col-sm-offset-8 col-sm-46">
        <div class="row">
            <div class="col-sm-4 inner-t-smurf">
                <mon-switch
                    [(ngModel)]="monLogin.office365.remember_me_step"
                    [monLabel]="'domain_scan_login_office365_remember_me_step'"
                    [name]="'domain_scan_login_office365_remember_me_step'"
                ></mon-switch>
            </div>
            <div class="col-sm-44 mt-2">
                <label for="domain_scan_login_office365_remember_me_step" class="font-weight-400" translate>
                    Remember me
                </label>
            </div>
        </div>
    </div>
</div>
