import { Injectable } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { UsersRepo } from '@monsido/modules/endpoints/api/admin/users.repo';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UsersService {
    constructor (private usersRepo: UsersRepo) {}

    getPage (params: HttpParams): Promise<User[]> {
        return this.usersRepo.getAll(params);
    }
}
