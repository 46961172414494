export enum Verticals {
    FINANCIAL_SERVICES = 'financial-services',
    RETAIL_WHOLESALE = 'retail-wholesale',
    CONSTRUCTION_INDUSTRIAL = 'construction-industrial',
    BUSINESS_SERVICES = 'business-services',
    GOVERNMENT = 'government',
    NON_PROFIT = 'non-profit',
    EDUCATION = 'education',
    LAW_ENFORCEMENT = 'law-enforcement',
    CONSUMER_SERVICES = 'consumer-services',
    ENTERTAINMENT_LEISURE = 'entertainment-leisure',
    HEALTHCARE = 'healthcare',
    LEGAL = 'legal',
    MEDIA = 'media',
    REAL_ESTATE = 'real-estate',
    TECHNOLOGY = 'technology',
    UTILITIES_NATURAL_RESOURCES = 'utilities-natural-resources',
    PROFESSIONAL_SERVICES = 'professional-services',
    LOCAL_GOVERNMENT = 'local-government',
    OTHER = 'other'
}

export type VerticalsType = `${Verticals}`;

export type VerticalsOptionsType = Array<{ name: string, value: VerticalsType }>
