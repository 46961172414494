import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeRepo } from '@monsido/modules/endpoints/api/admin/me.repo';
import { ActiveDialog, FormBuilderInputModule, FormBuilderValidationModule, IconsModule, LayoutModule, ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { SessionService } from '@monsido/core/session/session.service';
import { EmailValidatorService } from '@monsido/modules/utils/email-validator.service';
import _ from 'lodash';
import { Me } from '@monsido/modules/models/api/me';
import { FormsModule, NgForm } from '@angular/forms';
import { MonEscapeRegExpPipe } from 'app/filters/mon-escape-reg-exp.pipe';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-profile',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        FormBuilderInputModule,
        FormBuilderValidationModule,
        IconsModule,
        LayoutModule,
        MonEscapeRegExpPipe,
        TranslateModule,
    ],
    templateUrl: './profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormProfileComponent implements OnInit {
    @Input() profile: Me | null = null;
    @ViewChild('profileForm', { static: false }) profileForm: NgForm;
    emailPattern: string;
    saving: boolean = false;

    constructor (
        private meRepo: MeRepo,
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private sessionService: SessionService,
        private emailValidatorService: EmailValidatorService,
        private cdRef: ChangeDetectorRef,
    ) {}

    async ngOnInit (): Promise<void> {
        this.emailPattern = this.emailValidatorService.getEmailPattern();
        this.saving = false;

        const user = await this.sessionService.getUser();
        this.profile = user ? _.cloneDeep(user) : null;
        if (!this.profile.contact_info) {
            this.profile.contact_info = {
                phone: '',
                industry: '',
            };
        }
        this.cdRef.markForCheck();
    }

    async submit (): Promise<void> {
        this.saving = true;

        if (!this.profile.password || this.profile.password === this.profile.password_confirmation) {
            try {
                const me = await this.meRepo.update(this.profile);
                this.sessionService.afterLogin(me);
                this.activeDialog.close(this.profile);
                this.toastService.success(this.translateService.getString('Profile updated'));
            } finally {
                this.saving = false;
            }
        }
    }
}
