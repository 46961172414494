import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { ConstraintsAndExcludesComponent } from './constraints-and-excludes.component';

export const CONSTRAINTS_AND_EXCLUDES_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.admin.constraints_and_excludes',
        url: '/constraints-and-excludes',
        abstract: true,
        component: BaseStateComponent,
    },
    {
        name: 'base.admin.constraints_and_excludes.index',
        url: '',
        views: {
            '^.^.^.$default': {
                component: ConstraintsAndExcludesComponent,
            },
        },
        dynamic: true,
    },
];
