import { Ng2StateDeclaration } from '@uirouter/angular';
import { SourceCodeExcludesComponent } from './source-code-excludes.component';

export const SOURCE_CODE_EXCLUDE_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.admin.source_code_excludes',
        url: '/accessibility/source-code-excludes',
        abstract: true,
        component: SourceCodeExcludesComponent,
    },
    {
        name: 'base.admin.source_code_excludes.index',
        url: '?page_size&page',
        views: {
            '^.^.^.$default': {
                component: SourceCodeExcludesComponent,
            },
        },
    },
];
