 <mon-form-field-input [name]="'first_name'"
                      required
                      #first_name="ngModel"
                      [(ngModel)]="user.first_name"
                      monLabel="{{'First name' | translate}}"
                      monPlaceholder="{{'First name' | translate}}"
                      [useCustomError]="false"
                      [type]="'text'">
</mon-form-field-input>

<mon-form-field-input [(ngModel)]="user.last_name"
                      monLabel="{{'Last name' | translate}}"
                      monPlaceholder="{{'Last name' | translate}}"
                      [type]="'text'"
                      [name]="'last_name'">
</mon-form-field-input>

<mon-form-field-select-timezones id="timezone"
                                 [(model)]="user.timezone"
                                 monLabel="{{ 'Timezone' | translate }}"
                                 monHelpTxt="{{ 'Choose a timezone' | translate }}">
</mon-form-field-select-timezones>

<mon-form-field-input [name]="'email'"
                      #email="ngModel"
                      monEmailValidator
                      [(ngModel)]="user.email"
                      monLabel="{{ 'Email' | translate }}"
                      monPlaceholder="{{ 'Email' | translate }}"
                      [type]="'email'"
                      [useCustomError]="false"
                      required>
</mon-form-field-input>

<mon-form-field-checkbox
    [(model)]="user.customer_admin"
    [monName]="'customer_admin'"
    monLabel="{{'Customer admin' | translate}}">
</mon-form-field-checkbox>

<mon-form-field-select [monName]="'locale'"
                       [(model)]="user.locale"
                       monLabel="{{ 'language' | translate }}"
                       monPlaceholder="{{ 'language' | translate }}"
                       [monOptions]="supportedLanguages"
                       [monReturnValue]="'code'"
                       [hidden]="!isAdmin">
</mon-form-field-select>

<ng-container *ngIf="user.id">
    <mon-form-field-input [(ngModel)]="user.password"
                          monLabel="{{ 'Password' | translate }}"
                          monPlaceholder="{{ 'Password' | translate }}"
                          [type]="'password'"
                          #password
                          monHelpText="{{'If not filled, a password will be auto generated' | translate}}"
                          [name]="'password'">
    </mon-form-field-input>

    <mon-form-field-input [pattern]="password.value | monEscapeRegExp"
                          patternMsg="{{ 'Passwords do not match' | translate }}"
                          [(ngModel)]="user.password_confirmation"
                          [useCustomError]="false"
                          monLabel="{{ 'Password confirmation' | translate }}"
                          monPlaceholder="{{ 'Password confirmation' | translate }}"
                          [type]="'password'"
                          [name]="'password_confirmation'">
    </mon-form-field-input>
</ng-container>

<mon-form-field-checkbox [(model)]="user.can_sudo"
                         monLabel="{{ 'Can sudo' | translate }}"
                         [monName]="'can_sudo'">
</mon-form-field-checkbox>

<div class="row" *ngIf="!user.id">
    <div class="col-sm-11 pr-0">
        <mon-switch [(ngModel)]="user.send_welcome_email"
                    class="inline-block mt-2 mr-3 pull-right"
                    [monLabel]="'send_welcome_email'"
                    [name]="'send_welcome_email'">
        </mon-switch>
    </div>
    <div class="col-sm-37 pl-2 mt-3">
        <label for="send_welcome_email" class="font-weight-400 no-padding" translate>
            Send password mail on creation
        </label>
    </div>
</div>
