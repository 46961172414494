<form name="form" class="form-horizontal" #pdfForm="ngForm" (ngSubmit)="submit()">
    <div class="modal-header d-flex align-items-start">
        <div class="mr-auto">
            <h3 class="modal-title">{{title}}</h3>
        </div>
        <div class="ml-2">
            <mon-icon class="text-grey" [icon]="['ACTIONS', 'CLOSE']" (click)="cancel('cancelled')"></mon-icon>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="tokens" class="col-sm-8 col-form-label" translate>Tokens</label>
            <div class="col-sm-40">
                <input
                    type="number"
                    min="0"
                    class="form-control"
                    id="tokens"
                    [name]="'tokens'"
                    [placeholder]="'Tokens' | translate"
                    [(ngModel)]="tokens"
                />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-warning" type="button" (click)="cancel('cancelled')">Cancel</button>
        <button class="btn btn-primary" [disabled]="pdfForm.invalid" type="submit">Add</button>
    </div>
</form>
