import { DefaultLinkExcludedInterface } from '../default-constraints-and-excludes.interface';

export class DefaultLinkExcludeModel implements DefaultLinkExcludedInterface {

    regex: string = '';
    tags: string[] = [];

    constructor (entry: DefaultLinkExcludedInterface, cms: string) {
        this.regex = entry.regex;
        this.tags = ['default', cms];
    }

}
