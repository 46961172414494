import { Pipe, PipeTransform } from '@angular/core';
import { StringService } from '@monsido/tools/string.service';

@Pipe({
    name: 'monEscapeRegExp',
    standalone: true,
})
export class MonEscapeRegExpPipe implements PipeTransform {
    constructor () {}

    transform (str: string | number = ''): string {
        return StringService.escapeRegExp(str);
    }
}
