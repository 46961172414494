<form name="form" #form="ngForm" class="form-horizontal" (submit)="submit(form)">
    <div class="mon-dialog-header">
        <mon-page-header header="{{'Source code excludes' | translate}}"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Details</h3>
            </div>
            <div class="card-body" *ngIf="data">
                <div class="form-group">
                    <label class="col-form-label col-sm-4" for="description" translate>Name*</label>
                    <div class="col-sm-44">
                        <input placeholder="{{'Describe the exclude' | translate}}"
                               required="true"
                               name="short_description"
                               #short_description="ngModel"
                               [(ngModel)]="data.short_description"
                               class="form-control"
                               id="description"/>
                    </div>
                    <mon-form-field-errors
                        [offset]="4"
                        [showError]="short_description.touched && short_description.invalid"
                        [errors]="short_description.errors">
                    </mon-form-field-errors>
                </div>
                <div class="form-group row">
                    <label class="col-form-label text-right text-bold col-sm-4" for="cms" translate>Default for CMS</label>
                    <div class="col-sm-44">
                        <mon-form-field-select-ac
                                                name="cms"
                                               [(ngModel)]="data.default_for_cms"
                                               [monOptions]="cmses"
                                               monReturnValue="id"
                                               monPlaceholder="{{ 'None' | translate }}"
                                               [monRemoveButton]="true">
                        </mon-form-field-select-ac>
                    </div>
                </div>
                <div class="row" *ngIf="!accessibilityChecksLoading">
                    <div class="col-sm-offset-4 col-sm-44">
                        <div *ngFor="let rule of data.match_rules | slice:((page-1) * pageSize):(page * pageSize+1); index as i">
                            <mon-source-code-exclude-entry
                                [rule]="rule"
                                [typeOptions]="typeOptions"
                                [canBeDeleted]="data.match_rules.length > 1"
                                [accessibilityChecks]="defaultAccessibilityChecksOptions"
                                (update)="validate$.next(null)"
                                (remove)="onRuleRemove(i)"
                            ></mon-source-code-exclude-entry>
                        </div>

                        <div class="row">
                            <div class="col-xs-48 text-right">
                                <ngb-pagination
                                        [maxSize]="4"
                                        class="pagination-sm"
                                        [collectionSize]="data.match_rules.length"
                                        [(page)]="page"
                                        [pageSize]="pageSize">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-offset-4 col-sm-44">
                        <button type="button"
                                class="btn btn-secondary"
                                (click)="addMatchRule()">
                            <span class="text-link" [ngClass]="['ACTIONS', 'ADD'] | monIcons"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer d-flex align-items-start">
        <div class="pl-4">
            <span class="text-link fas fa-info-circle" aria-hidden="true"></span>
            <span class="ms-1" translate>Remember to add this source code exclude to your domain(s) through the Edit Domain screen.</span>
        </div>
        <button class="btn btn-secondary ml-auto" [disabled]="saving || form.invalid || !dataIsValid" type="submit" data-test="save-button">
            <span *ngIf="!saving" translate>Save</span>
            <span *ngIf="saving" class="fa-spin fa-fw" [ngClass]="['SPINNER', 'ICON'] | monIcons" aria-hidden="true"></span>
        </button>
    </div>
</form>
