import { Injectable } from '@angular/core';
import { CookieInterface } from '@monsido/modules/models/support/interfaces/cookie.interface';
import { CookiesRepo } from '@monsido/modules/endpoints/api/support/cookies.repo';
import { ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { DialogService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormCookieComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/cookie/cookie-form.component';
import { CloneService } from '@monsido/modules/utils/clone/clone.service';
import { Cookie } from '@monsido/modules/models/support/cookie';
import { TranslateService } from 'app/services/translate/translate.service';

@Injectable()
export class CookieService {
    constructor (
        private cookieRepo: CookiesRepo,
        private dialogService: DialogService,
        private toastService: ToastService,
        private translateService: TranslateService,
    ) {}

    getAll (params: Record<string, string | number>): Promise<CookieInterface[]> {
        return this.cookieRepo.getAll(params);
    }

    createCookie (cb: (...args: unknown[]) => void): void {
        this.dialogService.open(FormCookieComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb,
        });
    }

    editCookie (cookie: CookieInterface, cb: (...args: unknown[]) => void): void {
        const dialogRef = this.dialogService.open(FormCookieComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb,
        });

        dialogRef.componentInstance.cookie = CloneService.clone(cookie);
    }

    getCookies (page: number, pageSize: number, cookies: Cookie[]): Promise<Cookie[]> {
        page++;
        return this.cookieRepo.getAll({ page, page_size: pageSize }).then((data: Cookie[]) => {
            cookies = cookies.concat(data);
            if (data.length < pageSize) {
                return cookies;
            }
            return this.getCookies(page, pageSize, cookies);
        });
    }

    getAllCookies (): Promise<Cookie[]> {
        return this.getCookies(0, 100, []);
    }

    deleteCookie (id: number): Promise<void> {
        return this.cookieRepo.destroy(id).then(
            () => {
                this.toastService.success(this.translateService.getString('Cookie is deleted'));
            },
            () => {},
        );
    }
}
