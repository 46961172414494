<mon-page-header [monIcon]="['PLANS', 'ICON']"  header="{{'Cookies' | translate}}">
    <div class="text-right">
        <button class="btn btn-default mt-2 mb-4 mr-1" (click)="exportCookies($event)" data-test="export-cookies">{{'Export Cookies' | translate}}</button>
        <button class="btn btn-default mt-2 mb-4 mr-1" (click)="importCookies($event)" data-test="import-cookies">{{'Import Cookies' | translate}}</button>
        <button class="btn btn-default mt-2 mb-4" (click)="createCookie($event)" data-test="new-cookie">{{'New Cookie' | translate}}</button>
        <mon-table-search
            [minSearchLength]="1"
            [model]="search"
            (getPage)="onSearch($event)"
            data-test="search-input">
        </mon-table-search>
    </div>
</mon-page-header>

<p translate>
    This section on staging should only be updated by Rune and Thomas
</p>

<div class="card monsido-panel">
    <div class="card-body">
        <mon-table-container [collection]="cookies"
                             (pageChangeEvent)="onPageChange($event)"
                             (perPageChange)="onPageSizeChange($event)"
                             class="table-vertial-align"
                             [loading]="loading">
            <div class="table-container-body">
                <table class="table table-hover table-responsive table-fixed">
                    <thead>
                    <tr>
                        <th class="col-sm-12 col-md-12 col-lg-10" translate>Name</th>
                        <th class="col-sm-12 col-md-12 col-lg-10" translate>Platform</th>
                        <th class="col-sm-12 col-md-12 col-lg-10" translate>Type</th>
                        <th translate class="text-center col-sm-5 col-md-4 col-lg-4">Translations</th>
                        <th class="text-right col-sm-5 col-md-5 col-lg-4"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let cookie of cookies">
                        <td>{{ cookie.name }}</td>
                        <td>{{ cookie.platform }}</td>
                        <td>{{ cookie.type }}</td>
                        <td class="text-center">
                            {{ cookie.descriptions.length }}
                        </td>
                        <td class="text-right">
                            <mon-table-dropdown data-test="action-button" >
                                <li ngbDropdownItem role="menuitem">
                                    <a href (click)="editCookie($event, cookie)" data-test="edit-button">
                                        <mon-icon class="mr-1" [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                                        <span translate>Edit Cookie</span>
                                    </a>
                                </li>
                                <li ngbDropdownItem role="menuitem">
                                    <a href monPrompt="{{ 'To confirm deletion, please input the id of the cookie' | translate}} ( id: {{cookie.id}})"
                                       (monPromptAction)="onDeleteCookie(cookie, $event)" data-test="delete-button">
                                        <mon-icon class="mr-1" [icon]="['ACTIONS', 'DELETE']"></mon-icon>
                                        <span translate>Delete cookie</span>
                                    </a>
                                </li>
                            </mon-table-dropdown>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </div>
</div>
