import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Cookie } from '@monsido/modules/models/support/cookie';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CookieDescriptionLanguages, CookieType } from '@monsido/modules/models/support/interfaces/cookie.interface';
import { CookieService } from '@monsido/pages/backend-admin/cookies/repo/cookie.service';
import { Papa } from 'ngx-papaparse';
import { CookieCategoriesLocalizeService } from '../../../service/cookie-categories-localize.service';

interface SelectTypeOption {
    value: CookieType;
    name: string;
}

interface SelectLanguagesOption {
    value: CookieDescriptionLanguages;
    name: CookieDescriptionLanguages;
}

interface exportParams {
    language?: CookieDescriptionLanguages;
    type?: CookieType;
    platform?: string;
}

interface ExportCookie {
    language: CookieDescriptionLanguages;
    name: string;
    type: CookieType;
    platform: string;
    description: string;
    expiration: string;
}

@Component({
    selector: 'mon-form-backend-admin-cookie',
    templateUrl: 'export-form.html',
})
export class FormExportCookieComponent implements OnInit {
    @Input() cookie: Cookie = new Cookie();
    typeDropdownOptions: SelectTypeOption[];
    typeDropdownLanguages: SelectLanguagesOption[];
    form: NgForm;
    exporting: boolean = false;
    params: exportParams = {};
    cookieCategoriesLocalize: Record<string, string>;

    constructor (
        private activeDialog: ActiveDialog,
        private cookieService: CookieService,
        private papa: Papa,
        private cookieCategoriesLocalizeService: CookieCategoriesLocalizeService,
    ) {}

    ngOnInit (): void {
        this.cookieCategoriesLocalize = this.cookieCategoriesLocalizeService.CookieCategoriesLocalize();
        this.typeDropdownOptions = [
            {
                name: this.cookieCategoriesLocalize[CookieType.ESSENTIAL],
                value: CookieType.ESSENTIAL,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.BASIC],
                value: CookieType.BASIC,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.MARKETING],
                value: CookieType.MARKETING,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.ANALYTICS],
                value: CookieType.ANALYTICS,
            },
            {
                name: this.cookieCategoriesLocalize[CookieType.PERSONALISATION],
                value: CookieType.PERSONALISATION,
            },
        ];
        this.typeDropdownLanguages = [
            { value: CookieDescriptionLanguages.EN_US, name: CookieDescriptionLanguages.EN_US },
            { value: CookieDescriptionLanguages.DA_DK, name: CookieDescriptionLanguages.DA_DK },
            { value: CookieDescriptionLanguages.DE_DE, name: CookieDescriptionLanguages.DE_DE },
            { value: CookieDescriptionLanguages.ES_ES, name: CookieDescriptionLanguages.ES_ES },
            { value: CookieDescriptionLanguages.NB_NO, name: CookieDescriptionLanguages.NB_NO },
            { value: CookieDescriptionLanguages.NL_NL, name: CookieDescriptionLanguages.NL_NL },
        ];
    }

    submit (): void {
        this.exporting = true;
        this.cookieService.getAllCookies().then(async (data: Cookie[]) => {
            const cookies: ExportCookie[] = await this.parseCookies(data);
            this.downloadCsv(cookies);
            this.close();
        });
    }

    async parseCookies (cookies: Cookie[]): Promise<ExportCookie[]> {
        const data: ExportCookie[] = [];
        let cookie: ExportCookie;
        for (let i = 0; i < cookies.length; i++) {
            cookie = {
                name: cookies[i].name,
                platform: cookies[i].platform,
                type: cookies[i].type,
                description: '',
                expiration: '',
                language: this.params?.language,
            };
            if (this.params?.type !== cookies[i].type) {
                continue;
            }

            if (this.params?.platform !== cookies[i].platform) {
                continue;
            }

            if (this.params?.language) {
                for (let u = 0; u < cookies[u].descriptions.length; u++) {
                    if (cookies[i]?.descriptions[u]?.language === this.params.language) {
                        cookie.description = cookies[i].descriptions[u].text;
                    }
                }
                for (let u = 0; u < cookies[u].expirations.length; u++) {
                    if (cookies[i]?.expirations[u]?.language === this.params.language) {
                        cookie.expiration = cookies[i].expirations[u].text;
                    }
                }
            }
            data.push(cookie);
        }
        return data;
    }

    private downloadCsv (cookies: ExportCookie[]): void {
        const csvData = new Blob([this.papa.unparse(cookies, { quotes: true, header: true, delimiter: ';' })], {
            type: 'text/csv;charset=utf-8;',
        });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'download.csv');
        tempLink.click();
    }

    private close (): void {
        this.activeDialog.close(this.cookie);
    }
}
