<ng-container *ngIf="scan.readability">
    <table class="table table-fixed table-hover" [attr.aria-label]="'Readability test results: Key-Value pairs' | translate">
        <thead>
            <tr>
                <th translate>Key</th>
                <th translate class="text-right">Value</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td translate>Level</td>
                <td class="text-right">
                    {{ scan.readability.level }}
                </td>
            </tr>
            <tr>
                <td translate>Score</td>
                <td class="text-right">
                    {{ scan.readability.score }}
                </td>
            </tr>
            <tr>
                <td translate>Word count</td>
                <td class="text-right">
                    {{ scan.readability.word_count }}
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-fixed table-hover" [attr.aria-label]="'Text for readability test' | translate">
        <thead>
            <tr>
                <th translate>Text</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{scan.readability.text}}</td>
            </tr>
        </tbody>
    </table>
</ng-container>
<span *ngIf="!scan.readability" translate>No readability data</span>