/* eslint-disable valid-jsdoc */
import { Injectable } from '@angular/core';

@Injectable()
export class SearchService {
    /**
     * @desc Fuzzy string matching function. Sample taken from https://gist.github.com/dtjm/3961452
     * @link https://gist.github.com/dtjm/3961452
     * @param String needle - String to look for
     * @param String haystack - String to match
     * @return Boolean
     */
    fuzzy (needle: string, haystack: string): boolean {
        if (needle === '' || haystack === '') {
            return true;
        }

        needle = needle.toLowerCase().replace(/ /g, '');
        haystack = haystack.toLowerCase();

        // All characters in needle must be present in haystack
        let j: number = 0; // haystack position
        for (const character of needle) {
            // Go down the haystack until we find the current needle character
            while (character !== haystack[j]) {
                j++;

                // If we reached the end of the haystack, then this is not a match
                if (j === haystack.length) {
                    return false;
                }
            }
        }

        // At this point, we have matched every single letter in the needle without returning false
        return true;
    }
}
