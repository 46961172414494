import { Injectable } from '@angular/core';
import { JOB_TYPES } from '@monsido/modules/job/job.constant';
import { BehaviorSubject, interval, noop, Subscription } from 'rxjs';
import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';
import { BackendJobRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-job-repo';
import { ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Job } from '@monsido/modules/models/api/job';
import { startWith } from 'rxjs/operators';
import { TranslateService } from 'app/services/translate/translate.service';

type JobIdsMap = {
    [key: string]: JobInterface[];
};

type Jobs = {
    [key: string]: BehaviorSubject<JobInterface>;
};

type JobObservable = {
    obs: BehaviorSubject<JobInterface>;
    ref: number;
};

@Injectable({
    providedIn: 'root',
})
export class JobService {
    jobsIntervalObs: Record<string, Subscription>;
    jobs: Jobs = {};
    jobIds: JobIdsMap = {};
    pollingInterval = 30000;

    constructor (private backendJobRepo: BackendJobRepo, private toastService: ToastService, private translateService: TranslateService) {}

    init (): void {
        this.jobsIntervalObs = {};
        this.jobs = {};
        try {
            this.jobIds = JSON.parse(localStorage.getItem('jobIds')) || {};
        } catch (e) {
            this.jobIds = {};
        }

        this.initJobIds(this.jobIds);

        for (const type in JOB_TYPES) {
            if (JOB_TYPES[type]) {
                this.watchJobFromStorage(JOB_TYPES[type]);
            }
        }
    }

    initJobIds (obj: JobIdsMap): void {
        for (const type in JOB_TYPES) {
            if (JOB_TYPES[type] && !obj[JOB_TYPES[type]]) {
                obj[JOB_TYPES[type]] = [];
            }
        }
    }

    jobViewModelWrapper (job?: JobInterface): Job {
        return new Job(job || { jid: '', state: '' }, this.translateService);
    }

    saveJobsToStorage (): void {
        localStorage.setItem('jobIds', JSON.stringify(this.jobIds));
    }

    watchJobWithType (jobType: string): JobObservable[] {
        const result: JobObservable[] = [];
        this.jobIds[jobType].forEach((job) => {
            result.push({ obs: this.jobs[job.jid], ref: job.ref });
        });
        return result;
    }

    watchJob (job: JobInterface, type: string, entityRef: number): BehaviorSubject<JobInterface> {
        const { jid } = job;
        this.jobIds[type].push({
            jid,
            ref: entityRef,
            state: '',
        });
        this.jobs[jid] = new BehaviorSubject(job);

        const jobObservable = this.jobs[jid];
        this.jobsIntervalObs[jid] = interval(this.pollingInterval).subscribe(() => {
            return this.backendJobRepo.get(jid).then((data) => {
                jobObservable.next(data);
                if (data.state === 'completed' || data.state === 'failed') {
                    this.disposeJob(job, type);
                }
                this.handleJobResultNotification(data);
                return data;
            }, noop);
        });

        this.saveJobsToStorage();
        return jobObservable;
    }

    watchJobFromStorage (jobType: string): void {
        if (this.jobIds[jobType]) {
            this.jobIds[jobType].forEach((job) => {
                const { jid } = job;
                this.jobs[jid] = new BehaviorSubject({
                    jid,
                    state: '',
                });
                const jobObservable = this.jobs[jid];
                this.jobsIntervalObs[jid] = interval(this.pollingInterval)
                    .pipe(startWith(0))
                    .subscribe(async () => {
                        try {
                            const job = await this.backendJobRepo.get(jid);
                            jobObservable.next(job);
                            if (job.state === 'completed' || job.state === 'failed') {
                                this.disposeJob(job, jobType);
                            }
                            this.handleJobResultNotification(job);
                            return job;
                        } catch (e) {
                            return null;
                        }
                    });
                this.saveJobsToStorage();
            });
        }
    }

    disposeJob (job: JobInterface, type: string): void {
        const jobId = job.jid[job.jid.length - 1] === ':' ? job.jid.substring(0, job.jid.length - 1) : job.jid;
        const targetIndex = this.jobIds[type].findIndex((obj) => obj.jid === jobId);
        if (targetIndex > -1) {
            this.jobs[jobId].unsubscribe();
            this.jobsIntervalObs[jobId].unsubscribe();

            delete this.jobsIntervalObs[jobId];
            delete this.jobs[jobId];

            this.jobIds[type].splice(targetIndex, 1);
            this.saveJobsToStorage();
        }
    }

    handleJobResultNotification (job: JobInterface): void {
        switch (job.state) {
            case 'completed':
                this.toastService.success(this.translateService.getString('A job is completed'));
                break;
            case 'failed':
                this.toastService.error(this.translateService.getString('A job is failed'));
                break;
        }
    }
}
