<table class="table table-hover table-responsive table-fixed">
    <thead>
    <tr>
        <th class="col-sm-2 col-md-2 col-lg-2">
            <mon-table-select-item-all [(selectedItems)]="monSelectedDomains" [items]="computedDomainsArray">
            </mon-table-select-item-all>
        </th>
        <th class="col-sm-2 col-md-2 col-lg-2" translate>ID</th>
        <th translate>Domain title / URL</th>
        <th class="col-sm-5 col-md-5 col-lg-4 text-center" translate>Enabled options</th>
        <th class="col-sm-2 col-md-1 col-lg-1 text-center">&nbsp;</th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right" translate>Users</th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right">
            <span translate [translate-args]="{pagesCount: monCustomer.html_pages_count}" *ngIf="monCustomer">
                Crawled pages ([[pagesCount]])
            </span>
            <span translate *ngIf="!monCustomer">
                Crawled pages
            </span>
        </th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right" *ngIf="monCustomer">
            <span translate [translate-args]="{documentsCount: monCustomer.documents_count}">
                Crawled documents ([[documentsCount]])
            </span>
        </th>
        <th class="col-sm-4 col-md-3 col-lg-3 text-right" *ngIf="monCustomer">
            <span translate>Pages left</span>
            <span class="fa-stack fa-sm"
                  aria-hidden="true"
                  [ngbTooltip]="'Indicates the remaining no. of pages that are allowed to be scanned' | translate">
            <span class="fas fa-circle fa-stack-2x"></span>
            <span class="fas fa-question fa-stack-1x fa-inverse"></span>
            </span>
        </th>
        <th class="text-right col-sm-5 col-md-4 col-lg-4" *ngIf="monCustomer">
            <span translate [translate-args]="{totalCount: monCustomer.html_pages_count + monCustomer.documents_count}">
                Total Crawled pages and documents ([[totalCount]])
            </span>
        </th>
        <th class="col-sm-1 col-md-1 col-lg-1">&nbsp;</th>
        <th class="col-sm-4 col-md-4 col-lg-5 text-right" translate>Last scan</th>
        <th class="col-sm-9 col-md-7 col-lg-5" translate>Partner</th>
        <th class="col-sm-9 col-md-7 col-lg-5" *ngIf="monShowCustomer" translate>Customer</th>
        <th class="col-sm-5 col-md-5 col-lg-4 text-right"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let domain of computedDomainsArray" class="cursor-pointer">
        <td>
            <mon-table-select-item
                               (click)="$event.stopPropagation()"
                               class="inline-block"
                               [(selectedItems)]="monSelectedDomains"
                               [item]="domain">
            </mon-table-select-item>
        </td>
        <td class="text-left" [uiSref]="'base.backend_admin.customers.info.domain'" [uiParams]="{customer_id: domain.customer_id, domain_id: domain.id}">
            <div class="outer-r-small">{{domain.id}}</div>
            <mon-icon class="text-v-middle" [icon]="['DOMAINS', 'NOTES']" *ngIf="domain.notes" [ngbTooltip]="domain.notes"></mon-icon>
        </td>
        <td>
            <div class="d-flex flex-column text-ellipsis" [uiSref]="'base.backend_admin.customers.info.domain'" [uiParams]="{customer_id: domain.customer_id, domain_id: domain.id}">
                <div class="text-ellipsis inner-b-smurf">
                    <div class="inline-block" (click)="$event.stopPropagation()">
                        <a target="_blank" aria-describedby="linkOpensInNewTab" [attr.href]="domain.url">{{domain.title}}</a>
                    </div>
                </div>
                <div class="text-default text-ellipsis">
                    <div class="inline-block" (click)="$event.stopPropagation()">
                        <a target="_blank" aria-describedby="linkOpensInNewTab" [attr.href]="domain.url">
                            <mon-icon [icon]="['LINKS', 'EXTERNAL']"></mon-icon>
                        </a>&nbsp;<span class="text-dark-grey">{{domain.url}}</span>
                    </div>
                </div>
            </div>
        </td>
        <td class="text-v-middle text-center">
            <mon-icon
                *ngIf="domain.hasAccessibility"
                class="text-grey outer-r-smurf"
                [icon]="['DOMAINS', 'FEATURES', 'ACCESSIBILITY']"
                [ngbTooltip]="'Accessibility' | translate">
            </mon-icon>

            <mon-icon
                *ngIf="domain.hasStatistics"
                class="text-grey outer-r-smurf"
                [icon]="['DOMAINS', 'FEATURES', 'STATISTICS']"
                [ngbTooltip]="'Statistics' | translate">
            </mon-icon>

            <mon-icon
                *ngIf="domain.hasReadability"
                class="text-grey outer-r-smurf"
                [icon]="['DOMAINS', 'FEATURES', 'READABILITY_TEST']"
                [ngbTooltip]="'Readability' | translate">
            </mon-icon>

            <mon-icon
                *ngIf="domain.scan.scan_documents"
                class="text-grey outer-r-smurf"
                [icon]="['DOMAINS', 'SCAN', 'SCAN_DOCUMENTS'] "
                [ngbTooltip]="'Document scan' | translate">
            </mon-icon>

            <mon-icon
                *ngIf="domain.scan.enable_javascript"
                class="text-grey outer-r-smurf"
                [icon]="['DOMAINS', 'SCAN', 'ENABLE_JAVASCRIPT']"
                [ngbTooltip]="'Javascript crawl' | translate">
            </mon-icon>

            <mon-icon
                *ngIf="domain.scan.scan_subdomains"
                class="text-grey outer-r-smurf"
                [icon]="['DOMAINS', 'SCAN', 'SCAN_SUBDOMAIN']"
                [ngbTooltip]="'Subdomain scanning enabled' | translate">
            </mon-icon>

            <mon-icon
                *ngIf="domain.hasPageFix"
                class="text-grey outer-r-smurf"
                [icon]="['DOMAINS', 'FEATURES', 'PAGE_CORRECT']"
                [ngbTooltip]="'PageCorrect' | translate">
            </mon-icon>
        </td>
        <td class="text-center text-v-middle">
            <mon-icon
                class="text-v-middle inactive-domain outer-r-smurf"
                [icon]="['DOMAINS', 'INACTIVE']"
                *ngIf="!domain.active"
                [ngbTooltip]="'Domain is inactive' | translate">
            </mon-icon>
            <span class="text-nowrap" [ngbTooltip]="'Crawling' | translate" *ngIf="domain.crawl_status">
                <mon-icon [styles]="'text-dark-grey'" [icon]="['SPINNER', 'SYNC']" [spin]="true"></mon-icon>
                <span class="text-dark-grey" ng-show="domain.crawl_status">{{ domain.crawl_status.progress }} %</span>
            </span>
        </td>
        <td class="text-right">
            {{ domain.userCount }}
        </td>
        <td class="text-right">
            <span *ngIf="monCustomer && domain.crawl_history">
                {{ domain.crawl_history.page_count }}
            </span>
            <span *ngIf="!domain.crawl_history && domain.crawled_pages > 0">
                {{ domain.crawled_pages }}
            </span>
        </td>
        <td class="text-right" *ngIf="monCustomer">
            <span *ngIf="domain.crawl_history">
                {{ domain.crawl_history.documents_count }}
            </span>
        </td>
        <td class="text-right" *ngIf="monCustomer">
            <span *ngIf="!domain.hasMaxScannedPages" class="text-grey">
                N/A
                <span class="fa-stack fa-sm" [ngbTooltip]="'Max Pages are not defined on the Domain' | translate">
                  <span class="fas fa-circle fa-stack-2x"></span>
                  <span class="fas fa-question fa-stack-1x fa-inverse"></span>
                </span>
            </span>
            <span *ngIf="domain.hasMaxScannedPages" [ngClass]="{'text-danger': domain.pagesLeft < 0}">
                {{ domain.pagesLeft | number }}
                <span class="fa-stack fa-sm" [ngbTooltip]="'Pages scanned exceeds, max scanned pages' | translate">
                  <span class="fas fa-circle fa-stack-2x"></span>
                  <span class="fas fa-question fa-stack-1x fa-inverse"></span>
                </span>
            </span>
        </td>
        <td class="text-right" *ngIf="monCustomer">
            <span *ngIf="domain.crawl_history">
                {{ domain.crawl_history.page_count + domain.crawl_history.documents_count | number }}
            </span>
        </td>
        <td>
            <mon-icon
                *ngIf="domain.hasConstraintsOrExcludes"
                class="text-grey"
                [icon]="['DOMAINS', 'CONSTRAINTS']"
                [ngbTooltip]="'Indicates when some parts of the domain are not scanned due to Constraints / Exclusions being applied' | translate">
            </mon-icon>
        </td>
        <td class="text-right text-v-middle">
            <mon-user-date [twoLines]="true" [date]="domain.last_scan"></mon-user-date>
            <span *ngIf="!domain.last_scan" translate>Never scanned</span>
        </td>
        <td class="text-v-middle" (click)="$event.stopPropagation()">
            <a [uiSref]="'base.backend_admin.resellers.show'"
               [uiParams]="{reseller_id: domain.customer.reseller.id}"
               class="cursor-pointer"
               data-test="domain-customer-reseller-name">
                {{ domain.customer.reseller.name }}
            </a>
        </td>
        <td *ngIf="monShowCustomer" class="text-left text-v-middle text-ellipsis" (click)="$event.stopPropagation()">
            <a [uiSref]="'base.backend_admin.customers.show'"
               [uiParams]="{customer_id: domain.customer.id}"
               data-test="domain-customer-name">
                {{domain.customer.name}}
            </a>
        </td>
        <td class="text-right" (click)="$event.stopPropagation()">
            <button class="btn btn-secondary text-link" *ngIf="jobs.hasJob(domain.id)"
                    [ngbTooltip]="jobs.jobTooltip(domain.id)"
                    [triggers]="'focus mouseenter'"
                    [tooltipClass]="'text-ellipsis'">
                <span [ngClass]="jobs.jobCssClass(domain.id, '')"></span>
            </button>
            <mon-table-dropdown *ngIf="!jobs.hasJob(domain.id)">
                <li role="menuitem" data-test="domain-details-button" >
                    <a [uiSref]="'base.backend_admin.customers.info.domain'"
                       [uiParams]="{customer_id: domain.customer_id, domain_id: domain.id}">
                       <mon-icon [icon]="['ACTIONS', 'DETAILS']" [styles]="{'margin-right': '5px'}"></mon-icon>
                        <span translate>Domain details</span>
                    </a>
                </li>
                <li role="menuitem" data-test="edit-button">
                    <a (click)="onEditDomain(domain)">
                        <mon-icon [icon]="['ACTIONS', 'EDIT']" [styles]="{'margin-right': '5px'}"></mon-icon>
                        <span translate>Edit domain</span>
                    </a>
                </li>
                <li role="menuitem" data-test="domain-url-rewrite-button">
                    <a (click)="onRewriteDomainURL(domain)">
                        <mon-icon [icon]="['DOMAINS', 'REWRITE_URL']" [styles]="{'margin-right': '5px'}"></mon-icon>
                        <span translate>Domain URL Rewrite</span>
                    </a>
                </li>
                <li role="menuitem" data-test="rescan-domain-button">
                    <a [monConfirm]="'Are you sure you want to rescan this domain' | translate"
                       (monConfirmAction)="onRescan(domain)"
                       class="cursor-pointer"
                    >
                        <mon-icon [icon]="['DOMAINS', 'RESCAN']" [styles]="{'margin-right': '5px'}"></mon-icon>
                        <span translate>Rescan domain</span>
                    </a>
                </li>
                <li role="menuitem" data-test="clone-domain-button">
                    <a class="cursor-pointer" (click)="cloneDomain(domain)">
                        <mon-icon [icon]="['ACTIONS', 'CLONE']" [styles]="{'margin-right': '5px'}"></mon-icon>
                        <span translate>Clone domain</span>
                    </a>
                </li>
                <li *ngIf="domain.hasPageAssist" data-test="page-assist-button">
                    <a (click)="openPageAssist(domain)">
                        <span class="far fa-fw fa-universal-access inner-r-smurf" aria-hidden="true"></span>
                        <span translate>Open PageAssist</span>
                    </a>
                </li>
                <li *ngIf="domain.hasPageFix" data-test="page-correct-button">
                    <a (click)="openPageFix(domain)">
                        <span class="fa-fw fas fa-lock-alt inner-r-smurf" aria-hidden="true"></span>
                        <span translate>Open PageCorrect</span>
                    </a>
                </li>
                <li role="menuitem" data-test="delete-button">
                    <a monPrompt="To confirm deletion, please input the id of the domain - {{domain.title}} ( id: {{domain.id}})"
                       (monPromptAction)="deleteDomain($event)(domain)">
                        <mon-icon [icon]="['ACTIONS', 'DELETE']" [styles]="{'margin-right': '5px'}"></mon-icon>
                        <span translate>Delete domain</span>
                    </a>
                </li>
            </mon-table-dropdown>
        </td>
    </tr>
    </tbody>
</table>
