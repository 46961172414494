import { Component, Input } from '@angular/core';
import { ScansInterface } from '@monsido/modules/models/api/interfaces/scans.interface';

@Component({
    selector: 'mon-request-checker-readability-component',
    templateUrl: './readability.component.html',
})
export class ReadabilityComponent {
    @Input() url: string;
    @Input() scan: ScansInterface;
}
