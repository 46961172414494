<form name="form" class="form-horizontal" #importUsersForm="ngForm">
    <div class="mon-dialog-header">
        <mon-page-header
            [monIcon]="['USERS', 'SINGLE_ICON']"
            header="{{ 'Import users' | translate }}"
        />
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 1">
            <div class="card-header">
                <h3 class="font-weight-300">{{ 'Upload CSV' | translate }}</h3>
            </div>
            <div class="card-body">
                <mon-form-user-upload
                    [data]="data"
                    (dataChange)="onDataChange($event)"
                    [step]="step"
                    (stepChange)="onStepChange($event)"
                />
            </div>
        </div>

        <div class="accordion mx-3 mt-3" id="usersImportAccordion" *ngIf="step === 2">
            <div class="accordion-item panel-monsido-card">
                <h2 class="accordion-header card-header" id="panelsStayOpen-headingOne">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                    >
                        {{ 'Setup import' | translate }}
                    </button>
                </h2>
                <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                    data-bs-parent="#usersImportAccordion"
                >
                    <div class="accordion-body">
                        <mon-form-user-import-setup
                            [data]="data"
                            [selected]="selected"
                        />
                    </div>
                </div>
            </div>

            <div class="accordion-item panel-monsido-card mt-3">
                <h2 class="accordion-header card-header" id="panelsStayOpen-headingTwo">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                    >
                        {{ 'Select domains (is for all non-admin imported users)' | translate }}
                    </button>
                </h2>
                <div
                    id="panelsStayOpen-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                    data-bs-parent="#usersImportAccordion"
                >
                    <div class="accordion-body">
                        <mon-form-user-import-domains
                            [selected]="selected"
                            [customerId]="customerId"
                        />
                    </div>
              </div>
            </div>
        </div>

        <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 3">
            <div class="card-header">
                <h3 class="font-weight-300">{{ 'Confirm' | translate }}</h3>
            </div>
            <div class="card-body">
                <mon-user-import-confirm
                    [data]="data"
                    [selected]="selected"
                />
            </div>
        </div>
    </div>

    <div class="mon-dialog-footer text-right">
        <button
            class="btn btn-secondary"
            [disabled]="saving || importUsersForm.invalid"
            *ngIf="step === 2"
            (click)="step = 3"
        >
            {{ 'Next' | translate }}
        </button>
        <button
            class="btn btn-secondary"
            (click)="submit()"
            [disabled]="saving || importUsersForm.invalid"
            *ngIf="step === 3"
        >
            <span *ngIf="!saving">{{ 'Import users' | translate }}</span>
            <span *ngIf="saving" aria-hidden="true">
                <mon-icon [icon]="['SPINNER', 'ICON']" [spin]="true" />
            </span>
        </button>
    </div>
</form>
