<mon-form-field-input [name]="'first_name'"
                      required
                      #first_name="ngModel"
                      [(ngModel)]="user.first_name"
                      monLabel="{{ 'First name' | translate }}"
                      monPlaceholder="{{ 'First name' | translate }}"
                      [type]="'text'">
</mon-form-field-input>
<mon-form-field-errors
    [showError]="first_name.control.touched && first_name.control.invalid"
    [errors]="first_name.control.errors">
</mon-form-field-errors>

<mon-form-field-input [(ngModel)]="user.last_name"
                      monLabel="{{ 'Last name' | translate }}"
                      monPlaceholder="{{ 'Last name' | translate }}"
                      [type]="'text'"
                      [name]="'last_name'">
</mon-form-field-input>

<mon-form-field-input
    [name]="'email'"
    [(ngModel)]="user.email"
    monLabel="{{ 'Email' | translate }}"
    monPlaceholder="{{ 'Email' | translate }}"
    #email="ngModel"
    monEmailValidator
    [type]="'email'"
    required>
</mon-form-field-input>
<mon-form-field-errors
    [showError]="email.control.touched && email.control.invalid"
    [errors]="email.control.errors">
</mon-form-field-errors>

<mon-form-field-checkbox [(model)]="user.reseller_admin" monLabel="{{'Partner admin' | translate}}" [monName]="'is_Admin'"></mon-form-field-checkbox>

<ng-container *ngIf="user.id">
    <mon-form-field-input
        [(ngModel)]="user.password"
        monLabel="{{ 'Password' | translate }}"
        monPlaceholder="{{ 'Password' | translate }}"
        [type]="'password'"
        [name]="'password'">
    </mon-form-field-input>

    <mon-form-field-input
        [pattern]="regexEscape(user.password)"
        [(ngModel)]="user.password_confirmation"
        monLabel="{{ 'Password confirmation' | translate }}"
        monPlaceholder="{{ 'Password confirmation' | translate }}"
        [type]="'password'"
        #password_confirmation="ngModel"
        [name]="'password_confirmation'">
    </mon-form-field-input>
    <mon-form-field-errors
        [patternMsg]="'Passwords do not match' | translate"
        [showError]="password_confirmation.control.touched && password_confirmation.control.invalid"
        [errors]="password_confirmation.control.errors">
    </mon-form-field-errors>
</ng-container>
