import { Injectable } from '@angular/core';
import { BackendAdminApiClient, createDefaultHttpParams } from '../backend-admin-api-client';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

export type HebReport = {
    fileName: string,
    blob: Blob
}

@Injectable()
export class BackendHebReportRepo {
    constructor (private adminClient: BackendAdminApiClient) {}
    private fileNameRegex = /filename\s*=\s*['"]?([^\s'";]+)['"]?/;

    async getLatest (): Promise<HebReport> {
        const params = createDefaultHttpParams({}, new HttpHeaders({ Accept: 'application/octet-stream' }));
        params.responseType = 'blob';
        params.observe = 'response';

        const latestReport: HttpResponse<Blob> = await this.adminClient.getPromise('heb/latest', params);
        const contentDisposition = latestReport.headers.get('Content-Disposition');
        const fileName = this.fileNameRegex.exec(contentDisposition)[1] || 'latest.csv';

        return {
            fileName,
            blob: latestReport.body,
        };
    }

}
