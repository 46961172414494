import { Component, EventEmitter, Output } from '@angular/core';
import { Papa, ParseResult } from 'ngx-papaparse';
import { ImportDomainsService } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.service';

@Component({
    selector: 'mon-form-domain-upload',
    templateUrl: './upload.component.html',
})
export class FormDomainUploadComponent {
    @Output() nextFile: EventEmitter<ParseResult> = new EventEmitter<ParseResult>();

    constructor (private papa: Papa, private importDomService: ImportDomainsService) {}

    newBulkFile (event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        const file: File = element.files[0];

        if (file) {
            this.papa.parse(file, {
                complete: (result) => {
                    this.nextFile.emit(result);
                    setTimeout(() => {
                        this.importDomService.setStep(2);
                    });
                },
            });
        }
    }
}
