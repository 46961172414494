import { AccessibilitySeverityType, DomainGroupDataType } from 'types/domain';
import { DomainBaseModel } from './domain-base.model';

export class DomainGroupModel extends DomainBaseModel {
    domain_group_members: unknown[];
    description?: string;
    page_count: number;
    accessibility_checks_counters: {
        error?: number
        review?: number
        warning?: number
    };
    accessibility_checks_by_result_type_and_abbr_counters?: {
        error: AccessibilitySeverityType,
        warning: AccessibilitySeverityType,
        review: AccessibilitySeverityType
    };
    accessibility_pages_counters: {
        error?: number
        error_and_warning?: number
        error_and_warning_and_review?: number
    };
    accessibility_pages_by_result_type_and_abbr_counters?: {
        error: AccessibilitySeverityType
        error_and_warning: AccessibilitySeverityType
        error_and_warning_and_review: AccessibilitySeverityType
    };

    constructor (properties: DomainGroupDataType) {
        super(properties);
        this.domain_group_members = properties.domain_group_members;
        this.domainType = 'DomainGroup';
        this.description = properties.description;
        this.page_count = properties.page_count;
        this.accessibility_checks_counters = properties.accessibility_checks_counters;
        this.accessibility_checks_by_result_type_and_abbr_counters = properties.accessibility_checks_by_result_type_and_abbr_counters;
        this.accessibility_pages_counters = properties.accessibility_pages_counters;
        this.accessibility_pages_by_result_type_and_abbr_counters = properties.accessibility_pages_by_result_type_and_abbr_counters;
    }
}
