import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDomainScanComponent } from '@monsido/forms/domain/steps/scan/scan.component';
import { FormDomainScanLoginTypesOffice365Component } from '@monsido/forms/domain/steps/scan/login-types/office-365/office-365.component';
import { FormDomainScanLoginTypesFormComponent } from '@monsido/forms/domain/steps/scan/login-types/form/form.component';
import { FormDomainScanLoginTypesCustomMultistepComponent } from '@monsido/forms/domain/steps/scan/login-types/custom-multistep/custom-multistep.component';
import { FormDomainScanLoginTypesBasicAuthComponent } from '@monsido/forms/domain/steps/scan/login-types/basic-auth/basic-auth.component';
import { FormDomainScanInternalUrlsComponent } from '@monsido/forms/domain/steps/scan/internal-urls/internal-urls.component';
import { FormsBuilderModule, FormBuilderDynamicAcModule, LayoutModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CsvModule } from '@monsido/modules/csv/csv.module';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    declarations: [
        FormDomainScanComponent,
        FormDomainScanLoginTypesOffice365Component,
        FormDomainScanLoginTypesFormComponent,
        FormDomainScanLoginTypesCustomMultistepComponent,
        FormDomainScanLoginTypesBasicAuthComponent,
        FormDomainScanInternalUrlsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        LayoutModule,
        TranslateModule,
        FormsBuilderModule,
        PipesModule,
        NgbTooltipModule,
        NgbTimepickerModule,
        CsvModule,
        FormBuilderDynamicAcModule,
    ],
    exports: [
        FormDomainScanComponent,
        FormDomainScanLoginTypesOffice365Component,
        FormDomainScanLoginTypesFormComponent,
        FormDomainScanLoginTypesCustomMultistepComponent,
        FormDomainScanLoginTypesBasicAuthComponent,
        FormDomainScanInternalUrlsComponent,
    ],
})
export class ScanModule {}
