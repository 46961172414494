import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '../backend-admin-api-client';
import { BackendCrawlerScaleStatusInterface } from '@monsido/modules/endpoints/api/backend_admin/backend-crawler-scale-status.interface';

@Injectable()
export class BackendCrawlerRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    getAll (): Promise<unknown> {
        return this.adminClient.getPromise('crawlers/');
    }

    getScaleStatus (): Promise<BackendCrawlerScaleStatusInterface> {
        return this.adminClient.getPromise('crawlers/scale_status');
    }

    scaleUp (): Promise<unknown> {
        return this.adminClient.postPromise('crawlers/scale_up', {});
    }

    scaleDown (): Promise<unknown> {
        return this.adminClient.postPromise('crawlers/scale_down', {});
    }

    pauseCrawlers (): Promise<unknown> {
        return this.adminClient.postPromise('crawlers/pause', {});
    }

    resumeCrawlers (): Promise<unknown> {
        return this.adminClient.postPromise('crawlers/resume', {});
    }
}
