import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogContainerComponent } from './dialog-container.component';

@NgModule({
    declarations: [DialogContainerComponent],
    exports: [DialogContainerComponent],
    imports: [
        CommonModule,
    ],
})
export class DialogContainerModule { }
