<form name="userForm" #userForm="ngForm" class="form-horizontal" (ngSubmit)="userForm.valid && submit()">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']"
                         header="{{'New user' | translate }}"
                         *ngIf="!user.id">
        </mon-page-header>
        <mon-page-header [monIcon]="['USERS', 'SINGLE_ICON']"
                         header="{{'Edit user' | translate }}"
                         *ngIf="user.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>User information</h3>
            </div>
            <div class="card-body">
               <mon-form-reseller-user-basic [user]="formUser" [form]="userForm"></mon-form-reseller-user-basic>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer">
        <div class="col-md-48 text-right">
            <button class="btn btn-secondary" [disabled]="saving || userForm.invalid" type="submit" data-test="save-button">
                <span [hidden]="saving" translate>Save</span>
                <mon-icon [hidden]="!saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
            </button>
        </div>
    </div>
</form>
