<div class="row justify-content-center" >
    <div class="col-sm-48  col-md-20 ">
        <h1 class="mt-5 ">Select account</h1>
        <h2 data-test="partners-heading" class="mt-5">Partners</h2>
        <div class="mb-4" *ngIf="apiResellerEnvs.length > 0">
            <ng-container *ngFor="let env of this.resellerAgreements">
                <h3 class="mt-4">{{ env.env.name }}</h3>
                <ul class="list-group" *ngFor="let agreement of env.agreements | keyvalue">
                    <li class="list-group-item">
                        <a tabindex="0" data-test="select-partner-tab" class="object-fit-none h-100 d-inline-block w-100 py-2" (click)="selectAgreement(env.env, agreement.value)"  role="button">
                            {{ agreement.value.account.name  }}
                        </a>
                    </li>
                </ul>
            </ng-container>
        </div>
        <h2 data-test="backend-admin-heading" class="mt-5">Backend Admin</h2>
        <ul class="list-group" *ngIf="apiAdminEnvs.length > 0">
            <li class="list-group-item" *ngFor="let env of apiAdminEnvs">
                <a tabindex="0" data-test="select-environment-tab" class="object-fit-none h-100 d-inline-block w-100 py-2" (click)="selectAdminEnv(env)" role="button">
                    {{ env.name }}
                </a>
            </li>
        </ul>
    </div>
</div>
