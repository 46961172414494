import { NgModule } from '@angular/core';
import { JobService } from '@monsido/modules/job/job.service';
import { BackendJobRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-job-repo';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [TranslateModule],
    providers: [BackendJobRepo, JobService],
})
export class JobModule {}
