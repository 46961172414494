<mon-page-header
    [monIcon]="['CUSTOMERS', 'ICON']"
    header="{{'Customer:' | translate}} {{customer.name}}"
    *ngIf="customer"
/>
<mon-tabs
    [status]="activeTab"
    [options]="tabs"
    [showSearch]="false"
    (onStatus)="onTabChange($event)"
    *ngIf="customer"
>
    <div>
        <button
            class="btn btn-secondary pull-right mr-2"
            monPrompt="To confirm deletion, please input the id of the customer (id: {{customer.id}})"
            (monPromptAction)="onDeleteCustomer(customer, $event)"
            data-test="delete-customer-button"
        >
            <mon-icon [icon]="['ACTIONS', 'DELETE']" class="mr-1" />
            <span>{{ 'Delete customer' | translate }}</span>
        </button>
        <button
            class="btn btn-secondary pull-right mr-2"
            (click)="editCustomer()"
            data-test="edit-customer-button"
        >
            <mon-icon [icon]="['ACTIONS', 'EDIT']" class="mr-1" />
            <span>{{ 'Edit customer' | translate }}</span>
        </button>
    </div>
</mon-tabs>

<mon-panel [showHeader]="false" *ngIf="customer">
    <div class="card-body" slot="mon-panel-body">
        <div class="row">
            <div class="col-xs-48">
                <mon-customer-info-tab
                    *ngIf="activeTab === tabDefinitions.customer.value"
                    [customer]="customer"
                    data-test="customer-info-tab"
                    [showCustomerId]="true"
                />

                <mon-users-tab
                    *ngIf="activeTab === tabDefinitions.users.value"
                    data-test="users-tab"
                    [customer]="customer"
                    (getPageEvent)="getUsers($event)"
                    [page]="userPage"
                    [pageSize]="userPageSize"
                    [search]="userSearch"
                    [users]="users"
                    [loading]="userLoading"
                    (importUsers)="importUsers()"
                    (createUser)="createUser()"
                    (getParamsChange)="onGetUsersParamsChange($event)"
                />

                <mon-domains-tab
                    *ngIf="activeTab === tabDefinitions.domains.value"
                    [customer]="customer"
                    (getPageEvent)="getDomains($event)"
                    [page]="domainPage"
                    [pageSize]="domainPageSize"
                    [search]="domainSearch"
                    [domains]="domains"
                    [loading]="domainLoading"
                    (createDomain)="createDomain()"
                    data-test="domains-tab"
                />
            </div>
        </div>
    </div>
</mon-panel>
