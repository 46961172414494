import { Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { HeatmapsService } from '@monsido/pages/backend-admin/support/heatmaps/heatmaps.service';
import { Domain } from '@monsido/modules/models/api/domain';
import { SummaryParamsInterface } from '@monsido/pages/backend-admin/support/heatmaps/summary-params.interface';
import { HeatmapSummaryInterface } from '@monsido/modules/models/api/interfaces/heatmap-summary.interface';
import { Subscription } from 'rxjs';

interface Date {
    startDate: moment.Moment;
    endDate: moment.Moment;
}
@Component({
    selector: 'support-heatmaps-component',
    templateUrl: 'heatmaps.html',
    styleUrls: ['heatmaps.scss'],
})
export class HeatmapsComponent implements OnDestroy {
    searching: boolean = false;
    url: string;
    domain_id: number;
    date: Date;
    loading: boolean = false;
    loadingDomains = true;
    domains: Domain[] = [];
    heatmaps: HeatmapSummaryInterface[] = [];

    latestRequest: Subscription;

    constructor (private heatmapService: HeatmapsService) {
        this.date = {
            startDate: moment().subtract(8, 'days'),
            endDate: moment().subtract(1, 'days'),
        };
    }
    ngOnDestroy (): void {
        if (this.latestRequest && !this.latestRequest.closed) {
            this.latestRequest.unsubscribe();
        }
    }

    search (): void {
        this.loading = true;
        const params = {
            to: this.date.endDate.format('YYYY-MM-DD'),
            from: this.date.startDate.format('YYYY-MM-DD'),
        } as SummaryParamsInterface;

        if (this.url) {
            params.url = this.url;
        }
        const domain = this.domains[0];
        if (domain?.customer_id && domain.id) {
            this.heatmapService
                .getSummaryUrl(domain.customer_id, domain.id, params)
                .then((data: HeatmapSummaryInterface[]) => {
                    this.heatmaps = data;
                })
                .finally(() => {
                    this.loading = false;
                });
        } else {
            this.loading = false;
        }
    }

    onDomainIdUpdate (): void {
        if (this.latestRequest && !this.latestRequest.closed) {
            this.latestRequest.unsubscribe();
        }

        this.loadingDomains = true;
        this.latestRequest = this.heatmapService.getDomains$(this.domain_id?.toString()).subscribe((domains: Domain[]) => {
            this.domains = domains;
            this.loadingDomains = false;
            this.latestRequest.unsubscribe();
        });
    }

    canSearch (): boolean {
        return this.domains.length === 1;
    }
}
