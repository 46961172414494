import { Injectable } from '@angular/core';
import { BackendAdminApiClient } from '../backend-admin-api-client';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { PlainHttpParams } from '@monsido/http/params';
import { createDefaultHttpParams } from '../support-api-client';

@Injectable()
export class BackendCrawlQueueRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    get (domainId: number, params?: Record<string, string | number>): Promise<void> {
        return this.adminClient.getPromise('crawl_queue/' + domainId, { params }).then(this.toModels.bind(this));
    }

    getAll (params: Record<string, string | number>): Promise<CrawlQueue[]> {
        params = params || {};
        return this.adminClient
            .getPromise('crawl_queue/', createDefaultHttpParams(params, new HttpHeaders({ noParseSearch: 'true' })))
            .then(this.toModels.bind(this));
    }

    stopCrawl (domainId: number, params?: Record<string, string | number>): Promise<void> {
        params = params || {};
        return this.adminClient.deletePromise(`crawl_queue/${domainId}`, { params });
    }

    rejectCrawl (domainId: number, params?: Record<string, string | number>): Promise<void> {
        params = params || {};
        return this.adminClient.postPromise(`crawl_queue/${domainId}/reject`, params);
    }

    releaseCrawl (domainId: number, params?: HttpParams | { [param: string]: string | string[] }): Promise<void> {
        params = params || {};
        return this.adminClient.postPromise(`crawl_queue/${domainId}/release`, params);
    }

    support (domainId: number, params?: HttpParams | Record<string, string | unknown>): Promise<void> {
        params = params || {};
        return this.adminClient.patchPromise('crawl_queue/' + domainId, params);
    }

    startCrawl (customerId: number, domainId: number, params?: PlainHttpParams): Promise<void> {
        return this.adminClient.postPromise(`customers/${customerId}/domains/${domainId}/crawls`, createDefaultHttpParams(params));
    }

    private toModels (models: CrawlQueue[]): CrawlQueue[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }

        return models;
    }

    private toModel (model: CrawlQueue): CrawlQueue {
        return new CrawlQueue(model);
    }
}
