import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { BackendAdminCrawlQueueComponent } from './crawl-queue.component';

export const BACKEND_ADMIN_CRAWL_QUEUE_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.crawl_queue',
        url: '/crawl_queue',
        abstract: true,
        component: BaseStateComponent,
    },
    {
        name: 'base.backend_admin.crawl_queue.index',
        url: '?search?tab',
        views: {
            '^.^.^.$default': {
                component: BackendAdminCrawlQueueComponent,
            },
        },
        dynamic: true,
    },
];
