<form name="importForm" #importForm="ngForm" class="form-horizontal">
    <ng-container *ngIf='(stepObs | async) as step'>
        <div class="mon-dialog-header">
            <mon-page-header header="{{ monHeader }}"></mon-page-header>
        </div>
        <div class="mon-dialog-body-with-footer">
            <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 1">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Upload CSV</h3>
                </div>
                <div class="card-body">
                    <button type="submit" class="btn btn-secondary" (click)="fileButton.click()" translate>
                        Upload csv file
                    </button>
                    <input type="file" #fileButton id="new_bulk_csv" [attr.accept]="'.csv'" (change)="newBulkFile($event)" class="hidden">
                </div>
            </div>

            <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 2" >
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Select import</h3>
                </div>
                <div class="card-body">
                    <mon-form-field-select
                        *ngIf="step === 2"
                        [monName]="monProp"
                        [monLabel]="monSelectLabel"
                        [(model)]="selected[monProp]"
                        [monOptions]="options"
                        (changeEvent)="onChange($event)"
                        [monNameAttr]="'name'"
                        [monReturnValue]="'value'"
                        [monPlaceholder]="'Choose an option' | translate"
                    ></mon-form-field-select>
                </div>

            </div>
            <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 3">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Confirm</h3>
                </div>
                <div class="card-body">
                    {{ monConfirmText }}
                </div>
            </div>
        </div>
        <div class="mon-dialog-footer text-right">
            <button class="btn btn-secondary" [disabled]="saving || importForm.invalid" *ngIf="step === 2" translate (click)="incrementStep()">
                Next
            </button>
            <button class="btn btn-secondary" (click)='submit()' *ngIf="step === 3" data-test="import-rules-button">
                <span *ngIf="!saving" translate>Import rules</span>
                <mon-icon *ngIf="saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
            </button>
        </div>
    </ng-container>
</form>

