<div class="table-container-body">
    <table class="table table-fixed table-hover">
        <thead>
        <tr>
            <th translate>Key</th>
            <th translate class="text-right">Value</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Charset</td>
            <td class="text-right">
                {{ scan.charset }}
            </td>
        </tr>
        <tr>
            <td>Content length</td>
            <td class="text-right">
                {{ scan.content_length }}
            </td>
        </tr>
        <tr>
            <td>Content type</td>
            <td class="text-right">
                {{ scan.content_type }}
            </td>
        </tr>
        <tr>
            <td>Relation</td>
            <td class="text-right">
                {{ scan.relation }}
            </td>
        </tr>
        <tr>
            <td>Type</td>
            <td class="text-right">
                {{ scan.type }}
            </td>
        </tr>
        <tr>
            <td>Upgrade insecure</td>
            <td class="text-right">
                {{ scan.upgrade_insecure }}
            </td>
        </tr>
        </tbody>
    </table>
</div>
