import { AccessibilityCheckModel, AccessibilityCheckType } from '../models/accessibility-check-model';
import { AccessibilityCheckModelService } from '../models/accessibility-check-model.service';
import { SupportApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/support-api-client';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';

export type GetAllAccessibilityChecksParams = {
    page: number,
    page_size: number,
    search: string,
    'tags[]'?: string[],
    'responsibilities[]'?: string[],
    difficulty?: string,
    impact?: string,
}

@Injectable({
    providedIn: 'root',
})
export class BackendAccessibilityChecksRepo {
    private readonly path = 'admin/accessibility_checks';

    constructor (
        private apiClient: SupportApiClient,
        private modelService: AccessibilityCheckModelService) {

    }

    getAll (params?: GetAllAccessibilityChecksParams, headers?: HttpHeaders): Promise<CollectionInterface<AccessibilityCheckModel>> {
        return this.apiClient
            .getPromise(this.path, createDefaultHttpParams(params, headers))
            .then(data => this.toModels(data));
    }

    update (accessibilityCheck: AccessibilityCheckModel, headers?: HttpHeaders): Promise<AccessibilityCheckModel> {
        return this.apiClient
            .patchPromise(`${this.path}/${accessibilityCheck.id}`, accessibilityCheck, createDefaultHttpParams(undefined, headers))
            .then(data => {
                return this.toModel(data);
            });
    }

    protected toModels (checks: CollectionInterface<AccessibilityCheckType>): CollectionInterface<AccessibilityCheckModel> {
        for (let i = 0; i < checks.length; i++) {
            checks[i] = this.toModel(checks[i]);
        }

        return checks as CollectionInterface<AccessibilityCheckModel>;
    }

    protected toModel (check: AccessibilityCheckType): AccessibilityCheckModel {
        return this.modelService.createAccessibilityCheck(check);
    }
}
