import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { Reseller } from '@monsido/modules/models/api/interfaces/user.interface';
import { Customer } from '@monsido/modules/models/api/customer';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { FormBackendAdminCustomerComponent } from '@monsido/forms/backend_admin/customer/customer.component';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { ResellersRepo } from 'app/services/api/backend_admin/resellers-repo';
import { LayoutModule, TableModule, TabsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-partner-customers',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TableModule,
        TabsModule,
        TranslateModule,
        UIRouterModule,
    ],
    templateUrl: './partner-customers.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerCustomers extends BaseApiComponent implements OnInit {

    @Input() reseller: Reseller;

    customers: Customer[] = [];
    loading: boolean = false;

    constructor (
        urlService: UrlService,
        private monEventsService: MonEventService,
        private resellersRepo: ResellersRepo,
        private cdref: ChangeDetectorRef,
    ) {
        super(urlService);
    }

    ngOnInit (): void {
        this.customers = [];
        this.getPage();
    }

    getPage (): void {
        this.getCustomers();
    }

    createCustomer (): void {
        this.monEventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: FormBackendAdminCustomerComponent,
            data: {
                reseller: this.reseller,
                customer: {
                    settings: {
                        legacy_compliance: false,
                    },
                    subscription: {
                        plan_overrides: {},
                    },
                    eula: {},
                },
            },
            dialogOptions: {
                size: 'sm',
                cb: () => {
                    this.getPage();
                },
            },
        });
    }

    private getCustomers (): void {
        if (this.reseller) {
            const params = {
                page: this.page,
                page_size: this.pageSize,
                search: this.search,
            };

            this.loading = true;
            this.resellersRepo.getAllCustomers(this.reseller.id, params).then(
                (customers: Customer[]) => {
                    this.customers = customers;
                },
            )
                .finally(() => {
                    this.loading = false;
                    this.cdref.detectChanges();
                });
        }
    }

}
