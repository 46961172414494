/* eslint-disable guard-for-in */
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CustomerInterface, CustomerPlanOverrides, CustomerReseller } from '@monsido/modules/models/api/interfaces/customer.interface';
import { Plan } from '@monsido/modules/models/api/plan';
import { ToastService, ActiveDialog, MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { cloneDeep } from 'lodash';
import { BaseForm } from '@monsido/forms/base-form';
import { Observable, map } from 'rxjs';
import { VerticalsService } from '@monsido/pages/backend-admin/verticals/service/verticals.service';
import { VerticalsOptionsType } from '@monsido/pages/backend-admin/verticals/verticals.type';
import { ResellersRepo } from '../../../../app/services/api/backend_admin/resellers-repo';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-backend-admin-customer',
    templateUrl: 'customer.html',
})
export class FormBackendAdminCustomerComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() customer: CustomerInterface;
    @Input() monDialogId: string | number;
    @Input() reseller: CustomerReseller;
    @ViewChild('customerForm', { static: false }) customerForm: NgForm;
    model: CustomerInterface;
    onSave: boolean;
    plan: Plan;
    saveModel: CustomerInterface;
    verticalsOptions$: Observable<VerticalsOptionsType>;
    form: NgForm;

    constructor (
        private activeDialog: ActiveDialog,
        private backendResellerRepo: ResellersRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private promptService: MonPromptService,
        private verticalsService: VerticalsService,
    ) {
        super();
    }

    setPlan (plan: Plan): void {
        this.plan = plan;
        this.onSelectionChange();
    }

    ngAfterViewInit (): void {
        if (this.customerForm) {
            setTimeout(() => this.resetFormState(this.customerForm));
            this.activeDialog.setForm(this.customerForm);
        }
    }

    ngOnInit (): void {
        this.verticalsOptions$ = this.getMappedVerticalsOptionsObs();
        this.model = cloneDeep(this.customer);

        if (this.model) {
            if (this.reseller) {
                this.model.reseller = this.reseller;
            }
        }
        this.onSave = false;
        this.plan = null;
    }

    getMappedVerticalsOptionsObs (): Observable<VerticalsOptionsType> {
        const translatedVerticalsMap = this.verticalsService.getTranslatedVerticalsMap();
        return this.verticalsService.getVerticals()
            .pipe(
                map(verticals => verticals.map(vertical => {
                    return {
                        name: translatedVerticalsMap[vertical],
                        value: vertical,
                    };
                }).sort((a, b) => a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase() ? 1 : -1)),
            );
    }

    onSelectionChange (): void {
        if (this.customerForm) {
            // marking the form as touched because it does not perceive when a selection is removed;
            this.customerForm.form.markAsTouched();
        }
    }

    onSubmitClick (): void {
        if (!this.customerForm.touched) {
            this.customerForm.control.markAsPristine();
            this.close();
        } else {
            this.saveForm();
        }
    }

    /**
     * Saves the form data (AI generated).
     *
     * This method performs the following steps:
     * 1. Sets the `onSave` flag to true.
     * 2. Clones the current model to `saveModel`.
     * 3. If `plan_overrides` exist in the subscription, it sets the plan overrides.
     * 4. If the `eula` category is undefined, it deletes the `eula`.
     * 5. Determines whether to create a new customer or update an existing one based on the presence of `model.id`.
     * 6. Calls the appropriate backend method (`createCustomer` or `updateCustomer`).
     * 7. On successful save, resets the form state, shows a success toast, updates the customer data, and closes the dialog.
     * 8. Finally, sets the `onSave` flag to false.
     */
    saveForm (): void {
        this.onSave = true;

        // Customer information stored in this.model
        this.saveModel = cloneDeep(this.model);

        if (this.saveModel.subscription?.plan_overrides) {
            this.saveModel.subscription.plan_overrides = this.setPlanOverrides(this.saveModel.subscription.plan_overrides);
        }

        if (this.saveModel.eula && this.saveModel.eula.category === undefined) {
            delete this.saveModel.eula;
        }

        let promise;
        if (!this.model.id) {
            promise = this.backendResellerRepo.createCustomer(this.model.reseller.id, this.saveModel);
        } else {
            promise = this.backendResellerRepo.updateCustomer(this.model.reseller.id, this.saveModel);
        }
        promise
            .then(
                (data: CustomerInterface) => {
                    this.resetFormState(this.customerForm);
                    this.toastService.success(this.translateService.getString('Customer Saved'));
                    this.customer = data;
                    this.close(data);
                },
                () => {},
            )
            .finally(() => {
                this.onSave = false;
            });
    }

    close (customer?: CustomerInterface): void {
        this.activeDialog.close(customer);
    }

    setPlanOverrides (model: Record<string, string | string[] | number | number[] | boolean>): Record<string, string | string[] | number | number[] | boolean> {
        for (const key in model) {
            // NB! Check for null if the property was edited to empty
            if (
                (key === 'performance_tracking_allowed_frequencies' ||
                    key === 'uptime_intervals' ||
                    key === 'spell_check_engine_priority') &&
                (model[key] == null || Object.keys(model[key]).length === 0)
            ) {
                delete model[key];
                continue;
            }

            // Make sure that field is not a boolean.. or number. If value is still falsy, remove it from plan overrides
            const planKey = key as keyof CustomerPlanOverrides;
            if (model[planKey] == null || model[planKey] === '') {
                delete model[planKey];
            }
        }

        return model;
    }
}
