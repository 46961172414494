import { MonIconsService } from '../blocks/service/mon-icons.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'monIcons',
    standalone: true,
})
export class MonIconsPipe implements PipeTransform {
    constructor (private monIconsService: MonIconsService) {}

    transform (path: string[]): string {
        return this.monIconsService.getIcon(path);
    }
}
