import { Ng2StateDeclaration } from '@uirouter/angular';
import { BackendAdminDomainsComponent } from './backend-admin-domains.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const BACKEND_ADMIN_DOMAINS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.domains',
        url: '/domains',
        abstract: true,
    },
    {
        name: 'base.backend_admin.domains.index',
        url: '?search&page_size&page',
        params: RouteParamsConstant,
        views: {
            '^.^.^.$default': {
                component: BackendAdminDomainsComponent,
            },
        },
    },
];
