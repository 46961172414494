<mon-page-header [monIcon]="['DOMAINS', 'ICON']" header="{{'Domains' | translate}}">
</mon-page-header>
<mon-panel
        [minSearchLength]="1"
        [search]="search"
        [showHeader]="false"
        (onSearch)="onSearch($event)"
        [showSearch]="true" >
    <div class="card-body" slot="mon-panel-body">
        <mon-table-container [collection]="domains"
                         (pageChangeEvent)="onPageChange($event)"
                         (perPageChange)="onPageSizeChange($event)"
                         class="table-vertical-align"
                         [loading]="loading">
            <div class="table-container-body">
                <div class="table-container-top">
                    <button class="btn btn-secondary mb-1"
                            [disabled]="selectedDomains.length === 0"
                            monConfirm="{{'Are you sure you want to delete selected Domains?' | translate}}"
                            (monConfirmAction)="selectedDomains.length > 0 && deleteDomains()">
                        {{ "Delete selected domains" | translate }}
                    </button>
                </div>
                <mon-backend-admin-domains-table [monSelectedDomains]="selectedDomains"
                                            [monDomains]="domains"
                                            (monGetPageEvent)="getPage()"
                                            [monShowCustomer]="true">
                </mon-backend-admin-domains-table>
            </div>
        </mon-table-container>
    </div>
</mon-panel>
