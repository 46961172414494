import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { UsersComponent } from '@monsido/pages/admin/users/users.component';
import { RouteParamsConstant } from 'constant/route-params.constant';

export const users: Ng2StateDeclaration = {
    name: 'base.admin.users',
    url: '/users',
    abstract: true,
    component: BaseStateComponent,
};

export const usersIndex: Ng2StateDeclaration = {
    name: 'base.admin.users.index',
    url: '?search&page_size&page&plan_type',
    params: {
        ...RouteParamsConstant,
        plan_type: {
            value: 'all',
            dynamic: true,
        },
    },
    views: {
        '^.^.^.$default': {
            component: UsersComponent,
        },
    },
    dynamic: true,
};

export const USERS_STATES = [users, usersIndex];
