import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BackendPlansRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-plans.repo';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { Plan } from '@monsido/modules/models/api/plan';
import { find } from 'lodash';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'mon-form-backend-admin-customer-plan',
    templateUrl: 'plan.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class FormBackendAdminCustomerPlanComponent implements OnInit {
    isMonsidoReseller: boolean = false;
    @Input() customer: CustomerInterface;
    @Input() reseller: Record<string, string | number>;
    planOptions: Plan[];
    @Output() outputPlan = new EventEmitter<Plan>();
    dateModel: string;

    constructor (private backendPlansRepo: BackendPlansRepo) {}

    ngOnInit (): void {
        this.planOptions = [];
        if (this.customer.subscription) {
            this.dateModel = new Date(this.customer.subscription.subscription_start_at).toString();
        }
        this.isMonsidoReseller = environment.monsidoResellers.some(reseller => reseller.id === this.customer.reseller.id);
        this.getPlans();
    }

    setDate (val: string): void {
        this.customer.subscription.subscription_start_at = new Date(val).toString();
    }

    choosePlan (): void {
        const plan = this.planOptions.find(data => data.id === this.customer.subscription.plan_id);
        this.customer.subscription.plan.name = plan?.name ?? '';
    }

    getPlans (): void {
        const params: Record<string, string | number> = {
            page_size: 0,
        };
        let selectedPlan: Plan;
        if (this.reseller) {
            params.group = this.reseller.plan_group;
        }
        this.backendPlansRepo.getAll(params).then(
            (data) => {
                if (this.hasSubscription()) {
                    selectedPlan = data.find((plan) => {
                        return plan.id === this.customer.subscription.plan_id;
                    });
                }

                this.planOptions = data
                    .filter((plan: Plan) => {
                        return selectedPlan ? plan.group === selectedPlan.group : true;
                    })
                    .sort((a: Plan, b: Plan) => {
                        const aUp = a.name.toUpperCase();
                        const bUp = b.name.toUpperCase();
                        return aUp < bUp ? -1 : aUp > bUp ? 1 : 0;
                    });
                this.onSelectPlan();
            },
            () => {},
        );
    }

    onSelectPlan (): void {
        if (this.hasSubscription()) {
            const plan: Plan =
                find(this.planOptions, {
                    id: this.customer.subscription.plan_id,
                }) || null;
            this.outputPlan.emit(plan);
        }
    }

    hasSubscription (): boolean {
        return this.customer && this.customer.subscription && this.customer.subscription.plan_id > 0;
    }
}
