import { NgModule } from '@angular/core';
import { SupportHeatmapsModule } from '@monsido/pages/backend-admin/support/heatmaps/heatmaps.module';
import { UIRouterModule } from '@uirouter/angular';
import { SUPPORT_STATES } from '@monsido/pages/backend-admin/support/support.route';
import { SearchModule } from '@monsido/pages/backend-admin/support/search/search.module';
import { RequestCheckerModule } from '@monsido/pages/backend-admin/support/request-checker/request-checker.module';

@NgModule({
    imports: [
        RequestCheckerModule,
        SearchModule,
        SupportHeatmapsModule,
        UIRouterModule.forChild({ states: SUPPORT_STATES }),
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class SupportModule {}
