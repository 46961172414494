import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { ControlContainer, NgForm } from '@angular/forms';
import { BackendAdminUserForm } from '../user-form';
import { SessionService } from '@monsido/core/session/session.service';
import { LANGUAGE_CONSTANT } from '@monsido/core/constants/languages.constant';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';
import { StringService } from '@monsido/tools/string.service';

@Component({
    selector: 'mon-form-backend-admin-customer-user-basic',
    templateUrl: 'basic.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormBackendAdminCustomerUserBasicComponent implements OnInit {
    @Input() form: NgForm;

    private userValue: User;
    @Output() userChange = new EventEmitter();
    @Input()
    get user (): BackendAdminUserForm {
        return this.userValue;
    }

    set user (val: BackendAdminUserForm) {
        this.userValue = val;
        this.userChange.emit(this.userValue);
    }

    isAdmin: boolean = false;
    supportedLanguages: LanguageInterface[] = [];

    constructor (private sessionService: SessionService) {
        this.supportedLanguages = [LANGUAGE_CONSTANT.en, LANGUAGE_CONSTANT.da];
    }

    async ngOnInit (): Promise<void> {
        this.user.send_welcome_email = true;

        if (!this.user.locale) {
            this.user.locale = this.supportedLanguages[0].code;
        }
        if (this.sessionService.isBackendAdmin()) {
            this.isAdmin = true;
        }
    }

    regexEscape (input: string | number): string {
        return StringService.escapeRegExp(input);
    }
}
