import { Injectable } from '@angular/core';
import { BackendAdminApiClient, createDefaultHttpParams } from '../backend-admin-api-client';
import { Plan } from '@monsido/modules/models/api/plan';

@Injectable()
export class BackendPlansRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    get (id: number, params: Record<string, string | number>): Promise<Plan> {
        return this.adminClient.getPromise(`plans/${id}`, createDefaultHttpParams(params));
    }

    getAll (params: Record<string, string | number>): Promise<Plan[]> {
        params = params || ({} as Record<string, string | number>);
        return this.adminClient.getPromise('plans/', { params });
    }

    update (plan: Plan): Promise<void> {
        return this.adminClient.patchPromise(`plans/${plan.id}`, plan);
    }

    create (plan: Plan): Promise<void> {
        return this.adminClient.postPromise('plans', plan);
    }

    destroy (planId: number): Promise<void> {
        return this.adminClient.deletePromise(`plans/${planId}`);
    }
}
