/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { BackendAdminApiClient } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { OauthService } from '@monsido/oauth/oauth.service';
import { SupportApiClient } from '@monsido/modules/endpoints/api/support-api-client';
import { SessionService } from '@monsido/core/session/session.service';
import { environment } from '../../../environments/environment';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { CoreAgreementService } from '@monsido/core/session/services/agreement.service';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { LocalStorageService } from '@monsido/core/storage/local-storage.service';
import { MonHttpClientService } from '@monsido/http/http-client.service';

@Injectable()
export class LoaderService {
    constructor (
        private monEventsService: MonEventService,
        private apiClient: ApiClient,
        private backendAdminApiClient: BackendAdminApiClient,
        private supportApiClient: SupportApiClient,
        private oauthService: OauthService,
        private sessionService: SessionService,
        private coreAgreementService: CoreAgreementService,
        private localStorageService: LocalStorageService,
        private monHttpClientService: MonHttpClientService,
    ) {}

    initializeApp (): Promise<any> {
        return new Promise<any>((resolve: any) => {
            this.monEventsService.addListener('setAPI', () => {
                this.apiClient.setUrlPrefix(this.sessionService.getSelectedApi() + '/reseller/api/');
                this.backendAdminApiClient.setUrlPrefix(this.sessionService.getSelectedApi() + '/admin/api/');
            });

            this.monEventsService.addListener('beforeLoadUser', () => {
                this.apiClient.setHeader('Authorization', this.oauthService.getTokenHeaderValue());
                this.apiClient.setUrlPrefix(this.sessionService.getSelectedApi() + '/reseller/api/');
                this.backendAdminApiClient.setHeader('Authorization', this.oauthService.getTokenHeaderValue());
                this.backendAdminApiClient.setUrlPrefix(this.sessionService.getSelectedApi() + '/admin/api/');
                this.supportApiClient.setHeader('Authorization', this.oauthService.getTokenHeaderValue());
                this.supportApiClient.setUrlPrefix(environment.supportPath);
            });

            this.monEventsService.addListener('newAccessToken', () => {
                this.apiClient.setHeader('Authorization', this.oauthService.getTokenHeaderValue());
                this.backendAdminApiClient.setHeader('Authorization', this.oauthService.getTokenHeaderValue());
                this.supportApiClient.setHeader('Authorization', this.oauthService.getTokenHeaderValue());
            });

            this.monEventsService.addListener('exitAccount', (index: number) => {
                this.coreAgreementService.clearAgreement();
                return index;
            });

            this.monEventsService.addListener('logout', (index: number) => {
                if (this.apiClient.config.headers) {
                    this.apiClient.config.headers.delete('Authorization');
                    this.apiClient.config.headers.delete('Monsido-Account-Id');
                }
                if (this.backendAdminApiClient.config.headers) {
                    this.backendAdminApiClient.config.headers.delete('Authorization');
                    this.backendAdminApiClient.config.headers.delete('Monsido-Account-Id');
                }

                if (this.supportApiClient.config.headers) {
                    this.supportApiClient.config.headers.delete('Authorization');
                    this.supportApiClient.config.headers.delete('Monsido-Account-Id');
                }
                this.monHttpClientService.cancelRequests();
                return index;
            });

            this.monEventsService.addListener('setAgreement', (agreement: Agreement, index: number) => {
                const roles: string[] = [];
                this.apiClient.setHeader('Monsido-Account-Id', agreement.account.id.toString());
                roles.push(agreement.account.type.toLowerCase());
                if (agreement.admin) {
                    roles.push(agreement.account.type.toLowerCase() + '_admin');
                }
                this.sessionService.roles = roles;
                this.localStorageService.set('roles', roles.toString());
                return index;
            });


            return resolve();
        });
    }
}
